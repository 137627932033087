import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function signUpReducer(state = initialState.signUpState, action) {
    switch (action.type) {
        case actionTypes.SignUp.SIGN_UP_SET_CREDENTIALS.start:
            return { ...state, isFetching: true, step: 1 };

        case actionTypes.SignUp.SIGN_UP_SET_CREDENTIALS.success:
            return {
                ...state,
                isFetching: false,
                accountId: action.accountId,
                error: null,
                step: 2,
            };

        case actionTypes.SignUp.SIGN_UP_SET_CREDENTIALS.failure:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                step: 1,
            };

        case actionTypes.SignUp.SIGN_UP_SET_APIKEY.start:
            return { ...state, isFetching: true, step: 2 };

        case actionTypes.SignUp.SIGN_UP_SET_APIKEY.success:
            return {
                ...state,
                isFetching: false,
                error: null,
                step: 3,
            };

        case actionTypes.SignUp.SIGN_UP_SET_APIKEY.failure:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                step: 2,
            };

        case actionTypes.SignUp.SIGN_UP_ACCEPT_INVITATION.start:
            return { ...state, isFetching: true };

        case actionTypes.SignUp.SIGN_UP_ACCEPT_INVITATION.success:
            return { ...state, isFetching: false, error: null };

        case actionTypes.SignUp.SIGN_UP_ACCEPT_INVITATION.failure:
            return { ...state, isFetching: false, error: action.error };

        default:
            return state;
    }
}
