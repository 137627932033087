import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({ onClick }) =>
    (
        <svg className="cursor-pointer ml-2" onClick={onClick} onKeyDown={onClick} width="25" height="22" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable max-len */ }
            <path d="M20.566 8.98242C20.566 9.61523 20.0303 10.1109 19.4231 10.1109H18.2801L18.3051 15.743C18.3051 15.8379 18.298 15.9328 18.2873 16.0277V16.5938C18.2873 17.3707 17.6479 18 16.8586 18H16.2871C16.2478 18 16.2085 18 16.1692 17.9965C16.1192 18 16.0692 18 16.0192 18H14.8584H14.0012C13.2118 18 12.5725 17.3707 12.5725 16.5938V15.75V13.5C12.5725 12.8777 12.0617 12.375 11.4295 12.375H9.14363C8.51144 12.375 8.00068 12.8777 8.00068 13.5V15.75V16.5938C8.00068 17.3707 7.36134 18 6.57199 18H5.71477H4.57539C4.52181 18 4.46824 17.9965 4.41466 17.993C4.3718 17.9965 4.32894 18 4.28608 18H3.7146C2.92525 18 2.28591 17.3707 2.28591 16.5938V12.6562C2.28591 12.6246 2.28591 12.5895 2.28948 12.5578V10.1109H1.14295C0.500043 10.1109 0 9.61875 0 8.98242C0 8.66602 0.107152 8.38477 0.357173 8.13867L9.51509 0.28125C9.76512 0.0351562 10.0509 0 10.3009 0C10.5509 0 10.8366 0.0703125 11.0509 0.246094L20.1731 8.13867C20.4589 8.38477 20.6018 8.66602 20.566 8.98242Z" fill="white" />
        </svg>
    );

export default HomeIcon;

HomeIcon.propTypes =
  {
      onClick: PropTypes.func,
  };

HomeIcon.defaultProps =
  {
      onClick: () => {},
  };
