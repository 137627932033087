export const openWidget = () => window.Forethought('widget', 'open');

export const closeWidget = () => window.Forethought('widget', 'close');

export const hideWidget = () => window.Forethought('widget', 'hide');

export const showWidget = () => window.Forethought('widget', 'show');

export const onWidgetEvent = (eventType, callback) => {
    window.addEventListener('message', (event) => {
        if (event.data.event === eventType) {
            callback(event.data);
        }
    });
};

export const onWidgetOpen = (callback) => {
    onWidgetEvent('forethoughtWidgetOpened', callback);
};

export const onWidgetClose = (callback) => {
    onWidgetEvent('forethoughtWidgetClosed', callback);
};

export const onHandoff = (callback) => {
    onWidgetEvent('forethoughtWidgetIntegrationHandoffEvent', callback);
};
