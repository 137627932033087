import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useL10nContext } from 'wa.admin-ui.l10n';
import { useSectionContext } from '../../../entities/help-widget/context/section-context-provider';
import { useAssociationContext } from '../../../entities/help-widget/context/association-context-provider';
import sectionKeys from '../../../constants/section-keys';

import Header from './components/header';
import ServiceNotice from './sections/components/service-notice';
import BookACall from './sections/components/book-a-call';
import HelpSection from './components/help-section';

import useRenderSection from './hooks/use-render-section';
import useCollapseHeader from './hooks/use-collapse-header';

const HelpCenterWidget = () => {
    const { labels } = useL10nContext();
    const { isTrial } = useAssociationContext();
    const { section, serviceNotice } = useSectionContext();
    const [renderSection] = useRenderSection();

    // Collapse header when scrolled
    const collapseRef = useRef();
    const [collapsed] = useCollapseHeader(collapseRef);

    const isHome = section.key === sectionKeys.SECTION_HOME;
    const isElevio = section.key === sectionKeys.SECTION_ELEVIO;

    // Handle search input movement
    const [searchActive, setActive] = useState(false);

    useEffect(() => {
        const inputElement = document.querySelector('#_elev_io_placebo input');

        const handleInputChange = () => {
            setActive(true);
        };

        const handleClickOutside = (event) => {
            if (inputElement === event.target) {
                setActive(true);
            } else if (inputElement?.value?.trim().length === 0) {
                setActive(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        inputElement?.addEventListener('input', handleInputChange);

        return () => {
            inputElement?.removeEventListener('input', handleInputChange);
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Card className="-wa-help-center__main-card h-100 rounded-0 p-0">
                <Header
                    title={isTrial ? labels.TRIAL.HEADER : labels.PAID.HEADER}
                    description={isTrial ? labels.TRIAL.SUB_HEADER : labels.PAID.SUB_HEADER}
                    isHome={isHome}
                    collapsed={collapsed}
                />
                {serviceNotice && isHome &&
          (<ServiceNotice />)}
                <div className={(!searchActive && isHome && isTrial) ? null : 'd-none'}>
                    <HelpSection><BookACall /></HelpSection>
                </div>
                <div
                    // eslint-disable-next-line max-len
                    className={`${isElevio || isHome ? 'd-block' : 'd-none'} p-4 -wa-help-center__elevio-search -wa-help-center-z3`}
                >
                    {/* eslint-disable-next-line max-len */}
                    <elevio-element data-prevent-open-assistant="true" data-type="search" data-source-name="elevio-search" />
                </div>
                <div className={(searchActive && isHome && isTrial) ? null : 'd-none'}>
                    <HelpSection><BookACall /></HelpSection>
                </div>
                <Card.Body ref={collapseRef} className="-wa-help-center__body overflow-auto p-0">
                    {renderSection(section.key)}
                </Card.Body>
            </Card>
        </>
    );
};

export default HelpCenterWidget;
