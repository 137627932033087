import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import NotFoundPage from './NotFoundPage';
import { AuthWAPage } from './AuthWAPage';
import CampaignsHomePage from './Campaigns/CampaignsHomePage';
import NewCampaignTemplatePage from './Campaigns/NewCampaignTemplatePage';
import MainNavbar from './MainNavbar';
import CampaignDetailsPage from './Campaigns/CampaignDetailsPage';
import PathParamsBinder from './PathParamsBinder';
import EditCampaignPage from './Campaigns/EditCampaignPage';
import BillingDashboard from './Billing/BillingDashboard';
import SettingsPage from './Settings/SettingsPage';
import SettingsProfilePage from './Settings/SettingsProfilePage';
import SettingsWAIntegrationPage from './Settings/SettingsWAIntegrationPage';
import SubscriptionSettingsPage from './Settings/SubscriptionSettingsPage';
import TextReplySettings from './Settings/TextReplySettings/src/TextReplySettings';
import HelpCenter from './HelpCenter';
import Gainsight from './Gainsight';
import AuthRequired from './AuthRequired';
import GlobalNotification from './GlobalNotification/src/GlobalNotification';
import ConversationsListPage from './Conversations/ConversationsListPage';
import ConversationPage from './Conversations/ConversationPage';
import QueryParamsBinder from './QueryParamsBinder';
import StartSubscriptionPage from './Billing/StartSubscriptionPage';
import UpdateCardPage from './Billing/UpdateCardPage';
import SignUpWAPage from './SignUpWaPage';
import SubscriptionWizard from './SubscriptionWizard';
import ZendeskWidget from './ZendeskWidget';

const App = () =>
    (
        <div className="wrapper">
            <nav id="sidebar">
                <MainNavbar />
            </nav>
            <div id="content">
                <GlobalNotification />
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/campaigns" />
                    </Route>

                    <Route exact path="/campaigns">
                        <AuthRequired>
                            <CampaignsHomePage />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/campaigns/new">
                        <AuthRequired>
                            <PathParamsBinder>
                                <EditCampaignPage />
                            </PathParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/campaigns/new-from-template">
                        <AuthRequired>
                            <NewCampaignTemplatePage />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/campaigns/view/:campaignId">
                        <AuthRequired>
                            <PathParamsBinder>
                                <CampaignDetailsPage />
                            </PathParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/campaigns/edit/:campaignId">
                        <AuthRequired>
                            <PathParamsBinder>
                                <EditCampaignPage />
                            </PathParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/conversations">
                        <AuthRequired>
                            <QueryParamsBinder>
                                <ConversationsListPage />
                            </QueryParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/conversation/:recipientType/:recipientId">
                        <AuthRequired>
                            <PathParamsBinder>
                                <ConversationPage />
                            </PathParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/billing">
                        <AuthRequired>
                            <BillingDashboard />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings">
                        <AuthRequired>
                            <SettingsPage />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings/account-details">
                        <AuthRequired>
                            <SettingsProfilePage />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings/integration-settings">
                        <AuthRequired>
                            <SettingsWAIntegrationPage />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings/subscription">
                        <AuthRequired>
                            <SubscriptionSettingsPage />
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings/text-reply-settings">
                        <AuthRequired>
                            <QueryParamsBinder>
                                <TextReplySettings />
                            </QueryParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings/subscription/start">
                        <AuthRequired>
                            <QueryParamsBinder>
                                <StartSubscriptionPage />
                            </QueryParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/settings/subscription/update">
                        <AuthRequired>
                            <QueryParamsBinder>
                                <UpdateCardPage />
                            </QueryParamsBinder>
                        </AuthRequired>
                    </Route>

                    <Route exact path="/wa-signup">
                        <AuthRequired>
                            <SignUpWAPage />
                        </AuthRequired>
                    </Route>
                    <Route exact path="/subscription-wizard">
                        <AuthRequired>
                            <SubscriptionWizard />
                        </AuthRequired>
                    </Route>

                    <Route path="/create" component={AuthWAPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
            <HelpCenter useForethought={true} />
            <Gainsight />
            <ZendeskWidget />
        </div>
    );

export default App;
