import React from 'react';
import propTypes from 'prop-types';
import TwilioSubscriptionForm from '../../../../../TwilioSubscriptionForm/TwilioSubscriptionForm';

const StepContactInformation = ({ onNext, onPrev }) => {
    return (
        <TwilioSubscriptionForm 
            onNext={onNext}             
            onPrev={onPrev}
            isSettings={false}
            isReadOnly={false}
        />
    );
};

StepContactInformation.propTypes = {
    onNext: propTypes.func.isRequired,
    onPrev: propTypes.func.isRequired,
};

export default StepContactInformation;
