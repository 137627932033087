import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    Row, Col, OverlayTrigger, Button, Popover,
} from 'react-bootstrap';
import * as messagesActionCreators from '../../actions/messagesActions';
import * as recipientsActionCreators from '../../actions/recipientsActions';
import RecipientsListControl from '../Campaigns/BuildingBlocks/RecipientsListControl';
import formUtils from '../../utils/formUtils';
import recipientsTypes from '../../types/recipientsTypes';
import RecipientsList from '../Recipients/RecipientsList';

class ContactsConversations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: '+phone',
        };

        this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
        this.setSearchString = this.setSearchString.bind(this);
        this.renderSearchHint = this.renderSearchHint.bind(this);
        this.onRecipientsListChange = this.onRecipientsListChange.bind(this);
        this.onSearchRecipients = this.onSearchRecipients.bind(this);
        this.searchInput = React.createRef();
    }

    componentDidMount() {
        this.props.fetchRecipients(this.props.recipientsListId, this.props.searchString, true);
        this.setState({ searchString: this.props.searchString });
        this.searchInput.current.focus();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.recipientsListId !== prevProps.recipientsListId
            || this.props.searchString !== prevProps.searchString
        ) {
            this.props.fetchRecipients(this.props.recipientsListId, this.props.searchString, true);
        }
    }

    onRecipientsListChange(event) {
        if (event.target.name === 'recipientsListName') return;

        const stateUpdate = formUtils.inputChangeToStateUpdate(event);
        this.props.onContactFilterChanged(
            stateUpdate.recipientsGroupType || this.props.recipientsGroupType,
            stateUpdate.recipientsListId || this.props.recipientsListId,
            this.state.searchString,
        );
    }

    onSearchRecipients() {
        this.props.onContactFilterChanged(
            this.props.recipientsGroupType,
            this.props.recipientsListId,
            this.state.searchString,
        );
    }

    onSearchKeyPress(e) {
        if (e.key === 'Enter') {
            this.props.onContactFilterChanged(
                this.props.recipientsGroupType,
                this.props.recipientsListId,
                this.state.searchString,
            );
        }
    }

    setSearchString(e) {
        this.setState({ searchString: e.target.value });
    }

    // eslint-disable-next-line class-methods-use-this
    renderSearchHint() {
        return (
            <Popover style={{ width: 400 }}>
                <Popover.Content>
                    <p>
                        <b>+phone</b>
                        {' '}
                        filter recipients with phones;
                        <br />
                        <b>-phone</b>
                        {' '}
                        filter recipients w/o phone.
                        <br />
                        Example:
                        {' '}
                        <b>+phone Jack</b>
                        {' '}
                        will show all recipients with name Jack who has a phone
                    </p>
                </Popover.Content>
            </Popover>
        );
    }

    render() {
        const contactsContainers = this.props.contactsContainers[this.props.recipientsListId] || {};
        const recipients = contactsContainers.items || [];
        const threshold = 30;
        return (
            <div>
                <div className="mb-3">

                    <div>
                        <RecipientsListControl
                            groupType={this.props.recipientsGroupType}
                            recipientsListId={this.props.recipientsListId}
                            onFieldValChange={this.onRecipientsListChange}
                            estimateNumberOfContacts={false}
                        />
                    </div>
                    <Row noGutters>
                        <Col xs="10" className="pr-3">

                            <OverlayTrigger
                                style={{ zIndex: 1200, width: 400 }}
                                placement="bottom"
                                trigger="hover"
                                overlay={this.renderSearchHint()}
                            >
                                <div>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="mt-2 ml-3 position-absolute"
                                        style={{ opacity: 0.5 }}
                                    />
                                    <input
                                        name="searchText"
                                        ref={this.searchInput}
                                        onKeyPress={this.onSearchKeyPress}
                                        onChange={this.setSearchString}
                                        maxLength={40}
                                        type="text"
                                        className="pl-5 mimicFormControl"
                                        placeholder="Search"
                                        value={this.state.searchString}
                                    />
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col xs="2" className="d-flex justify-content-end text-nowrap">
                            <Button type="button" variant="outline-primary" block onClick={this.onSearchRecipients}>
                                <FontAwesomeIcon icon={faSearch} className="mr-2 d-sm-none d-md-inline-block" />
                                Search
                            </Button>
                        </Col>
                    </Row>
                </div>
                <RecipientsList
                    isFetching={contactsContainers.isFetching}
                    recipients={recipients}
                    onOpenRecipient={this.props.onOpenRecipient}
                    maxNumber={threshold}
                />

            </div>
        );
    }
}

ContactsConversations.propTypes = {
    // eslint-disable-next-line react/require-default-props
    contactsContainers: recipientsTypes.contactsContainers,
    fetchRecipients: propTypes.func.isRequired,

    onOpenRecipient: propTypes.func.isRequired,
    onContactFilterChanged: propTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    recipientsGroupType: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientsListId: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    searchString: propTypes.string,
};

function mapStateToProps(state) {
    return {
        contactsContainers: state.recipients.contacts,
    };
}

function mapDispatchToProps(dispatch) {
    const recipientActions = bindActionCreators(recipientsActionCreators, dispatch);
    const messagesActions = bindActionCreators(messagesActionCreators, dispatch);

    return {
        fetchRecipients: recipientActions.fetchRecipients,
        fetchRecipientMessages: messagesActions.fetchRecipientMessages,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContactsConversations);
