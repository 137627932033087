module.exports = {
    // account does not have active subscription
    unsubscribed: 'unsubscribed',

    // account has a subscription, WA API key and twilio subsccount
    active: 'active',

    // account was suspended, eg because of some suspicious activity
    suspended: 'suspended',

    // account was deleted
    deleted: 'deleted',
};
