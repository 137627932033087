/* istanbul ignore file */
import { legacyAddTag, messagingAddTag } from './helpers/add-tag';
import {
    getAccountId,
    getBillingPlanName,
    getAccountAgeDays,
    getPaymentGateway,
    getUserRoles,
    isTrialAccount, isFromTNS,
} from '../../selectors';
import { ageTags, userTags, paymentSystemTags } from './tags';

// eslint-disable-next-line import/no-anonymous-default-export
export default (accountInfo, accountInfoFinancialInfo, useMessaging) => {
    const addTag = useMessaging ? messagingAddTag : legacyAddTag;
    const tags = [];
    const isTNS = isFromTNS(accountInfo);
    const isPaid = !isTrialAccount(accountInfo);
    const accountAge = getAccountAgeDays(accountInfo);
    const userRoles = getUserRoles(accountInfo);
    const paymentGateway = getPaymentGateway(accountInfoFinancialInfo);

    tags.push(`AccountID ${getAccountId(accountInfo)}`, `BillingPlan ${getBillingPlanName(accountInfo)}`);

    // Check if chat is intiatied from TNS
    if (isTNS) {
        tags.push('TNS');
    }

    if (isPaid) {
        tags.push('paid');
    }

    // Account age tags
    if (accountAge <= 30) {
        tags.push('NewAccount');
    }


    // TODO: Mike include all other fields when public API is extended for TNS
    if (isTNS) {
        addTag(tags);
    } else {
        const age = ageTags(accountAge);
        const user = userTags(userRoles);
        const payments = paymentSystemTags(paymentGateway);

        const result = [...tags, ...age, ...user, ...payments];

        addTag(result);
    }
};
