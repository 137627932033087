import React from 'react';
import { Card } from 'react-bootstrap';
import AuthorizationRequired from '../AuthorizationRequired';
import './SettingsPage.css';
import IntegrationOptions from './IntegrationOptions/IntegrationOptionsContainer';

function SettingsWAIntegrationPage() {
    return (
        <div className="mainContent mb-5 pb-5">
            <AuthorizationRequired />
            <h1 className="fixedHeader">WildApricot integration settings</h1>

            <Card className="settingCard mt-5">
                <Card.Header>Integration options</Card.Header>
                <Card.Body>
                    <IntegrationOptions />
                </Card.Body>
            </Card>
        </div>
    );
}

export default SettingsWAIntegrationPage;
