import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

class SendMessageControl extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            message: '',
        };
        this.onSendMessage = this.onSendMessage.bind(this);
        this.setMessageString = this.setMessageString.bind(this);
    }

    onSendMessage(e) {
        if (!this.state.message) return;
        if (e.key === 'Enter' || e.type === 'click') {
            this.props.sendMessage(this.state.message);
            this.setState({ message: '' });
        }
    }

    setMessageString(e) {
        this.setState({ message: e.target.value });
    }

    render() {
        return (
            <div className="sendTestInputContainer">
                <input
                    type="text"
                    maxLength={150}
                    placeholder="Write a message..."
                    value={this.state.message}
                    onKeyPress={this.onSendMessage}
                    onChange={this.setMessageString}
                />
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    size="lg"
                    className="mt-2 ml-1 text-info"
                    onClick={this.onSendMessage}
                    style={{ opacity: this.state.message ? 1 : 0.5 }}
                />
            </div>
        );
    }
}

SendMessageControl.propTypes = {
    sendMessage: propTypes.func.isRequired,
};

export default SendMessageControl;
