import { push } from 'connected-react-router';
import actionTypes from '../constants/actionTypes';
import signUpService from '../apiServices/signUpService';
import loginService from '../apiServices/loginService';
import settingsService from '../apiServices/settingsService';
import authStorage from '../utils/authStorage';
import { fetchIntegrationOptions } from './settingsActions';

// eslint-disable-next-line consistent-return
export const routeToNextRequiredStep = () => async (dispatch) => {
    // eslint-disable-next-line import/no-named-as-default-member
    const { account } = await loginService.getMe();

    if (account) {
        if (account.apiKeyStatus !== 'valid') {
            return dispatch(push('/signup/apikey'));
        }
    } else {
        authStorage.clear();
    }

    dispatch(push('/'));
};

export const signUpSetCredentials = (email, password, fullName) => async (dispatch) => {
    dispatch({
        type: actionTypes.SignUp.SIGN_UP_SET_CREDENTIALS.start,
        email,
        password,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await signUpService.registerUserAndAccount(email, password, fullName);

    if (result.error) {
        dispatch({
            type: actionTypes.SignUp.SIGN_UP_SET_CREDENTIALS.failure,
            error: result.error,
        });
    } else {
        authStorage.setState(result.token, Date.now() + 1000 * 60 * 60 * 24, result.accountId);

        dispatch({
            type: actionTypes.SignUp.SIGN_UP_SET_CREDENTIALS.success,
        });

        dispatch({
            type: actionTypes.Auth.SIGN_IN.success,
            accountId: result.accountId,
            accessToken: result.token,
        });

        dispatch(push('/signup/apikey'));
    }
};

export const signUpSetApiKey = (apiKey) => async (dispatch) => {
    dispatch({
        type: actionTypes.SignUp.SIGN_UP_SET_APIKEY.start,
        apiKey,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.updateApiKey(apiKey);

    if (result.error) {
        dispatch({
            type: actionTypes.SignUp.SIGN_UP_SET_APIKEY.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.SignUp.SIGN_UP_SET_APIKEY.success,
        });

        dispatch(push('/signup/integration-options'));
    }
};

export const signUpCancel = () => (dispatch) => {
    dispatch(push('/'));
};

export const completeSignup = () => (dispatch) => {
    dispatch(push('/campaigns'));
};

export const signUpSaveIntegrationOptions = (integrationOptions) => async (dispatch) => {
    dispatch({
        type: actionTypes.SignUp.SIGN_UP_SET_INTEGRATION_OPTIONS.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.saveIntegrationOptions(integrationOptions);

    if (result.error) {
        dispatch({
            type: actionTypes.SignUp.SIGN_UP_SET_INTEGRATION_OPTIONS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.SignUp.SIGN_UP_SET_INTEGRATION_OPTIONS.success,
            integrationOptions,
        });
        dispatch(fetchIntegrationOptions()).then(dispatch(push('/campaigns')));
    }
};

export const acceptInvitation = (invitationToken, email, password) => async (dispatch) => {
    dispatch({
        type: actionTypes.SignUp.SIGN_UP_ACCEPT_INVITATION.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await signUpService.acceptInvitation(invitationToken, email, password);

    if (result.error) {
        dispatch({
            type: actionTypes.SignUp.SIGN_UP_ACCEPT_INVITATION.failure,
            error: result.error,
        });
    } else {
        authStorage.setState(result.token, Date.now() + 1000 * 60 * 60 * 24, result.accountId);

        dispatch({
            type: actionTypes.SignUp.SIGN_UP_ACCEPT_INVITATION.success,
        });

        dispatch({
            type: actionTypes.Auth.SIGN_IN.success,
            accountId: result.accountId,
            accessToken: result.token,
        });

        dispatch(push('/'));
    }
};


const signupActions = {
    signUpSetCredentials,
    signUpSetApiKey,
    signUpCancel,
    acceptInvitation,
};

export default signupActions;
