import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import qs from 'query-string';
import * as actionCreators from '../../actions/textCampaignsActions';

import AuthorizationRequired from '../AuthorizationRequired';
import EditCampaignForm from './EditCampaignForm';
import formUtils from '../../utils/formUtils';
import * as types from '../../types';
import LoadingIndicator from '../LoadingIndicator';
import './Campaigns.css';
import SendTestDialog from './SendTestDialog';
import SubscriptionForm from '../Billing/SubscriptionForm';
import ActionForbiddenAlert from '../ActionForbiddenAlert';
import { getIsBillingPlanProhibited } from '../../reducers/authReducer';

class EditCampaignPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            sendTestRequested: false,
        };

        this.onFieldValChange = this.onFieldValChange.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.sendTestMessage = this.sendTestMessage.bind(this);
        this.onSendTestClose = this.onSendTestClose.bind(this);
        this.renderSubscriptionForm = this.renderSubscriptionForm.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.confirmSubscription = this.confirmSubscription.bind(this);
        this.isSubscriptionRequired = this.isSubscriptionRequired.bind(this);
        this.isTestDialogVisible = this.isTestDialogVisible.bind(this);
        this.hideForbiddenAlert = this.hideForbiddenAlert.bind(this);
    }

    componentDidMount() {
        const campaignToFetch = this.props.campaignId || this.props.cloneCampaignId;

        if (campaignToFetch) {
            this.props.actions.fetchCampaign(campaignToFetch);
        } else {
            try {
                const defaults = JSON.parse(this.props.campaignDefaults || '{}');
                this.props.actions.initDefaultCampaign(defaults);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Failed to parse campaign defaults');
            }
        }

        this.props.actions.fetchSubscriptionStatus();
    }

    handleBack(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.actions.back();
    }

    onFieldValChange(event) {
        const stateUpdate = formUtils.inputChangeToStateUpdate(event);
        this.props.actions.updateCurrentCampaignState(stateUpdate);
    }

    onSendTestClose(action, phone) {
        const stateUpdate = { sendTestRequested: false };

        if (action === 'confirm') {
            if (this.props.isForbiddenBillingPlan) stateUpdate.forbiddenPlanAlert = true;
            else if (this.props.subscriptionStatus !== 'valid') {
                stateUpdate.subscriptionRequired = true;
                stateUpdate.pendingTestMessage = true;
                stateUpdate.phone = phone;
            } else {
                const { eventId, emailId } = this.getRelatedIds(this.props.campaign.recipientsListId);
                this.props.actions.sendTestMessage(phone, this.props.campaign.content, null, eventId, emailId);
            }
        }

        this.setState(stateUpdate);
    }

    // eslint-disable-next-line class-methods-use-this
    getRelatedIds(recipientsListId) {
        const result = {};

        if (recipientsListId.startsWith('NEWSLETTER_NON_OPENERS')) {
            result.emailId = Number.parseInt(recipientsListId.split('-')[1], 10);
        }

        if (recipientsListId.startsWith('EVENT_ATTENDEES')) {
            result.eventId = Number.parseInt(recipientsListId.split('-')[1], 10);
        }

        return result;
    }

    sendTestMessage() {
        this.setState({ sendTestRequested: true });
    }

    saveCampaign() {
        const dataToSave = { ...this.props.campaign };

        // if title is empty, then fill it with content (removing standard postfix)
        const regex = /\nfrom.*\s?\S?.*/gm;
        dataToSave.title = dataToSave.title || dataToSave.content.replace(regex, '');
        if (dataToSave.title.trim() === '') {
            dataToSave.title = `New campaign by ${this.props.userName} on ${new Date().toDateString()}`;
        }

        // save scheduleMoment in number format
        dataToSave.scheduleMomentUTCTimestamp = Date.parse(dataToSave.scheduleMoment);

        // clean statistics from cloned campaign
        dataToSave.statistics = null;

        if (this.props.cloneCampaignId) {
            dataToSave.id = null;
        }
        // store snapshot in state
        const stateUpdate = { pendingForSave: dataToSave };

        // do not activate for free/trial accounts
        if (
            this.props.isForbiddenBillingPlan && this.props.campaign.status === 'active'
        ) stateUpdate.forbiddenPlanAlert = true;

        // save if subscription is valid
        else if (this.props.subscriptionStatus === 'valid' || this.props.campaign.status !== 'active') {
            this.props.actions.saveCampaign(dataToSave);
        } else { // require subscription
            stateUpdate.subscriptionRequired = true;
        }
        this.setState(stateUpdate);
    }

    confirmSubscription(stripe, cardElement, billingDetails) {
        if (this.state.pendingTestMessage) {
            const { eventId, emailId } = this.getRelatedIds(this.props.campaign.recipientsListId);
            this.props.actions.createSubscriptionAndSendTest(
                stripe,
                cardElement,
                billingDetails,
                this.state.phone,
                this.props.campaign.content,
                null,
                eventId,
                emailId,
            );

            this.setState({ pendingTestMessage: false });
        } else {
            this.props.actions.createSubscriptionAndSaveCampaign(
                stripe,
                cardElement,
                billingDetails,
                this.state.pendingForSave,
            );
        }
    }

    cancelSubscription() {
        this.props.actions.updateCurrentCampaignState({ status: 'draft' });
        this.setState({
            pendingTestMessage: false,
            subscriptionRequired: false,
            pendingForSave: null,
        });
    }

    isSubscriptionRequired() {
        if (!this.props.campaign) return false;

        return this.props.subscriptionStatus
            && this.props.subscriptionStatus !== 'valid'
            && this.state.subscriptionRequired;
    }

    isTestDialogVisible() {
        return this.state.sendTestRequested
            && !this.isSubscriptionRequired();
    }

    hideForbiddenAlert() {
        this.setState({ forbiddenPlanAlert: false });
    }

    renderPageHeader() {
        if (this.props.campaignId == null) {
            return (<h1 className="fixedHeader">New campaign</h1>);
        } if (this.props.campaign == null) {
            return (<h1 className="fixedHeader">Edit campaign</h1>);
        }

        return (
            <h1 className="fixedHeader">
                Edit campaign
                {' '}
                <span className="d-none d-lg-inline">
                    &quot;
                    {this.props.campaign.title}
                    &quot;
                </span>
            </h1>
        );
    }

    renderSubscriptionForm() {
        let explanation;

        if (this.props.subscriptionStatus === 'missing') {
            explanation = 'You need to set up a payment method before proceeding with this action.';
        } else if (this.props.subscriptionStatus === 'cancelled') {
            explanation = 'Your account payments were cancelled. Please set up a payment method to proceed.';
        } else {
            explanation = 'Your card is expired, please provide another card.';
        }

        if (this.isSubscriptionRequired()) {
            return (
                <div className="campaignInfoContainer">
                    <Alert variant="info">
                        <Alert.Heading className="clearfix">
                            Payment method required
                        </Alert.Heading>
                        {explanation}
                    </Alert>

                    <div hidden={this.props.isOwner}>
                        <Alert variant="danger">
                            <Alert.Heading className="clearfix">
                                Access denied
                                <Button
                                    className="float-right"
                                    variant="outline-primary"
                                    onClick={this.cancelSubscription}
                                >
                                    Back to editing
                                </Button>
                            </Alert.Heading>
                            <p>
                                Only account owner can manage subscription.

                                Please contact an account owner and ask him to setup subscription.
                            </p>

                        </Alert>
                    </div>
                    <div hidden={!this.props.isOwner} className="mt-4">
                        <SubscriptionForm
                            onCancel={this.cancelSubscription}
                            cancelButtonTitle="Cancel and return to editing"
                            subscribeButtonTitle={(
                                this.state.pendingTestMessage
                                    ? 'Subscribe and send message'
                                    : 'Subscribe and activate campaign'
                            )}
                            onProcessCardInfo={this.confirmSubscription}
                        />
                    </div>

                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="mainContent">
                <AuthorizationRequired />

                {this.renderPageHeader()}

                <div className="mt-3">
                    {this.renderSubscriptionForm()}

                    <div hidden={this.isSubscriptionRequired()}>
                        <Button href="/campaigns" variant="link" onClick={this.handleBack} className="pl-0">
                            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                            Back to campaigns
                        </Button>
                        <LoadingIndicator loading={this.props.isFetching}>
                            <EditCampaignForm
                                campaign={this.props.campaign}
                                onSubmit={this.saveCampaign}
                                onFieldValChange={this.onFieldValChange}
                                onSendTest={this.sendTestMessage}
                            />

                            <Alert variant="danger" className="mt-2" hidden={this.props.error == null}>
                                <Alert.Heading>Failed to save campaign</Alert.Heading>
                                <p>{this.props.error}</p>
                            </Alert>
                        </LoadingIndicator>
                    </div>
                </div>

                <SendTestDialog onClose={this.onSendTestClose} show={this.isTestDialogVisible()} />

                <ActionForbiddenAlert show={this.state.forbiddenPlanAlert} onHide={this.hideForbiddenAlert} />
                <div className="mt-5 mb-5 d-sm-none d-md-block"> &nbsp;</div>
            </div>
        );
    }
}

EditCampaignPage.propTypes = {
    // eslint-disable-next-line react/require-default-props
    campaignId: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    campaign: types.campaign,
    // eslint-disable-next-line react/require-default-props
    isFetching: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    cloneCampaignId: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    error: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    campaignDefaults: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    subscriptionStatus: propTypes.string,
    // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
    isSaving: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    isOwner: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    userName: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    isForbiddenBillingPlan: propTypes.bool,
};

function mapStateToProps(state) {
    const isOwner = state.auth.me != null && state.auth.me.user.role === 'owner';

    return {
        campaign: state.campaignDetails.campaign || { title: '', description: '' },
        error: state.campaignDetails.error,
        isFetching: state.campaignDetails.isFetching || false,
        isSaving: state.campaignDetails.isSaving || false,
        cloneCampaignId: qs.parse(state.router.location.search).cloneCampaignId,
        campaignDefaults: qs.parse(state.router.location.search).campaignDefaults,
        subscriptionStatus: state.billing.subscriptionStatus,
        isOwner,
        userName: state.auth.me.user.name,
        isForbiddenBillingPlan: getIsBillingPlanProhibited(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditCampaignPage);
