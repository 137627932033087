import { useState, useEffect, useCallback } from 'react';
import supportedRegions from '../constants/supportedRegions';

// TODO: Refactor geolocation in components and state management (extract and reuse). Initial implementation looks scary.

export const detectCountry = () => {
    const lang = navigator.language.toLowerCase();

    return (lang === 'en-ca' || lang === 'fr-ca') ? 'CA' : 'US';
};

export const getDefaultRegion = (country = 'US') => {
    const countryData = supportedRegions[country] || supportedRegions.US;

    return countryData.default_state;
};

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export const getRegionSettings = (selectedCountry = 'US', state) => {
    const countryData = supportedRegions[selectedCountry] || supportedRegions.US;

    return {
        country: selectedCountry,
        postal_code_example: countryData.postal_code_example,
        postal_code_mask: countryData.postal_code_mask,
        state_codes: Object.getOwnPropertyNames(countryData.states),
        states: countryData.states,
        default_state: state || countryData.default_state,
        country_options: Object.getOwnPropertyNames(supportedRegions)
            .map((code) => ({
                value: code,
                displayName: supportedRegions[code].display_name,
            })),
    };
};

export const detectDefaultLocation = () => {
    const defaultCountry = detectCountry();
    const defaultRegion = getDefaultRegion(defaultCountry);

    return { defaultCountry, defaultRegion };
};

export const useRegionSettings = (country = detectCountry(), state = getDefaultRegion(country)) => {
    const [regionSettings, setRegionSettings] = useState(
        getRegionSettings(country, state),
    );
    const onCountryChange = useCallback((nextCountry) => {
        setRegionSettings(getRegionSettings(nextCountry));
    }, [setRegionSettings]);
    const onRegionChange = (nextCountry, nextState) => {
        setRegionSettings(
            getRegionSettings(nextCountry, nextState),
        );
    };

    useEffect(() => {
        setRegionSettings(
            getRegionSettings(country, state),
        );
    }, [country, state]);

    return {
        regionSettings,
        onCountryChange,
        onRegionChange,
    };
};
