import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function campaignMessagesReducer(state = initialState.messages.campaignMessages, action) {
    switch (action.type) {
        case actionTypes.Messages.FETCH_CAMPAIGN_MESSAGES.start: {
            return {
                ...state,
                [action.campaignId]: {
                    isFetching: true,
                    error: null,
                },
            };
        }

        case actionTypes.Messages.FETCH_CAMPAIGN_MESSAGES.failure: {
            return {
                ...state,
                [action.campaignId]: {
                    isFetching: false,
                    error: action.error,
                    messages: null,
                },
            };
        }

        case actionTypes.Messages.FETCH_CAMPAIGN_MESSAGES.success: {
            return {
                ...state,
                [action.campaignId]: {
                    isFetching: false,
                    error: null,
                    messages: action.messages,
                },
            };
        }

        default:
            return state;
    }
}
