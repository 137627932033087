import React from 'react';
import PropTypes from 'prop-types';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { useL10nContext } from 'wa.admin-ui.l10n';
import NavigationButton from '../../../components/navigation-button';
import sectionKeys from '../../../../../../../../../../../constants/section-keys';
import { useSectionContext }
    from '../../../../../../../../../../../entities/help-widget/context/section-context-provider';

const Email = ({ collapsed }) => {
    const { labels } = useL10nContext();
    const { setSection, section } = useSectionContext();

    const isActive = section.key === sectionKeys.SECTION_EMAIL;

    return (
        <NavigationButton
            active={isActive}
            name={labels.NAVIGATION.EMAIL.BUTTON_LABEL}
            icon={faEnvelope}
            onClick={() => setSection(sectionKeys.SECTION_EMAIL)}
            collapsed={collapsed}
        />
    );
};

export default Email;

Email.propTypes =
  {
      collapsed: PropTypes.bool,
  };

Email.defaultProps =
  {
      collapsed: false,
  };