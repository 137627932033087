import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
    NavItem, Navbar, Nav, Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSms, faFileInvoiceDollar, faCogs, faQuestionCircle, faBars, faTimes, faComments,
} from '@fortawesome/free-solid-svg-icons';
import LoginStatus from './LoginStatus';
import { getUser, getIsAuthenticated, getAccount } from '../reducers/authReducer';
import TwilioSubscriptionStatus from './TwilioSubscriptionStatus';
import Logo from './Logo';
import { openElevioHome } from './HelpCenter/src/utils/elevio/util/elevio-functions';
import DeleteAccount from './Debug/DeleteAccount/DeleteAccount';

function MenuItem({ navData }) {
    return (
        <NavLink exact to={navData.url} className="mt-4">
            <NavItem>
                {navData.icon && (
                    <div
                        style={{
                            width: '50px', height: '20px', display: 'inline-block', textAlign: 'center',
                        }}
                    >
                        <FontAwesomeIcon className="mr-2" icon={navData.icon} size="lg" />
                    </div>
                )}
                {navData.title}
                <small className="ml-2">
                    {navData.badge && (
                        <Badge variant="warning" className="text-white">{navData.badge}</Badge>
                    )}
                </small>
            </NavItem>
        </NavLink>
    );
}

MenuItem.propTypes = {
    navData: propTypes.shape({
        url: propTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        icon: propTypes.object,
        title: propTypes.string.isRequired,
        badge: propTypes.string,
    }).isRequired,
};

const appMenuItems = [
    { title: 'Text campaigns', url: '/campaigns', icon: faSms },
    { title: 'Conversations', url: '/conversations', icon: faComments },
    {
        title: 'Billing', url: '/billing', icon: faFileInvoiceDollar, ownerOnly: true,
    },
    { title: 'Settings', url: '/settings', icon: faCogs },
];

class MainNavbar extends React.Component {
    constructor(...args) {
        super(...args);

        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.showHelpWidget = this.showHelpWidget.bind(this);
        this.state = { sidebarState: '_close' };
    }

    toggleSidebar() {
        // eslint-disable-next-line react/no-access-state-in-setstate
        const newState = this.state.sidebarState === '_close' ? '_open' : '_close';
        this.setState({ sidebarState: newState });
    }

    // eslint-disable-next-line class-methods-use-this
    showHelpWidget(event) {
        event.preventDefault();
        openElevioHome();
    }

    render() {
        const navItems = this.props.setupComplete ? appMenuItems : [];

        return this.props.shouldDisplay && (
            <div>
                <FontAwesomeIcon icon={faBars} size="2x" id="burger" onClick={this.toggleSidebar} />
                <Navbar
                    onClick={this.toggleSidebar}
                    className={`flex-column p-0 shadow ${this.state.sidebarState}`}
                    id="sidebarMenu"
                >
                    <Navbar.Brand className="p-0 m-0 text-center w-100 bg-white">
                        <Logo />
                    </Navbar.Brand>
                    <DeleteAccount />
                    <div className="closeNavigationButton">
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </div>
                    <Nav className="position-relative flex-column p-4 pb-5" style={{ fontSize: '1.25rem' }}>
                        {navItems
                            .filter((item) => this.props.isOwner || !item.ownerOnly)
                            .map((item) => (
                                <MenuItem key={`${item.title}-${item.url}`} navData={item} />
                            ))}

                        <a // eslint-disable-line jsx-a11y/anchor-is-valid
                            href="#"
                            name="tns__elevio_help_home"
                            className="mt-4"
                            onClick={this.showHelpWidget}
                        >
                            <div className="nav-item">
                                <div
                                    style={{
                                        width: '50px', height: '20px', display: 'inline-block', textAlign: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faQuestionCircle} size="lg" />
                                </div>
                                Help and support
                            </div>
                        </a>
                        <TwilioSubscriptionStatus />
                    </Nav>
                    <div className="position-absolute w-100" style={{ bottom: '0', left: '0' }}>
                        <LoginStatus />
                    </div>
                </Navbar>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div id="menuShading" className={this.state.sidebarState} onClick={this.toggleSidebar} />
            </div>
        );
    }
}

MainNavbar.propTypes = {
    shouldDisplay: propTypes.bool.isRequired,
    isOwner: propTypes.bool.isRequired,
    setupComplete: propTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props
    twilioSubscriptionStatus: propTypes.object,
};

function mapStateToProps(state) {
    const user = getUser(state);
    const account = getAccount(state);

    return {
        isOwner: !!user && user.role === 'owner',
        shouldDisplay: (
            getIsAuthenticated(state)
            && !state.router.location.pathname.toLowerCase().startsWith('/subscription-wizard')
        ),
        setupComplete: !!account && account.setupComplete,
    };
}

export default connect(mapStateToProps)(MainNavbar);
