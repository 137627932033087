import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { dateUtils } from 'mp-utils';

function SubscriptionDetails() {
    const showHelpArticle = () => {
        // freshdeskHelper.openArticle(externalResources.freshdesk.pricingArticleId)
    };

    return (
        <div>
            <h5>
                Subscription details
                <Button onClick={showHelpArticle} variant="link">
                    <FontAwesomeIcon icon={faQuestionCircle} className="text-info" size="lg" />
                </Button>
            </h5>
            <table className="w-100 h6">
                <tbody>
                    <tr className="border-bottom">
                        <td className="pl-0 h6 text-muted">
                            Subscription
                        </td>
                        <td className="text-right">
                            Monthly
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td className="pl-0 h6 text-muted">
                            Virtual phone number,
                            <br />
                            per month
                        </td>
                        <td className="text-right">
                            $4 USD
                        </td>
                    </tr>
                    <tr className="border-bottom">
                        <td className="pl-0 h6 text-muted">
                            Price per text,
                            <br />
                            sent or received
                        </td>
                        <td className="text-right h6 text-nowrap">
                            $0.03 USD
                        </td>
                    </tr>
                </tbody>
            </table>

            <p className="mt-3">
                Your card will be charged
                <br />
                on
                {' '}
                <b>{dateUtils.nextBillingDate(Date.now(), Date.now() + 1000).toLocaleDateString()}</b>
                {' '}
                and
                then monthly on the same date, until you cancel the subscription.
            </p>
        </div>
    );
}

export default SubscriptionDetails;
