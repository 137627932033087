import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { useL10nContext } from 'wa.admin-ui.l10n';
import { showLiveChatWindow, isChatting } from '../../../../../../../../../../../utils/zopim-chat/zopim-api';
import { openWidget } from '../../../../../../../../../../../utils/forethought/api';
import NavigationButton from '../../../components/navigation-button';
import { useChatContext } from '../../../../../../../../../../../entities/help-widget/context/chat-context-provider';
import { useAssociationContext }
    from '../../../../../../../../../../../entities/help-widget/context/association-context-provider';

const Chat = ({ collapsed }) => {
    const { labels } = useL10nContext();
    const { online } = useChatContext();
    const { useForethought } = useAssociationContext();

    const talkingToAgent = isChatting();

    const handleOpen = useCallback(() => {
        if (talkingToAgent || !useForethought) {
            showLiveChatWindow();
        } else {
            openWidget();
        }
    }, [talkingToAgent, useForethought]);

    return (
        <NavigationButton
            name={labels.NAVIGATION.CHAT.BUTTON_LABEL}
            icon={faCommentDots}
            onClick={handleOpen}
            collapsed={collapsed}
            disabled={!online}
            disabledText={labels.NAVIGATION.CHAT.DISABLED}
        />
    );
};

export default Chat;

Chat.propTypes =
  {
      collapsed: PropTypes.bool,
  };

Chat.defaultProps =
  {
      collapsed: false,
  };
