import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperPlane, faCheck, faPencilRuler, faClock, faBan,
} from '@fortawesome/free-solid-svg-icons';

function CampaignStatusIcon({ campaign }) {
    const { status, processingStatus } = campaign || {};

    if (status === 'active') {
        if (processingStatus === 'none') return <FontAwesomeIcon icon={faClock} className="mr-2 text-primary" />;

        return <FontAwesomeIcon icon={faPaperPlane} className="mr-2 text-info" />;
    }

    if (status === 'draft') return <FontAwesomeIcon icon={faPencilRuler} className="mr-2 text-secondary" />;

    if (status === 'stopped' && processingStatus === 'none') {
        return <FontAwesomeIcon icon={faBan} className="mr-2 text-danger" />;
    }

    return <FontAwesomeIcon icon={faCheck} className="mr-2 text-success" />;
}

CampaignStatusIcon.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    campaign: propTypes.object.isRequired,
};

export default CampaignStatusIcon;
