import React from 'react';
import PropTypes from 'prop-types';
import  Icon from '../../../../../../../../../../../../../Common/icon';
import './navigation-button.css';
import HelpTooltip from '../../../../../../../../../../common/tooltip';

const NavigationButton = (
    {
        name,
        onClick,
        icon,
        children,
        disabled,
        disabledText,
        active,
        collapsed,
    }) =>
    (
        <div className="text-center">
            <div
                title={name}
                className={`${disabled ? '-wa-help-center__navigation-button__disabled' : 'cursor-pointer'}
         ${active ? '-wa-help_nav-active' : 'bg-white'} -wa-help-center__navigation-button shadow-sm
         rounded-lg mx-auto`}
                onClick={!disabled && onClick}
                onKeyDown={!disabled && onClick}
            >
                {
                    collapsed || disabled ?
                        (
                            <HelpTooltip text={disabled ? disabledText : name} placement="bottom">
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    {icon && !children ?
                                        (
                                            <Icon
                                                icon={icon}
                                                size="lg"
                                                className={`${active ? 'text-white' : 'text-primary'}`}
                                            />
                                        )
                                        :
                                        (children)}
                                </div>
                            </HelpTooltip>
                        )
                        :
                        (
                            <div className="d-flex justify-content-center align-items-center h-100">
                                {icon && !children ?
                                    (
                                        <Icon
                                            icon={icon}
                                            size="lg"
                                            className={`${active ? 'text-white' : 'text-primary'}`}
                                        />
                                    )
                                    : (children)}
                            </div>
                        )
                }
            </div>
            {
                !collapsed &&
        (
            <span className={disabled && '-wa-help-center__navigation-button__disabled'}>
                {name}
            </span>
        )
            }
        </div>
    );

NavigationButton.propTypes =
  {
      name: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      children: PropTypes.node,
      disabled: PropTypes.bool,
      disabledText: PropTypes.string,
      active: PropTypes.bool,
      collapsed: PropTypes.bool,
  };

NavigationButton.defaultProps =
  {
      name: '',
      onClick: null,
      icon: null,
      children: null,
      disabled: false,
      disabledText: '',
      active: false,
      collapsed: false,
  };

export default NavigationButton;
