import React from 'react';
import PropTypes from 'prop-types';
import { useL10nContext } from 'wa.admin-ui.l10n';
import  Icon from '../../../../../../../Common/icon';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';

import HelpTooltip from '../../../../common/tooltip';
import { useSectionContext } from '../../../../../entities/help-widget/context/section-context-provider';

import './help-badge.css';

const HelpBadge = ({ chatActive }) => {
    const { openWidget } = useSectionContext();
    const { labels } = useL10nContext();

    const isSecondaryMode =
    chatActive ?
        '-wa-help-center__widget-icon__secondary'
        :
        '-wa-help-center__widget-icon';

    return (
        <HelpTooltip text={labels.HELP_BADGE.HELP_TITLE} placement="left">
            <div
                id={isSecondaryMode}
                data-test-id="wa-help-badge__help-badge"
                // eslint-disable-next-line max-len
                className="mb-2 rounded-circle bg-primary cursor-pointer d-flex align-items-center justify-content-center"
                onClick={openWidget}
                onKeyDown={openWidget}
            >
                <div
                    className="text-white"
                >
                    <Icon
                        size={chatActive ? 'lg' : '2x'}
                        icon={faQuestion}
                    />
                </div>
            </div>
        </HelpTooltip>
    );
};

HelpBadge.propTypes =
  {
      chatActive: PropTypes.bool,
  };

HelpBadge.defaultProps =
  {
      chatActive: false,
  };

export default HelpBadge;
