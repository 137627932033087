import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function accountsReducer(state = initialState.accounts, action) {
    switch (action.type) {
        case actionTypes.Accounts.FETCH_ACCOUNTS.start:
            return {
                ...state,
                isFetching: true,
                list: null,
            };

        case actionTypes.Accounts.FETCH_ACCOUNTS.success:
            return {
                ...state,
                isFetching: false,
                list: action.accounts,
                error: null,
            };

        case actionTypes.Accounts.FETCH_ACCOUNTS.failure:
            return {
                ...state,
                isFetching: false,
                list: null,
                error: action.error,
            };

        default:
            return state;
    }
}
