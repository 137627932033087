// Centralized propType definitions
import propTypes from 'prop-types';

export const campaign = propTypes.shape({
    id: propTypes.string,
    status: propTypes.string,
    title: propTypes.string,
    sendOn: propTypes.number,
    content: propTypes.string,
    statistics: propTypes.shape({
        delivery: propTypes.shape({
            recipientsNumber: propTypes.number,
            sending: propTypes.number,
            delivered: propTypes.number,
            undelivered: propTypes.number,
            rejected: propTypes.number,
        }),
        price: propTypes.shape({
            pricePerRecipient: propTypes.number,
            estimatedTotalPrice: propTypes.number,
            charged: propTypes.number,
        }),
    }),
});

export const invoiceLine = propTypes.shape({
    title: propTypes.string.isRequired,
    price: propTypes.number.isRequired,
    type: propTypes.string,
    resourceId: propTypes.string,
});

export const invoice = propTypes.shape({
    title: propTypes.string,
    totalSum: propTypes.number,
    paid: propTypes.bool,
    invoiceLines: propTypes.arrayOf(invoiceLine),
    invoicePdf: propTypes.string,
});
