import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as actionCreators from '../actions/loginActions';
import { getIsAuthenticated } from '../reducers/authReducer';

class AuthorizationRequired extends React.Component {
    componentDidMount() {
        this.props.actions.validateToken(this.props.accessToken);
    }

    render() {
        if (this.props.isAuthenticated) {
            return null;
        }

        const targetUrl = window.location.href.replace(window.location.origin, '');

        return (
            <Redirect to={`/create?targetUrl=${encodeURIComponent(targetUrl)}`} />
        );
    }
}

AuthorizationRequired.propTypes = {
    isAuthenticated: propTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    accessToken: propTypes.string,
};

function mapStateToProps(state) {
    return {
        isAuthenticated: getIsAuthenticated(state),
        accessToken: state.auth.accessToken,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorizationRequired);
