import React from 'react';
import propTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

class CancelSubscriptionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onSignatureChange = this.onSignatureChange.bind(this);
    }

    onSignatureChange(e) {
        this.setState({ signatureValid: e.target.value === 'STOP' });
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onReject}>
                <Modal.Header>
                    Confirm subscription cancellation
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You are about to cancel your
                        {' '}
                        <i>WildApricot&apos;s text messaging</i>
                        {' '}
                        service.
                        This will cancel all active text messaging campaigns.
                        Please note that if you want to restart text messaging after cancellation, 
                        you will need to complete a new registration process. 
                        This involves obtaining a new virtual phone number and incurring a $30 registration fee.
                    </p>
                    <p>
                        If you still want to proceed with cancellation, please type STOP in the text field below 
                        and then click
                        {' '}
                        <b>Confirm</b>
                    </p>
                    <div>
                        <input className="mimicFormControl" placeholder="STOP" onChange={this.onSignatureChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={this.props.onReject}>No, keep it</Button>
                    <Button variant="danger" onClick={this.props.onConfirm} disabled={!this.state.signatureValid}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

CancelSubscriptionDialog.propTypes = {
    show: propTypes.bool,
    onConfirm: propTypes.func.isRequired,
    onReject: propTypes.func.isRequired,
};

CancelSubscriptionDialog.defaultProps = {
    show: false,
};

export default CancelSubscriptionDialog;
