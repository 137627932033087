import React from 'react';
import { node } from 'prop-types';

function PageContainer({ children }) {
    return (
        <div className="mainContent pb-5" style={{ maxWidth: '65rem' }}>
            {children}
        </div>
    );
}

PageContainer.propTypes = {
    children: node.isRequired,
};

export default PageContainer;
