import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './WizardLayout.scss';

const WizardLayout = ({ title, steps, activeStep, children }) => {
    return (
        <div className="-tns-fullscreen-container bg-light pt-3 pb-5 px-4">
            <Container>
                <h1 className="py-4 mb-3">
                    {title}
                </h1>
                <Row>
                    <Col>
                        <Card className="p-4">
                            <ul className="list-unstyled m-auto">
                                {steps.map(step =>
                                    step.key !== 'complete' &&
                                    (<li className=
                                        {`pb-4 ${activeStep.key === step.key ? 'font-weight-bold' : ''}`}>
                                        {step.progressTitle}
                                    </li>))}
                            </ul>
                        </Card>
                    </Col>
                    <Col xs={9}>
                        <Card>
                            {children}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WizardLayout;
