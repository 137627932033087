import React from 'react';
import propTypes from 'prop-types';
import CampaignsListRecord from './BuildingBlocks/CampaignsListRecord';

function CampaignsList({ campaigns, listTitle, onCampaignOpen }) {
    if (campaigns == null || !campaigns.length) {
        return null;
    }

    return (
        <div>
            <h3 className="mt-3 mb-2 text-muted">{listTitle}</h3>
            {campaigns.map((campaign) => (
                <CampaignsListRecord key={campaign.id} campaign={campaign} onCampaignOpen={onCampaignOpen} />
            ))}
        </div>
    );
}

CampaignsList.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    campaigns: propTypes.array.isRequired,
    onCampaignOpen: propTypes.func.isRequired,
    listTitle: propTypes.string.isRequired,
};

export default CampaignsList;
