import React from 'react';
import PropTypes from 'prop-types';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useL10nContext } from 'wa.admin-ui.l10n';
import NavigationButton from '../../../components/navigation-button';

const BookACall = ({ handleClick, collapsed }) => {
    const { labels } = useL10nContext();

    return (
        <NavigationButton
            collapsed={collapsed}
            name={labels.NAVIGATION.BOOK_A_CALL.BUTTON_LABEL}
            icon={faPhone}
            onClick={handleClick}
        />
    );
};

BookACall.propTypes =
  {
      handleClick: PropTypes.func.isRequired,
      collapsed: PropTypes.bool,
  };

BookACall.defaultProps =
  {
      collapsed: false,
  };

export default BookACall;