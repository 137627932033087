// eslint-disable-next-line import/no-anonymous-default-export
export default
{
    UNKNOWN: 'unknown',
    AFFINIPAY: 'AffiniPay',
    PERSONIFYPAY: 'PersonifyPay',
    PAYPAL: 'PayPalCheckout',
    STRIPE: 'Stripe',
    AUTHORIZENET: 'AuthorizeNet',
    MANUAL: 'Manual',
};

