const twilioStatuses = {
    noncompliant: 'noncompliant',
    compliant: 'compliant',
    draft: 'draft',
    pending_review: 'pending-review',
    in_review: 'in-review',
    twilio_rejected: 'twilio-rejected',
    twilio_approved: 'twilio-approved',
    fetching_error: 'fetching_error',
    campaign_rejected: 'campaign-rejected',
};

export default twilioStatuses;
