import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';

// SPA form does not post data on submit. Instead it only calls onSubmit handler.
function SPAForm({
    id, onSubmit, children, ...props
}) {
    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const form = (event || {}).currentTarget || document.getElementById(id);

        if (form.checkValidity() === true) {
            onSubmit(event);
        }
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Form {...props} id={id} onSubmit={handleSubmit}>
            {children}
        </Form>
    );
}

SPAForm.propTypes = {
    id: propTypes.string.isRequired,
    onSubmit: propTypes.func.isRequired,
    children: propTypes.node.isRequired,
};

export default SPAForm;
