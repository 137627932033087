import { push } from 'connected-react-router';
import actionTypes from '../constants/actionTypes';
import campaignsService from '../apiServices/campaignsService';
import billingActions from './billingActions';
import * as messagesActions from './messagesActions';

export const fetchCampaigns = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Campaigns.FETCH_CAMPAIGNS.start,
    });

    const result = await campaignsService.listCampaigns();

    if (result.error) {
        dispatch({
            type: actionTypes.Campaigns.FETCH_CAMPAIGNS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Campaigns.FETCH_CAMPAIGNS.success,
            campaigns: result.campaigns,
        });
    }
};

export const navigateToCampaign = (campaignId, status) => (dispatch) => {
    if (status === 'draft') {
        dispatch(push(`/campaigns/edit/${campaignId}`));
    } else {
        dispatch(push(`/campaigns/view/${campaignId}`));
    }
};

export const startNewCampaignFromTemplate = () => (dispatch) => {
    dispatch(
        push('/campaigns/new-from-template'),
    );
};

export const startNewCampaign = (campaignDefaults) => (dispatch) => {
    let url = '/campaigns/new/';

    if (campaignDefaults) {
        url += `?campaignDefaults=${JSON.stringify(campaignDefaults)}`;
    }
    dispatch(push(url));
};

export const fetchSubscriptionStatus = () => async (dispatch) => {
    // eslint-disable-next-line import/no-named-as-default-member
    dispatch(billingActions.fetchSubscriptionStatus());
};

export const saveCampaign = (campaign) => async (dispatch) => {
    if (!campaign) return;

    let result = null;

    if (campaign.id) {
        result = await campaignsService.updateCampaign(campaign);
    } else {
        result = await campaignsService.saveCampaign(campaign);
    }

    if (result.error) {
        dispatch({
            type: actionTypes.Campaigns.SAVE_CAMPAIGN.failure,
            error: result.error,
        });

        // eslint-disable-next-line consistent-return
        return false;
    }

    dispatch({
        type: actionTypes.Campaigns.SAVE_CAMPAIGN.success,
        campaignId: result.id,
    });

    dispatch(push(`/campaigns/view/${result.id}`));
};

// eslint-disable-next-line max-len
export const createSubscriptionAndSaveCampaign = (stripe, cardElement, billingDetails, campaign) => async (dispatch) => {
    dispatch(
        // eslint-disable-next-line import/no-named-as-default-member
        billingActions.createSubscription(
            stripe,
            cardElement,
            billingDetails,
            () => saveCampaign(campaign),
        ),
    );
};

export const sendTestMessage = messagesActions.sendSingleMessage;

// eslint-disable-next-line max-len
export const createSubscriptionAndSendTest = (stripe, cardElement, billingDetails, phone, content, eventId, emailId) => async (dispatch) => {
    dispatch(
        // eslint-disable-next-line import/no-named-as-default-member
        billingActions.createSubscription(
            stripe,
            cardElement,
            billingDetails,
            () => sendTestMessage(phone, content, eventId, emailId),
        ),
    );
};

export const fetchCampaign = (campaignId) => async (dispatch) => {
    dispatch({
        type: actionTypes.Campaigns.FETCH_CAMPAIGN_DETAILS.start,
        campaignId,
    });

    const result = await campaignsService.fetchCampaignDetails(campaignId);

    if (result.error) {
        dispatch({
            type: actionTypes.Campaigns.FETCH_CAMPAIGN_DETAILS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Campaigns.FETCH_CAMPAIGN_DETAILS.success,
            campaign: result.campaign,
        });
    }
};

export const showCampaignsList = (status) => (dispatch) => {
    dispatch(push(`/campaigns#${status}`));
};

export const back = () => (dispatch) => {
    dispatch(push('/campaigns#active'));
};

export const cancelCampaign = (campaignId) => async (dispatch) => {
    dispatch({
        type: actionTypes.Campaigns.CANCEL_CAMPAIGN.start,
    });

    const result = await campaignsService.stopCampaign(campaignId);

    if (result.error) {
        dispatch({
            type: actionTypes.Campaigns.CANCEL_CAMPAIGN.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Campaigns.CANCEL_CAMPAIGN.success,
            campaignId,
        });
    }

    dispatch(fetchCampaign(campaignId));
};

// this is an update of redux state, not an API call
export const updateCurrentCampaignState = (campaignUpdate) => (dispatch) => {
    dispatch({
        type: actionTypes.Campaigns.UPDATE_CURRENT_CAMPAIGN,
        campaignUpdate,
    });
};

export const duplicateCampaign = (campaignId) => (dispatch) => {
    dispatch(push(`/campaigns/new?cloneCampaignId=${campaignId}`));
};

export const fetchCampaignSuggestions = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Campaigns.FETCH_CAMPAIGN_SUGGESTIONS.start,
    });

    const result = await campaignsService.fetchCampaignSuggestions();

    if (result.error) {
        dispatch({
            type: actionTypes.Campaigns.FETCH_CAMPAIGN_SUGGESTIONS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Campaigns.FETCH_CAMPAIGN_SUGGESTIONS.success,
            suggestions: result.suggestions,
        });
    }
};

export const initDefaultCampaign = (externalDefaults) => (dispatch, getState) => {
    const state = getState();
    const orgTitle = state.auth.me ? state.auth.me.account.organizationTitle : 'NOORG';
    const defaults = {
        title: '',
        schedule: 'asap',
        status: 'draft',
        content: `\n\nfrom ${orgTitle}\nSend STOP to opt out.`,
    };
    dispatch({
        type: actionTypes.Campaigns.INIT_CURRENT_CAMPAIGN,
        campaign: Object.assign(defaults, externalDefaults || {}),
    });
};
