const waAccountInfoModel = {
    // Only used in TNS
    TNS: true,
    association: {
        id: 1,
        name: '',
        createdDate: '',
        billingPlan: {
            name: '',
            baseName: '',
            price: 0,
            upgradedDate: 0,
        },
        organizationType: '',
        urlName: '',
        isTestMode: false,
        isCloned: false,
    },
    user: {
        id: 0,
        securityProfileId: 0,
        name: '',
        createdDate: '',
        email: '',
        roles: [
            '',
        ],
    },
    billing: {
        paymentProcessorType: '',
        subscriptionPeriod: '',
        priceIncreaseLaunchDate: 0,
        priceIncreaseEffectiveDate: 0,
        priceIncreaseNotificationDate: 0,
        priceIncreaseUpdated: true,
        pssfAmount: 0,
    },
    payments: {
        currentPaymentGateway: '',
        associationCountry: '',
        affiniPayApplication: {
            isSubmitted: false,
            isApproved: null,
            submittedDate: null,
            isCanada: false,
        },
        payPalApplication: {
            isMerchantRegisteredInPayPal: false,
            isPrimaryEmailConfirmed: false,
        },
        isOutdatedIntegration: false,
    },
    isMembershipLevelRecurringAvailable: false,
};

export default waAccountInfoModel;
