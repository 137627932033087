import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import formatWaInformation from '../../../utils/format-wa-info';
import { getIsAuthenticated, getMe } from '../../../reducers/authReducer';
import useInitGainsight from './use-init-gainsight';

// Gainsight script initialization
// This component does not render any HTML element. Instead it manages Gainsight initialization
// See wa-account-info-model. Reflects Wild Apricot adminpanel accountInfo and accountFinancialInfo data
function Gainsight() {
    const [data, setData] = useState(null);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const accountInfo = useSelector(getMe);

    useEffect(() => {
        if (isAuthenticated && accountInfo) {
            const formattedResult = formatWaInformation(accountInfo);
            setData(formattedResult);
        }
    }, [isAuthenticated, accountInfo]);

    useInitGainsight(data);

    return null;
}

export default Gainsight;
