import React from 'react';
import HelpSection from '../../../components/help-section';
import { useSectionContext } from '../../../../../../entities/help-widget/context/section-context-provider';
import './elevio-articles.css';

const ElevioArticles = () => {
    const { article } = useSectionContext();

    return (
        <>
            <HelpSection>
                <div id="help-center-email" className="-wa-help-center__elevio-article position-relative w-100">
                    <elevio-element data-prevent-interlink-click="true" data-type="article" data-id={article} />
                </div>
                {article === null &&
          (
              <elevio-element data-prevent-open-assistant="true" data-type="category" data-id="root" />
          )}
                {article !== null &&
        (
            <>
                <elevio-element data-type="article-feedback" data-id={article} />
                <elevio-element data-prevent-open-assistant="true" data-type="article-related" data-id={article} />
            </>
        )}
            </HelpSection>
        </>
    );
};

export default ElevioArticles;