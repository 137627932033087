import React from 'react';
import { useL10nContext } from 'wa.admin-ui.l10n';
import Button from '../../../../../../../../../Common/button';
import { faCommentDots, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import BookACallButton from './components/book-a-call-button';

import { showLiveChatWindow } from '../../../../../../../utils/zopim-chat/zopim-api';
import { useChatContext } from '../../../../../../../entities/help-widget/context/chat-context-provider';
import { useSectionContext } from '../../../../../../../entities/help-widget/context/section-context-provider';

import sectionKeys from '../../../../../../../constants/section-keys';

const BookACall = () => {
    const { labels } = useL10nContext();

    const { setSection } = useSectionContext();
    const { online } = useChatContext();

    return (
        <>
            <h6>
                {labels.TRIAL.CONTACT_SECTION_TITLE}
            </h6>
            <ul>
                {labels.TRIAL.CONTACT_SECTION_BULLET_POINTS.map((blurb, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`point-${index}`}>
                        {blurb}
                    </li>
                ))}
            </ul>
            <div className="d-flex pb-2">
                <BookACallButton />
                {
                    online ?
                        (
                            <Button icon={faCommentDots} onClick={showLiveChatWindow}>
                                {labels.TRIAL.LIVE_CHAT_BUTTON_LABEL}
                            </Button>
                        )
                        :
                        (
                            <Button
                                icon={faEnvelope}
                                className="mr-4 px-4"
                                onClick={() => setSection(sectionKeys.SECTION_EMAIL)}
                            >
                                {labels.EMAIL_BUTTON_LABEL}
                            </Button>
                        )
                }
            </div>
        </>
    );
};

export default BookACall;
