import React from 'react';
import Button from '../../../../../../../../../../../../../../../Common/button';
import { useL10nContext } from 'wa.admin-ui.l10n';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import openAppointletWidget from './open-appointlet-widget';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../../../../../../../../../../../../reducers/authReducer';

export const AppointletButton = () => {
    const { labels } = useL10nContext();

    const user = useSelector(getUser);

    return (
        <>
            <Button
                className="mr-4"
                icon={faPhone}
                onClick={() => openAppointletWidget(user)}
            >
                {labels.BOOK_A_CALL_BUTTON_LABEL}
            </Button>
        </>
    );
};

export default AppointletButton;
