import React from 'react';
import propTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

function CardInfo({ card }) {
    if (!card) {
        return null;
    }

    return (
        <div>
            <Container className="pl-0 pr-0">
                <Row>
                    <Col className="mb-3">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="form-label">Credit card</label>
                        <div className="mimicFormControlReadOnly text-nowrap">
                            {'**** **** **** '}
                            {card.last4}
                        </div>
                    </Col>
                    <Col className="mb-3">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="form-label">Expires on</label>
                        <div className="mimicFormControlReadOnly text-nowrap">
                            {(`0${card.exp_month}`).slice(-2)}
                            {' / '}
                            {card.exp_year}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

CardInfo.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    card: propTypes.object,
};

CardInfo.defaultProps = {
    card: null,
};

export default CardInfo;
