import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft, faPencilRuler, faMailBulk, faSyncAlt, faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from '../../actions/textCampaignsActions';
import './Campaigns.css';

function NewCampaignFromTemplateButton({ title, icon, onClick }) {
    return (
        <Button
            onClick={onClick}
            block
            variant="outline-primary"
            style={{ maxHeight: 100, height: 100, minWidth: 245 }}
            className="mb-4 pl-4 pr-2 pt-4 pb-4"
        >
            <Row className="align-middle">
                <Col xs={2} className="text-right">
                    <FontAwesomeIcon icon={icon} size="2x" />
                </Col>
                <Col xs={10} className="text-left" style={{ fontSize: 18 }}>
                    {title}
                </Col>
            </Row>
        </Button>
    );
}

NewCampaignFromTemplateButton.propTypes = {
    title: propTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    icon: propTypes.object.isRequired,
    onClick: propTypes.func.isRequired,
};

class NewCampaignTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.handleBack = this.handleBack.bind(this);

        this.handleCreateFromScratch = this.handleCreateFromScratch.bind(this);
        this.handleRenewalReminder = this.handleRenewalReminder.bind(this);
        this.handleTextToNonOpeners = this.handleTextToNonOpeners.bind(this);
        this.handleUpdateToAttendees = this.handleUpdateToAttendees.bind(this);
    }

    handleBack(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.back();
    }

    handleTextToNonOpeners() {
        const campaignSettings = {
            recipientsGroupType: 'NEWSLETTER_NON_OPENERS',
            content:
                `Dear FULL_NAME,\n${this.props.orgTitle} sent you a message 'EMAIL_SUBJECT'. Read it at EMAIL_WEB_LINK`,
        };
        this.props.startNewCampaign(campaignSettings);
    }

    handleRenewalReminder() {
        const campaignSettings = {
            recipientsGroupType: 'PREDEFINED',
            recipientsListId: 'PREDEFINED-PENDING_RENEWAL_MEMBERS',
            content: `Dear FULL_NAME,\nYour membership at ${this.props.orgTitle} is about to expire.\nRenew at https://${this.props.orgDomain}/Sys/Profile`,
            title: 'Renewal reminder',
        };
        this.props.startNewCampaign(campaignSettings);
    }

    handleUpdateToAttendees() {
        const campaignSettings = {
            recipientsGroupType: 'EVENT_ATTENDEES',
            // eslint-disable-next-line max-len
            content: `Dear FULL_NAME,\nWe have an important update regarding EVENT_TITLE  ...\n\nfrom ${this.props.orgTitle}`,
        };
        this.props.startNewCampaign(campaignSettings);
    }

    handleCreateFromScratch() {
        const campaignSettings = null;
        this.props.startNewCampaign(campaignSettings);
    }

    render() {
        return (
            <div className="mainContent">
                <h1 className="fixedHeader">What is your goal today?</h1>

                <div className="mt-3">
                    <Button href="/campaigns" variant="link" onClick={this.handleBack} className="pl-0">
                        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                        Back to campaigns
                    </Button>

                    <div className="campaignInfoContainer mt-3">
                        <Row>
                            <Col>
                                <NewCampaignFromTemplateButton
                                    title="Send text to email non-openers"
                                    icon={faMailBulk}
                                    onClick={this.handleTextToNonOpeners}
                                />
                            </Col>

                            <Col>
                                <NewCampaignFromTemplateButton
                                    title="Remind about membership renewal"
                                    icon={faSyncAlt}
                                    onClick={this.handleRenewalReminder}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <NewCampaignFromTemplateButton
                                    title="Send update to event registrants"
                                    icon={faCalendarCheck}
                                    onClick={this.handleUpdateToAttendees}
                                />
                            </Col>

                            <Col>
                                <NewCampaignFromTemplateButton
                                    title="Create a campaign from scratch"
                                    icon={faPencilRuler}
                                    onClick={this.handleCreateFromScratch}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        );
    }
}

NewCampaignTemplatePage.propTypes = {
    back: propTypes.func.isRequired,
    startNewCampaign: propTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    orgTitle: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    orgDomain: propTypes.string,
};

function mapStateToProps(state) {
    return {
        orgTitle: state.auth.me.account.organizationTitle,
        orgDomain: state.auth.me.account.primaryDomainName,
    };
}

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators(actionCreators, dispatch);

    return {
        back: actions.back,
        startNewCampaign: actions.startNewCampaign,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewCampaignTemplatePage);
