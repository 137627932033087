import { messageDeliveryStatuses } from 'mp-constants';

const getDateValidationClass = (value) => {
    try {
        if (!value || value.includes('_')) {
            return { className: 'is-invalid', error: 'Please set the date and time.' };
        }

        const date = Date.parse(value);

        if (isNaN(date)) {
            return { className: 'is-invalid', error: 'Invalid date format.' };
        }

        if (date < Date.now()) {
            return { className: 'is-invalid', error: 'The date should be in future.' };
        }

        if (date > (Date.now() + 1000 * 60 * 60 * 24 * 365 * 2)) {
            return { className: 'is-invalid', error: 'The date is too far in future.' };
        }

        return { className: 'is-valid' };
    } catch (e) {
        return { className: 'is-invalid', error: e };
    }
};

const getCampaignStatusDisplayName = (status) => {
    switch (status) {
        case messageDeliveryStatuses.delivered:
            return 'Delivered';
        case messageDeliveryStatuses.sending:
            return 'Sending';
        case messageDeliveryStatuses.noPhone:
            return 'Invalid phone';
        case messageDeliveryStatuses.noConsent:
            return 'No consent';
        case messageDeliveryStatuses.failed:
            return 'Failed';
        case messageDeliveryStatuses.undelivered:
            return 'Undelivered';
        case messageDeliveryStatuses.optOut:
            return 'Opted out';

        default:
            return status;
    }
};

const buildCampaignStatDataSource = (stat) => [
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.delivered),
        value: stat.delivery.delivered,
        color: '#5db88e',
    },
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.sending),
        value: stat.delivery.sending,
        color: '#bee5cf',
    },
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.noPhone),
        value: stat.delivery.noPhone,
        color: '#939393',
    },
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.noConsent),
        value: stat.delivery.noConsent,
        color: '#f4bf4c',
    },
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.failed),
        value: stat.delivery.failed,
        color: '#d15d4a',
    },
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.undelivered),
        value: stat.delivery.undelivered,
        color: '#e98873',
    },
    {
        title: getCampaignStatusDisplayName(messageDeliveryStatuses.optedOut),
        value: stat.delivery.optOut,
        color: '#383838',
    },
];

const getScheduleText = (campaign) => {
    if (campaign.schedule === 'asap' && campaign.status === 'active' && campaign.processingStatus === 'none') {
        return 'in a couple of minutes';
    }

    if (campaign.schedule === 'asap' && campaign.status === 'draft') {
        return 'as soon as possible';
    }

    const dt = new Date(campaign.scheduleMomentUTCTimestamp);

    return `${dt.toDateString()} at ${dt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`; // .toLocaleString()
};

const getScheduleLabel = (campaign) => {
    if (campaign.status === 'draft') {
        return 'When to send?';
    }

    if (campaign.status === 'active' && campaign.processingStatus === 'none') {
        return 'Will be sent';
    }

    if (campaign.status === 'active') {
        return 'Started on';
    }

    if (campaign.status === 'stopped' && campaign.processingStatus === 'complete') {
        return 'Sent on';
    }

    if (campaign.status === 'stopped') {
        return 'Was scheduled for';
    }

    return '';
};

const utils = {
    getDateValidationClass,
    getCampaignStatusDisplayName,
    buildCampaignStatDataSource,
    getScheduleText,
    getScheduleLabel,
};

export default utils;
