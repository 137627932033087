const helpArticles = {
    TNS: {
        TNS: { id: 1911 },
        GETTING_STARTED: { id: 1902 },
        CONVERSATIONS: { id: 1889 },
        INTEGRATION_OPTIONS: { id: 1914 },
        PRICING: { id: 1892 },
        SUBSCRIPTION: { id: 1901 },
        TWILIO_REGISTRATION: { id: 1933 },
        CONSENT: { id: 1902 },
        PROFILE: { id: 1913 },
        CAMPAIGN: { id: 1885 },
        CAMPAIGN_VIEW: { id: 1908 },
        CAMPAIGN_SETUP: { id: 1888 },
        TNS_FILTERS: { id: 1909 },
        TNS_MACROS: { id: 1895 },
        TNS_BILLING: { id: 1890 },
        BEST_PRACTICES: { id: 1897 },
    },
    TNS_FAQS: {
        CAMPAIGN_PRICE: { id: 1893 },
        MESSAGE_COST: { id: 1894 },
        NEED_CONSENT: { id: 1899 },
        CONTACTS_OPT_OUT: { id: 1916 },
        RECIPIENTS_RECEIVE_MESSAGE: { id: 1898 },
        TEXT_OUTSIDE_US_CAN: { id: 1896 },
        ACCOUNT_SUSPENDED: { id: 1900 },
        TNS_TRIAL_FREE: { id: 1915 },
        LIMIT_MESSAGES: { id: 1917 },
    },
};

export default helpArticles;
