// eslint-disable-next-line import/no-anonymous-default-export
export default {
    UNKNOWN: 'unknown',
    NEW_ACCOUNT: 'NewAccount',
    M_0: { days: 31, tag: '<1 month' },
    M_2: { days: 61, tag: '1-2 month' },
    M_3: { days: 91, tag: '2-3 month' },
    M_4: { days: 121, tag: '3-4 month' },
    M_5: { days: 151, tag: '4-5 month' },
    M_6: { days: 181, tag: '5-6 month' },
    M_7: { days: 211, tag: '6-7 month' },
    M_8: { days: 241, tag: '7-8 month' },
    M_9: { days: 271, tag: '8-9 month' },
    M_10: { days: 301, tag: '9-10 month' },
    M_11: { days: 331, tag: '10-11 month' },
    M_12: { days: 366, tag: '11-12 month' },
    Y_2: { days: 731, tag: '1-2 year' },
    Y_3: { days: 1096, tag: '2-3 year' },
    Y_4: { days: 1461, tag: '3-4 year' },
    Y_5: { days: 1826, tag: '4-5 year' },
    Y_OLD: { days: 1825, tag: '5+ year' },
};
