export const openElevioHome = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (window.openHelpCenterHome && typeof window.openHelpCenterHome === 'function') {
        // eslint-disable-next-line no-underscore-dangle
        window.openHelpCenterHome();
    }
};

export const openHelpArticle = (articleId) => {
    // eslint-disable-next-line no-underscore-dangle
    if (window.openHelpCenterArticle && typeof window.openHelpCenterArticle === 'function') {
        // eslint-disable-next-line no-underscore-dangle
        window.openHelpCenterArticle(articleId);
    }
};
