import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle, faSpinner, faPhoneSlash, faThumbsDown, faAngry, faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const msgStatusesMap = {
    sending: { color: '#5db88e', icon: faSpinner },
    delivered: { color: '#5db88e', icon: faCheckCircle },
    noPhone: { color: '#939393', icon: faPhoneSlash },
    noConsent: { color: '#f4bf4c', icon: faThumbsDown },
    failed: { color: '#d15d4a', icon: faExclamationCircle },
    undelivered: { color: '#e98873', icon: faExclamationCircle },
    optedOut: { color: '#383838', icon: faAngry },

};

function ConversationMessage({ message, orgName, hideHeader }) {
    const isIncomming = (message.campaignId.startsWith('INCOMING'));
    const textAlign = isIncomming ? 'text-left' : 'text-right';
    const blockAlign = isIncomming ? 'justify-content-start' : 'justify-content-end';

    const authorName = isIncomming ? message.displayName : orgName;
    let color = isIncomming ? '#ddddff' : '#bbffbb';

    if (!isIncomming && message.status === 'failed' && !message.id) {
        color = '#eeeeee';
    }

    const msgDate = new Date(message.lastChange);
    // eslint-disable-next-line max-len
    const msgDateStr = `${msgDate.toDateString()} at ${msgDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

    const iconSettings = msgStatusesMap[message.status];

    const overlay = (
        <Popover>
            <Popover.Content>
                <span className="h6">
                    {' '}
                    Status:
                    {message.status}
                </span>
                {' '}
                <br />
                {message.failureReason}
            </Popover.Content>
        </Popover>
    );

    let header = (
        <span>
            {authorName}
            {' '}
            on
            {' '}
            {msgDateStr}
        </span>
    );
    if (message.campaignId.startsWith('cmp-')) {
        header = (
            <span>
                <a href={`/campaigns/view/${message.campaignId}`}>
                    {authorName}
                    {' '}
                    on
                    {' '}
                    {msgDateStr}
                </a>
            </span>
        );
    }
    const messageBlock = (
        <div
            className="pt-3 pb-3 pl-4 pr-4 d-inline-block"
            style={{ backgroundColor: color, borderRadius: 8, maxWidth: '85%' }}
        >
            <div className={`h6 text-muted ${textAlign}`}>
                {header}
                <OverlayTrigger overlay={overlay} placement="auto">
                    <FontAwesomeIcon icon={iconSettings.icon} color={iconSettings.color} className="ml-2" />
                </OverlayTrigger>

            </div>
            {message.content || 'NO CONTENT FOR THIS MESSAGE'}
        </div>
    );
    return (
        <div className={`pb-2 ${hideHeader ? ' ' : ' pt-3'}`}>
            <div className={`d-flex ${blockAlign}`}>
                {messageBlock}
            </div>

        </div>
    );
}

ConversationMessage.propTypes = {
    // eslint-disable-next-line react/require-default-props
    message: propTypes.shape({
        id: propTypes.string,
        content: propTypes.string.isRequired,
        campaignId: propTypes.string.isRequired,
        displayName: propTypes.string.isRequired,
        lastChange: propTypes.number.isRequired,
        status: propTypes.string.isRequired,
        failureReason: propTypes.string,
    }),
    orgName: propTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    hideHeader: propTypes.bool,
};

export default ConversationMessage;
