import { useState, useCallback } from 'react';

const useTermsOfUse = () => {
    const [isOpen, setIsOpen] = useState(false);

    return {
        isOpen,
        open: useCallback(() => {
            setIsOpen(true);
        }, [setIsOpen]),
        close: useCallback(() => {
            setIsOpen(false);
        }, [setIsOpen]),
    };
};

export default useTermsOfUse;
