import apiClient from './apiClient';
import apiUrls from './apiUrls';

const getAvailableAccounts = () => apiClient.executeGet(
    apiUrls.accounts.list,
    (responseContent) => ({ accounts: responseContent }),
);

const registerNewAccount = () => apiClient.executeRequest({
    url: apiUrls.accounts.create,
    method: 'POST',
});

// ONLY USE FOR DEBUG
const deleteAccount = (accountId) => apiClient.executeRequest({
    url: apiUrls.accounts.delete,
    method: 'DELETE',
    body: { accountId },
});
// ONLY USE FOR DEBUG

const methods = {
    getAvailableAccounts,
    registerNewAccount,
    deleteAccount,
};

export default methods;
