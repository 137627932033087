import React from 'react';
import { Button } from 'react-bootstrap';
import accountService from '../../../apiServices/accountsService';
import { connect } from 'react-redux';

// Used to clear Twilio and Stripe data to test registration workflow
const DeleteAccount = ({ accountId }) => {
    const location = window.location.href;

    if (location.toLowerCase().includes('tns-sandbox.wildapricot') || process.env.NODE_ENV === 'development') {
        return (
            <div className="mx-auto">
                <Button
                    variant="danger"
                    /* eslint-disable max-len */
                    onClick={async () => accountService.deleteAccount(accountId)
                        .then(alert('Data deleted. Please reopen TNS from WildApricot'))}
                >
                    DEBUG: Delete Twilio & Stripe data
                </Button>
            </div>);
    }
    return null;
};

function mapStateToProps(state) {
    const { me } = state.auth;

    return {
        accountId: me?.account?.id || false,
    };
}
export default connect(
    mapStateToProps,
)(DeleteAccount);

