import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useL10nContext } from 'wa.admin-ui.l10n';
import useRenderTutorialWidget from './hooks/use-render-tutorial-widget';

const TutorialWidget = () => {
    const { labels } = useL10nContext();
    const [loading, error] = useRenderTutorialWidget();

    return (
        <>
            {loading && !error &&
        (
            <div className="d-flex h-100 text-center p-5 ">
                <Spinner className="mx-auto" animation="border" variant="primary" />
            </div>
        )}
            {error &&
      (
          <Alert variant="danger">
              {labels.ERROR.TUTORIAL_ERROR}
          </Alert>
      )}
        </>
    );
};

export default TutorialWidget;