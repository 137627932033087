import React from 'react';
import propTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import LoadingIndicator from '../LoadingIndicator';
import ConversationMessage from './ConversationMessage';

function Conversation({
    orgName, messages, isFetchingMessages, fetchMessagesError, nextDirectMessage,
}) {
    let lastDirection = null;
    const fakeSendInProgressMessage = {
        id: nextDirectMessage.id,
        content: nextDirectMessage.content || '',
        campaignId: 'DIRECT',
        displayName: nextDirectMessage.phone,
        lastChange: nextDirectMessage.sendTimestamp,
        status: nextDirectMessage.error ? 'failed' : 'sending',
        failureReason: nextDirectMessage.error,
    };

    const messageInProgressBlock = (!nextDirectMessage.isSending && !nextDirectMessage.error) ? null : (
        <ConversationMessage
            message={fakeSendInProgressMessage}
            orgName={orgName}
            hideHeader={lastDirection === 'OUT'}
        />
    );
    return (
        <div>

            <LoadingIndicator loading={isFetchingMessages && !messages.length}>
                <div>
                    {messages.map((m) => {
                        const curDirection = m.campaignId.startsWith('INCOMING') ? 'IN' : 'OUT';
                        const result = (
                            <ConversationMessage
                                message={m}
                                key={m.id}
                                orgName={orgName}
                                hideHeader={lastDirection === curDirection}
                            />
                        );
                        lastDirection = curDirection;
                        return result;
                    })}

                    {messageInProgressBlock}
                </div>

                <div hidden={messages.length > 0 || fetchMessagesError || isFetchingMessages}>
                    <Alert variant="info" className="text-center p-4 mt-5">
                        There are no messages for this recipient so far.
                        <br />
                        Would you like to send him a direct message?
                    </Alert>
                </div>
                <Alert variant="danger" hidden={!fetchMessagesError}>
                    <Alert.Heading>
                        Failed to load messages
                    </Alert.Heading>
                    {fetchMessagesError}
                </Alert>
            </LoadingIndicator>

        </div>
    );
}

Conversation.propTypes = {
    orgName: propTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    messages: propTypes.array.isRequired,
    isFetchingMessages: propTypes.bool.isRequired,
    // eslint-disable-next-line react/require-default-props
    fetchMessagesError: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    nextDirectMessage: propTypes.object,
};

export default Conversation;
