import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import SplashScreenLoader from './SplashScreenLoader/SplashScreenLoader';
import { isAppConfigValid } from '../utils/config';

function Root({ store, history }) {
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!isAppConfigValid() && !error) {
            setError(true);
        }
    }, [error]);

    return (
        <SplashScreenLoader
            loading={false}
            error={error}
            errorTitle="Unable to load application configuration file."
            errorMessage={(
                <>
                    `Please try to reload the page.

                    If the problem persists, please contact our
                    {' '}
                    <a href="mailto:support@wildapricot.com">support team</a>
                    .
                </>
            )}
        >
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </SplashScreenLoader>
    );
}

Root.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    store: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
};

export default Root;
