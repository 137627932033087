import React from 'react';
import { Alert } from 'react-bootstrap';

const ServiceNotice = () =>
    (
        <Alert variant="warning" className="mb-0">
            There are some open issues, please
            {' '}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://forums.wildapricot.com/forums/308923-service-notices-downtime-maintenance"
            >
                check our service notices.
            </a>
        </Alert>
    );

export default ServiceNotice;
