import { useEffect } from 'react';
import { client as elevioClient } from 'elevio';
import sectionKeys from '../../constants/section-keys';
import useInitElevio from './hooks/use-init-elevio';

// eslint-disable-next-line import/no-anonymous-default-export
export default (isTrial, setActiveArticle, handleOnElevioLinkClick, handleOpen, articleRef, sectionRef) => {
    const openSectionArticle = (articleId) => {
        handleOpen();
        handleOnElevioLinkClick(sectionKeys.SECTION_ELEVIO, articleId);
        setActiveArticle(articleId);
    };

    const [elevioInit] = useInitElevio(openSectionArticle, isTrial);

    useEffect(() => {
        elevioInit();
        elevioClient.on('search:article:clicked', ({ articleId }) => {
            handleOnElevioLinkClick(sectionKeys.SECTION_ELEVIO, articleId, articleRef.current);
            articleRef.current = articleId;
            sectionRef.current = sectionKeys.SECTION_ELEVIO;
        });

        elevioClient.on('suggestions:article:clicked', ({ articleId }) => {
            handleOnElevioLinkClick(sectionKeys.SECTION_ELEVIO, articleId, articleRef.current);
            articleRef.current = articleId;
            sectionRef.current = sectionKeys.SECTION_ELEVIO;
        });

        elevioClient.on('related:article:clicked', ({ articleId }) => {
            handleOnElevioLinkClick(sectionKeys.SECTION_ELEVIO, articleId, articleRef.current);
            articleRef.current = articleId;
            sectionRef.current = sectionKeys.SECTION_ELEVIO;
        });

        elevioClient.on('category:article:clicked', ({ articleId }) => {
            handleOnElevioLinkClick(sectionKeys.SECTION_ELEVIO, articleId, articleRef.current);
            articleRef.current = articleId;
            sectionRef.current = sectionKeys.SECTION_ELEVIO;
        });

        elevioClient.on('article:interlink:clicked', ({ id }) => {
            handleOnElevioLinkClick(sectionKeys.SECTION_ELEVIO, id, articleRef.current);
            articleRef.current = id;
            sectionRef.current = sectionKeys.SECTION_ELEVIO;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elevioInit]);
};
