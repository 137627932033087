module.exports = {
    INVALID_INPUT: 'ERR_INVALID_INPUT', // generic validation error, see errorDetails for more info
    MISSING_PARAMETER: 'ERR_MISSING_PARAMETER',
    INVALID_API_KEY: 'ERR_INVALID_API_KEY',
    UNSUPPORTED_BILLING_PLAN: 'ERR_UNSUPPORTED_BILLING_PLAN',
    SIMPLE_PASSWORD: 'ERR_SIMPLE_PASSWORD',
    DUPLICATE_EMAIL: 'ERR_DUPLICATE_EMAIL',

    INVALID_CREDENTIALS: 'ERR_INVALID_CREDENTIALS',

    AUTHORIZATION_HEADER_MISSING: 'ERR_AUTHORIZATION_HEADER_MISSING',
    AUTHORIZATION_SCHEMA_INVALID: 'ERR_AUTHORIZATION_SCHEMA_INVALID',
    AUTHORIZATION_TOKEN_MISSING: 'ERR_AUTHORIZATION_TOKEN_MISSING',
    AUTHORIZATION_TOKEN_INVALID: 'ERR_AUTHORIZATION_TOKEN_INVALID',
    AUTHORIZATION_TOKENCHECKSUM_INVALID: 'ERR_AUTHORIZATION_TOKENCHECKSUM_INVALID',

    CAMPAIGN_NON_EDITABLE: 'ERR_CAMPAIGN_NON_EDITABLE',
    CAMPAIGN_IMPOSSIBLE_TRANSITION: 'ERR_CAMPAIGN_IMPOSSIBLE_TRANSITION',

    TWILIO_ERROR: 'ERR_TWILIO_ERROR',
    STRIPE_ERROR: 'ERR_STRIPE_ERROR',
    INVALID_SUBSCRIPTION: 'ERR_INVALID_SUBSCRIPTION',
    SUBSCRIPTION_EXISTS: 'ERR_SUBSCRIPTION_EXISTS',

    ACCOUNT_SUSPENDED: 'ERR_ACCOUNT_SUSPENDED',

    INVALID_PHONE_NUMBER: 'ERR_INVALID_PHONE_NUMBER',
};
