import React from 'react';
import propTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {
    Form, Col, Button, Alert, Row,
} from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import SPAForm from '../SPAForm';
import './CheckoutForm.css';
import LoadingIndicator from '../LoadingIndicator';
import formUtils from '../../utils/formUtils';
import StripeButton from './StripeButton';
import { detectCountry, getRegionSettings } from '../../utils/geoLocation';

import { dateUtils } from 'mp-utils';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onFieldValChange = this.onFieldValChange.bind(this);

        const detectedCountry = detectCountry();
        this.state = getRegionSettings(detectedCountry);
    }

    componentDidMount() {
        if (this.props.defaultCountry) {
            const stateUpdate = getRegionSettings(this.props.defaultCountry);
            stateUpdate.default_state = this.props.defaultRegion;
            this.setState(stateUpdate);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultCountry !== this.props.defaultCountry) {
            const stateUpdate = getRegionSettings(this.props.defaultCountry);
            stateUpdate.default_state = this.props.defaultRegion;
            this.setState(stateUpdate);
        }
    }

    async handleSubmit(e) {
        const billingDetails = {
            name: e.target.elements.cardholder.value,
            email: this.props.defaultEmail,
            address: {
                city: e.target.elements.city.value,
                country: e.target.elements.country.value,
                state: e.target.elements.state.value,
                postal_code: e.target.elements.postal_code.value,
                line1: e.target.elements.address.value,
            },
        };

        const cardElement = this.props.elements.getElement('card');

        this.props.processCardInfo(this.props.stripe, cardElement, billingDetails);
    }

    onCountryChange(e) {
        this.setState({ country: e.target.value });
        this.setState(getRegionSettings(e.target.value));
    }

    onFieldValChange(event) {
        const stateUpdate = formUtils.inputChangeToStateUpdate(event);
        this.setState(stateUpdate);
    }

    render() {
        return (
            <SPAForm onSubmit={this.handleSubmit} id="cardInfoForm" autocomplete="on">
                <Row>
                    <Col xs={12} lg={7}>

                        <div className="d-lg-none mb-3">
                            {this.props.explanation}
                        </div>

                        <h5 className="mb-3">Card information</h5>
                        <Form.Group>
                            <Form.Label>Name on card</Form.Label>
                            <Form.Control
                                type="text"
                                name="cardholder"
                                autoComplete="cc-name"
                                maxLength={30}
                                placeholder="Jane Smith"
                                className="text-uppercase"
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Card</Form.Label>
                            <CardElement
                                className="form-control pt-2"
                                hidePostalCode
                                style={{ base: { fontSize: '16px' } }}
                            />
                        </Form.Group>

                        <h5 className="mt-4 mb-3">Billing address</h5>
                        <Form.Group>
                            <Form.Row>
                                <Col>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                        name="country"
                                        type="select"
                                        as="select"
                                        value={this.state.country}
                                        onChange={this.onCountryChange}
                                    >
                                        {this.state.country_options.map(((s, idx) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <option key={idx} value={s.value}>{s.displayName}</option>
                                        )))}
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Label>ZIP</Form.Label>
                                    <MaskedFormControl
                                        name="postal_code"
                                        autoComplete="postal-code"
                                        placeholder={this.state.postal_code_example}
                                        mask={this.state.postal_code_mask}
                                        required
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                name="address"
                                autoComplete="street-address"
                                type="text"
                                placeholder="123 Park St"
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Row>
                                <Col>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control name="city" autoComplete="address-level2" required />
                                </Col>
                                <Col>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        name="state"
                                        autoComplete="address-level1"
                                        type="text"
                                        placeholder={this.state.default_state}
                                        required
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        <Alert className="mt-3" variant="danger" hidden={!this.props.error}>
                            {this.props.error}
                        </Alert>
                    </Col>

                    <Col lg={5}>
                        <div className="d-none d-lg-block">
                            {this.props.explanation} 
                            <Alert variant="warning" className="tns-checkout-form__description">

                                On
                                {' '}
                                <span className="font-weight-bold">
                                    {dateUtils.nextBillingDate(Date.now(), Date.now() + 1000).toLocaleDateString()}
                                </span>
                                {' '}
                                your card will be charged:
                                <ul>
                                    <li>
                                        One-time registration fee of $30
                                    </li>
                                    <li>
                                        1st month dedicated virtual phone number fee of $6
                                    </li>
                                    <li>
                                        Message fees according to usage
                                    </li>
                                </ul>
                                You will be charged monthly thereafter for the virtual phone number and usage.
                            </Alert>
                            <Form.Row
                                className="pl-3"
                                style={{
                                    position: 'absolute', bottom: 15, right: 15, width: '100%',
                                }}
                            >
                                <Col>
                                    <Button
                                        type="button"
                                        block
                                        variant="outline-secondary"
                                        onClick={this.props.onCancel}
                                        disabled={this.props.isCreatingSubscription}
                                    >
                                        {this.props.cancelButtonTitle}
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="lg"
                                        block
                                        variant="primary"
                                        disabled={this.props.isCreatingSubscription}
                                    >
                                        <LoadingIndicator
                                            loading={this.props.isCreatingSubscription}
                                            loadingText="Saving..."
                                        >
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                                            {this.props.subscribeButtonTitle}
                                        </LoadingIndicator>
                                    </Button>
                                </Col>

                            </Form.Row>
                        </div>
                    </Col>
                </Row>
                <Row className="d-lg-none" noGutters>
                    <Col xs={12} sm={6} className="mt-2 pr-sm-2">
                        <Button
                            type="button"
                            block
                            variant="outline-secondary"
                            onClick={this.props.onCancel}
                            disabled={this.props.isCreatingSubscription}
                        >
                            {this.props.cancelButtonTitle}
                        </Button>
                    </Col>
                    <Col xs={12} sm={6} className="mt-2 pl-sm-2">
                        <Button type="submit" block variant="primary" disabled={this.props.isCreatingSubscription}>
                            <LoadingIndicator loading={this.props.isCreatingSubscription} loadingText="Saving...">
                                <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                                {this.props.subscribeButtonTitle}
                            </LoadingIndicator>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <StripeButton />
                    </Col>
                </Row>
            </SPAForm>
        );
    }
}

CheckoutForm.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    stripe: propTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    elements: propTypes.object.isRequired,
    processCardInfo: propTypes.func.isRequired,
    defaultEmail: propTypes.string.isRequired,
    onCancel: propTypes.func.isRequired,
    cancelButtonTitle: propTypes.string.isRequired,
    subscribeButtonTitle: propTypes.string.isRequired,
    error: propTypes.string,
    isCreatingSubscription: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    defaultCountry: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    defaultRegion: propTypes.string,
    explanation: propTypes.element.isRequired,
};

CheckoutForm.defaultProps = {
    error: '',
    isCreatingSubscription: false,
};

export default injectStripe(CheckoutForm);
