import React from 'react';
import PropTypes from 'prop-types';
import CoachesPicture from './coaches-picture';
import './home-splash.css';

const HomeSplash = ({ title, description }) =>
    (
        <div className="-wa-help-center__home-splash position-relative d-flex my-auto">
            <CoachesPicture />
            <div className="mx-2 my-auto px-4">
                <h4>
                    {title}
                </h4>
                <span>
                    {description}
                </span>
            </div>
        </div>
    );

HomeSplash.propTypes =
  {
      title: PropTypes.string,
      description: PropTypes.string,
  };

HomeSplash.defaultProps =
  {
      title: '',
      description: '',
  };

export default HomeSplash;