import React from 'react';
import propTypes from 'prop-types';
import {
    Form, Popover, OverlayTrigger, Row, Col, DropdownButton, ButtonGroup, Dropdown, Alert,
} from 'react-bootstrap';
import { smsCalculator } from 'mp-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { mergeTags } from 'mp-constants';
import { openHelpArticle } from '../../HelpCenter/src/utils/elevio/util/elevio-functions';
import elevioHelpArticles from '../../../constants/elevio-help-articles';

class SmsContentControl extends React.Component {
    constructor(props) {
        super(props);
        this.insertMergeTag = this.insertMergeTag.bind(this);
        this.getMergeTagOption = this.getMergeTagOption.bind(this);
        this.renderMergeTagListItem = this.renderMergeTagListItem.bind(this);
        this.onContentInputBlur = this.onContentInputBlur.bind(this);
        this.contentInput = React.createRef();
        this.state = {};
    }

    onContentInputBlur(event) {
        const { selectionStart, selectionEnd } = event.target;

        this.setState({
            contentSelectionStart: selectionStart,
            contentSelectionEnd: selectionEnd,
        });
    }

    getMergeTagOption(tag) {
        return (
            <Dropdown.Item
                disabled={!mergeTags.canResolve(tag, this.props.groupType)}
                eventKey={tag}
                onSelect={this.insertMergeTag}
            >
                {mergeTags.getTagDisplayName(tag)}
            </Dropdown.Item>
        );
    }

    insertMergeTag(eventKey) {
        const { contentSelectionStart, contentSelectionEnd } = this.state;
        const hasSelection = typeof contentSelectionStart === 'number' && typeof contentSelectionEnd === 'number';
        const { content = '' } = this.props;
        const contentLengthIndex = Math.max(content.length - 1, 0);
        const selectionStart = hasSelection ? contentSelectionStart : contentLengthIndex;
        const selectionEnd = hasSelection ? contentSelectionEnd : contentLengthIndex;
        // eslint-disable-next-line max-len
        const value = `${content.substring(0, selectionStart)}${hasSelection ? '' : '\n'}${eventKey}${content.substring(selectionEnd)}`;

        const fakeContentEvent = {
            target: { name: 'content', value },
        };
        this.props.onFieldValChange(fakeContentEvent);
        setTimeout(() => {
            this.contentInput.current.style.height = `${this.contentInput.current.scrollHeight + 10}px`;
        }, 0);
    }

    renderMergeTagListItem(tag) {
        if (mergeTags.canResolve(tag, this.props.groupType)) {
            return (
                <li key={tag} className="mt-1">
                    <b><i>{tag}</i></b>
                    {' '}
                    {mergeTags.getTagComment(tag)}
                    .
                </li>
            );
        }

        return (
            <li key={tag} className="mt-1 text-danger">
                <b><i>{tag}</i></b>
                {' '}
                cannot be resolved.
            </li>
        );
    }

    render() {
        const messageStat = smsCalculator.getMessageStat(this.props.content);

        const statPopover = (
            <Popover className="pl-4 pr-4 pt-3 bg-light">
                <p>
                    The message length exceeds the SMS message limit.
                    <br />
                    It can still be sent but will cost more since it will be sent as multiple chunks.
                </p>
                <p hidden={messageStat.encoding === 'gsm7'}>
                    The message contains characters, which are not supported by GSM7 encoding.
                    System will encode message as
                    {' '}
                    {messageStat.encoding}
                    , it reduces maximum message length to
                    {' '}
                    {messageStat.blockSize}
                </p>
            </Popover>
        );

        const detectedTags = mergeTags
            .getAllTags()
            .filter((mt) => (this.props.content || '').includes(mt));

        return (
            <Form.Group controlId="content" className="mt-4">
                <Row>
                    <Col className="d-flex align-items-end">
                        <Form.Label>
                            Message
                            <span className="ml-2 text-danger" hidden={messageStat.numberOfBlocks <= 1}>
                                <OverlayTrigger placement="top" overlay={statPopover}>
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                </OverlayTrigger>
                            </span>
                        </Form.Label>
                    </Col>
                    <Col className="text-right pb-1">
                        <DropdownButton
                            as={ButtonGroup}
                            title="Macros"
                            id="macros-button"
                            variant="outline-primary"
                            alignRight
                        >
                            {this.getMergeTagOption(mergeTags.fullName)}
                            <Dropdown.Divider />
                            {this.getMergeTagOption(mergeTags.eventTitle)}
                            {this.getMergeTagOption(mergeTags.eventStartDate)}
                            {this.getMergeTagOption(mergeTags.eventLocation)}
                            <Dropdown.Divider />
                            {this.getMergeTagOption(mergeTags.emailSubject)}
                            {this.getMergeTagOption(mergeTags.emailWebLink)}
                        </DropdownButton>
                    </Col>
                </Row>
                <Form.Control
                    name="content"
                    ref={this.contentInput}
                    maxLength={messageStat.maxLength}
                    onChange={this.props.onFieldValChange}
                    as="textarea"
                    rows="4"
                    value={this.props.content}
                    onBlur={this.onContentInputBlur}
                />
                <Alert hidden={!detectedTags.length} variant="info" className="mt-3 h6">
                    This message contains
                    {' '}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,max-len */}
                    <dfn
                        style={{ cursor: 'pointer', borderBottom: '1px dashed' }}
                        onClick={() => openHelpArticle(elevioHelpArticles.TNS.TNS_MACROS.id)}>
                        macros
                    </dfn>
                    :
                    <ul className="mt-2">
                        {detectedTags.map((t) => this.renderMergeTagListItem(t))}
                    </ul>
                </Alert>
            </Form.Group>
        );
    }
}

SmsContentControl.propTypes = {
    // eslint-disable-next-line react/require-default-props
    content: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    groupType: propTypes.string,
    onFieldValChange: propTypes.func.isRequired,
};

export default SmsContentControl;
