import { push } from 'connected-react-router';
import actionTypes from '../constants/actionTypes';
import authStorage from '../utils/authStorage';
import loginService from '../apiServices/loginService';
import signUpService from '../apiServices/signUpService';

export const create = (watoken) => async (dispatch) => {
    authStorage.clear();
    // start authentication process
    dispatch({
        type: actionTypes.Auth.SIGN_IN.start,
    });

    const result = await loginService.create(watoken);

    if (result.error) {
        dispatch({
            type: actionTypes.Auth.SIGN_IN.failure,
            error: result.error,
        });
    } else {
        authStorage.setState(result.token, Date.now() + 1000 * 60 * 60 * 24, result.accountId);
        dispatch({
            type: actionTypes.Auth.SIGN_IN.success,
            accessToken: result.token,
            accountId: result.accountId,
        });
    }
};

// example of a thunk using the redux-thunk middleware
export const signIn = (email, password) => async (dispatch) => {
    // start authentication process
    dispatch({
        type: actionTypes.Auth.SIGN_IN.start,
        email,
    });

    const result = await loginService.login(email, password);

    if (result.error) {
        dispatch({
            type: actionTypes.Auth.SIGN_IN.failure,
            error: result.error,
        });
    } else if (result.ambiguousAccount) {
        dispatch({
            type: actionTypes.Auth.SIGN_IN_AMBIGOUS_ACCOUNT,
            accessToken: result.token,
            ambiguousAccount: result.ambiguousAccount,
            availableAccounts: result.availableAccounts,
            email,
        });
    } else {
        authStorage.setState(result.token, Date.now() + 1000 * 60 * 60 * 24, result.accountId);
        dispatch({
            type: actionTypes.Auth.SIGN_IN.success,
            accessToken: result.token,
            accountId: result.accountId,
        });
    }
};

export const signInSelectAccount = (accountId) => async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.accessToken;

    const result = await loginService.loginSelectAccount(token, accountId);

    if (result.error) {
        dispatch({
            type: actionTypes.Auth.SIGN_IN.failure,
            error: result.error,
        });
    } else {
        authStorage.setState(result.token, Date.now() + 1000 * 60 * 60 * 24, result.accountId);
        dispatch({
            type: actionTypes.Auth.SIGN_IN.success,
            accessToken: result.token,
            accountId: result.accountId,
        });
    }
};

export const signOut = () => {
    authStorage.clear();
    window.location = window.location.origin;
};

export const validateToken = (token) => (dispatch) => {
    const authState = authStorage.getState();

    if (authState.token !== token || authState.expiresAt < Date.now()) {
        authStorage.clear();
        dispatch({
            type: actionTypes.Auth.SIGN_OUT,
        });
    }
};

export const startCreateAccount = (defaultEmail) => (dispatch) => {
    if (defaultEmail) {
        dispatch(push(`/signup?email=${encodeURIComponent(defaultEmail)}`));
    } else {
        dispatch(push('/signup'));
    }
};

export const restorePassword = (email) => async (dispatch) => {
    dispatch({
        type: actionTypes.Auth.REQUEST_RESTORE_PASSWORD.start,
    });

    const result = await loginService.restorePassword(email);

    if (result.error) {
        dispatch({
            type: actionTypes.Auth.REQUEST_RESTORE_PASSWORD.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Auth.REQUEST_RESTORE_PASSWORD.success,
        });
    }
};

export const resetPassword = (resetPasswordToken, password) => async (dispatch) => {
    dispatch({
        type: actionTypes.Auth.RESET_PASSWORD.start,
    });

    const result = await loginService.resetPassword(resetPasswordToken, password);

    if (result.error) {
        dispatch({
            type: actionTypes.Auth.RESET_PASSWORD.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Auth.RESET_PASSWORD.success,
        });

        if (result.ambiguousAccount) {
            dispatch({
                type: actionTypes.Auth.SIGN_IN_AMBIGOUS_ACCOUNT,
                accessToken: result.token,
                ambiguousAccount: result.ambiguousAccount,
                availableAccounts: result.availableAccounts,
                email: result.email,
            });
        } else {
            authStorage.setState(result.token, Date.now() + 1000 * 60 * 60 * 24, result.accountId);
            dispatch({
                type: actionTypes.Auth.SIGN_IN.success,
                accountId: result.accountId,
                accessToken: result.token,
            });
        }
    }
};

export const fetchCurrentUserInfo = () => async (dispatch, getState) => {
    if (getState().auth.fetchingCurrentUser) return;

    dispatch({
        type: actionTypes.Auth.FETCH_CURRENT_USER_INFO.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await loginService.getMe();

    if (result.error || !result.account || !result.account.waAccountId) {
        dispatch({
            type: actionTypes.Auth.FETCH_CURRENT_USER_INFO.failure,
            error: result.error,
        });

        authStorage.clear();
    } else {
        dispatch({
            type: actionTypes.Auth.FETCH_CURRENT_USER_INFO.success,
            user: result.user,
            account: result.account,
        });
    }
};

export const setupComplete = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Auth.SETUP_COMPLETE.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await signUpService.setupComplete();

    if (result.error) {
        dispatch({
            type: actionTypes.Auth.SETUP_COMPLETE.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Auth.SETUP_COMPLETE.success,
        });

        dispatch(push('/campaigns'));
    }
};
