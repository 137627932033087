import React from 'react';
import {
    Card, Container, Row, Col, Table, Badge, Alert,
} from 'react-bootstrap';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { dateUtils } from 'mp-utils';
import * as types from '../../types';

function InvoiceLine({ invoiceLine }) {
    if (invoiceLine.type === 'text-campaign') {
        return (
            <tr style={{ lineHeight: '150%' }}>
                <td>
                    Text campaign:
                    <Link to={`/campaigns/view/${invoiceLine.resourceId}`} className="ml-1">{invoiceLine.title}</Link>
                </td>
                <td className="text-right text-nowrap">
                    $
                    {invoiceLine.price.toFixed(2)}
                    {' '}
                    USD
                </td>
            </tr>
        );
    }

    return (
        <tr style={{ lineHeight: '150%' }}>
            <td>
                {invoiceLine.title}
                {' '}
                x
                {' '}
                {invoiceLine.numberOfItems}
            </td>
            <td className="text-right  text-nowrap">
                $
                {invoiceLine.price.toFixed(2)}
                {' '}
                USD
            </td>
        </tr>
    );
}

InvoiceLine.propTypes = {
    // eslint-disable-next-line react/require-default-props
    invoiceLine: types.invoiceLine,
};

function BillingMonthCard({ invoice, subscriptionTimestamp }) {
    const year = invoice.period.slice(0, 4);
    //  let month = Date.
    const date = new Date(`${invoice.period}-03`); // 2009-11-10
    const month = date.toLocaleString('default', { month: 'long' });
    const renderStatusBadge = () => {
        let variant = 'info';
        const status = invoice.status || 'pending';
        let statusText = '';
        switch (status) {
            case 'finalized':
                variant = 'success';
                statusText = 'Finalized';
                break;
            case 'paid':
                variant = 'success';
                statusText = 'Paid';
                break;
            case 'pending':
                variant = 'secondary';
                statusText = 'Estimated total';
                break;
            case 'unpaid':
                variant = 'danger';
                statusText = 'Not paid';
                break;
            default:
                return <span />;
        }

        return (
            <Badge className="p-2" hidden={invoice.totalSum === 0} variant={variant}>
                {statusText}
            </Badge>
        );
    };

    const renderUpcomingInvoiceNotification = () => {
        const status = invoice.status || 'pending';
        if (status !== 'pending' || !subscriptionTimestamp) return <span />;

        const nextBillingDate = dateUtils.nextBillingDate(subscriptionTimestamp, Date.now());
        return (
            <Alert variant="secondary" className="mb-0">
                This is a preview of the invoice that will be billed on
                {' '}
                {dateUtils.toDateString(nextBillingDate)}
                .
                It will accumulate all usage until that date.
            </Alert>
        );
    };

    const renderNextPayment = () => {
        if (invoice.invoicePdf) {
            return (
                <a
                    className="text-right mr-1"
                    hidden={!invoice.invoicePdf}
                    href={invoice.invoicePdf}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                    Download invoice
                </a>
            );
        }

        const status = invoice.status || 'pending';
        if (status !== 'pending' || !subscriptionTimestamp) return <span />;

        const nextBillingDate = dateUtils.nextBillingDate(subscriptionTimestamp, Date.now());
        return (
            <div>
                Next bill date:
                {' '}
                {dateUtils.toDateString(nextBillingDate)}
            </div>
        );
    };

    const invoiceLines = invoice.invoiceLines.slice();
    invoiceLines.sort((l1, l2) => (l1.type < l2.type ? -1 : 1));

    /*const totalMessagesCount = invoiceLines
        .filter((l) => l.type === 'incoming-message' || l.type === 'text-campaign')
        .reduce((acc, line) => acc + line.numberOfItems, 0);
    const discountedMessages = Math.max(totalMessagesCount - 3000, 0);
    const discount = discountedMessages * 0.009;

    const invoiceAfterDiscount = invoice.totalSum - discount;
    if (discount > 0) {
        invoiceLines.push({
            title: 'Volume discount',
            type: 'other',
            price: -discount,
            numberOfItems: discountedMessages,
        });
    }*/

    return (
        <Card>
            <Card.Header>
                <Row className="p-0 m-0" noGutters>
                    <Col>
                        <h5 className="ml-3">
                            {year}
                            {' '}
                            {month}
                        </h5>
                    </Col>
                    <Col className="text-right mr-3">
                        {renderNextPayment()}
                    </Col>
                </Row>

            </Card.Header>

            <Card.Body>
                <Container fluid>
                    <Row>
                        <Col md="auto">
                            <div className="mt-5 mb-5 text-nowrap" style={{ fontSize: 72 }}>
                                $
                                {invoice.totalSum.toFixed(2)}
                            </div>
                            <div className="h5 mb-3 text-center">
                                {renderStatusBadge()}
                            </div>
                        </Col>
                        <Col>
                            <div hidden={!invoice.totalSum}>
                                <div>
                                    <span className="h6 ml-1">Usage</span>
                                </div>
                                <Table size="sm" className="mt-2">
                                    <tbody>
                                        {/* eslint-disable-next-line react/no-array-index-key */}
                                        {invoiceLines.map((s, idx) => <InvoiceLine key={idx} invoiceLine={s} />)}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderUpcomingInvoiceNotification()}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
}

BillingMonthCard.propTypes = {
    // eslint-disable-next-line react/require-default-props
    invoice: types.invoice,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    idx: propTypes.number,
    // eslint-disable-next-line react/require-default-props
    subscriptionTimestamp: propTypes.number,
};

export default BillingMonthCard;
