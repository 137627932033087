import React from 'react';
import './coaches-picture.css';
import { useThemeContext } from '../../../../../../../../../../entities/help-widget/context/theme-context-provider';

const CoachesPicture = () => {
    const { logo } = useThemeContext();

    return (
        <>
            <img className="-wa-help-center__wa-team rounded-circle" src={logo} alt="" />
        </>
    );
};

export default CoachesPicture;