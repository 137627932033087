import React from 'react';
import PropTypes from 'prop-types';
import  Icon from '../../../../../../../Common/icon';
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { useL10nContext } from 'wa.admin-ui.l10n';

import HelpTooltip from '../../../../common/tooltip';
import './chat-badge.css';

export const IncomingMessages = ({ onClick, onKeyDown, unread }) =>
    (
        <div
            data-test-id="wa-help-badge__chat-badge__incoming"
            className="-wa-help-center__chat-widget-icon__unread-message
      rounded-circle bg-danger text-center text-white ml-auto position-fixed cursor-pointer"
            onClick={onClick}
            onKeyDown={onKeyDown}
        >
            {unread}
        </div>
    );

IncomingMessages.propTypes =
  {
      onClick: PropTypes.func.isRequired,
      onKeyDown: PropTypes.func.isRequired,
      unread: PropTypes.number,
  };

IncomingMessages.defaultProps =
  {
      unread: null,
  };

const ChatBadge = ({ onClick }) => {
    const { labels } = useL10nContext();

    return (
        <HelpTooltip text={labels.HELP_BADGE.CHAT_TITLE} placement="left">
            <div
                id="-wa-help-center__chat-widget-icon"
                data-test-id="wa-help-badge__chat-badge"
                // eslint-disable-next-line max-len
                className="rounded-circle bg-primary cursor-pointer d-flex align-items-center justify-content-center"
                onClick={onClick}
                onKeyDown={onClick}
            >
                <div>
                    <div className="text-white">
                        <Icon
                            size="2x"
                            icon={faCommentDots}
                        />
                    </div>
                </div>
            </div>
        </HelpTooltip>
    );
};

ChatBadge.propTypes =
  {
      onClick: PropTypes.func.isRequired,
  };

export default ChatBadge;
