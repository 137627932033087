import React from 'react';
import twilioStatuses from '../../constants/twilioStatuses';
import { Alert } from 'react-bootstrap';

const TwilioRegNumberNotification = ({ status, isReadOnly, loading, einFound, einChecked, error }) => {
    const displaySuccessBox = !isReadOnly && !loading && einFound && einChecked;
    const displayEinNotFound = !isReadOnly && !loading && !einFound && einChecked;

    return (
        <div>
            { status  !== twilioStatuses.twilio_rejected && !isReadOnly &&
                (!displaySuccessBox && !displayEinNotFound) &&
                (<div className="pt-2">
                    <div className="text-muted">
                        <span>
                            We cannot process registration without a valid Tax ID/EIN/BN. &nbsp;
                        </span>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers"
                        >
                            Learn more
                        </a>
                    </div>
                </div>)
            }
            {error && !loading ?
                (<Alert className="mt-3" variant="danger">
                    An error has occured while attempting to verify your information with
                    {' '}
                    <a
                        target='_blank'
                        // eslint-disable-next-line max-len
                        href="https://www.irs.gov/charities-non-profits/exempt-organizations-business-master-file-extract-eo-bmf"
                        rel="noopener noreferrer"
                    >
                        Internal Revenue Services
                    </a>.
                    {' '}
                    Please enter information manually.
                </Alert>)
                :
                displaySuccessBox ? (<Alert className="mt-3" variant="info">
                    We have verified your EIN with
                    {' '}
                    <a
                        target='_blank'
                        // eslint-disable-next-line max-len
                        href="https://www.irs.gov/charities-non-profits/exempt-organizations-business-master-file-extract-eo-bmf"
                        rel="noopener noreferrer"
                    >
                        Internal Revenue Services
                    </a>.
                    {' '}
                    Please review the provided information to confirm its accuracy.
                </Alert>)
                    :
                    displayEinNotFound &&
                    (
                        <Alert className="mt-3" variant="info">
                            Please complete the form below with information that matches your organization's
                            Tax ID/EIN/BN.
                        </Alert>
                    )
            }
        </div>
    );
};

export default TwilioRegNumberNotification;
