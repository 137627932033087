import React from 'react';
import { Button } from 'react-bootstrap';
import CampaginTextLogo from './CampaginTextLogo';
import { NavLink } from 'react-router-dom';

function CampaignsListFirstUseState() {
    return (
        <div style={{ margin: 'auto', maxWidth: 800 }} className="text-center mt-5 mb-5 pt-lg-5 pb-lg-5">
            <div className="pb-4">
                <CampaginTextLogo />
            </div>
            <h3 className="mb-5 font-weight-bold">
                Please register to begin sending text messages.
            </h3>
            <h5 className="mb-3">
                You can start messaging as soon as your registration is approved
            </h5>
            <div>
                <NavLink exact to='/subscription-wizard' className="mt-4">
                    <Button type="button" variant="primary" className="mt-4">
                        Register
                    </Button>
                </NavLink>
            </div>
        </div>
    );
}

export default CampaignsListFirstUseState;
