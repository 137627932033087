import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function recipientGroupTypesReducer(state = initialState.recipients.groupTypes, action) {
    switch (action.type) {
        case actionTypes.Recipients.FETCH_RECIPIENTS_GROUP_TYPES.start:
            return {
                ...state,
                isFetching: true,
                error: null,
                items: null,
            };

        case actionTypes.Recipients.FETCH_RECIPIENTS_GROUP_TYPES.success:
            return {
                ...state,
                isFetching: false,
                error: null,
                items: action.groupTypes,
            };

        case actionTypes.Recipients.FETCH_RECIPIENTS_GROUP_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                items: null,
            };

        default:
            return state;
    }
}
