import userRoles from './constants/user-roles';

// eslint-disable-next-line import/no-anonymous-default-export
export default (roles) => {
    const tags = [];

    if (roles.includes('SysAdmin')) tags.push(userRoles.SYS_ADMIN);
    if (roles.includes('AccountReadOnlyAdministrator')) tags.push(userRoles.READ_ONLY);
    if (roles.includes('AccountAdministrator')) tags.push(userRoles.FULL_ADMIN);
    if (roles.includes('MembershipManager')) tags.push(userRoles.MEMBER_MANAGER);
    if (roles.includes('EventManager')) tags.push(userRoles.EVENT_MANAGER);
    if (roles.includes('DonationManager')) tags.push(userRoles.DONATION_MANAGER);
    if (roles.includes('WebSiteAdmin')) tags.push(userRoles.WEB_ADMIN);
    if (roles.includes('RestrictedWebSiteAdmin')) tags.push(userRoles.RESTRICTED_WEB_ADMIN);
    if (roles.includes('NewsletterManager')) tags.push(userRoles.NEWSLETTER_MANAGER);
    if (roles.includes('StoreManager')) tags.push(userRoles.STORE_MANAGER);

    return (tags);
};
