// eslint-disable-next-line import/no-anonymous-default-export
export default
{
    ACCOUNT_ID: '5937fe412bf99',
    CONFIG:
    {
        hidePopupWidgetButton: true,
        enabled: true,
        hideLauncher: true,
        articleFeedbackEnabled: true,
    },
    TRANSLATIONS:
    {
        modules: {
            support: {
                create_new_ticket: 'Submit support request',
            },
        },
    },
};
