import React from 'react';
import propTypes from 'prop-types';

import { Spinner } from 'react-bootstrap';

function LoadingIndicator({ loading, loadingText, children }) {
    if (loading) {
        return (
            <div>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ml-2">{loadingText || 'Loading...'}</span>
            </div>
        );
    }

    return (
        <div>{children}</div>
    );
}

LoadingIndicator.propTypes = {
    // eslint-disable-next-line react/require-default-props
    loading: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    children: propTypes.node,
    // eslint-disable-next-line react/require-default-props
    loadingText: propTypes.string,
};

export default LoadingIndicator;
