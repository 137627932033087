import React from 'react';
import { errorCodes } from 'mp-constants';

const decodeErrorCode = ({ reason, errorDetails, parameter }) => {
    switch (reason) {
        case errorCodes.MISSING_PARAMETER:
            return `Missing parameter: "${parameter}"`;
        case errorCodes.SUBSCRIPTION_EXISTS:
            return 'You have already set up a payment method for this account.';
        case errorCodes.UNSUPPORTED_BILLING_PLAN:
            // eslint-disable-next-line max-len
            return 'WildAprico\'s text messaging can not work with WildAprocot accounts on Free or Trial billing plans.';
        case errorCodes.INVALID_API_KEY:
            // eslint-disable-next-line max-len
            return 'API key does not provide access to WildApricot system. Please copy and paste the key without modifications.';
        case errorCodes.SIMPLE_PASSWORD:
            return 'Password is too simple.';
        case errorCodes.DUPLICATE_EMAIL:
            return (
                <p>
                    Email is already in use.

                    Learn how to
                    {' '}
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                        href="#"
                        onClick={(e) => { /* open freshdesk "60000681469" */ e.preventDefault(); }}
                    >
                        create an additional account for the same email
                    </a>
                    .
                </p>
            );
        case errorCodes.INVALID_INPUT:
            return `Invalid request parameters: ${errorDetails}`;
        case errorCodes.STRIPE_ERROR:
            return `Payment processor reported an error: ${errorDetails}`;
        default:
            return reason;
    }
};

const methods = { decodeErrorCode };

export default methods;
