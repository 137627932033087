// eslint-disable-next-line import/no-anonymous-default-export
export default
{
    SYS_ADMIN: 'SysAdmin',
    READ_ONLY: 'ReadOnlyAdmin',
    FULL_ADMIN: 'FullAdmin',
    MEMBER_MANAGER: 'MembershipManager',
    EVENT_MANAGER: 'EventManager',
    DONATION_MANAGER: 'DonationManager',
    WEB_ADMIN: 'WebsiteEditor',
    RESTRICTED_WEB_ADMIN: 'RestrictedWebsiteEditor',
    NEWSLETTER_MANAGER: 'NewsletterManager',
    STORE_MANAGER: 'StoreManager',
};
