import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function latestIncomingMessagesReducer(state = initialState.messages.latestIncomingMessages, action) {
    switch (action.type) {
        case actionTypes.Messages.FETCH_LATEST_INCOMMING_MESSAGES.start: {
            return {
                ...state,
                isFetching: true,
                error: null,
            };
        }

        case actionTypes.Messages.FETCH_LATEST_INCOMMING_MESSAGES.failure: {
            return {
                ...state,
                isFetching: false,
                error: action.error,
                messages: null,
            };
        }

        case actionTypes.Messages.FETCH_LATEST_INCOMMING_MESSAGES.success: {
            return {
                ...state,
                isFetching: false,
                error: null,
                messages: action.messages,
            };
        }

        default:
            return state;
    }
}
