import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './RegisterNotification.scss';

const RegisterNotification = () => {
    return (
        <div className="tns-global-notification__register-container text-center py-3">
            <span>
                Please register to begin sending messages.
                <NavLink exact to='/subscription-wizard' className="mt-4">
                    <Button variant="warning" className="mx-4">
                        Register
                    </Button>
                </NavLink>
            </span>
        </div>
    );
};

export default RegisterNotification;
