import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import * as wizardActionCreator from '../../../../../../actions/subscriptionWizardActions';
import * as billingActionCreator from '../../../../../../actions/billingActions';
import LoadingIndicator from '../../../../../LoadingIndicator';
import StripeCheckoutForm from './StripeCheckoutForm';
import { detectDefaultLocation } from '../../../../../../utils/geoLocation';
import { getTwilioFormData } from '../../../../../../reducers/subscriptionWizardReducer';
import { saveTwilioSubscription } from '../../../../../../actions/settingsActions';
import { fetchCurrentUserInfo } from '../../../../../../actions/loginActions';

const StepSubscriptionSettings = (
    {
        // External
        onPrev,

        // Internal
        defaultEmail,
        stripePublishableKey,
        isFetching,
        error,
        // eslint-disable-next-line react/forbid-prop-types
        wizardActions,
        billingActions,
        isCreatingSubscription,
        // eslint-disable-next-line react/require-default-props
        defaultCountry,
        // eslint-disable-next-line react/require-default-props
        defaultRegion,
    }) => {

    useEffect( () => {
        if (!stripePublishableKey && !isFetching) {
            billingActions.fetchStripeConstants();
        }
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const twilioFormData = useSelector(getTwilioFormData);

    const onComplete = () => {
        // Redirect to main page and grab subscription values once wizard complete
        dispatch(fetchCurrentUserInfo());
        history.push('/campaigns');
    };
    const submitTwilioData = (twilio) => dispatch(saveTwilioSubscription(twilio));

    const confirmSubscription = async (stripe, cardElement, billingDetails, twilio ) => {
        await wizardActions.createStripeAndTwilioSubscription(stripe, cardElement, billingDetails, twilio, onComplete);
    };

    const renderForm = () => {
        if (stripePublishableKey) {
            return (
                <StripeProvider apiKey={stripePublishableKey}>
                    <Elements>
                        <StripeCheckoutForm
                            twilioSubscriptionData={ { twilioSubscription: { ...twilioFormData } } }
                            processCardInfo={confirmSubscription}
                            submitTwilioData={submitTwilioData}
                            onCancel={onPrev}
                            cancelButtonTitle="Back"
                            subscribeButtonTitle="Pay now"
                            defaultEmail={defaultEmail}
                            error={error}
                            isCreatingSubscription={isCreatingSubscription}
                            defaultCountry={defaultCountry}
                            defaultRegion={defaultRegion}
                        />
                    </Elements>
                </StripeProvider>
            );
        }

        return (
            <span>...</span>
        );
    };

    return (
        <LoadingIndicator loading={isFetching}>
            {renderForm()}
        </LoadingIndicator>
    );
};

StepSubscriptionSettings.propTypes = {
    // external
    onCancel: propTypes.func.isRequired,
    cancelButtonTitle: propTypes.string.isRequired,
    subscribeButtonTitle: propTypes.string.isRequired,
    onProcessCardInfo: propTypes.func.isRequired,
    explanation: propTypes.element.isRequired,

    // internal
    defaultEmail: propTypes.string.isRequired,
    stripePublishableKey: propTypes.string,
    isFetching: propTypes.bool,
    error: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    wizardActions: propTypes.object.isRequired,
    billingActions: propTypes.object.isRequired,
    isCreatingSubscription: propTypes.bool.isRequired,
    // eslint-disable-next-line react/require-default-props
    defaultCountry: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    defaultRegion: propTypes.string,
};

StepSubscriptionSettings.defaultProps = {
    stripePublishableKey: '',
    isFetching: false,
    error: '',
};

function mapStateToProps(state) {
    return {
        defaultEmail: state.auth.me.user.email,
        stripePublishableKey: state.billing.stripePublishableKey,
        isFetching: state.billing.isFetchingStripeConstants || false,
        error: state.billing.fetchStripeConstantsError || state.subscriptionWizard.error,
        isCreatingSubscription: state.subscriptionWizard.isCreatingSubscription || false,
        ...detectDefaultLocation(),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        wizardActions: bindActionCreators(wizardActionCreator, dispatch),
        billingActions: bindActionCreators(billingActionCreator, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StepSubscriptionSettings);
