import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import md5 from 'js-md5';
import * as actionCreators from '../../actions/textCampaignsActions';
import LoadingIndicator from '../LoadingIndicator';
import CampaignSuggestionCard from './CampaignSuggestionCard';
import CampaignsList from './CampaignsList';
import CampaignsListFirstUseState from './CampaignsListFirstUseState';
import CampaignsListRegisterState from './CampaignsListRegisterState';
import twilioStatuses from '../../constants/twilioStatuses';

class CampaignsDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAllSuggestions: false,
            dismissedSuggestions: JSON.parse(sessionStorage.getItem('dismissedSuggestions') || '[]'),
        };
        this.renderSuggestionCard = this.renderSuggestionCard.bind(this);
        this.renderSuggestionsList = this.renderSuggestionsList.bind(this);
        this.handleStartNewCampaign = this.handleStartNewCampaign.bind(this);
        this.showAllSuggestions = this.showAllSuggestions.bind(this);
        this.dismissSuggestion = this.dismissSuggestion.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchCampaignSuggestions();
    }

    handleStartNewCampaign() {
        this.props.actions.startNewCampaignFromTemplate();
    }

    dismissSuggestion(suggestion) {
        const hash = md5(suggestion.title);
        const { dismissedSuggestions: prevDismissedSuggestions } = this.state;

        const dismissedSuggestions = [hash, ...prevDismissedSuggestions];
        this.setState({ dismissedSuggestions });
        sessionStorage.setItem('dismissedSuggestions', JSON.stringify(dismissedSuggestions));
    }

    showAllSuggestions() {
        this.setState({ showAllSuggestions: true });
    }

    renderSuggestionCard(suggestion, idx) {
        return (
            <div key={idx}>
                <CampaignSuggestionCard
                    suggestion={suggestion}
                    useSuggestion={this.props.actions.startNewCampaign}
                    onDismissCard={this.dismissSuggestion}
                />
            </div>
        );
    }

    renderSuggestionsList() {
        let suggestions = this.props.suggestions.filter((s) => !this.state.dismissedSuggestions.includes(md5(s.title)));

        const showMoreButtonHidden = this.state.showAllSuggestions || suggestions.length <= 3;

        suggestions = this.state.showAllSuggestions
            ? suggestions
            : suggestions.slice(0, 3);

        return (
            <div>
                {suggestions.map((s, idx) => this.renderSuggestionCard(s, idx))}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    className="showAllSuggestionsButton text-uppercase h6"
                    onClick={this.showAllSuggestions}
                    hidden={showMoreButtonHidden}
                >
                    Show more suggestions
                    {' '}
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            </div>
        );
    }

    render() {
        const billingPlan = (this.props.me.account.billingPlan || '').toLowerCase();

        const registerState =
        !this.props.me.account.hasSubscription && !this.props.me.account.hasTwilioAccount
        && !(billingPlan === 'free' || billingPlan === 'trial')
        && (this.props.Status !== twilioStatuses.in_review && this.props.Status !== twilioStatuses.pending_review);

        if (registerState) {
            return (
                <CampaignsListRegisterState />
            );
        }
        const hasSuggestions = this.props.suggestions.length > 0;
        const hasCampaigns = this.props.lastCampaigns.length > 0 || this.props.scheduledCampaigns.length > 0;
        // eslint-disable-next-line no-mixed-operators
        if (!this.props.isFetching && (this.props.firstUse || !hasSuggestions && !hasCampaigns)) {
            return (
                <CampaignsListFirstUseState
                    showRegistrationComplete={this.props.showRegistrationComplete}
                    onNewCampaign={this.handleStartNewCampaign} />
            );
        }

        return (
            <div>
                <div className="pl-0 pr-0 pt-3 mr-0 ml-0">
                    <LoadingIndicator loading={this.props.isFetching}>
                        {this.renderSuggestionsList()}
                        <Alert variant="danger" className="mt-2" hidden={this.props.error == null}>
                            <Alert.Heading>Failed to load data</Alert.Heading>
                            <p>{this.props.error}</p>
                        </Alert>
                    </LoadingIndicator>
                    {/* eslint-disable-next-line max-len */}
                    <CampaignsList campaigns={this.props.lastCampaigns} onCampaignOpen={this.props.onCampaignOpen} listTitle="Latest sent campaigns" />
                    {/* eslint-disable-next-line max-len */}
                    <CampaignsList campaigns={this.props.scheduledCampaigns} onCampaignOpen={this.props.onCampaignOpen} listTitle="Scheduled campaigns" />
                </div>
            </div>
        );
    }
}

CampaignsDashboard.propTypes = {
    status: propTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    me: propTypes.object.isRequired,
    isFetching: propTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    suggestions: propTypes.array,
    // eslint-disable-next-line react/require-default-props
    error: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    lastCampaigns: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    scheduledCampaigns: propTypes.array,
    onCampaignOpen: propTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    firstUse: propTypes.bool,
    showRegistrationComplete: propTypes.bool,
};

CampaignsDashboard.defaultProps = {
    showRegistrationComplete: false,
};

function mapStateToProps(state) {
    const { account } = state.auth.me;

    return {
        status: account?.twilioSubscription?.Status,
        me: state.auth.me,
        isFetching: state.campaigns.isFetchingSuggestions || false,
        suggestions: state.campaigns.suggestions || [],
        error: state.campaigns.fetchSuggestionsError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CampaignsDashboard);
