import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button, Tabs, Tab, Container, Row, Col, FormCheck,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from '../../actions/textCampaignsActions';
import CampaignsList from './CampaignsList';
import CampaignsDashboard from './CampaignsDashboard';
import './Campaigns.css';
import AuthorizationRequired from '../AuthorizationRequired';
import CampaignsListFirstUseState from './CampaignsListFirstUseState';
import CampaignsListNothingFound from './CampaignsListNothingFound';
import LoadingIndicator from '../LoadingIndicator';
import twilioStatuses from '../../constants/twilioStatuses';
import CampaignsListRegisterState from './CampaignsListRegisterState';

function SearchInput({ onSearchChange }) {
    return (
        <Container className="pl-0 pr-0 ml-0 mr-0">
            <Row>
                <Col>
                    <div>
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="mt-2 ml-3 position-absolute"
                            style={{ opacity: 0.5 }}
                        />
                        <input
                            name="searchText"
                            onChange={onSearchChange}
                            type="text"
                            className="pl-5 mt-3 mb-2 mimicFormControl"
                            placeholder="Search"
                        />
                    </div>
                </Col>
                <Col sm={12} md="auto">
                    <div className="mt-3 mb-2 pt-1 mr-2">
                        <FormCheck
                            name="myCampaignsFilter"
                            onChange={onSearchChange}
                            label={<span className="ml-2">Show only my campaigns</span>}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

SearchInput.propTypes = {
    onSearchChange: propTypes.func.isRequired,
};

class CampaignsHomePage extends React.Component {
    constructor() {
        super();

        this.state = { search: '' };

        this.onCampaignOpen = this.onCampaignOpen.bind(this);
        this.onNewCampaign = this.onNewCampaign.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.getCampaigns = this.getCampaigns.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.renderCampaignsList = this.renderCampaignsList.bind(this);
        this.getLastCampaigns = this.getLastCampaigns.bind(this);
        this.getScheduledCampaigns = this.getScheduledCampaigns.bind(this);
        this.renderDashboard = this.renderDashboard.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchCampaigns();
    }

    onSearchChange(event) {
        if (event.target.name === 'searchText') this.setState({ search: event.target.value });
        if (event.target.name === 'myCampaignsFilter') this.setState({ onlyMyCampaigns: event.target.checked });
    }

    onCampaignOpen(campaignId, status) {
        if (campaignId) this.props.actions.navigateToCampaign(campaignId, status);
        else this.props.actions.startNewCampaignFromTemplate();
    }

    onNewCampaign() {
        this.props.actions.startNewCampaignFromTemplate();
    }

    onTabChange(key) {
        this.props.actions.showCampaignsList(key);
    }

    getCampaigns(status) {
        let result = this.props.campaigns.filter((c) => c.status === status);
        if (this.state.search.length > 0) {
            const search = this.state.search.toLowerCase();
            const isMatch = (campaign) => {
                const index = `${campaign.title} | ${campaign.content}`.toLowerCase();
                return index.includes(search);
            };
            result = result.filter(isMatch);
        }

        if (this.state.onlyMyCampaigns) {
            result = result.filter((c) => c.authorId === this.props.currentUserId);
        }

        result.sort((a, b) => (a.lastChange > b.lastChange ? -1 : 1));
        return result;
    }

    getLastCampaigns() {
        const result = this.props.campaigns
            .filter((c) => c.status === 'active' || c.status === 'stopped')
            .filter((c) => c.statistics != null);
        result.sort((a, b) => (a.lastChange > b.lastChange ? -1 : 1));
        return result.slice(0, 3);
    }

    getScheduledCampaigns() {
        const result = this.props.campaigns
            .filter((c) => c.status === 'active' && c.processingStatus === 'none');
        result.sort((a, b) => (a.scheduleMomentUTCTimestamp < b.scheduleMomentUTCTimestamp ? -1 : 1));
        return result;
    }

    renderCampaignsList() {
        const billingPlan = (this.props.me.account.billingPlan || '').toLowerCase();

        const registerState =
            !this.props.me.account.hasSubscription && !this.props.me.account.hasTwilioAccount
            && !(billingPlan === 'free' || billingPlan === 'trial')
            && (this.props.Status !== twilioStatuses.in_review && this.props.Status !== twilioStatuses.pending_review);

        if (registerState) {
            return (
                <CampaignsListRegisterState />
            );
        }

        if (this.props.campaigns.length === 0) {
            return <CampaignsListFirstUseState
                onNewCampaign={this.onNewCampaign}
                showRegistrationComplete={this.props.showRegistrationComplete}
            />;
        }
        const activeCampaigns = this.getCampaigns('active');
        const drafts = this.getCampaigns('draft');
        const pastCampaigns = this.getCampaigns('stopped');
        if (!activeCampaigns.length && !drafts.length && !pastCampaigns.length) {
            return (
                <div>
                    <SearchInput onSearchChange={this.onSearchChange} />
                    <CampaignsListNothingFound />
                </div>
            );
        }

        return (
            <div>
                <SearchInput onSearchChange={this.onSearchChange} />
                <CampaignsList
                    campaigns={this.getCampaigns('active')}
                    listTitle="Active campaigns"
                    onCampaignOpen={this.onCampaignOpen}
                />
                <CampaignsList
                    campaigns={this.getCampaigns('draft')}
                    listTitle="Drafts"
                    onCampaignOpen={this.onCampaignOpen}
                />
                <CampaignsList
                    campaigns={this.getCampaigns('stopped')}
                    listTitle="Past campaigns"
                    onCampaignOpen={this.onCampaignOpen}
                />
            </div>
        );
    }

    renderDashboard() {
        return (
            <div>
                <CampaignsDashboard
                    lastCampaigns={this.getLastCampaigns()}
                    scheduledCampaigns={this.getScheduledCampaigns()}
                    onCampaignOpen={this.onCampaignOpen}
                    firstUse={!this.props.campaigns.length}
                    onNewCampaign={this.props.actions.startNewCampaignFromTemplate}
                    showRegistrationComplete={this.props.showRegistrationComplete}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="mainContent">
                <AuthorizationRequired />
                {/* eslint-disable-next-line max-len */}
                <Button type="button" variant="primary" className="hideOnMobile float-right mt-2" onClick={this.onNewCampaign}>
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    Start a new campaign
                </Button>
                <h1 className="fixedHeader">Text campaigns</h1>
                {/* eslint-disable-next-line max-len */}
                <Button type="button" variant="primary" block className="hideOnDesktop pt-3 pb-3 mb-2 mt-1" onClick={this.onNewCampaign}>
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    Start a new campaign
                </Button>

                <div className="mt-4">
                    <LoadingIndicator loading={!this.props.campaignsLoaded}>

                        <Tabs activeKey={this.props.filterStatus} onSelect={this.onTabChange} mountOnEnter>
                            {/* eslint-disable-next-line max-len */}
                            <Tab eventKey="dashboard" title="Dashboard" style={{ backgroundImage1: 'linear-gradient(to bottom, #e0FFF0, #ffffff)', minHeight: '70vh' }}>
                                {this.renderDashboard()}
                            </Tab>
                            <Tab eventKey="active" title="All campaigns">

                                {this.renderCampaignsList()}
                            </Tab>
                        </Tabs>
                    </LoadingIndicator>
                </div>

                <div style={{ height: 100 }} />
            </div>
        );
    }
}

CampaignsHomePage.propTypes = {
    campaignsLoaded: propTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    campaigns: propTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    filterStatus: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    currentUserId: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    showRegistrationComplete: propTypes.bool,
};

CampaignsHomePage.defaultProps = {
    showRegistrationComplete: false,
};

function mapStateToProps(state) {
    const { account } = state.auth.me;

    return {
        status: account?.twilioSubscription?.Status,
        me: state.auth.me,
        campaignsLoaded: state.campaigns.campaignsLoaded,
        filterStatus: state.router.location.hash.replace('#', '') || 'dashboard',
        campaigns: state.campaigns.campaigns,
        currentUserId: state.auth.me.user.id,
        showRegistrationComplete: state.subscriptionWizard.wizardCompleted || false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CampaignsHomePage);
