import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './authReducer';
import signUp from './signUpReducer';
import campaigns from './campaignsReducer';
import campaignDetails from './campaignDetailsReducer';
import recipients from './recipientsReducer';
import billing from './billingReducer';
import subscriptionWizard from './subscriptionWizardReducer';
import settings from './settingsReducer';
import usersManagement from './usersReducer';
import messages from './messagesReducer';
import accounts from './accountsReducer';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    signUp,
    campaigns,
    campaignDetails,
    recipients,
    billing,
    subscriptionWizard,
    settings,
    usersManagement,
    messages,
    accounts,
});

export default rootReducer;
