import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function billingReducer(state = initialState.billing, action) {
    switch (action.type) {
        case actionTypes.Billing.FETCH_INVOICES.start:
            return {
                ...state,
                isFetching: true,
                invoices: null,
            };

        case actionTypes.Billing.FETCH_INVOICES.success:
            return {
                ...state,
                isFetching: false,
                invoices: action.invoices,
                error: null,
            };

        case actionTypes.Billing.FETCH_INVOICES.failure:
            return {
                ...state,
                isFetching: false,
                invoices: null,
                error: action.error,
            };

        case actionTypes.Billing.FETCH_STRIPE_CONSTANTS.start:
            return {
                ...state,
                isFetchingStripeConstants: true,
            };

        case actionTypes.Billing.FETCH_STRIPE_CONSTANTS.failure:
            return {
                ...state,
                isFetchingStripeConstants: false,
                stripePublishableKey: null,
                fetchStripeConstantsError: action.error,
            };

        case actionTypes.Billing.FETCH_STRIPE_CONSTANTS.success:
            return {
                ...state,
                isFetchingStripeConstants: false,
                stripePublishableKey: action.stripePublishableKey,
                fetchStripeConstantsError: null,
            };

        case actionTypes.Billing.FETCH_SUBSCRIPTION_STATUS.start:
            return { ...state, isFetchingSubscriptionStatus: true };

        case actionTypes.Billing.FETCH_SUBSCRIPTION_STATUS.failure:
            return {
                ...state,
                isFetchingSubscriptionStatus: false,
                fetchSubscriptionStatusError: action.error,
                subscriptionStatus: null,
            };

        case actionTypes.Billing.FETCH_SUBSCRIPTION_STATUS.success:
            return {
                ...state,
                isFetchingSubscriptionStatus: false,
                subscriptionStatus: action.status,
                fetchSubscriptionStatusError: null,
                card: action.card,
                subscriptionTimestamp: action.subscriptionTimestamp,
            };

        case actionTypes.Billing.START_SUBSCRIPTION.start:
            return { ...state, isCreatingSubscription: true };

        case actionTypes.Billing.START_SUBSCRIPTION.failure:
            return {
                ...state,
                isCreatingSubscription: false,
                subscriptionError: action.error,
            };

        case actionTypes.Billing.START_SUBSCRIPTION.success:
            return {
                ...state,
                isCreatingSubscription: false,
                subscriptionStatus: action.status,
                subscriptionError: null,
            };

        case actionTypes.Billing.UPDATE_PAYMENT_METHOD.start:
            return { ...state, isCreatingSubscription: true };

        case actionTypes.Billing.UPDATE_PAYMENT_METHOD.failure:
            return {
                ...state,
                isCreatingSubscription: false,
                subscriptionError: action.error,
            };

        case actionTypes.Billing.UPDATE_PAYMENT_METHOD.success:
            return {
                ...state,
                isCreatingSubscription: false,
                subscriptionStatus: action.status,
                subscriptionError: null,
            };

        case actionTypes.Billing.CANCEL_SUBSCRIPTION.start:
            return { ...state, isCancellingSubscription: true };

        case actionTypes.Billing.CANCEL_SUBSCRIPTION.failure:
            return {
                ...state,
                isCancellingSubscription: false,
                cancelSubscriptionError: action.error,
            };

        case actionTypes.Billing.CANCEL_SUBSCRIPTION.success:
            return {
                ...state,
                isCancellingSubscription: false,
                subscriptionStatus: action.status,
                cancelSubscriptionError: null,
            };

        default:
            return state;
    }
}
