import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import formatWaInformation from '../../../utils/format-wa-info';
import { getIsAuthenticated, getMe } from '../../../reducers/authReducer';
import useInitZendeskWidget from './use-init-zendesk-widget';

// Online chat widget
// This component does not render any HTML element. Instead it manages Zendesk chat widget
// See wa-account-info-model. Reflects Wild Apricot adminpanel accountInfo and accountFinancialInfo data
function ZendeskWidget() {
    const [data, setData] = useState(null);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const accountInfo = useSelector(getMe);

    useEffect(() => {
        if (isAuthenticated && accountInfo) {
            const formattedResult = formatWaInformation(accountInfo);
            setData(formattedResult);
        }
    }, [isAuthenticated, accountInfo]);

    useInitZendeskWidget(data, {});
    return null;
}

export default ZendeskWidget;
