import React from 'react';
import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import CampaignStatusIcon from './CampaignStatusIcon';
import campaignUtils from '../campaignUtils';

function CampaignsListRecord({ campaign, onCampaignOpen }) {
    const onRowClick = () => {
        onCampaignOpen(campaign.id, campaign.status);
    };

    const renderDate = () => (
        <div className="pb-1">
            <span className="h6 text-muted">
                {campaignUtils.getScheduleLabel(campaign)}
            </span>
            {' '}
            {campaignUtils.getScheduleText(campaign)}
        </div>
    );

    const renderStatistics = () => {
        if (campaign.statistics) {
            const total = Object.values(campaign.statistics.delivery).reduce(
                (acc, element) => acc + element,
                0,
            );

            const { delivered } = campaign.statistics.delivery;

            return (
                <div>
                    <span className="h6 text-muted">Delivered to</span>
                    {' '}
                    {delivered}
                    {' '}
                    out of
                    {' '}
                    {total}
                </div>
            );
        }

        return null;
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="campaignRow pt-3 pb-3" onClick={onRowClick}>
            <h5 className="campaignListItemHeader">
                <CampaignStatusIcon campaign={campaign} />
                {campaign.title}
            </h5>
            <Row className="pl-2">
                <Col xs={12} sm={8}>
                    <div className="pb-1">
                        <span className="h6 text-muted">To</span>
                        {' '}
                        {campaign.recipientsListName}
                    </div>

                    {renderDate()}
                </Col>
                <Col xs={12} sm={4}>
                    <div className="text-info" hidden={!campaign.systemComment}>
                        {campaign.systemComment}
                    </div>

                    {renderStatistics()}
                </Col>
            </Row>
        </div>
    );
}

CampaignsListRecord.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    campaign: propTypes.object.isRequired,
    onCampaignOpen: propTypes.func.isRequired,
};

export default CampaignsListRecord;
