/* istanbul ignore file */
// Account Properties
export const getAccountName = accountInfo => accountInfo?.association?.name;
export const getAccountId = accountInfo => accountInfo?.association?.id;
export const getBillingPlanName = accountInfo => accountInfo?.association?.billingPlan?.baseName;
export const getOrganizationType = accountInfo => accountInfo?.association?.organizationType;
export const getUrlName = accountInfo => accountInfo?.association?.urlName;

export const isCloned = accountInfo => accountInfo?.association?.isCloned;
export const isTestMode = accountInfo => accountInfo?.association?.isTestMode;
export const isTrialAccount = accountInfo => accountInfo?.association?.billingPlan?.baseName === 'Trial';
export const isFreeAccount = accountInfo => accountInfo?.association?.billingPlan?.baseName === 'Free';

// User Properties
export const getUserId = accountInfo => accountInfo?.user?.id;
export const getUserRoles = accountInfo => accountInfo?.user?.roles;
export const getUserName = accountInfo => accountInfo?.user?.name;
export const getUserEmail = accountInfo => accountInfo?.user?.email;

// Financial Properties
export const getPaymentGateway = accountInfo => accountInfo?.payments?.currentPaymentGateway;
export const getAssociationCountry = accountInfo => accountInfo?.payments?.associationCountry;

// Account Age
const getCreatedDate = accountInfo => accountInfo?.association?.createdDate;
export const getAccountAgeDays = (accountInfo) => {
    const createdDate = new Date(getCreatedDate(accountInfo) * 1000);
    const dateNow = new Date();
    return Math.ceil(Math.abs(dateNow - createdDate) / (1000 * 60 * 60 * 24));
};

// Other properties
export const isFromTNS = accountInfo => accountInfo.TNS;
