import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import {
    Form, Row, Col, Button, Alert, OverlayTrigger, Tooltip, InputGroup,
} from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import { object, string, bool } from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import TermsOfUse, { useTermsOfUse } from '../TwilioSubscriptionForm/TermsOfUse';
import TwilioAutoFillField from './TwilioAutoFillField';
import LoadingIndicator from '../LoadingIndicator';
import { detectDefaultLocation, useRegionSettings } from '../../utils/geoLocation';
import supportedRegions from '../../constants/supportedRegions';
import { getUser, getAccount } from '../../reducers/authReducer';
import { getTwilioFormData } from '../../reducers/subscriptionWizardReducer';
import { saveTwilioFormData } from '../../actions/subscriptionWizardActions';
import { fetchEin, fetchTwilioSubscription, saveTwilioSubscription } from '../../actions/settingsActions';
import {
    getTwilioSubscriptionFetchError,
    getTwilioSubscriptionSaving,
    getTwilioSubscriptionSaveError,
    getTwilioSubscriptionFromAccount,
    getTwilioSubscription,
    getSettings,
    getEin,
}
    from '../../reducers/settingsReducer';
import './TwilioSubscriptionForm.scss';
import twilioStatuses from '../../constants/twilioStatuses';
import businessTypeNames from './constants/businessTypeNames';
import validateEinValues from './helpers/validateEinValues';
import TwilioRegNumberNotification from './TwilioRegNumberNotification';
import TwilioSubscriptionDescription from './TwilioSubscriptionDescription';
import InfoTooltip from '../Common/InfoTooltip';
import mapKeyToEinModel from './helpers/mapKeyToEinModel';

const validationSchema = object().shape({
    cusFriendlyName: string().trim().required('This field is required.'),
    cusFirstName: string().trim().required('This field is required.'),
    cusLastName: string().trim().required('This field is required.'),
    cusEmail: string().trim().email('E-mail is invalid.').required('This field is required.'),
    cusPhone: string().trim().matches(
        /^\+\d \d{3} \d{3}-\d{4}$/,
        'Invalid phone number.',
    ).required('This field is required.'),
    bizUrl: string().trim().required('This field is required.'),
    bizOrgTitle: string().trim().required('This field is required.'),
    bizRegType: string().trim().required('This field is required.'),
    bizRegTypeName: string()
        .when('bizRegType', { is: 'Other', then: string().trim().required('This field is required.') }),
    bizRegNumber: string().trim().required('This field is required.'),
    cusStreet: string().trim().required('This field is required.'),
    cusCity: string().trim().required('This field is required.'),
    cusRegion: string().trim().matches(/^[A-Z]{2}$/, 'Invalid state value.').required('This field is required.'),
    cusPostalCode: string().trim().matches(
        /^(\d{5}(-\d{4})?|[a-z]\d[a-z] \d[a-z]\d)$/i,
        'Invalid postal code.',
    ).required('This field is required.'),
    cusIsoCountry: string().trim().required().oneOf(Object.keys(supportedRegions), 'Invalid value.'),
    accepted: bool().required().oneOf([true], 'Terms must be accepted.'),
});

const normalizeTwilioSubscriptionParams = (values) => ({
    bizOrgTitle: values.bizOrgTitle.trim(),
    cusFriendlyName: values.cusFriendlyName.trim(),
    cusEmail: values.cusEmail.trim(),
    cusFirstName: values.cusFirstName.trim(),
    cusLastName: values.cusLastName.trim(),
    cusPhone: `+${values.cusPhone.replace(/[^\d]+/g, '')}`,
    bizUrl: values.bizUrl.trim(),
    bizRegType: values.bizRegType.trim(),
    bizRegTypeName: values.bizRegTypeName.trim(),
    bizRegNumber: values.bizRegNumber.trim(),
    cusPeronFriendlyName: `${values.cusFirstName.trim()} ${values.cusLastName.trim()}`,
    cusStreet: values.cusStreet.trim(),
    cusCity: values.cusCity.trim(),
    cusRegion: `${values.cusIsoCountry.trim()}-${values.cusRegion.trim()}`.toUpperCase(),
    cusPostalCode: values.cusPostalCode.trim().toUpperCase(),
    cusIsoCountry: values.cusIsoCountry.trim().toUpperCase(),
    accepted: !!values.accepted,
});

const denormalizeTwilioSubscriptionParams = (subscriptionParams = {}, defaultParams = {}) => ({
    bizOrgTitle: subscriptionParams.bizOrgTitle || defaultParams.bizOrgTitle || '',
    cusFriendlyName: subscriptionParams.cusFriendlyName || defaultParams.cusFriendlyName || '',
    cusEmail: subscriptionParams.cusEmail || defaultParams.cusEmail || '',
    cusFirstName: subscriptionParams.cusFirstName || defaultParams.cusFirstName || '',
    cusLastName: subscriptionParams.cusLastName || defaultParams.cusLastName || '',
    cusPhone: (subscriptionParams.cusPhone || defaultParams.cusPhone || '')
        .replace(/[^\d]+/, '')
        .split('')
        .reduce(
            (acc, digit, i) => `${acc}${i === 1 || i === 4 ? ' ' : ''}${i === 7 ? '-' : ''}${digit}`,
            '+',
        ),
    bizUrl: subscriptionParams.bizUrl || defaultParams.bizUrl || '',
    bizRegType: subscriptionParams.bizRegType || defaultParams.bizRegType || '',
    bizRegNumber: subscriptionParams.bizRegNumber || defaultParams.bizRegNumber || '',
    cusStreet: subscriptionParams.cusStreet || defaultParams.cusStreet || '',
    cusCity: subscriptionParams.cusCity || defaultParams.cusCity || '',
    cusRegion: (subscriptionParams.cusRegion || defaultParams.cusRegion || '').split('-').pop(),
    cusPostalCode: (subscriptionParams.cusPostalCode || defaultParams.cusPostalCode || ''),
    cusIsoCountry: subscriptionParams.cusIsoCountry || defaultParams.cusIsoCountry || '',
    bizRegTypeName: subscriptionParams.bizRegTypeName
    || (defaultParams.cusIsoCountry === 'CA' ?  businessTypeNames.BN : businessTypeNames.EIN) || '',
    accepted: subscriptionParams.accepted || defaultParams.accepted || false,
});

function TwilioSubscriptionForm({ onNext, onPrev, isSettings, isReadOnly }) {
    const dispatch = useDispatch();

    const [success, setSuccess] = useState(false);
    const [einToCheck, setEinToCheck] = useState(null);
    const [einChecked, setEinChecked] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const { defaultCountry, defaultRegion } = detectDefaultLocation();

    const { Status } = useSelector(getTwilioSubscription) || {};
    const { isFetchingEin, fetchEinError, einFetched } = useSelector(getSettings) || {};
    const ein = useSelector(getEin) || {};
    const user = useSelector(getUser);
    const account = useSelector(getAccount);
    const { isOpen, open, close } = useTermsOfUse();
    const twilioSubscription = useSelector(getTwilioSubscriptionFromAccount);
    const fetchError = useSelector(getTwilioSubscriptionFetchError);
    const twilioFormData = useSelector(getTwilioFormData) || {};
    const isSaving = useSelector(getTwilioSubscriptionSaving);
    const saveError = useSelector(getTwilioSubscriptionSaveError);

    const einFound = (ein !== null || true) && Object.keys(ein).length > 0;

    useEffect( () => {
        if (isReadOnly) dispatch(fetchTwilioSubscription);
    }, [dispatch, isReadOnly, isSettings]);

    const denormalizedTwilioSubscriptionParams =
        denormalizeTwilioSubscriptionParams((!isSettings && twilioFormData)
            || twilioSubscription, {
            bizOrgTitle: account && account.organizationTitle,
            bizUrl: account && account.primaryDomainName,
            cusFriendlyName: account && account.organizationTitle,
            cusEmail: user && user.email,
            cusFirstName: user && user.firstName,
            cusLastName: user && user.lastName,
            cusRegion: defaultRegion,
            cusIsoCountry: defaultCountry,
            bizRegType: defaultCountry === 'CA' ? businessTypeNames.BN : businessTypeNames.EIN,
        });
    const { regionSettings, onCountryChange, onRegionChange } = useRegionSettings(
        denormalizedTwilioSubscriptionParams.cusIsoCountry,
        denormalizeTwilioSubscriptionParams.cusRegion,
    );
    const {
        // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
        country_options,
        // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
        postal_code_mask,
        // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
        postal_code_example,
        states,
        // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
        default_state,
    } = regionSettings;
    const postalCodeRef = useRef();
    const countryRef = useRef();

    const handleFetchEin = (value) => {
        setVerifying(true);
        setTimeout(() => {
            dispatch(fetchEin(value || ''));
            setEinChecked(true);
            setVerifying(false);
        }, 2000);
    };

    useEffect(() => {
        if (einFound) {
            const event = { bubbles: true, target: { value: 'US' } };
            onCountryChange('US');
            countryRef.current.dispatchEvent(new Event('change', event));
        }
    }, [einFound, onCountryChange]);

    if (fetchError) {
        return (<Alert variant="danger">
            <Alert.Heading>Unable to load TNS data.</Alert.Heading>
            <p className="fontSize1rem">
                Please try to reload the page.

                If the problem persists, please contact our
                {' '}
                <a href="mailto:support@wildapricot.com">support team</a>
                .
            </p>
        </Alert>);
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={denormalizedTwilioSubscriptionParams}
            onSubmit={(values) => {
                dispatch(
                    isSettings ?
                        saveTwilioSubscription({ twilioSubscription: normalizeTwilioSubscriptionParams(values) }
                            , () => setSuccess(true))
                        :
                        saveTwilioFormData({ twilioSubscription: normalizeTwilioSubscriptionParams(values) }
                            , onNext),
                );
            }
            }
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
            }) => (
                <Form
                    id="contact-information-and-terms-of-use"
                    onSubmit={handleSubmit}
                    style={{ maxWidth: '52.25rem' }}
                >
                    <TwilioSubscriptionDescription
                        isSettings={isSettings}
                        rejected={Status === twilioStatuses.twilio_rejected}
                    />
                    <Row>
                        <Form.Group as={Col} controlId="bzRegNumber">
                            <Form.Label>
                                Tax ID/EIN/BN
                            </Form.Label>
                            <InfoTooltip
                                id='TNS-Settings_consent-field'
                                placement='right'
                            >
                                <p>
                                    The Tax ID number, also known in the US as the
                                    <b> Employer Identification Number (EIN) </b>
                                    and in Canada as the
                                    <b> Business Number (BN) </b>, is a unique
                                    nine-digit number assigned by the government to business entities for
                                    the purposes of identification.
                                </p>
                            </InfoTooltip>
                            <InputGroup>
                                <Form.Control
                                    readOnly={isReadOnly}
                                    type="text"
                                    name="bizRegNumber"
                                    value={values.bizRegNumber}
                                    isInvalid={touched.bizRegNumber && !!errors.bizRegNumber}
                                    onChange={(event) => {
                                        handleChange(event);
                                        if (values.cusIsoCountry === 'US') {
                                            setEinToCheck(event.target.value);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-secondary"
                                        hidden={isSettings}
                                        className="ml-0 rounded-right"
                                        style={{ width: '7rem' }}
                                        onClick={() => handleFetchEin(values.bizRegNumber)}
                                        disabled={isFetchingEin || verifying || values.bizRegNumber === ''}
                                    >
                                        Search
                                    </Button>
                                </InputGroup.Append>
                                <Form.Control.Feedback type="invalid">
                                    {errors.bizRegNumber}
                                </Form.Control.Feedback>
                            </InputGroup>
                            <div>
                                <TwilioRegNumberNotification
                                    einChecked={einChecked}
                                    einFound={einFound}
                                    isReadOnly={isReadOnly}
                                    loading={!einFetched || verifying}
                                    error={fetchEinError}
                                />
                            </div>
                        </Form.Group>
                    </Row>
                    <LoadingIndicator loading={isFetchingEin || verifying} loadingText="Verifying...">
                        <div hidden={!einFetched && !isSettings}>
                            <Form.Group className="pb-2 mb-4">
                                <Row>
                                    <Form.Group as={Col} controlId="cusFriendlyName">
                                        <TwilioAutoFillField
                                            label="Business name"
                                            input={einToCheck}
                                            value={values.cusFriendlyName}
                                            setValue={setFieldValue}
                                            name="cusFriendlyName"
                                            type="text"
                                            autoFillData={ein}
                                            overwrite
                                            isInvalid={touched.cusFriendlyName && !!errors.cusFriendlyName}
                                            feedbackError={errors.cusCity}
                                            isReadOnly={isReadOnly}
                                            isSettings={isSettings}
                                            onChange={handleChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="bizUrl">
                                        <Form.Label>Business URL</Form.Label>
                                        <Form.Control
                                            readOnly={isReadOnly}
                                            type="text"
                                            name="bizUrl"
                                            value={values.bizUrl}
                                            isInvalid={touched.bizUrl && !!errors.bizUrl}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                        >
                                            {errors.cusFriendlyName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="cusFirstName">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control
                                            readOnly={isReadOnly}
                                            type="text"
                                            name="cusFirstName"
                                            autoComplete="given-name"
                                            value={values.cusFirstName}
                                            isInvalid={touched.cusFirstName && !!errors.cusFirstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                        >
                                            {errors.cusFirstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="cusLastName">
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control
                                            readOnly={isReadOnly}
                                            type="text"
                                            name="cusLastName"
                                            autoComplete="family-name"
                                            value={values.cusLastName}
                                            isInvalid={touched.cusLastName && !!errors.cusLastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                        >
                                            {errors.cusLastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Form.Group controlId="cusEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        readOnly={isReadOnly}
                                        type="email"
                                        name="cusEmail"
                                        autoComplete="email"
                                        value={values.cusEmail}
                                        isInvalid={touched.cusEmail && !!errors.cusEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                    >
                                        {errors.cusEmail}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Row>
                                    <Form.Group as={Col} controlId="cusPhone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            readOnly={isReadOnly}
                                            as={MaskedInput}
                                            name="cusPhone"
                                            autoComplete="tel"
                                            mask="+1 111 111-1111"
                                            placeholder="+1 123 456–7890"
                                            value={values.cusPhone}
                                            isInvalid={touched.cusPhone && !!errors.cusPhone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                        >
                                            {errors.cusPhone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} />
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="cusStreet">
                                <TwilioAutoFillField
                                    label="Street Address"
                                    input={einToCheck}
                                    value={values.cusStreet}
                                    setValue={setFieldValue}
                                    name="cusStreet"
                                    type="text"
                                    autoFillData={ein}
                                    overwrite
                                    autoComplete="address-line1"
                                    isInvalid={touched.cusStreet && !!errors.cusStreet}
                                    feedbackError={errors.cusStreet}
                                    isReadOnly={isReadOnly}
                                    isSettings={isSettings}
                                    onChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Form.Group as={Col} controlId="cusCity">
                                        <TwilioAutoFillField
                                            label="City"
                                            input={einToCheck}
                                            value={values.cusCity}
                                            setValue={setFieldValue}
                                            name="cusCity"
                                            type="text"
                                            autoFillData={ein}
                                            overwrite
                                            autoComplete="address-level2"
                                            isInvalid={touched.cusCity && !!errors.cusCity}
                                            isSettings={isSettings}
                                            feedbackError={errors.cusCity}
                                            isReadOnly={isReadOnly}
                                            onChange={handleChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="cusRegion">
                                        <TwilioAutoFillField
                                            label={ values.cusIsoCountry === 'CA' ?
                                                'Province'
                                                :
                                                'State'
                                            }
                                            input={einToCheck}
                                            value={Object.keys(states).includes(values.cusRegion) ?
                                                values.cusRegion : setFieldValue('cusRegion', default_state)}
                                            setValue={setFieldValue}
                                            autoComplete="address-level1"
                                            name="cusRegion"
                                            type="select"
                                            as="select"
                                            country={values.cusIsoCountry}
                                            overwrite
                                            autoFillData={ein}
                                            isInvalid={touched.cusRegion && !!errors.cusRegion}
                                            feedbackError={errors.cusRegion}
                                            isReadOnly={isReadOnly}
                                            isSettings={isSettings}
                                            onChange={(event) => {
                                                onRegionChange(values.cusIsoCountry, event.target.value);
                                                handleChange(event);
                                            }}
                                            handleBlur={handleBlur}
                                            options={Object.entries(states)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="cusIsoCountry">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            disabled={isReadOnly}
                                            name="cusIsoCountry"
                                            type="select"
                                            as="select"
                                            value={values.cusIsoCountry}
                                            isInvalid={!!errors.cusIsoCountry}
                                            ref={countryRef}
                                            onChange={async (event) => {
                                                onCountryChange(event.target.value);
                                                if (event.target.value === 'CA') {
                                                    setFieldValue('bizRegType', businessTypeNames.BN);
                                                    setFieldValue('bizRegTypeName', businessTypeNames.BN);
                                                } else {
                                                    setFieldValue('bizRegType', businessTypeNames.EIN);
                                                    setFieldValue('bizRegTypeName', businessTypeNames.EIN);
                                                }

                                                handleChange(event);
                                                if (postalCodeRef.current) {
                                                    postalCodeRef.current.mask.setValue('');
                                                }

                                                if (einFound) {
                                                    await setFieldValue('bizRegType', businessTypeNames.EIN);
                                                    await setFieldValue('bizRegTypeName', businessTypeNames.EIN);
                                                    await setFieldValue('cusIsoCountry', 'US');
                                                    // eslint-disable-next-line max-len
                                                    onRegionChange('US', mapKeyToEinModel('cusRegion', ein));
                                                    // eslint-disable-next-line max-len
                                                    await setFieldValue('cusRegion', mapKeyToEinModel('cusRegion', ein));
                                                } else {
                                                    await setFieldValue('cusPostalCode', '', false);
                                                    await setFieldTouched('cusPostalCode', false, false);
                                                }
                                            }}
                                        >
                                            {/* eslint-disable-next-line camelcase */}
                                            {country_options.map((({ value, displayName }) => (
                                                <option key={value} value={value}>{displayName}</option>
                                            )))}
                                        </Form.Control>
                                        <Form.Control.Feedback
                                            type="invalid"
                                        >
                                            {errors.cusIsoCountry}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="cusPostalCode">
                                        <TwilioAutoFillField
                                            label={ values.cusIsoCountry === 'CA' ?
                                                'Postal Code'
                                                :
                                                'ZIP Code'
                                            }
                                            input={einToCheck}
                                            value={values.cusPostalCode}
                                            setValue={setFieldValue}
                                            name="cusPostalCode"
                                            // eslint-disable-next-line camelcase
                                            mask={isReadOnly ? '*******' : postal_code_mask}
                                            // eslint-disable-next-line camelcase
                                            placeholder={postal_code_example}
                                            type="text"
                                            overwrite
                                            autoFillData={ein}
                                            autoComplete="postal-code"
                                            isInvalid={touched.cusPostalCode && !!errors.cusPostalCode}
                                            feedbackError={errors.cusPostalCode}
                                            isReadOnly={isReadOnly}
                                            isSettings={isSettings}
                                            onChange={handleChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Form.Group>
                                </Row>
                            </Form.Group>
                            {
                                !validateEinValues(values, ein) && !isSettings &&
                                (
                                    <Alert variant="warning">
                                        If the information you've provided is accurate,
                                        you can proceed to the next step.
                                    </Alert>
                                )
                            }
                            <Form.Group controlId="accepted">
                                {denormalizedTwilioSubscriptionParams.accepted
                                    ? (
                                        <>
                                            <Form.Control
                                                as="input"
                                                type="hidden"
                                                name="accepted"
                                                isInvalid={false}
                                                value
                                            />
                                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                            <Form.Label className="fontSize1rem">
                                                <span>I agree to </span>
                                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                                    href="#"
                                                    onClick={(event) => { event.preventDefault(); open(); }}
                                                >
                                                    terms and conditions
                                                </a>
                                            </Form.Label>
                                        </>
                                    )
                                    : (
                                        <Form.Check className="pl-4">
                                            <Form.Check.Input
                                                readOnly={isReadOnly}
                                                type="checkbox"
                                                name="accepted"
                                                isInvalid={touched.accepted && !!errors.accepted}
                                                onChange={handleChange}
                                                checked={values.accepted}
                                            />
                                            <Form.Check.Label className="ml-2 fontSize1rem">
                                                <span>I agree to </span>
                                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                                    href="#"
                                                    onClick={(event) => { event.preventDefault(); open(); }}
                                                >
                                                    terms and conditions
                                                </a>
                                            </Form.Check.Label>
                                            <Form.Control.Feedback
                                                type="invalid"
                                            >
                                                {errors.accepted}
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    )}
                                <TermsOfUse
                                    canAgree={!denormalizedTwilioSubscriptionParams.accepted}
                                    onAgree={() => {
                                        setFieldValue('accepted', true);
                                        close();
                                    }}
                                    onClose={close}
                                    isOpen={isOpen}
                                />
                            </Form.Group>
                        </div>
                    </LoadingIndicator>
                    {saveError && (
                        <Alert variant="danger">
                            <Alert.Heading>Unable to save data.</Alert.Heading>
                            <p className="fontSize1rem">
                                Please correct the information and retry.

                                If the problem persists, please contact our
                                {' '}
                                <a href="mailto:support@wildapricot.com">support team</a>
                                .
                            </p>
                        </Alert>
                    )}
                    {success && (
                        <Alert variant="success">
                            Information has been successfully submitted.
                        </Alert>
                    )}
                    <div className="d-flex justify-content-between">
                        {!isSettings ?

                            (
                                <>
                                    <Button
                                        id="TNS_Contact_Back"
                                        className="mt-4"
                                        variant="outline-secondary"
                                        onClick={onPrev}
                                    >
                                        Back
                                    </Button>
                                    {
                                        !einFetched ?
                                            <OverlayTrigger
                                                key="tns-form__next-button-tooltip"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-tns-form__next-button-tooltip">
                                                        Please verify Tax ID
                                                    </Tooltip>
                                                }
                                            >
                                                <Button
                                                    id="TNS_Contact_Next"
                                                    type="submit"
                                                    className="mt-4"
                                                    disabled
                                                >
                                                    <LoadingIndicator loading={isSaving}>
                                                        Next
                                                    </LoadingIndicator>
                                                </Button>
                                            </OverlayTrigger>
                                            :
                                            (<Button
                                                id="TNS_Contact_Next"
                                                type="submit"
                                                className="mt-4"
                                                disabled={isSaving || !einFetched}
                                            >
                                                <LoadingIndicator loading={isSaving}>
                                                    Next
                                                </LoadingIndicator>
                                            </Button>)
                                    }
                                </>) : null
                        }
                        {isSettings  && !isReadOnly ? (
                            <Button type="submit" className="mt-4" disabled={isSaving}>
                                <LoadingIndicator loading={isSaving}>
                                    Resubmit
                                </LoadingIndicator>
                            </Button>
                        ) : null }
                    </div>
                </Form>
            )}
        </Formik>
    );
}

TwilioSubscriptionForm.propTypes = {
    isSettings: propTypes.bool,
    onNext: propTypes.func,
    onPrev: propTypes.func,
};

TwilioSubscriptionForm.defaultProps = {
    isSettings: true,
    onNext: () => {},
    onPrev: () => {},
};
export default TwilioSubscriptionForm;
