import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CampaginTextLogo from './CampaginTextLogo';
import { useSelector } from 'react-redux';
import { getTwilioSubscription } from '../../reducers/settingsReducer';
import twilioStatuses from '../../constants/twilioStatuses';

function CampaignsListFirstUseState({ onNewCampaign, showRegistrationComplete }) {
    const handleNewCampaignClick = () => {
        onNewCampaign();
        return false;
    };

    const { Status } = useSelector(getTwilioSubscription) || {};

    return (
        <div style={{ margin: 'auto', maxWidth: 800 }} className="text-center mt-5 mb-5 pt-lg-5 pb-lg-5">
            <div className="pb-4">
                <CampaginTextLogo showRegistrationComplete={showRegistrationComplete} />
            </div>
            {
                showRegistrationComplete ?
                    (<>
                        <h2 className="font-weight-bold">Hooray!</h2>
                        <h4 className="mb-5 font-weight-bold">Your registration has been successfully submitted.</h4>
                        {
                            Status === twilioStatuses.pending_review &&
                            (
                                <h5>
                                    Once your application has been approved, you can begin sending text messages.
                                </h5>
                            )
                        }
                    </>)
                    :
                    (<h4 className="mb-5 font-weight-bold">Welcome to WildApricot&apos;s text messaging</h4>)
            }
            {
                !showRegistrationComplete &&
                    (
                        <h5>
                            Start a new text campaign by clicking the
                            {' '}
                            <span className="font-weight-bold">
                                + Start a new campaign
                            </span>
                            {' '}
                            button
                        </h5>
                    )
            }
            {
                Status === twilioStatuses.pending_review &&
                (
                    <p>
                        Note: you can only save campaigns as drafts prior to application approval.
                    </p>
                )
            }
            <div>
                <Button type="button" variant="primary" className="mt-4" onClick={handleNewCampaignClick}>
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    Start a new campaign
                </Button>
            </div>
        </div>
    );
}

CampaignsListFirstUseState.propTypes = {
    onNewCampaign: propTypes.func.isRequired,
    showRegistrationComplete: propTypes.bool,
};

CampaignsListFirstUseState.defaultProps = {
    showRegistrationComplete: false,
};

export default CampaignsListFirstUseState;
