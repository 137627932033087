import React from 'react';
import { bool, func } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

export { default as useTermsOfUse } from './useTermsOfUse';

/* eslint-disable */
const TermsOfUse = ({
    isOpen,
    onClose,
    onAgree,
    canAgree,
    ...props
}) => (
    <Modal
        show={isOpen}
        onHide={onClose}
        size="lg"
        aria-labelledby="terms-of-use-title"
        centered
        scrollable
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <Modal.Header closeButton>
            <Modal.Title id="terms-of-use-title">Text Utility End User License Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="fontSize1rem">
              This Text Utility End User License Agreement (“<strong className="font-weight-bold">Agreement</strong>”) is a binding agreement between you (“<strong className="font-weight-bold">End User</strong>” or “<strong className="font-weight-bold">you</strong>”)

              and WildApricot, Inc. (“<strong className="font-weight-bold">Company</strong>”).

              This Agreement governs your use of the text utility including all related documentation (the “<strong className="font-weight-bold">Application</strong>”)

              offered in connection with your use of the WildApricot Platform and incorporates by reference the relevant terms and conditions

              (<a href="https://www.wildapricot.com/TermsOfUse" rel="noreferrer noopener" target="_blank">https://www.wildapricot.com/TermsOfUse</a>)(the “<strong className="font-weight-bold">TOU</strong>”).

              In the event of any conflict between the terms set forth herein and those contained in the TOU, this Agreement will govern.

              Any capitalized terms used herein but not defined will have the meaning set forth in the TOU.
            </p>
            <p className="fontSize1rem">
              BY CLICKING THE “AGREE” BUTTON,

              YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT;

              (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT;

              (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS;

              AND (D) WILL STRICTLY COMPLY WITH ALL APPLICABLE LAW, INCLUDING THE TELEPHONE CONSUMER PROTECTION ACT (“<strong className="font-weight-bold">TCPA</strong>”),

              IN YOUR USE OF THE APPLICATION. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION.
            </p>
            <ol className="small">
                <li className="mb-3">
                    <u>License Grant.</u>&nbsp;

                    Subject to the terms of this Agreement, Company grants you a limited, non-exclusive, and nontransferable license to download, install, and use the Application,

                    strictly in accordance with the Application&apos;s documentation.

                    You will not:

                    (a) copy the Application, except as expressly permitted by this license;

                    (b) modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;

                    (c) reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;

                    (d) remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent,

                    or other intellectual property or proprietary rights notices from the Application, including any copy thereof;

                    (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application,

                    or any features or functionality of the Application, to any third party for any reason,

                    including by making the Application available on a network where it is capable of being accessed by more than one device at any time;

                    (f) remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management,

                    or security features in or protecting the Application; or (g) use the Application in, or in association with, the design, construction,

                    maintenance, or operation of any hazardous environments or systems, including any power generation systems;

                    aircraft navigation or communication systems, air traffic control systems, or any other transport management systems;

                    safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire,

                    or other safety response systems; and military or aerospace applications, weapons systems, or environments.
                </li>
                <li className="mb-3">
                    <u>Reservation of Rights.</u>&nbsp;

                    You acknowledge and agree that the Application is provided under license, and not sold, to you.

                    You do not acquire any ownership interest in the Application under this Agreement, or any other

                    rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions,

                    and restrictions, under this Agreement. Company and its licensors and service providers reserve and will retain their entire right,

                    title, and interest in and to the Application, including all copyrights, trademarks, and other intellectual property rights therein

                    or relating thereto, except as expressly granted to you in this Agreement.
                </li>
                <li className="mb-3">
                    <u>Term and Termination.</u>&nbsp;

                    The term of Agreement commences when acknowledge your acceptance and will continue in effect until terminated by you or Company as set forth herein.

                    You may terminate this Agreement by deleting or disconnecting the Application and all copies thereof.

                    Company may terminate this Agreement at any time without notice.  In addition, this Agreement will terminate immediately and automatically without any

                    notice if you violate any of the terms and conditions of this Agreement.  Upon termination, all rights granted to you under this Agreement will also terminate;

                    and you must cease all use of the Application and delete all copies of the Application.  Termination will not limit any of Company&apos;s rights or remedies

                    at law or in equity.
                </li>

                <li className="mb-3">
                    <u>Disclaimer of Warranties.</u>&nbsp;

                    THE APPLICATION IS PROVIDED TO YOU ON A STRICTLY “<strong className="font-weight-bold">AS-IS</strong>” BASIS AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND.

                    TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE

                    LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,

                    WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,

                    AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE.

                    WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET

                    YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES,

                    OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                </li>
                <li className="mb-3">
                    <u>Limitation of Liability.</u>&nbsp;

                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS,

                    HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:

                    (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION,

                    COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES OR

                    (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.

                    THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),

                    OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

                    SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
                </li>
                <li className="mb-3">
                    <u>Indemnification.</u>&nbsp;

                    You agree to indemnify, defend, and hold harmless Company and its officers, directors, employees, agents, affiliates, successors,

                    and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest,

                    awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys&apos; fees,

                    arising from or relating to your use or misuse of the Application,

                    including but not limited to the content you submit or make available through this Application,

                    or your breach of this Agreement or any applicable law, including the TCPA.
                </li>
                <li className="mb-3">
                    <u>Entire Agreement.</u>&nbsp;

                    This Agreement constitutes the entire agreement between you and Company with respect to the Application

                    and supersedes all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application.
                </li>
            </ol>
        </Modal.Body>
        <Modal.Footer>
            <Button variant={canAgree ? 'secondary' : 'primary'} onClick={onClose}>Close</Button>
            {canAgree && (
                <Button variant="primary" onClick={onAgree}>Agree</Button>
            )}
        </Modal.Footer>
    </Modal>
    /* eslint-enable */
);

TermsOfUse.propTypes = {
    isOpen: bool,
    onClose: func.isRequired,
    onAgree: func.isRequired,
    canAgree: bool,
};

TermsOfUse.defaultProps = {
    isOpen: false,
    canAgree: false,
};

export default TermsOfUse;
