import React from 'react';
import { node, bool } from 'prop-types';
import { Spinner, Container, Alert } from 'react-bootstrap';
import './SplashScreenLoader.css';

function SplashScreenLoader({
    loading,
    error,
    errorTitle,
    errorMessage,
    children,
}) {
    if (!loading && !error) {
        return children;
    }

    return (
        <Container fluid className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center">
            {error ? (
                <Alert variant="danger" className="shadow-sm">
                    {errorTitle && (
                        <Alert.Heading>{errorTitle}</Alert.Heading>
                    )}
                    {errorMessage && (
                        <p className="splash-screen-loader__error-message">{errorMessage}</p>
                    )}
                </Alert>
            ) : (
                <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>
            )}
        </Container>
    );
}

SplashScreenLoader.propTypes = {
    loading: bool,
    error: bool,
    errorTitle: node,
    errorMessage: node,
    children: node,
};

SplashScreenLoader.defaultProps = {
    loading: false,
    error: false,
    errorTitle: null,
    errorMessage: null,
    children: null,
};

export default SplashScreenLoader;
