import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';

// this helper binds path params to props
// it supports only a single child
class QueryParamsBinder extends React.Component {
    render() {
        return React.cloneElement(this.props.children, this.props.queryParams);
    }
}

QueryParamsBinder.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: propTypes.node,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    queryParams: propTypes.any,
};

function mapStateToProps(state) {
    return {
        queryParams: qs.parse(state.router.location.search),
    };
}

export default connect(
    mapStateToProps,
)(QueryParamsBinder);
