import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

function ActionForbiddenAlert({ show, onHide }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Action is not allowed</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                You can send messages using WildApricot&apos;s text messaging from paid accounts only.
                <br />
                You cannot send messages from free or trial accounts.
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={onHide} name="cancel"> OK </Button>
            </Modal.Footer>
        </Modal>
    );
}

ActionForbiddenAlert.propTypes = {
    // eslint-disable-next-line react/require-default-props
    show: propTypes.bool,
    onHide: propTypes.func.isRequired,
};

export default ActionForbiddenAlert;
