import React from 'react';
import propTypes from 'prop-types';
import {
    Card, Button, Row, Col, OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMailBulk, faMagic, faCalendar, faLightbulb, faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

function CampaignSuggestionCard({ suggestion, useSuggestion, onDismissCard }) {
    const getIcon = (iconName) => {
        switch (iconName) {
            case 'mail-bulk': return faMailBulk;
            case 'magic': return faMagic;
            case 'calendar': return faCalendar;
            default: return faLightbulb;
        }
    };

    const dismissCard = () => {
        onDismissCard(suggestion);
    };

    const renderActionBlock = (action, idx) => {
        const processAction = () => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSuggestion(action.campaignDefaults);
        };
        return (
            <div className={idx > 0 ? 'mt-4' : ''} key={idx}>
                <Row>
                    <Col sm={12} md={8} xl={9}>
                        <Card.Text>
                            {action.description}
                        </Card.Text>
                    </Col>
                    <Col sm={12} md={4} xl={3}>
                        <div className="d-flex justify-content-end w-100">
                            <Button className="mb-2 mt-1" variant="outline-primary" onClick={processAction}>
                                {action.callToAction}
                            </Button>
                        </div>
                    </Col>
                </Row>

            </div>
        );
    };

    return (
        <div className="pt-3 pb-3">
            <h5 className="campaignListItemHeader">
                <Row className="mr-0 pr-2">
                    <Col xs={11}>
                        <FontAwesomeIcon icon={getIcon(suggestion.icon)} className="mr-2" />
                        {suggestion.title}
                    </Col>
                    <Col xs={1} className="text-right mr-0 pr-0  text-secondary">
                        <OverlayTrigger
                            placement="left"
                            overlay={<span className="mt-2 p-2 bg-light h6 text-muted">DISMISS</span>}
                        >
                            <span style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faTimesCircle} onClick={dismissCard} />
                            </span>
                        </OverlayTrigger>
                    </Col>
                </Row>

            </h5>
            <div className="pl-2">
                {suggestion.actions.map((a, idx) => renderActionBlock(a, idx))}
            </div>
        </div>
    );
}

CampaignSuggestionCard.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    suggestion: propTypes.object.isRequired,
    useSuggestion: propTypes.func.isRequired,
    onDismissCard: propTypes.func.isRequired,
};

export default CampaignSuggestionCard;
