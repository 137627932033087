import React from 'react';
import propTypes from 'prop-types';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash, faSearch } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from '../../LoadingIndicator';
import RecipientsList from '../../Recipients/RecipientsList';

import './RecipientsListDialog.scss';

class RecipientsListDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: '',
            filter: null,
            validChecked: true,
            invalidChecked: true,
            phone: 'phone-any',
            consent: 'consent-any',
        };

        this.renderRecipientRow = this.renderRecipientRow.bind(this);
        this.renderTableOrError = this.renderTableOrError.bind(this);
        this.searchRecipients = this.searchRecipients.bind(this);
        this.setSearchString = this.setSearchString.bind(this);
        this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchRecipients(this.props.recipientsListId, this.state.searchString, true);
    }

    onSearchKeyPress(e) {
        if (e.key === 'Enter') {
            this.searchRecipients();
        }
    }

    mapValueToFilterString(type, value) {
        if (type === 'phone') {
            switch (value) {
                case 'phone-any': return null;
                case 'phone-valid': return ('+phone');
                case 'phone-invalid': return ('-phone');
                default: return null;
            }
        } else {
            switch (value) {
                case 'consent-any': return null;
                case 'consent-valid':return ('+consent');
                case 'consent-invalid': return ('-consent');
                default: return null;
            }
        }
    }

    handleOnChange(e) {
        const value = e.target.value;
        const dropdownType = e.target.name;

        const currentState = { ...this.state };
        const currentFilter = currentState.filter || '';

        const newState = { ...currentState, [dropdownType]: value };
        const filterString = this.mapValueToFilterString(dropdownType, value);

        switch (dropdownType) {
            case 'phone':
                if (filterString !== null) {
                    newState.filter = currentFilter.replace(/(\+|-)(phone)/g, '') + filterString;
                } else {
                    const updatedFilter = currentFilter.replace(/[+-](phone)/g, '');
                    newState.filter = updatedFilter || null;
                }
                break;
            case 'consent':
                if (filterString !== null) {
                    newState.filter = currentFilter.replace(/(\+|-)(consent)/g, '') + filterString;
                } else {
                    const updatedFilter = currentFilter.replace(/[+-](consent)/g, '');
                    newState.filter = updatedFilter || null;
                }
                break;
            default:
                break;
        }

        this.setState(newState, () => this.searchRecipients());
    }

    setSearchString(e) {
        this.setState({ ...this.state, searchString: e.target.value });
    }

    searchRecipients() {
        this.props.fetchRecipients(
            this.props.recipientsListId,
            (this.state.filter === null ?
                this.state.searchString
                :
                this.state.filter + ' ' + this.state.searchString),
            true);
    }

    // eslint-disable-next-line class-methods-use-this
    renderRecipientRow(recipient) {
        return (
            <tr key={recipient.id}>
                <td>{recipient.displayName}</td>
                <td style={{ minWidth: 200 }}>
                    <FontAwesomeIcon
                        icon={recipient.phoneNumberValid ? faPhone : faPhoneSlash}
                        className={recipient.phoneNumberValid ? 'rowValidPhone' : 'rowInvalidPhone'}
                    />
                    <span className="ml-3">{recipient.phone}</span>
                </td>
            </tr>
        );
    }

    renderTableOrError() {
        if (this.error) {
            return (
                <Alert variant="danger" className="mt-5">
                    <Alert.Heading>Failed to load recipients list</Alert.Heading>
                    <p>{this.props.error}</p>
                </Alert>
            );
        }

        const recipients = this.props.recipients || [];
        const threshold = 300;

        return (
            <div className="tableFixHead">
                <RecipientsList
                    maxNumber={threshold}
                    recipients={recipients.slice(0, threshold)}
                    isFetching={this.props.isLoadingRecipients}
                />
            </div>
        );
    }

    render() {
        return (
            <Modal show scrollable dialogClassName="modal-75w" onHide={this.props.onClose}>
                <Modal.Header>
                    <Modal.Title>{this.props.listTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ minHeight: 200 }}>
                    <Row noGutters>
                        <Col sm="5" className="pr-3">
                            <div>
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    className="mt-2 ml-3 position-absolute"
                                    style={{ opacity: 0.5 }}
                                />
                                <input
                                    name="searchText"
                                    onKeyPress={this.onSearchKeyPress}
                                    onChange={this.setSearchString}
                                    maxLength={40}
                                    type="text"
                                    className="pl-5 mimicFormControl"
                                    placeholder="Search"
                                    value={this.state.searchString}
                                />
                            </div>
                        </Col>
                        <Col sm="5" className="px-3 my-auto d-flex">
                            <Row>
                                <Col>
                                    <div className="d-flex align-items-end mb-2">
                                        <Form.Label className="wa-recipient-list-filter__label pr-2">
                                            Phone:
                                        </Form.Label>
                                        <Form.Control
                                            className="wa-recipient-list-filter__option"
                                            type="select"
                                            as="select"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.handleOnChange}
                                        >
                                            <option key="phone-any" value="phone-any">
                                                Any
                                            </option>
                                            <option key="phone-valid" value="phone-valid">
                                                Valid
                                            </option>
                                            <option key="phone-invalid" value="phone-invalid">
                                                Invalid
                                            </option>
                                        </Form.Control>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex align-items-end">
                                        <Form.Label className="wa-recipient-list-filter__label pr-2">
                                            Consent:
                                        </Form.Label>
                                        <Form.Control
                                            className="wa-recipient-list-filter__option"
                                            inline
                                            type="select"
                                            as="select"
                                            name="consent"
                                            value={this.state.consent}
                                            onChange={this.handleOnChange}
                                        >
                                            <option key="consent-any" value="consent-any">
                                                Any
                                            </option>
                                            <option key="consent-valid" value="consent-valid">
                                                Set
                                            </option>
                                            <option key="consent-invalid" value="consent-invalid">
                                                Not set
                                            </option>
                                        </Form.Control>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="2" className="d-flex justify-content-end text-nowrap">
                            <Button
                                type="button"
                                variant="outline-primary"
                                block
                                onClick={this.searchRecipients}
                            >
                                <FontAwesomeIcon icon={faSearch} className="mr-2 d-sm-none d-md-inline-block" />
                                Search
                            </Button>
                        </Col>
                    </Row>

                    <div className="mt-3">
                        <LoadingIndicator loading={this.props.isLoadingRecipients}>
                            {this.renderTableOrError()}
                        </LoadingIndicator>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.onClose} name="close">Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

RecipientsListDialog.propTypes = {
    onClose: propTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    recipientsListId: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    listTitle: propTypes.string,

    // eslint-disable-next-line react/require-default-props
    isLoadingRecipients: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    error: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    recipients: propTypes.array,
    fetchRecipients: propTypes.func.isRequired,
};

export default RecipientsListDialog;
