import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntegrationOptions from '../Settings/IntegrationOptions/IntegrationOptionsContainer';
import PageContainer from './PageContainer';
import { getSetupComplete, getSetupCompleteSaving } from '../../reducers/authReducer';
import LoadingIndicator from '../LoadingIndicator';
import { push } from 'connected-react-router';

function SignUpWaPage() {
    const dispatch = useDispatch();
    const setupComplete = useSelector(getSetupComplete);
    const isSaving = useSelector(getSetupCompleteSaving);

    if (setupComplete) {
        dispatch(push('/campaigns'));
    }

    return (
        <PageContainer>
            {isSaving ?
                (< LoadingIndicator loading={isSaving} loadingText="Completing setup..."/>)
                :
                (
                    <IntegrationOptions isStartup />
                )

            }
        </PageContainer>
    );
}

export default SignUpWaPage;
