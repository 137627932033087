import React from 'react';
import propTypes from 'prop-types';
import {
    Form, Alert, Spinner, OverlayTrigger, Popover,
} from 'react-bootstrap';
import * as types from '../../types';
import EditableCampaignButtons from './BuildingBlocks/EditableCampaignButtons';
import ScheduleControl from './BuildingBlocks/ScheduleControl';
import SmsContentControl from './BuildingBlocks/SmsContentControl';
import RecipientsListControl from './BuildingBlocks/RecipientsListControl';
import SPAForm from '../SPAForm';
import campaignUtils from './campaignUtils';
import TwilioStatuses from '../../constants/twilioStatuses';
import CampaignPriceEstimation from './BuildingBlocks/CampaignPriceEstimation';
import { getIsBillingPlanProhibited } from '../../reducers/authReducer';
import { connect } from 'react-redux';

function EditCampaignForm({
    campaign,
    onFieldValChange,
    onSubmit,
    onSendTest,
    isSaving,
    isBillingPlanProhibited,
    hasSubscription,
    twilioApproved,
}) {
    const canSend = hasSubscription && twilioApproved && !isBillingPlanProhibited;

    const canSave = () => {
        let result = !isSaving;

        if (campaign == null || !campaign.content || !campaign.content.trim() || !campaign.recipientsListId) {
            result = false;
        }

        if (campaign && campaign.schedule !== 'asap') {
            const { error } = campaignUtils.getDateValidationClass(campaign.scheduleMoment);

            if (error) result = false;
        }

        return result;
    };

    const titlePopover = (
        <Popover className="pl-4 pr-4 pt-3 bg-light">
            <p>
                Title is optional, but it may help you identify this campaign later.
            </p>
        </Popover>
    );
    return (
        <div className="campaignInfoContainer">

            <Alert className="fixed-top text-center" variant="info" hidden={!isSaving}>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ml-2">Saving, please wait.</span>
            </Alert>

            <div className="mt-3" style={{ opacity: isSaving ? 0.5 : 1 }}>
                <SPAForm id="editCampaignForm" onSubmit={onSubmit}>

                    <RecipientsListControl
                        groupType={campaign.recipientsGroupType}
                        recipientsListId={campaign.recipientsListId}
                        recipientsListName={campaign.recipientsListName}
                        onFieldValChange={onFieldValChange}
                    />

                    <SmsContentControl
                        content={campaign.content}
                        onFieldValChange={onFieldValChange}
                        groupType={campaign.recipientsGroupType}
                    />

                    <div className="mb-4 mt-4">
                        <ScheduleControl
                            schedule={campaign.schedule}
                            scheduleMoment={campaign.scheduleMoment}
                            onFieldValChange={onFieldValChange}
                        />
                    </div>

                    <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <OverlayTrigger placement="top" overlay={titlePopover}>
                            <Form.Control
                                name="title"
                                onChange={onFieldValChange}
                                type="text"
                                placeholder="Campaign title (optional)"
                                value={campaign.title}
                                maxLength="64"
                            />
                        </OverlayTrigger>
                    </Form.Group>

                    <CampaignPriceEstimation
                        content={campaign.content}
                        recipientsListId={campaign.recipientsListId}
                    />

                    <EditableCampaignButtons
                        canSend={canSend}
                        canSave={canSave()}
                        canSendTest={!!campaign.content}
                        onFieldValChange={onFieldValChange}
                        onSendTest={onSendTest}
                    />

                </SPAForm>

            </div>
        </div>
    );
}

EditCampaignForm.propTypes = {
    // eslint-disable-next-line react/require-default-props
    campaign: types.campaign,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    error: propTypes.string,
    onFieldValChange: propTypes.func.isRequired,
    onSendTest: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    isSaving: propTypes.bool,
    hasSubscription: propTypes.bool,
    twilioApproved: propTypes.bool,
    isBillingPlanProhibited: propTypes.bool,
};

function mapStateToProps(state) {
    const { account } = state.auth.me;
    return {
        twilioApproved: account?.twilioSubscription?.Status === TwilioStatuses.twilio_approved,
        hasSubscription: account.hasSubscription === true && account.lastPaimentSucceeded !== false,
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
    };
}
export default connect(
    mapStateToProps,
)(EditCampaignForm);
