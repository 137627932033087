import { createContext, useContext } from 'react';

export const SectionContext = createContext({
    section: null,
    setSection: null,
    updateSectionState: null,
    openWidget: null,
    closeWidget: null,
    article: null,
    setActiveArticle: null,
    currentState: null,
    popState: null,
    pushState: null,
    serviceNotice: null,
    flushState: null,
    stateRef: null,
});

export const useSectionContext = () => useContext(SectionContext);

export default SectionContext;