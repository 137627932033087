import apiClient from './apiClient';
import apiUrls from './apiUrls';

const fetchRecipientsGroupTypes = () => apiClient.executeGet(
    apiUrls.recipients.groupTypes,
    (responseContent) => ({ groupTypes: responseContent }),
);

const fetchRecipientsGroups = async (groupType) => apiClient.executeGet(
    apiUrls.recipients.recipientsOptions(groupType),
    (responseContent) => ({ groups: responseContent }),
);

const fetchRecipientsCount = (recipientsListId) => apiClient.executeGet(
    apiUrls.recipients.estimateRecipientsNumber(recipientsListId),
);

const fetchRecipients = async (recipientsListId, searchString, top) => apiClient.executeGet(
    apiUrls.recipients.list(recipientsListId, searchString, top),
);

const fetchRecipient = (recipientType, recipientId) => apiClient.executeGet(
    apiUrls.recipients.getRecipient(recipientType, recipientId),
);

const methods = {
    fetchRecipientsGroupTypes,
    fetchRecipientsGroups,
    fetchRecipientsCount,
    fetchRecipients,
    fetchRecipient,
};

export default methods;
