import React from 'react';
import propTypes from 'prop-types';
import { faPhoneSlash, faHand, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function RecipientListItem({
    recipient,
    onOpen,
    waHost,
    hasConsentField,
}) {
    const handleOpen = () => {
        if (onOpen) onOpen(recipient);
    };

    const cursorStyle = onOpen ? 'pointer' : 'default';

    let icon = null;

    if (!recipient.phoneNumberValid) {
        icon = { icon: faPhoneSlash, tooltip: 'No or invalid phone number' };
    } else if (hasConsentField && !recipient.consent) {
        icon = { icon: faHand, tooltip: 'No consent given' };
    }

    const waUrl = `https://${waHost}/admin/contacts/details/?contactId=${recipient.id}`;

    return (
        <tr style={{ cursor: cursorStyle }}>
            <td className="clearFix">
                <a
                    href={waUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-2 mt-3 float-right"
                    hidden={recipient.recipientType !== 'contact'}
                >
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Open in WildApricot</Tooltip>}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </OverlayTrigger>
                </a>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="clearFix" onClick={handleOpen}>
                    {icon && (
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{icon.tooltip}</Tooltip>}>
                            <FontAwesomeIcon icon={icon.icon} className="rowInvalidPhone mr-3 mt-3 pt-1 float-right" />
                        </OverlayTrigger>
                    )}
                    <div className="h6 text-muted">{recipient.displayName}</div>
                    <div className="pt-1">
                        {recipient.phone}
                    </div>
                </div>
            </td>
        </tr>
    );
}

RecipientListItem.propTypes = {
    // eslint-disable-next-line react/require-default-props
    recipient: propTypes.shape({
        id: propTypes.string.isRequired,
        displayName: propTypes.string.isRequired,
        phoneNumberValid: propTypes.bool.isRequired,
        phone: propTypes.string,
        recipientType: propTypes.string,
    }),
    // eslint-disable-next-line react/require-default-props
    onOpen: propTypes.func,
    waHost: propTypes.string.isRequired,
    hasConsentField: propTypes.bool.isRequired,
};

export default RecipientListItem;
