import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../../../../../../Common/icon';
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import HomeIcon from './home-icon';
import { useSectionContext } from '../../../../../../../../entities/help-widget/context/section-context-provider';

import './task-bar.css';
import sectionKeys from '../../../../../../../../constants/section-keys';

const TaskBar = ({ isHome, collapsed }) => {
    const {
        section,
        setSection,
        closeWidget,
        currentState,
        popState,
    } = useSectionContext();

    const handleBack = () => {
        if (currentState !== null && currentState !== undefined) {
            if (currentState?.sectionKey !== null && currentState?.sectionKey !== undefined) {
                if (currentState?.articleId !== undefined && currentState?.articleId !== null) {
                    setSection(currentState.sectionKey, currentState.articleId);
                } else {
                    setSection(currentState.sectionKey, null);
                }
            }
            popState();
        }
    };

    return (
        <div className="-wa-help-center__task-bar position-relative">
            {isHome ?
                (
                    <div className="d-flex justify-content-end">
                        <Icon
                            size="lg"
                            data-test-id="wa-help-center_task-bar"
                            className="cursor-pointer"
                            icon={faTimes}
                            onClick={closeWidget}
                            onKeyDown={closeWidget}
                        />
                    </div>
                )
                :
                (
                    <div className="d-flex justify-content-between">
                        <div
                            className={`position-relative d-flex align-items-center
            ${collapsed ? '-wa-help-center__back-button__collapsed' : '-wa-help-center__back-button'}`}
                        >
                            <Icon
                                size="lg"
                                className="cursor-pointer"
                                icon={faChevronLeft}
                                onClick={handleBack}
                                onKeyDown={handleBack}
                            />
                            <HomeIcon
                                data-test-id="wa-help-center_task-bar"
                                onClick={() => setSection(sectionKeys.SECTION_HOME)}
                                onKeyDown={() => setSection(sectionKeys.SECTION_HOME)}
                            />
                        </div>
                        {!collapsed && section?.title}
                        <Icon
                            size="lg"
                            data-test-id="wa-help-center_task-bar"
                            className="cursor-pointer"
                            icon={faTimes}
                            onClick={closeWidget}
                            onKeyDown={closeWidget}
                        />
                    </div>
                )}
        </div>
    );
};

TaskBar.propTypes =
  {
      isHome: PropTypes.bool,
      collapsed: PropTypes.bool,
  };

TaskBar.defaultProps =
  {
      isHome: true,
      collapsed: false,
  };
export default TaskBar;
