import ages from './constants/ages';

// Age in days since account creation
// eslint-disable-next-line import/no-anonymous-default-export
export default (accountAge) => {
    const tags = [];

    let tagName = 'unknown';
    if (accountAge < ages.M_0.days) {
        tags.push(ages.NEW_ACCOUNT);
        tagName = ages.M_0.tag;
    } else if (accountAge < ages.M_2.days) tagName = ages.M_2.tag;
    else if (accountAge < ages.M_3.days) tagName = ages.M_3.tag;
    else if (accountAge < ages.M_4.days) tagName = ages.M_4.tag;
    else if (accountAge < ages.M_5.days) tagName = ages.M_5.tag;
    else if (accountAge < ages.M_6.days) tagName = ages.M_6.tag;
    else if (accountAge < ages.M_7.days) tagName = ages.M_7.tag;
    else if (accountAge < ages.M_8.days) tagName = ages.M_8.tag;
    else if (accountAge < ages.M_9.days) tagName = ages.M_9.tag;
    else if (accountAge < ages.M_10.days) tagName = ages.M_10.tag;
    else if (accountAge < ages.M_11.days) tagName = ages.M_11.tag;
    else if (accountAge < ages.M_12.days) tagName = ages.M_12.tag;
    else if (accountAge < ages.Y_2.days) tagName = ages.Y_2.tag;
    else if (accountAge < ages.Y_3.days) tagName = ages.Y_3.tag;
    else if (accountAge < ages.Y_4.days) tagName = ages.Y_4.tag;
    else if (accountAge < ages.Y_5.days) tagName = ages.Y_5.tag;
    else if (accountAge > ages.Y_OLD.days) tagName = ages.Y_OLD.tag;

    tags.push(tagName);
    return (tags);
};
