// eslint-disable-next-line import/no-anonymous-default-export
export default
{
    webWidget:
    {
        chat:
        {
            hideWhenOffline: false,
        },
    },
};
