import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, ToggleButton } from 'react-bootstrap';

import Icon from '../../icon';

import './button.less';

const WAButton = ({
    children,
    toggle,
    icon,
    iconProps,
    ...buttonProps
}) => {
    let iconEl = null;

    if (icon) {
        const otherIconProps = iconProps;
        const classes = classnames({
            '-wa-ui-component__button-icon': true,
            '-wa-ui-component__button-icon_icon-only': React.Children.count(children) === 0,
        });
        iconEl = (
            <Icon
                {...otherIconProps}
                icon={icon}
                fixedWidth
                className={classes}
            />
        );
    }

    return toggle
        ? (
            <ToggleButton {...buttonProps}>
                {iconEl}
                {children}
            </ToggleButton>
        )
        : (
            <Button {...buttonProps}>
                {iconEl}
                {children}
            </Button>
        );
};

WAButton.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.shape({}),
    toggle: PropTypes.bool,
    iconProps: PropTypes.shape({}),
};

WAButton.defaultProps = {
    iconProps: {},
    icon: null,
    toggle: false,
};

export default WAButton;
