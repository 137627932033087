import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const InfoTooltip = ({ id, children, title, placement }) =>
    (
        <>
            <OverlayTrigger
                trigger="click"
                placement={placement}
                overlay={
                    <Popover id={id}>
                        {
                            title &&
                            (<Popover.Title as="h3">
                                {title}
                            </Popover.Title>)
                        }
                        <Popover.Content>
                            {children}
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button variant="link">
                    <FontAwesomeIcon icon={faQuestionCircle} className="text-info" size="lg" />
                </Button>
            </OverlayTrigger>
        </>
    );

export default InfoTooltip;

InfoTooltip.propTypes =
    {
        placement: PropTypes.string,
        children: PropTypes.node,
        title: PropTypes.string,
    };

InfoTooltip.defaultProps =
    {
        placement: 'right',
        children: null,
        title: '',
    };
