import React, { createRef } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Form, Col, Row, Button,
} from 'react-bootstrap';
import * as settingsActionCreators from '../../../actions/settingsActions';
import * as actionCreators from '../../../actions/recipientsActions';
import RecipientsListDialog from './RecipientsListDialog';
import LoadingIndicator from '../../LoadingIndicator';
import recipientsTypes from '../../../types/recipientsTypes';
import { getHasConsentField } from '../../../reducers/settingsReducer';

class RecipientsListControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.onGroupTypeChanged = this.onGroupTypeChanged.bind(this);
        this.onSelectedListChanged = this.onSelectedListChanged.bind(this);
        this.renderNumberOfRecipients = this.renderNumberOfRecipients.bind(this);
        this.renderGroupTypes = this.renderGroupTypes.bind(this);
        this.renderGroups = this.renderGroups.bind(this);
        this.showRecipientsList = this.showRecipientsList.bind(this);
        this.hideRecipientsList = this.hideRecipientsList.bind(this);
        this.fetchCount = this.fetchCount.bind(this);
        this.onWindowFocus = this.onWindowFocus.bind(this);
        this.groupTypeRef = createRef();
    }

    async componentDidMount() {
        await this.props.actions.fetchIntegrationOptions();
        await this.props.actions.fetchRecipientsGroupTypes();

        if (this.props.groupType) {
            await this.props.actions.fetchRecipientsGroups(this.props.groupType);
        }

        if (this.props.recipientsListId) {
            await this.fetchCount(this.props.recipientsListId); // this.props.actions.fetchRecipientsCount(this.props.recipientsListId);
            this.onSelectedListChanged({
                target: {
                    name: 'recipientsListId', value: this.props.recipientsListId,
                },
            });
        }

        window.addEventListener('focus', this.onWindowFocus);
    }

    componentDidUpdate(prevProps) {
        if (this.props.groupTypesContainer.items && !this.props.groupType) {
            this.onGroupTypeChanged({
                target: {
                    name: 'recipientsGroupType',
                    value: this.props.groupTypesContainer.items[0].id,
                },
            });
        }

        if (!this.props.groupType) return;

        const groupsContainer = this.props.groupsContainers[this.props.groupType] || {};
        const groups = groupsContainer.items;

        if (groups) {
            let newListId = null;
            if (groups.length > 0) {
                const selectedGroup = groups.find((g) => g.id === this.props.recipientsListId);
                newListId = !selectedGroup ? groups[0].id : this.props.recipientsListId;
            }

            if (this.props.recipientsListId !== newListId) {
                this.onSelectedListChanged({
                    target: {
                        name: 'recipientsListId', value: newListId,
                    },
                });
            }
        } else if (this.props.groupType !== prevProps.groupType) {
            this.onGroupTypeChanged({
                target: {
                    name: 'recipientsGroupType', value: this.props.groupType,
                },
            });
        }

        if (this.props.recipientsListId && prevProps.recipientsListId !== this.props.recipientsListId) {
            this.fetchCount(this.props.recipientsListId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.onWindowFocus);
    }

    async onWindowFocus() {
        if (this.props.groupTypesContainer.items && !this.props.groupType) {
            this.onGroupTypeChanged({
                target: {
                    name: 'recipientsGroupType',
                    value: this.props.groupTypesContainer.items[0].id,
                },
            });
        }

        if (!this.props.groupType) return;

        const groupsContainer = this.props.groupsContainers[this.props.groupType] || {};
        const groups = groupsContainer.items;

        if (groups) {
            let newListId = null;

            if (groups.length > 0) {
                const selectedGroup = groups.find((g) => g.id === this.props.recipientsListId);

                newListId = !selectedGroup ? groups[0].id : this.props.recipientsListId;
            }

            if (this.props.recipientsListId !== newListId) {
                this.onSelectedListChanged({
                    target: {
                        name: 'recipientsListId', value: newListId,
                    },
                });
            }
        } else {
            this.onGroupTypeChanged({
                target: {
                    name: 'recipientsGroupType', value: this.props.groupType,
                },
            });
        }

        if (this.props.recipientsListId) {
            await this.fetchCount(this.props.recipientsListId);
        }
    }

    onGroupTypeChanged(e) {
        const groupType = e.target.value;

        if (groupType) {
            this.props.actions.fetchRecipientsGroups(groupType);
        }

        this.props.onFieldValChange(e);
    }

    onSelectedListChanged(e) {
        const groupId = e.target.value;

        this.fetchCount(groupId);

        this.props.onFieldValChange(e);

        const noneItem = { title: 'NONE' };

        if (this.props.groupTypesContainer.items) {
            const groupType = this.props.groupTypesContainer.items
                .find((gt) => gt.id === this.props.groupType) || noneItem;

            const groupsContainer = this.props.groupsContainers[this.props.groupType];

            if (groupsContainer && groupsContainer.items) {
                const group = groupsContainer.items.find((g) => g.id === groupId) || noneItem;

                this.props.onFieldValChange({
                    target: {
                        name: 'recipientsListName',
                        value: `${groupType.title}: ${group.title}`,
                    },
                });
            }
        }
    }

    async fetchCount(groupId) {
        if (!this.props.estimateNumberOfContacts || !groupId) {
            return;
        }

        const groupCountContainer = this.props.groupCountsContainers[groupId] || {};

        if (!groupCountContainer.isFetching) {
            await this.props.actions.fetchRecipientsCount(groupId);
        }
    }

    showRecipientsList() {
        this.setState({ showRecipientsList: true });
    }

    hideRecipientsList() {
        this.setState({ showRecipientsList: false });
    }

    renderGroups() {
        const isFetchingGroupTypes = this.props.groupTypesContainer.isFetching;
        const groupContainer = this.props.groupsContainers[this.props.groupType];
        const isFetchingGroups = groupContainer && groupContainer.isFetching;

        if (isFetchingGroupTypes || isFetchingGroups) {
            return (<span className="form-control">Loading, please wait...</span>);
        }

        if (groupContainer) {
            const options = groupContainer.items;

            if (options && options.length) {
                return (
                    <Form.Control
                        name="recipientsListId"
                        onChange={this.onSelectedListChanged}
                        as="select"
                        type="select"
                        value={this.props.recipientsListId}
                    >
                        {options.map((opt) => (
                            <option key={opt.id} value={opt.id}>{opt.title}</option>
                        ))}
                    </Form.Control>
                );
            }
            return (<p className="mimicFormControlReadOnly mb-0">No recipients for message</p>);
        }

        return null;
    }

    renderGroupTypes() {
        let canChangeGroupType = true;
        const groupsContainer = this.props.groupsContainers[this.props.groupType];

        if (groupsContainer && groupsContainer.isFetching) {
            canChangeGroupType = false;
        }

        const { groupTypesContainer } = this.props;

        if (groupTypesContainer.items) {
            return (
                <Form.Control
                    disabled={!canChangeGroupType}
                    name="recipientsGroupType"
                    onChange={this.onGroupTypeChanged}
                    as="select"
                    type="select"
                    value={this.props.groupType}
                    ref={this.groupTypeRef}
                >
                    {groupTypesContainer.items.map((item) => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                </Form.Control>
            );
        }

        if (groupTypesContainer.isFetching) {
            return (<span className="form-control">Loading, please wait...</span>);
        }

        if (groupTypesContainer.error) {
            return (<span className="form-control">Failed to load data from WildApricot</span>);
        }

        return null;
    }

    renderNumberOfRecipients() {
        if (!this.props.estimateNumberOfContacts) return null;

        let message = '';
        let allowViewList = true;
        let count = 0;
        const groupCountContainer = this.props.groupCountsContainers[this.props.recipientsListId];

        if (!this.props.recipientsListId) {
            // eslint-disable-next-line max-len
            message = `${count} recipient${count !== 1 ? 's' : ''} with a phone number and consent given.`;
        } else if (groupCountContainer) {
            if (groupCountContainer.error) {
                message = 'Failed to estimate number of recipients.';
            } else {
                count = groupCountContainer.count;
                // eslint-disable-next-line max-len
                message = `${count} recipient${count !== 1 ? 's' : ''} with a phone number and consent given.`;
            }
        }

        const isLoading = (this.props.recipientsListId && !groupCountContainer)
            || (groupCountContainer && groupCountContainer.isFetching);

        return (
            <Row className="mt-2" style={{ minHeight: 42 }}>
                <Col className="mt-2">
                    <Form.Text className="h6">
                        <LoadingIndicator loadingText="Estimating number of recipients" loading={isLoading}>
                            <span>{message}</span>
                        </LoadingIndicator>
                    </Form.Text>
                </Col>
                <Col xs={12} md="auto" className="d-flex justify-content-end mt-2">
                    <Button onClick={this.showRecipientsList} block variant="outline-primary" disabled={!allowViewList}>
                        Show the list
                    </Button>
                </Col>
            </Row>
        );
    }

    renderRecipientsDialog() {
        if (!this.state.showRecipientsList) return null;

        const contactsContainer = this.props.contactsContainers[this.props.recipientsListId] || {};
        return (
            <RecipientsListDialog
                onClose={this.hideRecipientsList}
                recipientsListId={this.props.recipientsListId}
                listTitle={this.props.recipientsListName}
                isLoadingRecipients={contactsContainer.isFetching}
                error={contactsContainer.error}
                recipients={contactsContainer.items}
                fetchRecipients={this.props.actions.fetchRecipients}
            />
        );
    }

    render() {
        return (
            <Form.Group>
                <Form.Label>Recipients</Form.Label>
                <Row>
                    <Col md="6">
                        {this.renderGroupTypes()}
                    </Col>
                    <Col md="6" className="mt-2 mt-md-0">
                        {this.renderGroups()}
                    </Col>
                </Row>

                {this.renderNumberOfRecipients()}

                {this.renderRecipientsDialog()}

            </Form.Group>
        );
    }
}

RecipientsListControl.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    groupTypesContainer: recipientsTypes.groupTypesContainer,
    // eslint-disable-next-line react/require-default-props
    groupsContainers: recipientsTypes.groupsContainers,
    // eslint-disable-next-line react/require-default-props
    groupCountsContainers: recipientsTypes.groupCountsContainers,
    // eslint-disable-next-line react/require-default-props
    contactsContainers: recipientsTypes.contactsContainers,
    hasConsentField: propTypes.bool.isRequired,

    // own
    estimateNumberOfContacts: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    groupType: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientsListId: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientsListName: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    onFieldValChange: propTypes.func,
};

RecipientsListControl.defaultProps = {
    estimateNumberOfContacts: true,
};

function mapStateToProps(state) {
    return {
        state: state.recipients,
        groupTypesContainer: state.recipients.groupTypes,
        groupsContainers: state.recipients.groups,
        groupCountsContainers: state.recipients.groupCounts,
        contactsContainers: state.recipients.contacts,
        hasConsentField: getHasConsentField(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...actionCreators, ...settingsActionCreators }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecipientsListControl);
