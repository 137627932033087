import React from 'react';
import PropTypes from 'prop-types';
import { useL10nContext } from 'wa.admin-ui.l10n';
import HelpSearch from './help-search';
import NavigationButton from '../../../components/navigation-button';
import sectionKeys from '../../../../../../../../../../../constants/section-keys';
import { useSectionContext }
    from '../../../../../../../../../../../entities/help-widget/context/section-context-provider';

const Elevio = ({ collapsed }) => {
    const { labels } = useL10nContext();
    const { setSection, section } = useSectionContext();

    const isActive = section.key === sectionKeys.SECTION_ELEVIO;

    return (
        <NavigationButton
            active={isActive}
            name={labels.NAVIGATION.ARTICLES.BUTTON_LABEL}
            onClick={() => setSection(sectionKeys.SECTION_ELEVIO)}
            collapsed={collapsed}
        >
            <HelpSearch active={isActive} />
        </NavigationButton>
    );
};

export default Elevio;

Elevio.propTypes =
  {
      collapsed: PropTypes.bool,
  };

Elevio.defaultProps =
  {
      collapsed: false,
  };