// see https://medium.com/@jcbaey/authentication-in-spa-reactjs-and-vuejs-the-right-way-e4a9ac5cd9a3 for explanation

const authStorage = {
    getState() {
        return {
            token: sessionStorage.getItem('auth:access_token'),
            expiresAt: sessionStorage.getItem('auth:expires_at'),
            accountId: sessionStorage.getItem('auth:accountId'),
        };
    },
    setState(token, expireIn, accountId) {
        sessionStorage.setItem('auth:access_token', token);
        sessionStorage.setItem('auth:expires_at', expireIn);
        sessionStorage.setItem('auth:accountId', accountId);
    },
    clear() {
        sessionStorage.removeItem('auth:access_token');
        sessionStorage.removeItem('auth:expires_at');
        sessionStorage.removeItem('auth:accountId');
    },
};

export default authStorage;
