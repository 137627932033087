import React from 'react';
import { useAssociationContext } from
    '../../../../../../entities/help-widget/context/association-context-provider';

import elevioModuleIds from '../../../../../../constants/elevio-module-ids';
import './email.css';

const Email = () => {
    const { isTrial } = useAssociationContext();

    return (
        <div
            key={window.location.pathname}
            id="help-center-email"
            className={`${isTrial ? '-wa-help-center__email-container-trial' : '-wa-help-center__email-container-paid'}
        position-relative w-100 h-100`}
        >
            <elevio-element
                data-prevent-open-assistant="true"
                data-type="addon"
                data-id={isTrial ? elevioModuleIds.CONTACT_COACH : elevioModuleIds.SUPPORT_EMAIL}
            />
        </div>
    );
};

export default Email;