// eslint-disable-next-line import/no-anonymous-default-export
export default
{
    TITLE: 'Help Center',
    CHAT_BUTTON_LABEL: 'Chat',
    EMAIL_BUTTON_LABEL: 'Email',
    BOOK_A_CALL_BUTTON_LABEL: 'Book a call',
    LAUNCH_TUTORIAL_BUTTON_LABEL: 'Launch interactive tutorial library',
    HELP_BADGE:
    {
        HELP_TITLE: 'Help center',
        CHAT_TITLE: 'Live chat',
    },
    NAVIGATION:
    {
        HOME: { BUTTON_LABEL: 'Home', HEADER_TITLE: 'Home' },
        CHAT: { BUTTON_LABEL: 'Chat', HEADER_TITLE: 'Chat', DISABLED: 'Chat offline' },
        BOOK_A_CALL: { BUTTON_LABEL: 'Book a Call', HEADER_TITLE: 'Book a Call' },
        EMAIL: { BUTTON_LABEL: 'Email', HEADER_TITLE: 'Email us!' },
        ARTICLES: { BUTTON_LABEL: 'Help', HEADER_TITLE: 'Help Articles' },
        TUTORIAL: { BUTTON_LABEL: 'Tutorials', HEADER_TITLE: 'Interactive Tutorials' },
    },
    TRIAL:
    {
        HEADER: 'Get in touch!',
        SUB_HEADER: 'Our coaches are here to help',
        CONTACT_SECTION_TITLE: 'Need help? Our coaches are here to guide you at no cost!',
        CONTACT_SECTION_BULLET_POINTS:
        [
            'No pressure to buy',
            'Learn how to set everything up right the first time',
            'Get expert advice on whether WildApricot is right for you',
        ],
        LIVE_CHAT_BUTTON_LABEL: 'Live chat',
        ERROR_LABEL: 'Failed to load',
        APPOINTLET_ERROR: 'Failed to load booking configuration. Try reloading help center.',
        ERROR_RETRY_BUTTON_LABEL: 'Retry',
        APPOINTLET_SETTINGS: {
            SCRIPT_URL: 'https://www.appointletcdn.com/loader/loader.min.js',
            SUBJECT: 'I want to discuss...',
            UTM: {
                TRIAL: {
                    UTM_SOURCE: 'Getting Started',
                    UTM_MEDIUM: 'Booking',
                    UTM_CAMPAIGN: 'Engagement',
                },
            },
        },
        APPOINTLET_BUTTON_NAME: 'Book a call',
        APPOINTLET_BOOKING_COMPLETE_HEADER: 'You have successfully booked a call!',
        APPOINTLET_BOOKING_COMPLETE:
        `An email with the call details was sent to you.
        You can change the time or cancel the call using the link provided.`,
        ONBOARDING_SUBMIT_EMAIL_URL: 'https://support.wildapricot.com/hc/en-us/requests/new#onboarding',
    },
    PAID:
    {
        HEADER: 'Hello!',
        SUB_HEADER: 'Let’s help you find an answer',
        INTERACTIVE_TUTORIAL_LABEL: 'Interactive tutorials',
        CONTACT_SECTION_TITLE: 'Can\'t find the answer you\'re looking for?',
        CONTACT_SECTION_DESCRIPTION: 'Here are other ways to get the help you need:',
        SUPPORT_HOURS_NOTICE: 'Our support hours are Monday to Thursday 9AM-7PM EST, Friday 10AM-5PM EST',
    },
    ERROR:
    {
        TUTORIAL_ERROR: 'An error has occurred. Please reload this tab.',
    },
};
