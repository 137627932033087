import React from 'react';
import propTypes from 'prop-types';
import { Button, Modal, FormLabel } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';

class SendTestDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = { defaultPhone: '1' };

        this.handleSend = this.handleSend.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onFieldValChange = this.onFieldValChange.bind(this);
    }

    componentDidMount() {
        const testPhone = window.sessionStorage.getItem('test-phone');

        if (testPhone) {
            this.setState({ defaultPhone: testPhone });
        }
    }

    handleSend(event) {
        // eslint-disable-next-line prefer-destructuring
        const value = document.getElementById('testPhoneInput').value;
        this.props.onClose(event.target.name, value);
    }

    onFieldValChange(e) {
        this.setState({ defaultPhone: e.target.value });
        window.sessionStorage.setItem('test-phone', e.target.value);
    }

    onHide() {
        this.props.onClose('cancel');
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>Send test message</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="mb-3">
                        You can send a test message to any mobile number.
                        The message will be sent immediately.
                    </div>

                    <FormLabel>Phone number</FormLabel>
                    <MaskedFormControl
                        id="testPhoneInput"
                        placeholder="+1 (123) 456–7890"
                        mask="+1 (111) 111–1111"
                        value={this.state.defaultPhone}
                        onChange={this.onFieldValChange}
                    />

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={this.handleSend} name="cancel">Cancel</Button>
                    <Button variant="primary" onClick={this.handleSend} name="confirm">Send test message</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

SendTestDialog.propTypes = {
    onClose: propTypes.func.isRequired,
    show: propTypes.bool.isRequired,
};

export default SendTestDialog;
