import React from 'react';
import HelpSection from '../../../../components/help-section';
import './elevio.css';

const Elevio = () =>
    (
        <>
            <HelpSection>
                <div key={window.location.pathname}>
                    <elevio-element data-prevent-open-assistant="true" data-type="suggestions" />
                </div>
            </HelpSection>
        </>
    );

export default Elevio;
