import React from 'react';
import AuthorizationRequired from '../AuthorizationRequired';
import TwilioSubscriptionForm from '../TwilioSubscriptionForm/TwilioSubscriptionForm';
import NotAuthorized from '../NotAuthorized';
import propTypes from 'prop-types';
import twilioStatuses from '../../constants/twilioStatuses';
import { getHasTwilioAccount, getIsBillingPlanProhibited } from '../../reducers/authReducer';
import { getTwilioSubscription } from '../../reducers/settingsReducer';
import { connect, useSelector } from 'react-redux';

const SettingsProfilePage = ({ hasSubscription, isBillingPlanProhibited }) => {
    const hasTwilioAccount = useSelector(getHasTwilioAccount);
    const { Status } = useSelector(getTwilioSubscription) || {};
    const isAvailable = hasTwilioAccount && hasSubscription && !isBillingPlanProhibited;

    return (
        <div className="mainContent pb-5">
            <AuthorizationRequired/>
            <h1 className="fixedHeader">Account details</h1>
            { isAvailable ?
                (<TwilioSubscriptionForm
                    isSettings={true}
                    isReadOnly={Status !== twilioStatuses.twilio_rejected}
                />)
                : <NotAuthorized /> }
        </div>
    );
};

SettingsProfilePage.propTypes = {
    hasSubscription: propTypes.bool.isRequired,
    isBillingPlanProhibited: propTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { me } = state.auth;

    return {
        hasSubscription: me.account.hasSubscription,
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
    };
}

export default connect(
    mapStateToProps,
)(SettingsProfilePage);
