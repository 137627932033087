import React from 'react';
import propTypes from 'prop-types';
import IntegrationOptions from '../../../../../Settings/IntegrationOptions/IntegrationOptionsContainer';

const StepIntegrationSettings = ({ onNext, onPrev }) => {
    return (
        <IntegrationOptions
            isSettings={false}
            onNext={onNext}
            onPrev={onPrev}
        />
    );
};

StepIntegrationSettings.propTypes = {
    onNext: propTypes.func.isRequired,
};

export default StepIntegrationSettings;
