import React from 'react';
import propTypes from 'prop-types';
import { messageDeliveryStatuses } from 'mp-constants';
import {
    Button, Container, Row, Col, OverlayTrigger, Popover, Alert,
} from 'react-bootstrap';
import * as types from '../../types';
import CampaignStatistics from './CampaignStatistics';
import campaignUtils from './campaignUtils';

function CampaignDetailsView({
    campaign, onCancelCampaign, onDuplicateCampaign, onEditCampaign, onNavigateToCampaignResponses,
}) {
    const cancelHint = (
        <Popover>
            <Popover.Content>
                <p>
                    You can cancel campaign only before messages are sent to network.
                </p>
            </Popover.Content>
        </Popover>
    );

    const renderButtons = () => (
        <Col className="d-flex justify-content-end">
            <OverlayTrigger placement="top" overlay={cancelHint}>
                <Button
                    variant="outline-danger"
                    type="button"
                    className="ml-3"
                    hidden={campaign.status !== 'active' || campaign.processingStatus !== 'none'}
                    onClick={onCancelCampaign}
                >
                    Cancel campaign
                </Button>
            </OverlayTrigger>
            <Button
                className="ml-3"
                variant="primary"
                type="button"
                onClick={onDuplicateCampaign}
            >
                Duplicate
            </Button>
            <Button
                className="ml-3"
                variant="primary"
                type="button"
                hidden={campaign.status !== 'draft'}
                onClick={onEditCampaign}
            >
                Edit
            </Button>
        </Col>
    );

    const renderIntro = () => {
        if (campaign.status === 'draft') {
            return (
                <div>
                    <h4>This is a draft campaign.</h4>
                </div>
            );
        }

        if (campaign.status === 'active' && campaign.processingStatus === 'none') {
            return (<h4>This campaign is scheduled for delivery.</h4>);
        }

        if (campaign.status === 'active') {
            let percent = 10;

            if (campaign.statistics && campaign.statistics.delivery) {
                let total = 0;
                let processed = 0;

                Object.keys(campaign.statistics.delivery).forEach((key) => {
                    const element = campaign.statistics.delivery[key];

                    total += element;

                    if (key !== messageDeliveryStatuses.sending) {
                        processed += element;
                    }
                });

                percent += (processed * 90 / total);
            }
            // campaign.statistics
            return (
                <div>
                    <h4 className="mb-4">
                        This campaign is processing,
                        {' '}
                        {Math.round(percent)}
                        % has completed.
                    </h4>
                    <Alert variant="warning">
                        It may take several minutes to display campaign results after it is finished.
                    </Alert>
                    <div hidden={!campaign.processingStatus}>
                        <CampaignStatistics campaign={campaign} />
                    </div>
                </div>
            );
        }

        if (campaign.status === 'stopped' && campaign.processingStatus === 'complete') {
            return (
                <div>
                    <h4 className="mb-4">This campaign has completed.</h4>
                    {/* eslint-disable-next-line max-len */}
                    <CampaignStatistics campaign={campaign} onNavigateToCampaignResponses={onNavigateToCampaignResponses} />
                </div>
            );
        }

        if (campaign.status === 'stopped') {
            return (
                <div>
                    <h4>This campaign was cancelled. Messages were not sent.</h4>

                    <Alert variant="info" className="mt-3" hidden={!campaign.systemComment}>
                        Reason:
                        {' '}
                        {campaign.systemComment}
                    </Alert>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="campaignInfoContainer">
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        {renderIntro()}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <h6 className="text-muted">Recipients</h6>
                        <p className="mimicFormControlReadOnly">{campaign.recipientsListName}</p>
                    </Col>
                    <Col sm={12} md={6}>
                        <h6 className="text-muted">{campaignUtils.getScheduleLabel(campaign)}</h6>
                        <p className="mimicFormControlReadOnly">{campaignUtils.getScheduleText(campaign)}</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h6 className="text-muted">Message</h6>
                        <p className="mimicFormControlReadOnly">{campaign.content}</p>
                    </Col>
                </Row>

                <Row className="mt-3">
                    {renderButtons()}
                </Row>

            </Container>
        </div>
    );
}

CampaignDetailsView.propTypes = {
    // eslint-disable-next-line react/require-default-props
    campaign: types.campaign,
    onCancelCampaign: propTypes.func.isRequired,
    onDuplicateCampaign: propTypes.func.isRequired,
    onEditCampaign: propTypes.func.isRequired,
    onNavigateToCampaignResponses: propTypes.func.isRequired,
};

export default CampaignDetailsView;
