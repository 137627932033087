import tutorialContentFrameStyleOverride from './tutorial-content-frame-style-override';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const retryAppendChild = () => {
        const frame =
      window.document
          .getElementsByClassName('apt-widget-window')['widget-window']
          ?.contentDocument.head;

        if (frame !== undefined) {
            const $style = document.createElement('style');
            frame.appendChild($style);
            $style.innerHTML = tutorialContentFrameStyleOverride;
        } else {
            setTimeout(retryAppendChild, 100);
        }
    };

    retryAppendChild();
};
