import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ title, icon, description }) =>
    (
        <div className="d-flex pb-5">
            <div className="px-3">
                {icon}
            </div>
            <div>
                <div>
                    <h5>
                        {title}
                    </h5>
                </div>
                <div>
                    {description}
                </div>
            </div>

        </div>
    );
export default ListItem;

ListItem.propTypes =
    {
        title: PropTypes.string,
        icon: PropTypes.node,
        description: PropTypes.node,
    };

ListItem.defaultProps =
    {
        title: '',
        icon: null,
        description: '',
    };
