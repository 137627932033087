import authStorage from '../utils/authStorage';
import apiErrorDecoder from './apiErrorDecoder';

const getStandardHeaders = () => {
    const { token } = authStorage.getState() || {};
    const result = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    if (token) {
        result.Authorization = `Bearer ${token}`;
    }

    return result;
};

// eslint-disable-next-line max-len
const defaultErrorMessage = 'Something went wrong, server did not provide a meaningfull response. Please try again in a minute or contact support.';
const executeRequest = async (
    {
        url,
        method,
        headers,
        body,
    },
    transformResponse,
) => {
    // eslint-disable-next-line no-param-reassign
    headers = headers || getStandardHeaders();

    if (!url) {
        // console.error('executeRequest, url param is missing');
        return;
    }

    if (!method) {
        // console.error('executeRequest, method param is missing');
        return;
    }

    if (method.toUpperCase() === 'GET') {
        // eslint-disable-next-line no-param-reassign
        body = null;
    } else {
        // eslint-disable-next-line no-param-reassign
        body = JSON.stringify(body || {});
    }

    try {
        const response = await fetch(url, {
            method,
            headers,
            body,
            credentials: 'include',
        });

        if (response.status === 400) {
            // eslint-disable-next-line no-shadow
            const data = await response.json();
            const error = apiErrorDecoder.decodeErrorCode(data);

            // eslint-disable-next-line consistent-return
            return { error, apiResponse: data };
        }

        if (response.status >= 400) {
            // eslint-disable-next-line consistent-return
            return { error: defaultErrorMessage };
        }

        const responseContent = await response.json() || {};

        if (transformResponse) {
            // eslint-disable-next-line consistent-return
            return transformResponse(responseContent);
        }

        // eslint-disable-next-line consistent-return
        return responseContent;
    } catch (e) {
        // console.error(e);
        // eslint-disable-next-line consistent-return
        return { error: defaultErrorMessage };
    }
};

const executeGet = (url, transformResponse) => executeRequest({ url, method: 'GET' }, transformResponse);

const apiClient = { getStandardHeaders, executeRequest, executeGet };

export default apiClient;
