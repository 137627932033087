import apiUrls from './apiUrls';
import apiClient from './apiClient';

export const updateEmail = (email) => apiClient.executeRequest({
    url: apiUrls.settings.setEmail,
    method: 'POST',
    body: { email },
});

export const updatePassword = (password) => apiClient.executeRequest({
    url: apiUrls.settings.setPassword,
    method: 'POST',
    body: { password },
});

export const updateApiKey = (apiKey) => apiClient.executeRequest({
    url: apiUrls.settings.apiKey,
    method: 'POST',
    body: { apiKey },
});

export const fetchApiKey = () => apiClient.executeGet(apiUrls.settings.apiKey);

export const fetchWaFields = () => apiClient.executeGet(
    apiUrls.settings.waFields,
    (response) => ({ waFields: response }),
);

export const fetchIntegrationOptions = () => apiClient.executeGet(
    apiUrls.settings.integrationOptions,
    (response) => ({ integrationOptions: response }),
);

export const saveIntegrationOptions = (integrationOptions) => apiClient.executeRequest({
    url: apiUrls.settings.integrationOptions,
    method: 'POST',
    body: { integrationOptions },
});

export const fetchTwilioSubscription = () => apiClient.executeGet(
    apiUrls.settings.twilioSubscription,
    (response) => ({ payload: response }),
);

export const saveTwilioSubscription = (payload) => apiClient.executeRequest({
    url: apiUrls.settings.twilioSubscription,
    method: 'POST',
    body: payload,
});

export const fetchTwilioSettings = () => apiClient.executeGet(
    apiUrls.settings.twilioSettings,
    (response) => ({ payload: response }),
);

export const saveTwilioSettings = (payload) => apiClient.executeRequest( {
    url: apiUrls.settings.twilioSettings,
    method: 'POST',
    body: payload,
});

export const fetchConsentWebpages = () => apiClient.executeGet(
    apiUrls.settings.webPages,
    (response) => ({ consentPages: response }),
);

export const fetchEin = (ein) => apiClient.executeGet(
    apiUrls.settings.ein + '?ein=' + ein,
    (response) => ({ ein: response }),
);

const methods = {
    fetchApiKey,
    updateApiKey,
    updateEmail,
    updatePassword,
    fetchWaFields,
    fetchIntegrationOptions,
    saveIntegrationOptions,
    fetchTwilioSubscription,
    saveTwilioSubscription,
    fetchTwilioSettings,
    saveTwilioSettings,
    fetchConsentWebpages,
    fetchEin,
};

export default methods;
