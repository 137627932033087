import { useState, useEffect } from 'react';
import injectContentStyling from '../helpers/inject-content-styling';

const useRenderTutorialWidget = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (typeof window.aptrinsic !== 'undefined' && typeof window.aptrinsic === 'function') {
            window.aptrinsic('kcb', 'open', {
                onDone: () => {
                    injectContentStyling();
                    setLoading(false);
                },
            });
        } else {
            setError(true);
        }
        return () => {
            if (typeof window.aptrinsic !== 'undefined' && typeof window.aptrinsic === 'function') {
                window.aptrinsic('kcb', 'hide', {
                    onDone: () => {
                        setLoading(true);
                    },
                });
            } else {
                setError(false);
            }
        };
    }, [setLoading, setError]);

    return [loading, error];
};

export default useRenderTutorialWidget;