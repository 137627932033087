import React from 'react';
import propTypes from 'prop-types';
import { Button, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faVial, faFile } from '@fortawesome/free-solid-svg-icons';

function EditableCampaignButtons({
    canSendTest, canSave, onSendTest, onFieldValChange, canSend,
}) {
    const handleSaveDraft = () => {
        onFieldValChange({ target: { name: 'status', value: 'draft' } });
    };
    const handleActivate = async () => {
        const form = document.getElementById('editCampaignForm');
        if (form.checkValidity() === true) {
            onFieldValChange({ target: { name: 'status', value: 'active' } });
        }
    };

    return (
        <Form.Row>
            <Col xl={3} />
            <Col md={4} xl={3} className="mt-2 text-nowrap">
                {!canSave ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Sending messages unavailable</Tooltip>}
                    >
                        <span className="d-inline-block">
                            <Button
                                type="submit"
                                variant="outline-secondary"
                                block
                                disabled
                                style={{ pointerEvents: 'none' }}
                            >
                                <FontAwesomeIcon icon={faFile} className="mr-2" />
                                {' '}
                                Save as a draft
                            </Button>
                        </span>
                    </OverlayTrigger>
                ) : (
                    <Button
                        type="submit"
                        variant="outline-secondary"
                        block
                        onClick={handleSaveDraft}
                    >
                        <FontAwesomeIcon icon={faFile} className="mr-2" />
                        {' '}
                        Save as a draft
                    </Button>
                )}
            </Col>
            <Col md={4} xl={3} className="mt-2 text-nowrap">
                {!canSend || !canSendTest ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Sending messages unavailable until registration 
                            and subscription complete</Tooltip>}
                    >
                        <span className="d-inline-block">
                            <Button type="button" variant="success" block disabled style={{ pointerEvents: 'none' }}>
                                <FontAwesomeIcon icon={faVial} className="mr-2" />
                                {' '}
                                Send test message
                            </Button>
                        </span>
                    </OverlayTrigger>
                ) : (
                    <Button type="button" variant="success" block onClick={onSendTest}>
                        <FontAwesomeIcon icon={faVial} className="mr-2" />
                        {' '}
                        Send test message
                    </Button>
                )}
            </Col>
            <Col md={4} xl={3} className="mt-2 text-nowrap">
                {!canSend || !canSave ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Sending messages unavailable until registration 
                            and subscription complete</Tooltip>}
                    >
                        <span className="d-inline-block">
                            <Button
                                type="submit"
                                variant="primary"
                                block
                                value="Launch"
                                disabled
                                style={{ pointerEvents: 'none' }}
                            >
                                <FontAwesomeIcon icon={faRocket} className="mr-2" />
                                {' '}
                                Launch the campaign
                            </Button>
                        </span>
                    </OverlayTrigger>
                ) : (
                    <Button
                        type="submit"
                        variant="primary"
                        block
                        onClick={handleActivate}
                        value="Launch"
                    >
                        <FontAwesomeIcon icon={faRocket} className="mr-2" />
                        {' '}
                        Launch the campaign
                    </Button>
                )}
            </Col>
        </Form.Row>
    );
}

EditableCampaignButtons.propTypes = {
    canSendTest: propTypes.bool.isRequired,
    canSend: propTypes.bool.isRequired,
    canSave: propTypes.bool.isRequired,
    onSendTest: propTypes.func.isRequired,
    onFieldValChange: propTypes.func.isRequired,
};


export default EditableCampaignButtons;
