import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from '../../actions/textCampaignsActions';
import * as messagesActionCreators from '../../actions/messagesActions';
import AuthorizationRequired from '../AuthorizationRequired';
import * as types from '../../types';
import CampaignDetailsView from './CampaignDetailsView';
import LoadingIndicator from '../LoadingIndicator';

class CampaignDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this);
        this.onCancelCampaign = this.onCancelCampaign.bind(this);
        this.onDuplicateCampaign = this.onDuplicateCampaign.bind(this);
        this.onEditCampaign = this.onEditCampaign.bind(this);
        this.reloadCampaign = this.reloadCampaign.bind(this);
        this.onNavigateToCampaignResponses = this.onNavigateToCampaignResponses.bind(this);
        this.intervalRef = null;
    }

    componentDidMount() {
        this.props.actions.fetchCampaign(this.props.campaignId);

        // refresh the page every 20 seconds
        const hoRefresher = (self) => () => {
            if (!document.hidden) self.reloadCampaign();
        };

        this.intervalRef = setInterval(hoRefresher(this), 20000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalRef);
    }

    handleBack(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.actions.back();
    }

    onCancelCampaign() {
        this.props.actions.cancelCampaign(this.props.campaignId);
    }

    onDuplicateCampaign() {
        this.props.actions.duplicateCampaign(this.props.campaignId);
    }

    onEditCampaign() {
        this.props.actions.navigateToCampaign(this.props.campaignId, this.props.campaign.status);
    }

    onNavigateToCampaignResponses() {
        this.props.navigateToConversations('latest', { campaignId: this.props.campaignId });
    }

    reloadCampaign() {
        if (this.props.campaign.status === 'active') this.props.actions.fetchCampaign(this.props.campaignId);
    }

    render() {
        return (
            <div className="mainContent">
                <AuthorizationRequired />
                <h1 className="fixedHeader text-nowrap text-truncate">
                    Campaign &apos;
                    {this.props.campaign.title}
                    &apos;
                </h1>
                <Button href="/campaigns" variant="link" onClick={this.handleBack}>
                    <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                    Back to campaigns
                </Button>
                <LoadingIndicator loading={!this.props.campaign.id}>
                    <CampaignDetailsView
                        campaign={this.props.campaign}
                        onCancelCampaign={this.onCancelCampaign}
                        onDuplicateCampaign={this.onDuplicateCampaign}
                        onEditCampaign={this.onEditCampaign}
                        onNavigateToCampaignResponses={this.onNavigateToCampaignResponses}
                    />
                </LoadingIndicator>
            </div>
        );
    }
}

CampaignDetailsPage.propTypes = {
    campaignId: propTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    isFetching: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    campaign: types.campaign,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    navigateToConversations: propTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isFetching: state.campaignDetails.isFetching,
        campaign: state.campaignDetails.campaign || {},
        error: state.campaignDetails.error,
    };
}

function mapDispatchToProps(dispatch) {
    const messagesActions = bindActionCreators(messagesActionCreators, dispatch);

    return {
        actions: bindActionCreators(actionCreators, dispatch),
        navigateToConversations: messagesActions.navigateToConversations,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CampaignDetailsPage);
