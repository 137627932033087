import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function sendMessageReducer(state = initialState.messages.sendMessage, action) {
    switch (action.type) {
        case actionTypes.Messages.SEND_MESSAGE.start: {
            return {
                ...state,
                isSending: true,
                error: null,
                lastSentMessageId: null,
                phone: action.phone,
                content: action.content,
                recipientId: action.recipientId,
                sendTimestamp: Date.now(),
            };
        }

        case actionTypes.Messages.SEND_MESSAGE.failure: {
            return {
                ...state,
                isSending: false,
                error: action.error,
                lastSentMessageId: null,
            };
        }

        case actionTypes.Messages.SEND_MESSAGE.success: {
            return {
                ...state,
                isSending: false,
                error: null,
                lastSentMessageId: action.messageId,
            };
        }

        default:
            return state;
    }
}
