import { useEffect } from 'react';
import zendesk from '../../HelpCenter/src/scripts/zendesk-chat/index';

const useInitZendeskWidget = (data) => {
    useEffect(() => {
        if (data) {
            zendesk(data);
        }
    }, [data]);
};

export default useInitZendeskWidget;
