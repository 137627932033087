import React from 'react';
import PropTypes from 'prop-types';

const HelpSearch = ({ active }) => {
    const color = active ? '#FFFFFF' : '#2B93D1';

    return (
        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_817_563)">
                {/* eslint-disable max-len */ }
                <path d="M15.2577 23.5096H0.842001C0.319104 23.3307 0.000470655 22.9973 0.000470655 22.4301C0 16.6146 -0.000470655 10.7985 0.000941309 4.98295C0.000941309 4.33948 0.450417 3.90621 1.1098 3.90391C1.9471 3.90115 2.78439 3.90345 3.62169 3.90345C3.70546 3.90345 3.78971 3.90345 3.89702 3.90345C3.89702 3.7995 3.89702 3.71763 3.89702 3.63576C3.89702 2.77888 3.89373 1.92153 3.89843 1.06465C3.9022 0.466259 4.34697 0.00263197 4.92681 0.00217202C7.92394 -0.00104762 10.9211 -0.00196751 13.9182 0.00861129C14.0726 0.00907124 14.2641 0.0840427 14.3752 0.188911C15.8677 1.59543 17.3483 3.01391 18.8328 4.42871C19.0738 4.65822 19.2968 4.91349 19.5679 5.10115C19.7534 5.22948 19.9143 5.35044 19.9995 5.55696V18.6889C19.8687 19.3154 19.4126 19.635 18.7283 19.613C17.9399 19.5877 17.1502 19.607 16.3614 19.607C16.2785 19.607 16.1962 19.607 16.0992 19.607C16.0992 20.5002 16.0832 21.3626 16.1044 22.2245C16.1199 22.8561 15.8931 23.304 15.2572 23.5101L15.2577 23.5096ZM9.47428 5.06758H1.18605V22.3441H14.9127V10.2618H14.6228C13.144 10.2618 11.6647 10.2618 10.1859 10.2618C9.6809 10.2618 9.47428 10.0612 9.47428 9.56909C9.47334 8.15337 9.47428 6.73811 9.47428 5.32239V5.06804V5.06758ZM13.3718 1.16354H5.0826V3.90345C5.17814 3.90345 5.26192 3.90345 5.3457 3.90345C6.87909 3.90345 8.41248 3.90529 9.9454 3.90115C10.1779 3.90069 10.361 3.96784 10.5309 4.13112C12.0492 5.58732 13.5737 7.03799 15.0977 8.48867C15.2935 8.67495 15.4728 8.89296 15.7025 9.02589C15.9896 9.19193 16.1025 9.39983 16.1016 9.71351C16.0969 12.5362 16.0987 15.3589 16.0987 18.1816V18.4387H18.8083V6.35865C18.7019 6.35865 18.6092 6.35865 18.5165 6.35865C17.0382 6.35865 15.5594 6.35865 14.081 6.35865C13.577 6.35865 13.3708 6.15765 13.3708 5.66413C13.3699 4.24887 13.3708 2.83361 13.3708 1.41835V1.16308L13.3718 1.16354ZM18.027 5.2005C16.8537 4.12606 15.7194 3.08796 14.5578 2.0241V5.2005H18.027ZM10.658 5.92584V9.10132H14.1258C12.9595 8.03332 11.8294 6.99844 10.658 5.92584Z" fill={color} />
                <path d="M18.0268 5.19984H14.5576V2.02344C15.7197 3.0873 16.8535 4.1254 18.0268 5.19984Z" fill="none" />
                <path d="M10.6572 5.92578C11.8282 6.99838 12.9587 8.03326 14.125 9.10126H10.6572V5.92578Z" fill="none" />
                <path d="M12.445 21.3777C14.145 20.2522 14.5893 17.9934 13.4376 16.3321C12.2859 14.6708 9.97451 14.2366 8.27451 15.3621C6.5745 16.4875 6.1302 18.7464 7.28189 20.4077C8.19214 21.7208 9.86861 22.305 11.4232 21.851H11.4227C11.4312 21.8777 11.4411 21.9034 11.4533 21.9296L12.7867 24.6797C12.9217 24.9589 13.263 25.078 13.5487 24.946C13.8343 24.814 13.9562 24.4805 13.8212 24.2013L12.4878 21.4513C12.4756 21.4255 12.4615 21.4007 12.445 21.3773V21.3777ZM13.3232 19.4032C12.7391 21.0019 10.9389 21.8354 9.30289 21.2646C7.66689 20.6938 6.81406 18.9345 7.39815 17.3357C7.98223 15.7369 9.78248 14.9035 11.4185 15.4743C13.0545 16.0451 13.9073 17.8044 13.3232 19.4032Z" fill={color} />
                <path d="M7.7315 16.9831C6.30024 16.9831 4.86851 16.9817 3.43725 16.9845C3.19627 16.985 3.0306 16.8875 2.94918 16.6676C2.87576 16.4694 2.94777 16.2224 3.1365 16.1262C3.25322 16.0669 3.40383 16.0499 3.53938 16.0494C6.00326 16.0453 8.46761 16.0462 10.9315 16.0462C11.3071 16.0462 11.6826 16.0425 12.0578 16.0476C12.3985 16.0522 12.583 16.2274 12.5797 16.5301C12.5764 16.8125 12.382 16.9822 12.0498 16.9822C10.6105 16.9836 9.17123 16.9827 7.73197 16.9827L7.7315 16.9831Z" fill={color} />
                <path d="M7.74354 19.7443C6.30428 19.7443 4.86502 19.7429 3.42575 19.7462C3.21725 19.7462 3.02852 19.6864 2.98099 19.4881C2.9438 19.334 2.96593 19.1427 3.02899 18.9955C3.10242 18.824 3.29774 18.8189 3.47753 18.8194C5.80068 18.8217 8.12383 18.8207 10.447 18.8207C10.9944 18.8207 11.5422 18.8189 12.0896 18.8217C12.3903 18.823 12.5814 19.007 12.58 19.2857C12.5786 19.5672 12.3922 19.7434 12.0853 19.7439C10.6381 19.7452 9.19128 19.7443 7.74401 19.7443H7.74354Z" fill={color} />
                <path d="M6.78015 14.2074C5.66141 14.2074 4.54313 14.2061 3.42439 14.2088C3.21824 14.2093 3.05492 14.1467 2.96879 13.9549C2.8869 13.7723 2.88643 13.5709 3.03986 13.4453C3.15847 13.3483 3.34296 13.2848 3.49875 13.2829C4.67209 13.2701 5.84543 13.2756 7.01878 13.2756C8.06692 13.2756 9.11507 13.2733 10.1632 13.277C10.5605 13.2783 10.7897 13.644 10.6089 13.977C10.5082 14.1624 10.3392 14.2102 10.1364 14.2093C9.01765 14.2061 7.89937 14.2074 6.78062 14.2074H6.78015Z" fill={color} />
                <path d="M5.39726 10.5121C6.0463 10.5121 6.69533 10.5112 7.34389 10.5121C7.66441 10.5126 7.85785 10.6887 7.86114 10.9767C7.86397 11.2559 7.66582 11.4362 7.34013 11.4366C6.03453 11.4385 4.72893 11.4385 3.42287 11.4366C3.09859 11.4366 2.91974 11.2609 2.92491 10.961C2.93009 10.6782 3.11318 10.5126 3.42663 10.5121C4.0832 10.5112 4.74023 10.5121 5.39679 10.5121H5.39726Z" fill={color} />
                <path d="M4.48328 7.75002C4.84239 7.75002 5.20103 7.74726 5.56014 7.75094C5.84912 7.7537 6.03362 7.93676 6.0308 8.2072C6.02844 8.46478 5.82842 8.67221 5.5592 8.67405C4.84145 8.67911 4.12323 8.67911 3.40549 8.67405C3.10662 8.67221 2.92259 8.47995 2.92683 8.19571C2.93059 7.92756 3.11509 7.75324 3.4069 7.75094C3.76601 7.74772 4.12465 7.75002 4.48376 7.75002H4.48328Z" fill={color} />
                {/* eslint-enable max-len */}
            </g>
            <defs>
                <clipPath id="clip0_817_563">
                    <rect width="20" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HelpSearch;

HelpSearch.propTypes =
  {
      active: PropTypes.bool,
  };

HelpSearch.defaultProps =
  {
      active: false,
  };