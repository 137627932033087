import { createContext, useContext } from 'react';

export const ChatContext = createContext({
    chatActive: null,
    unread: null,
    startChat: null,
    endChat: null,
    isUnread: null,
    clearUnread: null,
});

export const useChatContext = () => useContext(ChatContext);

export default ChatContext;