import propTypes from 'prop-types';

const contact = propTypes.shape({
    id: propTypes.string,
    displayName: propTypes.string.isRequired,
    phoneNumberValid: propTypes.bool.isRequired,
    phone: propTypes.string,
});

const contactsContainers = propTypes.objectOf(propTypes.shape({
    isFetching: propTypes.bool,
    error: propTypes.string,
    items: propTypes.arrayOf(contact),
}));

const groupTypesContainer = propTypes.shape({
    isFetching: propTypes.bool,
    error: propTypes.string,
    items: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string.isRequired,
        title: propTypes.string.isRequired,
    })),
});

const groupsContainers = propTypes.objectOf(propTypes.shape({
    isFetching: propTypes.bool,
    error: propTypes.string,
    items: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string.isRequired,
        title: propTypes.string.isRequired,
    })),
}));

const groupCountsContainers = propTypes.objectOf(propTypes.shape({
    isFetching: propTypes.bool,
    error: propTypes.string,
    count: propTypes.number,
}));

const api = {
    contactsContainers,
    groupTypesContainer,
    groupsContainers,
    groupCountsContainers,
};

export default api;
