import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function campaignReducer(state = initialState.campaignDetails, action) {
    switch (action.type) {
        case actionTypes.Campaigns.SAVE_CAMPAIGN.start:
            return {
                ...state,
                isSaving: true,
            };

        case actionTypes.Campaigns.SAVE_CAMPAIGN.success:
            return {
                ...state,
                isSaving: false,
                error: null,
            };

        case actionTypes.Campaigns.SAVE_CAMPAIGN.failure:
            return {
                ...state,
                isSaving: false,
                error: action.error,
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGN_DETAILS.start:
            return {
                ...state,
                isFetching: true,
                id: action.campaignId,
                campaign: state.campaign && state.campaign.id === action.campaignId ? state.campaign : null,
                error: null,
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGN_DETAILS.success:
            return {
                ...state,
                isFetching: false,
                error: null,
                campaign: action.campaign,
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGN_DETAILS.failure:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                campaign: null,
            };

        case actionTypes.Campaigns.UPDATE_CURRENT_CAMPAIGN:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                campaign: { ...state.campaign, ...action.campaignUpdate },
            };

        case actionTypes.Campaigns.CANCEL_CAMPAIGN.start:
            return {
                ...state,
                isSaving: true,
            };

        case actionTypes.Campaigns.CANCEL_CAMPAIGN.success:
            return {
                ...state,
                isSaving: false,
                error: null,
                campaign: action.campaign,
            };

        case actionTypes.Campaigns.CANCEL_CAMPAIGN.failure:
            return {
                ...state,
                isSaving: false,
                error: action.error,
            };

        case actionTypes.Campaigns.INIT_CURRENT_CAMPAIGN:
            return { ...state, campaign: action.campaign };

        default:
            return state;
    }
}
