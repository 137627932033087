import forethoughtSettings from './constants/forethought-settings';
import './forethought.css';

const ForethoughtChat = (accountInfo) => {
    const body = window.top.document.querySelector('body');
    const { association, user } = accountInfo;
    const { email, name } = user;
    const { id } = association;

    if (document.getElementById(forethoughtSettings.ID)) return;

    const forethoughtScript = document.createElement('script');
    forethoughtScript.id = forethoughtSettings.ID;
    forethoughtScript.src = forethoughtSettings.PATH;
    forethoughtScript.setAttribute('data-api-key', forethoughtSettings.API_KEY);
    forethoughtScript.setAttribute('config-ft-theme-color', '#2b93d1');
    forethoughtScript.setAttribute('offset-y', '1.25rem');
    forethoughtScript.setAttribute('offset-x', '1.1rem');
    forethoughtScript.setAttribute('config-ft-disable-proactive-prompt', true);
    forethoughtScript.setAttribute('hidden', 'true');
    forethoughtScript.setAttribute('config-ft-disable-close', 'false');
    forethoughtScript.setAttribute('hide-ft-after-zd', 'true');
    forethoughtScript.setAttribute('data-ft-email', email);
    forethoughtScript.setAttribute('data-ft-full-name', name);
    forethoughtScript.setAttribute('data-ft-account-id', id);

    if (body) {
        body.appendChild(forethoughtScript);
    }

    // eslint-disable-next-line consistent-return
    return () => {
        const script = document.getElementById(forethoughtSettings.ID);
        if (script) {
            script.remove();
        }
    };
};

export default ForethoughtChat;
