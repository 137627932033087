import { useCallback, useState } from 'react';
import { chatWindowActive, isChatting } from '../../../utils/zopim-chat/zopim-api';
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [chatActive, setChatActive] = useState(isChatting());
    const [unread, setUnread] = useState(0);
    const [online, setOnline] = useState(false);

    const startChat = useCallback(() => setChatActive(true), [setChatActive]);
    const endChat = useCallback(() => setChatActive(false), [setChatActive]);

    const setChatOnline = useCallback(() => setOnline(true), [setOnline]);
    const setChatOffline = useCallback(() => setOnline(false), [setOnline]);

    const isUnread = useCallback((number) => {
        const chatWindowOpened = chatWindowActive();
        if (!chatWindowOpened) {
            setUnread(number);
        }
    }, [setUnread]);

    const clearUnread = useCallback(() => setUnread(0), [setUnread]);

    return [
        chatActive,
        unread,
        online,
        startChat,
        endChat,
        isUnread,
        clearUnread,
        setChatOnline,
        setChatOffline,
    ];
};
