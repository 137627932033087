import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from '../actions/loginActions';

class LoginStatus extends React.Component {
    componentDidMount() {
        if (!this.props.me) {
            this.props.fetchCurrentUserInfo();
        }
    }

    render() {
        if (this.props.me == null) return null;

        const getFontSize = (text) => Math.min(20, Math.max(12, 450 / text.length));

        return (
            <div className="m-3">
                <div
                    className="p-2 text-nowrap text-white-50"
                    style={{
                        overflow: 'hidden', textOverflow: 'ellipsis', fontSize: getFontSize(this.props.me.user.email),
                    }}
                >
                    {this.props.me.user.email}
                </div>
                <div
                    className="p-2 text-nowrap mb-3"
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        // fontSize: getFontSize(this.props.me.account.organizationTitle),
                    }}
                >
                    <a
                        href={`https://${this.props.me.account.primaryDomainName}/admin/`}
                        rel="noreferrer noopener"
                        className="text-decoration-none"
                    >
                        <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} size="lg" />
                        Back to WildApricot
                    </a>
                </div>
            </div>
        );
    }
}

LoginStatus.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    me: propTypes.object,
    // eslint-disable-next-line react/require-default-props
    fetchCurrentUserInfo: propTypes.func,
};

function mapStateToProps(state) {
    return {
        me: state.auth.me,
    };
}

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators(actionCreators, dispatch);

    return {
        fetchCurrentUserInfo: actions.fetchCurrentUserInfo,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginStatus);
