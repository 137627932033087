import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import IntegrationOptions from './IntegrationOptions';
import LoadingIndicator from '../../LoadingIndicator';
import * as actionCreators from '../../../actions/settingsActions';
import { getSettings } from '../../../reducers/settingsReducer';
import { fetchIntegrationOptions, fetchWaFields, fetchConsentWebpages } from '../../../actions/settingsActions';

const IntegrationOptionsContainer = (
    {
        isStartup,
        me,
        isSettings,
        onNext,
        onPrev,
        actions,
        isFetching,
        primaryUrl,
    },
) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchIntegrationOptions());
        dispatch(fetchWaFields());
        dispatch(fetchConsentWebpages());
    }, [dispatch]);

    const {
        fetchError,
        saveError,
        fetchFieldsError,
        integrationOptions,
        isUpdatingIntegrationOptions,
        waFields,
        consentPages,
    } = useSelector(getSettings);

    if (!waFields || !integrationOptions || !consentPages) {
        return (
            <LoadingIndicator
                loading
                loadingText="Loading fields configuration from your WildApricot account"
            />
        );
    }
    return (
        <>
            <IntegrationOptions
                isStartup={isStartup}
                me={me}
                isSettings={isSettings}
                onNext={onNext}
                onPrev={onPrev}
                actions={actions}
                fetchError={fetchError}
                saveError={saveError}
                fetchFieldsError={fetchFieldsError}
                isFetching={isFetching}
                isSaving={isUpdatingIntegrationOptions}
                integrationOptions={integrationOptions}
                fields={waFields}
                consentPages={consentPages}
                primaryUrl={primaryUrl}
            />
        </>
    );

};

IntegrationOptionsContainer.propTypes = {
    isStartup: propTypes.bool,
    isSettings: propTypes.bool,
    onNext: propTypes.func,
    onPrev: propTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    fetchError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    saveError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    fetchFieldsError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    isFetching: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    isSaving: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    integrationOptions: propTypes.object,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    fields: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    consentPages: propTypes.array,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    primaryUrl: propTypes.string,
};

IntegrationOptionsContainer.defaultProps = {
    isSettings: true,
    isStartup: false,
    onNext: () => {},
    onPrev: () => {},
};

function mapStateToProps(state) {
    return {
        me: state.auth.me,
        primaryUrl: 'https://' + state.auth.me.account.primaryDomainName + '/',
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntegrationOptionsContainer);
