import React from 'react';
import ContactUs from '../../components/contact-us';
import Elevio from '../../components/elevio';

import HelpSection from '../../../components/help-section';

import { useAssociationContext } from
    '../../../../../../entities/help-widget/context/association-context-provider';

export const paidView =
  (
      <>
          <Elevio isTrial={false} />
          <HelpSection><ContactUs /></HelpSection>
      </>
  );

export const trialView =
  (
      <>
          <Elevio isTrial />
      </>
  );

const Home = () => {
    const { isTrial } = useAssociationContext();

    return (
        <>
            {isTrial ? trialView : paidView}
        </>
    );
};

export default Home;
