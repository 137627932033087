import React from 'react';
import propTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthorizationRequired from '../AuthorizationRequired';
import './SettingsPage.css';
import { getHasTwilioAccount, getIsBillingPlanProhibited } from '../../reducers/authReducer';

function SettingsPage({ isAccountOwner, hasSubscription, isBillingPlanProhibited }) {

    const hasTwilioAccount = useSelector(getHasTwilioAccount);

    return (
        <div className="mainContent">
            <AuthorizationRequired />
            <h1 className="fixedHeader mb-4">Settings</h1>

            {hasTwilioAccount && hasSubscription && !isBillingPlanProhibited
                ?
                (<div className="mt-3 pt-3 h5">
                    <Link to="/settings/account-details">Account details</Link>
                </div>)
                : null
            }
            <div className="mt-3 pt-2 h5" hidden={!isAccountOwner}>
                <Link to="/settings/integration-settings">WildApricot integration settings</Link>
            </div>
            {hasTwilioAccount && !isBillingPlanProhibited
                ?
                (<div className="mt-3 pt-2 h5" hidden={!isAccountOwner}>
                    <Link to="/settings/subscription">Subscription</Link>
                </div>)
                : null
            }
            {hasSubscription && !isBillingPlanProhibited
                ?
                (<div className="mt-3 pt-2 h5" hidden={!isAccountOwner}>
                    <Link to="/settings/text-reply-settings">Text reply settings</Link>
                </div>)
                : null
            }
        </div>
    );
}

SettingsPage.propTypes = {
    isAccountOwner: propTypes.bool,
    hasSubscription: propTypes.bool.isRequired,
    isBillingPlanProhibited: propTypes.bool.isRequired,
};

SettingsPage.defaultProps = {
    isAccountOwner: false,
};

function mapStateToProps(state) {
    const { me } = state.auth;

    return {
        isAccountOwner: me != null && me.user.role === 'owner',
        hasSubscription: me.account.hasSubscription,
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
    };
}

export default connect(
    mapStateToProps,
)(SettingsPage);
