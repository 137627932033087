import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Alert, Table } from 'react-bootstrap';
import LoadingIndicator from '../LoadingIndicator';
import RecipientListItem from './RecipientListItem';
import { getHasConsentField } from '../../reducers/settingsReducer';
import { fetchIntegrationOptions } from '../../actions/settingsActions';

function RecipientsList({
    isFetching, recipients, maxNumber, onOpenRecipient, waHost,
}) {
    const dispatch = useDispatch();
    const hasConsentField = useSelector(getHasConsentField);

    useEffect(() => {
        dispatch(fetchIntegrationOptions());
    }, [dispatch]);

    return (
        <LoadingIndicator loading={isFetching}>
            <div>
                <div hidden={!recipients.length}>
                    <Table striped hover responsive>
                        <tbody>
                            {recipients.slice(0, maxNumber).map((r) => (
                                <RecipientListItem
                                    recipient={r}
                                    onOpen={onOpenRecipient}
                                    waHost={waHost}
                                    hasConsentField={hasConsentField}
                                    key={r.id}
                                />
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Alert variant="info" hidden={recipients.length <= maxNumber}>
                    This interface displays only first
                    {' '}
                    {maxNumber}
                    {' '}
                    contacts. Use search to refine the list.
                </Alert>
                <Alert variant="info" hidden={recipients.length > 0} className="mt-5 text-center">
                    There is no contacts matching criteria.

                    Try to refine search criteria.
                </Alert>
            </div>
        </LoadingIndicator>
    );
}
RecipientsList.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    recipients: propTypes.array.isRequired,
    // eslint-disable-next-line react/require-default-props
    isFetching: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    maxNumber: propTypes.number,
    // eslint-disable-next-line react/require-default-props
    onOpenRecipient: propTypes.func,
    waHost: propTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        waHost: state.auth.me.account.primaryDomainName,
    };
}

export default connect(
    mapStateToProps,
)(RecipientsList);
