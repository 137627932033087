import React, { useEffect, cloneElement } from 'react';
import { node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { fetchCurrentUserInfo, validateToken } from '../actions/loginActions';
import {
    getUser, getIsAuthenticated, getAccessToken, getHasTwilioAccount, getSetupComplete,
} from '../reducers/authReducer';
import  { fetchTwilioSettings, fetchTwilioSubscription } from '../actions/settingsActions';

function AuthRequired({ children }) {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const hasTwilioAccount = useSelector(getHasTwilioAccount);
    const setupComplete = useSelector(getSetupComplete);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const accessToken = useSelector(getAccessToken);
    const { pathname } = useLocation();

    useEffect(() => {
        dispatch(validateToken(accessToken));

        if (!user) {
            dispatch(fetchCurrentUserInfo());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (hasTwilioAccount) {
            dispatch(fetchTwilioSubscription());
            dispatch(fetchTwilioSettings());
        }
    }, [dispatch, hasTwilioAccount]);

    if (isAuthenticated) {
        if (!user) {
            return null;
        }

        if (pathname !== '/wa-signup' && !setupComplete) {
            return (
                <Redirect to="/wa-signup" />
            );
        }

        return cloneElement(children);
    }

    const targetUrl = window.location.href.replace(window.location.origin, '');

    return (
        <Redirect to={`/create?targetUrl=${encodeURIComponent(targetUrl)}`} />
    );
}

AuthRequired.propTypes = {
    children: node.isRequired,
};

export default AuthRequired;
