import React from 'react';
import { Button } from 'react-bootstrap';
import elevioHelpArticles from '../../../constants/elevio-help-articles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { openHelpArticle } from '../../HelpCenter/src/utils/elevio/util/elevio-functions';

const TermsOfUseCard = () => {
    return (
        <div className="tns-wa-signup__terms-of-use-container mb-5">
            <div className="p-4">
                <h1 className="mb-4 font-weight-bold">
                    WildApricot text messaging
                </h1>
                <p className="tns-wa-signup__form-description">
                    With WildApricot’s  text messaging, you now have a new way of communicating
                    with your users.
                    <br/>
                    <br/>
                    To start, you will need to select which field you are using to collect user mobile
                    number. This will let us know where to send text messages to.
                    <br/>
                    <br/>
                    You will also need to set up a consent field (this is mandatory according to
                    recent regulations). You could skip setting up consent field now, but you will have
                    to setup one before registering your business for sending messages.
                    <Button
                        onClick={() => openHelpArticle(elevioHelpArticles.TNS.CONSENT.id)}
                        variant="link"
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} className="text-info" size="lg" />
                    </Button>
                </p>
            </div>
        </div>
    );
};

export default TermsOfUseCard;
