import einKeyMap from '../constants/einKeyMap';

// eslint-disable-next-line import/no-anonymous-default-export
export default (input, response) => {
    // eslint-disable-next-line no-prototype-builtins
    if (einKeyMap.hasOwnProperty(input)) {
        if (response !== null && response !== undefined) {
            return response[einKeyMap[input]];
        }
    } else {
        return '';
    }
};
