const getMessageCharset = (message) => {
    if (message == null) {
        return 'gsm7';
    }

    let result = 'gsm7';
    // eslint-disable-next-line @typescript-eslint/naming-convention,max-len
    let gsm_7_charset =  " \n\r@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
    for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i);
        let char = String.fromCharCode(charCode);
        if (gsm_7_charset.includes(char)) { continue; }
        //if(charCode<=127 && result != "ucs2") { result = "ascii"; continue; }
        result = 'ucs2';
        break;
    }
    return result;
};

const getMessageLengthForEncoding = (enc) => {
    if (enc === 'gsm7') return 153;
    //if(enc == "ascii") return 140;
    return 67;
};

const getNumberOfBlocksForMessage = (message) => {
    let enc = getMessageCharset(message);
    let blockLength = getMessageLengthForEncoding(enc);
    return Math.ceil( message.length / blockLength );
};

const getMessageStat = (message) => {
    message = message || '';
    let enc = getMessageCharset(message);
    let blockSize = getMessageLengthForEncoding(enc);
    return {
        length: message.length,
        encoding: enc,
        blockSize: blockSize,
        numberOfBlocks: getNumberOfBlocksForMessage(message),
        maxLength: blockSize * 5,
    };
};

module.exports = { getMessageCharset, getMessageLengthForEncoding, getNumberOfBlocksForMessage, getMessageStat };
