import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { smsCalculator } from 'mp-utils';
import { mergeTags } from 'mp-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import elevioHelpArticles from '../../../constants/elevio-help-articles';
import { openHelpArticle } from '../../HelpCenter/src/utils/elevio/util/elevio-functions';
import { getMessagePrice } from '../../../reducers/authReducer';

function CampaignPriceEstimation({ content, recipientsCounts, recipientsListId, messagePrice }) {
    const showHelpArticle = () => {
        openHelpArticle(elevioHelpArticles.TNS_FAQS.CAMPAIGN_PRICE.id);
    };
    const numberOfRecipients = (recipientsCounts[recipientsListId] || {}).count || 0;
    let hasMergeTags = false;
    const possibleMergeTags = mergeTags.getAllTags();
    possibleMergeTags.forEach((mt) => {
        hasMergeTags = hasMergeTags || (content || '').includes(mt);
    });

    const messageStat = smsCalculator.getMessageStat(content);
    const pricePerMessage = (messagePrice * messageStat.numberOfBlocks).toFixed(2);
    const total = (numberOfRecipients * pricePerMessage).toFixed(2);
    return (
        <div className="mt-4 mb-3 h6">
            The estimated campaign price is
            {' '}
            {numberOfRecipients}
            {' '}
            x $
            {pricePerMessage}
            {' '}
            = $
            {total}
            {' '}
            USD.
            <Button onClick={showHelpArticle} variant="link">
                <FontAwesomeIcon icon={faQuestionCircle} className="text-info" size="lg" />
            </Button>

            <div hidden={!hasMergeTags}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <dfn
                    style={{ cursor: 'pointer', borderBottom: '1px dashed' }}
                    onClick={() => openHelpArticle(elevioHelpArticles.TNS.TNS_MACROS.id)}
                >
                    Macros
                </dfn>
                &nbsp;will affect message length and may result in changing the campaign price.
            </div>
        </div>
    );
}

CampaignPriceEstimation.propTypes = {
    // eslint-disable-next-line react/require-default-props
    content: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientsListId: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    recipientsCounts: propTypes.object,
    // eslint-disable-next-line react/require-default-props
    messagePrice: propTypes.number,
};

function mapStateToProps(state) {
    const recipientsCounts = state.recipients.groupCounts || {};
    return {
        recipientsCounts,
        messagePrice: getMessagePrice(state),
    };
}

export default connect(
    mapStateToProps,
)(CampaignPriceEstimation);
