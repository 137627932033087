import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';
import storeUtils from '../../utils/storeUtils';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function contactsReducer(state = initialState.recipients.contacts, action) {
    switch (action.type) {
        case actionTypes.Recipients.FETCH_RECIPIENTS_LIST.start: {
            return {
                ...state,
                [action.recipientsListId]: {
                    isFetching: true,
                    error: null,
                    items: null,
                },
            };
        }

        case actionTypes.Recipients.FETCH_RECIPIENTS_LIST.success: {
            const stateUpdate = {};
            stateUpdate[action.recipientsListId] = {
                isFetching: false,
                error: null,
                items: action.recipients,
            };

            action.recipients.forEach((recipient) => {
                const key = storeUtils.getRecipientKey(recipient.id, recipient.recipientType);
                stateUpdate[key] = { isFetching: false, data: recipient };
            });

            return { ...state, ...stateUpdate };
        }

        case actionTypes.Recipients.FETCH_RECIPIENTS_LIST.failure: {
            return {
                ...state,
                [action.recipientsListId]: {
                    isFetching: false,
                    error: action.error,
                    items: null,
                },
            };
        }

        case actionTypes.Recipients.FETCH_RECIPIENT.start: {
            const stateUpdate = {};
            const key = storeUtils.getRecipientKey(action.recipientId, action.recipientType);

            stateUpdate[key] = {
                isFetching: true,
                error: null,
                data: null,
            };

            return { ...state, ...stateUpdate };
        }

        case actionTypes.Recipients.FETCH_RECIPIENT.success: {
            const stateUpdate = {};
            const key = storeUtils.getRecipientKey(action.recipientId, action.recipientType);

            stateUpdate[key] = {
                isFetching: false,
                error: null,
                data: action.recipient,
            };

            return { ...state, ...stateUpdate };
        }

        case actionTypes.Recipients.FETCH_RECIPIENT.failure: {
            const stateUpdate = {};
            const key = storeUtils.getRecipientKey(action.recipientId, action.recipientType);

            stateUpdate[key] = {
                isFetching: false,
                error: action.error,
                data: null,
            };

            return { ...state, ...stateUpdate };
        }

        default:
            return state;
    }
}
