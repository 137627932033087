import { useState, useCallback } from 'react';

import sections from '../../../components/help-center-widget/src/sections';
import sectionKeys from '../../../constants/section-keys';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [section, setSection] = useState(sections[sectionKeys.SECTION_HOME]);

    const changeSection =
    useCallback((key) => {
        setSection(sections[key]);
    }, [setSection]);

    return [section, changeSection];
};
