import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import * as actionCreators from '../../actions/billingActions';
import LoadingIndicator from '../LoadingIndicator';
import CheckoutForm from './CheckoutForm';
import { detectDefaultLocation } from '../../utils/geoLocation';

class SubscriptionForm extends React.Component {
    constructor(props) {
        super(props);

        this.renderForm = this.renderForm.bind(this);
        this.handleSubscribe = this.handleSubscribe.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        if (!this.props.stripePublishableKey && !this.props.isFetching) {
            this.props.actions.fetchStripeConstants();
        }
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleSubscribe(stripe, cardElement, billingDetails) {
        this.props.onProcessCardInfo(stripe, cardElement, billingDetails);
    }

    renderForm() {
        if (this.props.stripePublishableKey) {
            return (
                <StripeProvider apiKey={this.props.stripePublishableKey}>
                    <Elements>
                        <CheckoutForm
                            processCardInfo={this.handleSubscribe}
                            onCancel={this.handleCancel}
                            cancelButtonTitle={this.props.cancelButtonTitle}
                            subscribeButtonTitle={this.props.subscribeButtonTitle}
                            defaultEmail={this.props.defaultEmail}
                            error={this.props.error}
                            isCreatingSubscription={this.props.isCreatingSubscription}
                            defaultCountry={this.props.defaultCountry}
                            defaultRegion={this.props.defaultRegion}
                            explanation={this.props.explanation}
                        />
                    </Elements>
                </StripeProvider>
            );
        }

        return (
            <span>...</span>
        );
    }

    render() {
        return (
            <LoadingIndicator loading={this.props.isFetching}>
                {this.renderForm()}
            </LoadingIndicator>
        );
    }
}

SubscriptionForm.propTypes = {
    // external
    onCancel: propTypes.func.isRequired,
    cancelButtonTitle: propTypes.string.isRequired,
    subscribeButtonTitle: propTypes.string.isRequired,
    onProcessCardInfo: propTypes.func.isRequired,
    explanation: propTypes.element.isRequired,

    // internal
    defaultEmail: propTypes.string.isRequired,
    stripePublishableKey: propTypes.string,
    isFetching: propTypes.bool,
    error: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    isCreatingSubscription: propTypes.bool.isRequired,
    // eslint-disable-next-line react/require-default-props
    defaultCountry: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    defaultRegion: propTypes.string,
};

SubscriptionForm.defaultProps = {
    stripePublishableKey: '',
    isFetching: false,
    error: '',
};

function mapStateToProps(state) {
    return {
        defaultEmail: state.auth.me.user.email,
        stripePublishableKey: state.billing.stripePublishableKey,
        isFetching: state.billing.isFetchingStripeConstants || false,
        error: state.billing.fetchStripeConstantsError || state.billing.subscriptionError,
        isCreatingSubscription: state.billing.isCreatingSubscription || false,
        ...detectDefaultLocation(),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubscriptionForm);
