import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import * as messagesActionCreators from '../../actions/messagesActions';
import * as recipientsActionCreators from '../../actions/recipientsActions';
import * as billingActionCreators from '../../actions/billingActions';
import ConversationWithSend from './ConversationWithSend';
import storeUtils from '../../utils/storeUtils';
import LoadingIndicator from '../LoadingIndicator';
import './conversations.css';
import { getIsBillingPlanProhibited } from '../../reducers/authReducer';

class ConversationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.backToEntryPage = this.backToEntryPage.bind(this);
        this.startSubscription = this.startSubscription.bind(this);
    }

    componentDidMount() {
        if (!this.props.recipientDisplayName) {
            this.props.fetchRecipient(this.props.recipientType, this.props.recipientId);
        }
    }

    startSubscription() {
        this.props.navigateToStartSubscription(window.location.pathname);
    }

    // eslint-disable-next-line class-methods-use-this
    backToEntryPage() {
        window.history.back();
    }

    render() {
        const { hasSubscription, isBillingPlanProhibited } = this.props;
        return (
            <div className="mainContent">
                <LoadingIndicator loading={!this.props.recipientDisplayName}>
                    <h1 className="fixedHeader">{`Conversation with ${this.props.recipientDisplayName}`}</h1>
                    <div>
                        <Button variant="link" onClick={this.backToEntryPage}>
                            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                            Back to latest responses
                        </Button>
                    </div>
                    { hasSubscription && !isBillingPlanProhibited  ?

                        (<ConversationWithSend
                            recipientId={this.props.recipientId}
                            recipientType={this.props.recipientType}
                            recipientDisplayName={this.props.recipientDisplayName}
                            onBackToList={this.backToEntryPage}
                            recipientPhone={this.props.recipientPhone}
                            onStartSubscription={this.startSubscription}
                        />)
                        :
                        (<Alert
                            className="mx-auto my-5 w-50"
                            variant="warning"
                        >
                            Messaging is disabled. Please register and ensure account is fully approved.
                        </Alert>)
                    }
                </LoadingIndicator>
            </div>
        );
    }
}

ConversationsPage.propTypes = {
    // eslint-disable-next-line react/require-default-props
    recipientId: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientType: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientDisplayName: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientPhone: propTypes.string,
    fetchRecipient: propTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    navigateToConversations: propTypes.func.isRequired,
    navigateToStartSubscription: propTypes.func.isRequired,
    hasSubscription: propTypes.bool.isRequired,
    isBillingPlanProhibited: propTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    const key = storeUtils.getRecipientKey(ownProps.recipientId, ownProps.recipientType);
    const recipientData = state.recipients.contacts[key] || { data: {} };
    const { account } = state.auth.me;

    return {
        recipientDisplayName: recipientData.data && recipientData.data.displayName,
        recipientPhone: recipientData.data && recipientData.data.phone,
        hasSubscription:
            account.hasSubscription === true,
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
    };
}

function mapDispatchToProps(dispatch) {
    const messagesActions = bindActionCreators(messagesActionCreators, dispatch);
    const recipientsActions = bindActionCreators(recipientsActionCreators, dispatch);
    const billingActions = bindActionCreators(billingActionCreators, dispatch);
    return {
        navigateToConversations: messagesActions.navigateToConversations,
        navigateToStartSubscription: billingActions.navigateToStartSubscription,
        fetchRecipient: recipientsActions.fetchRecipient,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConversationsPage);
