module.exports = {
    // account does not have active subscription
    incoming: (accountId) => `INCOMING-${accountId}`,

    // account has a subscription, WA API key and twilio subsccount
    test: 'TEST-MESSAGES',
    outcoming: (id) => `cmp-${id}`,
    unknown: 'UNKNOWN',
    isIncomingId: (campaignId) => campaignId.startsWith('INCOMING'),
};
