import apiClient from './apiClient';
import apiUrls from './apiUrls';
import jwt from '../utils/jwt';

const create = (watoken) => apiClient.executeRequest({
    url: apiUrls.auth.create,
    method: 'POST',
    body: { watoken },
}, (responseContent) => {
    const { token } = responseContent;
    const { error, result } = jwt.verifyToken(token);

    // this is strange if token was not verified, but still let's check
    if (error) return { error };

    return { token, userId: result.sub, accountId: result.accountId };
});

const login = (email, password) => apiClient.executeRequest({
    url: apiUrls.auth.login,
    method: 'POST',
    body: { email, password },
}, (responseContent) => {
    const { token, ambiguousAccount, availableAccounts } = responseContent;
    const { error, result } = jwt.verifyToken(token);

    // this is strange if token was not verified, but still let's check
    if (error) return { error };

    if (ambiguousAccount) {
        return {
            token,
            userId: result.sub,
            ambiguousAccount,
            availableAccounts,
        };
    }

    return { token, userId: result.sub, accountId: result.accountId };
});

const loginSelectAccount = (token, accountId) => apiClient.executeRequest({
    url: apiUrls.auth.loginSelectAccount,
    method: 'POST',
    body: { token, accountId },
}, (responseContent) => {
    const { token: responseToken } = responseContent;
    const { error, result } = jwt.verifyToken(responseToken);

    // this is strange if token was not verified, but still let's check
    if (error) return { error };

    return { token: responseToken, userId: result.sub, accountId: result.accountId };
});

const restorePassword = (email) => apiClient.executeRequest({
    url: apiUrls.auth.restorePassword,
    method: 'POST',
    body: { email },
});

const resetPassword = (resetPasswordToken, password) => apiClient.executeRequest({
    url: apiUrls.auth.resetPassword,
    method: 'POST',
    body: { resetPasswordToken, password },
});

export const getMe = () => apiClient.executeGet(apiUrls.auth.me);

const methods = {
    create,
    login,
    loginSelectAccount,
    restorePassword,
    resetPassword,
    getMe,
};

export default methods;
