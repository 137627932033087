import appendTags from './chat-tags/src/chat-tags';
import {
    getUserName,
    getUserEmail,
    getAccountName,
    isTrialAccount,
    isTestMode,
    isCloned,
} from './selectors';

export const departments =
  {
      ONBOARDING: '10993624791309',
      SUPPORT: '10993555637133',
  };

/* istanbul ignore next */
const onWidgetLoad = (accountInfo, accountInfoFinancialInfo, useMessaging) => {
    if (useMessaging) {
        appendTags(accountInfo, accountInfoFinancialInfo, useMessaging);
    } else {
    // Check which department to forward chat to
        const department = isTrialAccount(accountInfo) && !isTestMode(accountInfo) && !isCloned(accountInfo)
            ? departments.ONBOARDING : departments.SUPPORT;

        // Widget configuration
        window.zE('webWidget', 'hide');
        window.zE('webWidget', 'updateSettings',
            {
                webWidget:
          {
              chat:
              {
                  hideWhenOffline: true,
                  departments:
                  {
                      select: department,
                  },
              },
              answerBot:
              {
                  suppress: isTrialAccount(accountInfo),
              },
          },
            });
        window.zE('webWidget', 'identify',
            {
                name: getUserName(accountInfo),
                email: getUserEmail(accountInfo),
                organization: getAccountName(accountInfo),
            });
        window.zE('webWidget:on', 'chat:connected', () => {
            window.zE('webWidget', 'updateSettings',
                {
                    webWidget:
            {
                chat:
                {
                    hideWhenOffline: true,
                    departments:
                    {
                        select: department,
                    },
                },
                answerBot:
                {
                    suppress: isTrialAccount(accountInfo),
                },
            },
                });
            window.zE('webWidget', 'prefill',
                {
                    name: { value: getUserName(accountInfo) },
                    email: { value: getUserEmail(accountInfo) },
                });
            window.zE('webWidget', 'show');
        });

        // Add tags to chat session
        appendTags(accountInfo, accountInfoFinancialInfo);
    }
};

export default onWidgetLoad;
