import React, { useEffect, useState } from 'react';
import WizardLayout from './components/WizardLayout';
import StepIntro from './steps/StepIntro';
import StepContactInformation from './steps/StepContactInformation';
import StepIntegrationSettings from './steps/StepIntegrationSettings';
import StepSubscriptionSettings from './steps/StepSubscriptionSettings';
import StepComplete from './steps/StepComplete';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CampaginTextLogo from '../../Campaigns/CampaginTextLogo';

const SubscriptionWizard = ({ hasSubscription, hasTwilioAccount }) => {
    useEffect(() => {
        window.onbeforeunload = function () {
            return 'Data will be lost if you leave the page, are you sure?';
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const wizardSteps = [
        {
            key: 'introduction',
            title: 'Before you register',
            progressTitle: 'Before you register',
            isDone: false,
        },
        {
            key: 'integration-settings',
            title: 'WildApricot integration settings',
            progressTitle: 'Integration settings',
            isDone: false,
        },
        {
            key: 'contact-settings',
            title: 'Contact information and terms of use',
            progressTitle: 'Contact information',
            isDone: false,
        },
        {
            key: 'subscription',
            title: 'Create subscription',
            progressTitle: 'Subscription settings',
            isDone: false,
        },
        {
            key: 'complete',
            title: 'Registration completed',
            isDone: false,
        },
    ];
    const [steps, setSteps] = useState(wizardSteps);
    const [activeStep, setActiveStep] = useState(steps[0]);
    const history = useHistory();

    const handleNext = () => {
        const index = steps.findIndex(step => step.key === activeStep.key);
        setSteps(prevStep => prevStep.map(x => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
        }));
        setActiveStep(steps[index + 1]);
    };

    const handleBack = () => {
        const index = steps.findIndex(step => step.key === activeStep.key);
        if (index === 0) return;
        setActiveStep(steps[index - 1]);
        setSteps(prevStep => prevStep.map(x => {
            if (x.key === activeStep.key) x.isDone = false;
            return x;
        }));
        setActiveStep(steps[index - 1]);
    };

    const renderStep = (step) => {
        switch (step) {
            case 'introduction':
                return (<StepIntro onNext={handleNext} />);
            case 'contact-settings':
                return (<StepContactInformation onNext={handleNext} onPrev={handleBack} />);
            case 'integration-settings':
                return (<StepIntegrationSettings onNext={handleNext} onPrev={handleBack} />);
            case 'subscription':
                return (<StepSubscriptionSettings onNext={handleNext} onPrev={handleBack} />);
            case 'complete':
                return (<StepComplete />);
            default:
                return (<StepContactInformation onNext={handleNext} />);
        }
    };

    return (
        <WizardLayout
            title='Set up text messaging'
            steps={steps}
            activeStep={activeStep}
        >
            {hasSubscription || !hasTwilioAccount ?
                (<div className="p-4">
                    <h3> {activeStep.title}</h3>
                    <div className="pt-4">
                        {renderStep(activeStep.key)}
                    </div>
                </div>)
                :
                <div className="p-4 text-center">
                    <div className="pb-4">
                        <CampaginTextLogo showRegistrationComplete={true} />
                    </div>
                    <h2 className="font-weight-bold">Hooray!</h2>
                    <h4 className="mb-5 font-weight-bold">Your registration has been successfully submitted.</h4>
                    <Button
                        className="mx-3"
                        variant="primary"
                        onClick={() => history.push('/')}
                    >
                        Return home
                    </Button>
                </div>
            }
        </WizardLayout>
    );
};

function mapStateToProps(state) {
    const { me } = state.auth;

    return {
        hasSubscription: me.account.hasSubscription || false,
        hasTwilioAccount: me.account.hasTwilioAccount || false,
    };
}
export default connect(
    mapStateToProps,
)(SubscriptionWizard);
