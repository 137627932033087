import actionTypes from '../constants/actionTypes';
import recipientsService from '../apiServices/recipientsService';

export const fetchRecipientsGroupTypes = (forceReload) => async (dispatch, getState) => {
    // check in state first
    const state = getState();
    const groupTypes = state.recipients.groupTypes.items;

    if (groupTypes && !forceReload) return;

    dispatch({
        type: actionTypes.Recipients.FETCH_RECIPIENTS_GROUP_TYPES.start,
    });

    const result = await recipientsService.fetchRecipientsGroupTypes();

    if (result.error) {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_GROUP_TYPES.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_GROUP_TYPES.success,
            groupTypes: result.groupTypes,
            cache: false,
        });
    }
};

export const fetchRecipientsGroups = (groupType) => async (dispatch, getState) => {
    // check in state first
    const state = getState();
    const groupsContainer = state.recipients.groups[groupType] || {};

    if (groupsContainer.isFetching) return;

    dispatch({
        type: actionTypes.Recipients.FETCH_RECIPIENTS_GROUPS.start,
        groupType,
    });

    const result = await recipientsService.fetchRecipientsGroups(groupType);

    if (result.error) {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_GROUPS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_GROUPS.success,
            groupType,
            groups: result.groups,
        });
    }
};

export const fetchRecipientsCount = (recipientsListId) => async (dispatch, getState) => {
    // check in state first
    const state = getState();
    const countContainer = state.recipients.groupCounts[recipientsListId] || {};

    if (countContainer.isFetching) return;

    dispatch({
        type: actionTypes.Recipients.FETCH_RECIPIENTS_COUNT.start,
        recipientsListId,
    });

    const result = await recipientsService.fetchRecipientsCount(recipientsListId);

    if (result.error) {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_COUNT.failure,
            error: result.error,
            recipientsListId,
        });
    } else {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_COUNT.success,
            count: result.recipientsCount,
            recipientsListId,
        });
    }
};

export const fetchRecipients = (recipientsListId, searchString, forceReload) => async (dispatch, getState) => {
    // check in state first
    const state = getState();
    const contactsContainer = state.recipients.contacts[recipientsListId] || {};

    if (contactsContainer.isFetching && contactsContainer.searchString === searchString) return;

    dispatch({
        type: actionTypes.Recipients.FETCH_RECIPIENTS_LIST.start,
        recipientsListId,
    });

    if (contactsContainer.items && !forceReload) {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_LIST.success,
            recipients: contactsContainer.items,
            recipientsListId,
        });

        return;
    }

    const result = await recipientsService.fetchRecipients(recipientsListId, searchString, 51);

    if (result.error) {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_LIST.failure,
            error: result.error,
            recipientsListId,
        });
    } else {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENTS_LIST.success,
            recipients: result.recipients,
            recipientsListId,
            searchString,
        });
    }
};

export const fetchRecipient = (recipientType, recipientId) => async (dispatch) => {
    dispatch({
        type: actionTypes.Recipients.FETCH_RECIPIENT.start,
        recipientId,
        recipientType,
    });

    const result = await recipientsService.fetchRecipient(recipientType, recipientId);

    if (result.error) {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENT.failure,
            error: result.error,
            recipientId,
            recipientType,
        });
    } else {
        dispatch({
            type: actionTypes.Recipients.FETCH_RECIPIENT.success,
            recipient: result.recipient,
            recipientId,
            recipientType,
        });
    }
};
