import buildApiAction from './actionBuilder';

const actionTypes = {
    Auth: {
        SIGN_IN: buildApiAction('SIGN_IN'),
        SIGN_IN_AMBIGOUS_ACCOUNT: 'SIGN_IN_AMBIGOUS_ACCOUNT',
        SIGN_OUT: 'SIGN_OUT',
        REQUEST_RESTORE_PASSWORD: buildApiAction('REQUEST_RESTORE_PASSWORD'),
        RESET_PASSWORD: buildApiAction('RESET_PASSWORD'),
        FETCH_CURRENT_USER_INFO: buildApiAction('FETCH_CURRENT_USER_INFO'),
        SETUP_COMPLETE: buildApiAction('SETUP_COMPLETE'),
    },

    SignUp: {
        SIGN_UP_SET_CREDENTIALS: buildApiAction('SIGN_UP_SET_CREDENTIALS'),
        SIGN_UP_SET_APIKEY: buildApiAction('SIGN_UP_SET_APIKEY'),
        SIGN_UP_SET_INTEGRATION_OPTIONS: buildApiAction('SIGN_UP_SET_INTEGRATION_OPTIONS'),
        SIGN_UP_CANCEL: 'SIGN_UP_CANCEL',
        SIGN_UP_ACCEPT_INVITATION: buildApiAction('SIGN_UP_ACCEPT_INVITATION'),
        SIGN_UP_ADDITIONAL_ACCOUNT: buildApiAction('SIGN_UP_ADDITIONAL_ACCOUNT'),
    },

    Campaigns: {
        FETCH_CAMPAIGN_DETAILS: buildApiAction('FETCH_CAMPAIGN_DETAILS'),
        FETCH_CAMPAIGNS: buildApiAction('FETCH_CAMPAIGNS'),
        SAVE_CAMPAIGN: buildApiAction('SAVE_CAMPAIGN'),
        CANCEL_CAMPAIGN: buildApiAction('CANCEL_CAMPAIGN'),
        UPDATE_CURRENT_CAMPAIGN: 'UPDATE_CURRENT_CAMPAIGN',
        INIT_CURRENT_CAMPAIGN: 'INIT_CURRENT_CAMPAIGN',

        FETCH_CAMPAIGN_SUGGESTIONS: buildApiAction('FETCH_CAMPAIGN_SUGGESTIONS'),
    },

    Recipients: {
        FETCH_RECIPIENTS_GROUP_TYPES: buildApiAction('FETCH_RECIPIENTS_GROUP_TYPES'),
        FETCH_RECIPIENTS_GROUPS: buildApiAction('FETCH_RECIPIENTS_GROUPS'),
        FETCH_RECIPIENTS_COUNT: buildApiAction('FETCH_RECIPIENTS_COUNT'),
        FETCH_RECIPIENTS_LIST: buildApiAction('FETCH_RECIPIENTS_LIST'),
        FETCH_RECIPIENT: buildApiAction('FETCH_RECIPIENT'),
    },

    Billing: {
        FETCH_INVOICES: buildApiAction('BILLING_FETCH_INVOICES'),
        FETCH_STRIPE_CONSTANTS: buildApiAction('BILLING_FETCH_STRIPE_CONSTANTS'),
        FETCH_SUBSCRIPTION_STATUS: buildApiAction('BILLING_FETCH_SUBSCRIPTION_STATUS'),
        START_SUBSCRIPTION: buildApiAction('BILLING_START_SUBSCRIPTION'),
        UPDATE_PAYMENT_METHOD: buildApiAction('UPDATE_PAYMENT_METHOD'),
        CANCEL_SUBSCRIPTION: buildApiAction('BILLING_CANCEL_SUBSCRIPTION'),
    },

    SubscriptionWizard: {
        SAVE_TWILIO_FORM_DATA: 'SAVE_TWILIO_FORM_DATA',
        SUBMIT_STRIPE_SUBSCRIPTION: buildApiAction('SUBMIT_STRIPE_SUBSCRIPTION'),
        SUBMIT_TWILIO_SUBSCRIPTION: buildApiAction('SUBMIT_TWILIO_SUBSCRIPTION'),
    },

    Settings: {
        UPDATE_EMAIL: buildApiAction('SETTINGS_UPDATE_EMAIL'),
        UPDATE_PASSWORD: buildApiAction('SETTINGS_UPDATE_PASSWORD'),
        FETCH_APIKEY: buildApiAction('SETTINGS_FETCH_APIKEY'),
        UPDATE_APIKEY: buildApiAction('SETTINGS_UPDATE_APIKEY'),
        FETCH_INTEGRATION_OPTIONS: buildApiAction('SETTINGS_FETCH_INTEGRATION_OPTIONS'),
        UPDATE_INTEGRATION_OPTIONS: buildApiAction('SETTINGS_UPDATE_INTEGRATION_OPTIONS'),
        FETCH_WA_FIELDS: buildApiAction('FETCH_WA_FIELDS'),
        FETCH_TWILIO_SUBSCRIPTION: buildApiAction('FETCH_TWILIO_SUBSCRIPTION'),
        SAVE_TWILIO_SUBSCRIPTION: buildApiAction('SAVE_TWILIO_SUBSCRIPTION'),
        REFETCH_TWILIO_SUBSCRIPTION: buildApiAction('REFETCH_TWILIO_SUBSCRIPTION'),
        FETCH_TWILIO_SETTINGS: buildApiAction('FETCH_TWILIO_SETTINGS'),
        SAVE_TWILIO_SETTINGS: buildApiAction('SAVE_TWILIO_SETTINGS'),
        FETCH_CONSENT_PAGES: buildApiAction('FETCH_CONSENT_PAGES'),
        FETCH_EIN: buildApiAction('FETCH_EIN'),
    },

    Users: {
        FETCH_USERS: buildApiAction('SECURITY_FETCH_USERS'),
        ADD_USER: buildApiAction('SECURITY_ADD_USER'),
        UPDATE_USER: buildApiAction('SECURITY_UPDATE_USER'),
        DELETE_USER: buildApiAction('SECURITY_DELETE_USER'),
    },

    Messages: {
        FETCH_CAMPAIGN_MESSAGES: buildApiAction('FETCH_CAMPAIGN_MESSAGES'),
        FETCH_RECIPIENTS_MESSAGES: buildApiAction('FETCH_RECIPIENTS_MESSAGES'),
        FETCH_LATEST_INCOMMING_MESSAGES: buildApiAction('FETCH_LATEST_INCOMMING_MESSAGES'),
        SEND_MESSAGE: buildApiAction('SEND_MESSAGE'),
    },

    Accounts: {
        FETCH_ACCOUNTS: buildApiAction('FETCH_ACCOUNTS'),
        CREATE_NEW_ACCOUNT: buildApiAction('CREATE_NEW_ACCOUNT'),
    },
};

export default actionTypes;
