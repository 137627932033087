import zendeskWidget from './src/zendesk-widget';

let counter = 0;
const MAX_ATTEMPTS = 20;

/* istanbul ignore next */
const hideChatBadge = (count) => {
    if (typeof window.$zopim === 'function' && typeof window.$zopim.livechat === 'object') {
        window.$zopim.livechat.badge.hide();
    } else {
        counter += 1;
        if (counter < MAX_ATTEMPTS) {
            setTimeout(() => hideChatBadge(count), 500);
        }
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (accountInfo, accountFinancialInfo) => {
    zendeskWidget(accountInfo, accountFinancialInfo, false);
    hideChatBadge(counter);
};
