import React from 'react';
import { Button } from 'react-bootstrap';
import CampaginTextLogo from '../../../../../Campaigns/CampaginTextLogo';
import { useHistory } from 'react-router-dom';

const StepComplete = () => {
    const history = useHistory();

    return (
        <div className="p-4 text-center">
            <div className="pb-4">
                <CampaginTextLogo showRegistrationComplete={true} />
            </div>
            <h2 className="font-weight-bold">Hooray!</h2>
            <h4 className="mb-5 font-weight-bold">Your registration has been successfully submitted.</h4>
            <Button
                className="mx-3"
                variant="primary"
                onClick={() => history.push('/')}
            >
                Return home
            </Button>
        </div>
    );
};

export default StepComplete;
