import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert } from 'react-bootstrap';
import * as billingActionCreators from '../../actions/billingActions';
import SubscriptionForm from './SubscriptionForm';

class UpdateCardPage extends React.Component {
    constructor(props) {
        super(props);
        this.returnBack = this.returnBack.bind(this);
        this.confirmSubscription = this.confirmSubscription.bind(this);
        this.renderExplanation = this.renderExplanation.bind(this);
    }

    returnBack() {
        window.location = this.props.source || '/';
    }

    async confirmSubscription(stripe, cardElement, billingDetails) {
        await this.props.updateCard(stripe, cardElement, billingDetails, () => {
            this.returnBack();
        });
    }

    // eslint-disable-next-line class-methods-use-this
    renderExplanation() {
        return (
            <Alert variant="warning">
                You already have an active subscription. You are about to update your credit card info.
            </Alert>
        );
    }

    render() {
        return (
            <div className="mainContent">
                <h1 className="fixedHeader">Updating payment method</h1>
                <div className="mt-3" style={{ maxWidth: 900 }}>
                    <SubscriptionForm
                        onCancel={this.returnBack}
                        cancelButtonTitle="Cancel"
                        subscribeButtonTitle="Update card"
                        onProcessCardInfo={this.confirmSubscription}
                        explanation={this.renderExplanation()}
                    />
                </div>
            </div>
        );
    }
}

UpdateCardPage.propTypes = {
    source: propTypes.string.isRequired,

    updateCard: propTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    hasSubscription: propTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        hasSubscription: state.auth.me.account.hasSubscription,
    };
}

function mapDispatchToProps(dispatch) {
    const billingActions = bindActionCreators(billingActionCreators, dispatch);

    return {
        updateCard: billingActions.updateBillingMethod,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateCardPage);
