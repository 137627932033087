import apiUrls from './apiUrls';
import apiClient from './apiClient';

export const registerUserAndAccount = (email, password, fullName) => apiClient.executeRequest({
    url: apiUrls.signup.registerAccount,
    method: 'POST',
    body: { email, password, fullName },
});

export const registerAccountOnly = () => apiClient.executeRequest({
    url: apiUrls.signup.registerAccount,
    method: 'POST',
});

export const acceptInvitation = (invitationToken, email, password) => apiClient.executeRequest({
    url: apiUrls.signup.acceptInvitation,
    method: 'POST',
    body: { invitationToken, email, password },
});

export const setupComplete = () => apiClient.executeRequest({
    url: apiUrls.signup.setupComplete,
    method: 'POST',
    body: { setupComplete: true },
});

const methods = { registerAccountOnly, registerUserAndAccount, acceptInvitation, setupComplete };

export default methods;
