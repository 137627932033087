import mapKeyToEinModel from './mapKeyToEinModel';
import einKeyMap from '../constants/einKeyMap';

// eslint-disable-next-line import/no-anonymous-default-export
export default (values, ein) => {
    // If EIN does not exist in database, skip value validation
    if (Object.keys(ein).length === 0) {
        return true;
    }
    for (let key in einKeyMap) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(key)) {
            if (values[key] !== mapKeyToEinModel(key, ein)) {
                return false;
            }
        }
    }
    return true;
};
