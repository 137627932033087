import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as messagesActionCreators from '../../actions/messagesActions';
import * as campaignsActionCreators from '../../actions/textCampaignsActions';
import LoadingIndicator from '../LoadingIndicator';
import NoIncomingMessages from './NoIncomingMessages';
import CampaignsFilter from './CampaignsFilter';
import IncomingMessageRecord from './IncomingMessageRecord';

class LatestConversations extends React.Component {
    constructor(props) {
        super(props);

        this.onCampaignIdChange = this.onCampaignIdChange.bind(this);
        this.getCurrentCampaignId = this.getCurrentCampaignId.bind(this);
    }

    componentDidMount() {
        this.props.fetchCampaigns();
        this.props.fetchLatestMessages();
    }

    onCampaignIdChange(currentCampaignId) {
        this.props.onCampaignFilterChanged(currentCampaignId);
    }

    getCurrentCampaignId() {
        return this.props.campaignId || 'NONE';
    }

    render() {
        let messagesForCurCampaign = this.props.messages;
        if (this.getCurrentCampaignId() !== 'NONE') {
            messagesForCurCampaign = messagesForCurCampaign.filter(
                (msg) => msg.replyToCampaignId === this.getCurrentCampaignId(),
            );
        }

        const onlyLatestMessages = messagesForCurCampaign.reduce((acc, msg) => {
            const prevMsg = acc.find((m) => m.recipientIdWithType === msg.recipientIdWithType);

            if (!prevMsg) {
                acc.push(msg);
            } else if (msg.lastChange > prevMsg.lastChange) {
                const idx = acc.indexOf(prevMsg);
                acc[idx] = msg;
            }

            return acc;
        }, []);

        return (
            <div>
                <CampaignsFilter
                    isDisabled={this.props.isDisabled}
                    campaigns={this.props.campaigns}
                    campaignId={this.getCurrentCampaignId()}
                    onSelectionChange={this.onCampaignIdChange}
                />
                <div className="mt-3">
                    {!this.props.isDisabled &&
                        (
                            <LoadingIndicator loading={this.props.isFetching}>
                                <div>
                                    {onlyLatestMessages.map((msg) => (
                                        <IncomingMessageRecord
                                            key={msg.id}
                                            message={msg}
                                            onOpenMessage={this.props.onOpenMessage}
                                        />
                                    ))}
                                    <div hidden={onlyLatestMessages.length > 0}>
                                        <NoIncomingMessages />
                                    </div>
                                </div>
                            </LoadingIndicator>
                        )}
                </div>
            </div>
        );
    }
}

LatestConversations.propTypes = {
    isDisabled: propTypes.bool.isRequired,
    fetchLatestMessages: propTypes.func.isRequired,

    // eslint-disable-next-line react/forbid-prop-types
    messages: propTypes.array.isRequired,
    // eslint-disable-next-line react/require-default-props
    isFetching: propTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    error: propTypes.string,

    fetchCampaigns: propTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    campaigns: propTypes.array.isRequired,

    onCampaignFilterChanged: propTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    campaignId: propTypes.string,
    onOpenMessage: propTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        messages: state.messages.latestIncomingMessages.messages || [],
        isFetching: state.messages.latestIncomingMessages.isFetching || false,
        error: state.messages.latestIncomingMessages.error,

        campaigns: state.campaigns.campaigns || [],
    };
}

function mapDispatchToProps(dispatch) {
    const messagesActions = bindActionCreators(messagesActionCreators, dispatch);
    const campaignsActions = bindActionCreators(campaignsActionCreators, dispatch);

    return {
        fetchCampaigns: campaignsActions.fetchCampaigns,
        fetchLatestMessages: messagesActions.fetchLatestMessages,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LatestConversations);
