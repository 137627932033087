import React from 'react';
import propTypes from 'prop-types';

import { Form, Row, Col } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import campaignUtils from '../campaignUtils';

function ScheduleControl({ onFieldValChange, schedule, scheduleMoment }) {
    return (
        <div>
            <Form.Group controlId="schedule">
                <Form.Label>When to send?</Form.Label>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Check
                            onChange={onFieldValChange}
                            type="radio"
                            name="schedule"
                            value="asap"
                            id="schedule-asap"
                            label="As soon as possible"
                            checked={schedule == null || schedule === 'asap'}
                        />
                        <Form.Check
                            onChange={onFieldValChange}
                            type="radio"
                            name="schedule"
                            value="onDate"
                            id="schedule-onDate"
                            label="On selected date/time"
                            checked={schedule === 'onDate'}
                        />
                    </Col>
                    <Col>
                        <div hidden={schedule !== 'onDate'}>
                            <MaskedFormControl
                                className={campaignUtils.getDateValidationClass(scheduleMoment).className}
                                name="scheduleMoment"
                                value={scheduleMoment}
                                onChange={onFieldValChange}
                                placeholder="MM/DD/YYYY HH:mm"
                                mask="11/11/1111 11:11"
                            />
                            <Form.Control.Feedback type="invalid">
                                {campaignUtils.getDateValidationClass(scheduleMoment).error}
                            </Form.Control.Feedback>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
        </div>
    );
}

ScheduleControl.propTypes = {
    // eslint-disable-next-line react/require-default-props
    schedule: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    scheduleMoment: propTypes.string,
    onFieldValChange: propTypes.func.isRequired,
};

export default ScheduleControl;
