import apiClient from './apiClient';
import apiUrls from './apiUrls';

const getRecipientMessages = (recipientType, recipientId, includePhone) => apiClient.executeGet(
    apiUrls.messages.recipientMessages(recipientType, recipientId, includePhone),
);

const getCampaignMessages = (campaignId, status) => apiClient.executeGet(
    apiUrls.messages.campaignMessages(campaignId, status),
);

const getCampaignResponses = (campaignId) => apiClient.executeGet(apiUrls.messages.campaignResponses(campaignId));

const getLatestResponses = (maxMessages) => apiClient.executeGet(apiUrls.messages.latestResponses(maxMessages));

const sendSingleMessage = (phone, content, recipient, eventId, emailId) => apiClient.executeRequest({
    url: apiUrls.messages.sendSingle,
    method: 'POST',
    body: {
        phone,
        content,
        recipient,
        eventId,
        emailId,
    },
});

const methods = {
    getRecipientMessages,
    getCampaignMessages,
    getCampaignResponses,
    getLatestResponses,
    sendSingleMessage,
};

export default methods;
