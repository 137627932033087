import waAccountInfoModel from '../constants/wa-account-info-model';

const formatWaInfo = (data) => {
    try {
        // Response data from /auth/me request
        const accountData = data.account;
        const userData = data.user;

        // Filter out unused characters from /auth/me response. Example wa_lds_wa-vdi-01-029_1
        const accountId = accountData.id.match(/^.*_(.*)$/)[1];
        const userId = userData.id.match(/^.*_(.*)$/)[1];

        return ({
            // Only used in TNS
            TNS: true,
            association: {
                id: accountId,
                name: accountData.organizationTitle,
                createdDate: '',
                billingPlan: {
                    name: accountData.billingPlan,
                    baseName: accountData.billingPlan,
                    price: 0,
                    upgradedDate: 0,
                },
                organizationType: '',
                urlName: accountData.primaryDomainName,
                isTestMode: false,
                isCloned: false,

            },
            user: {
                id: userId,
                securityProfileId: 0,
                name: userData.name,
                createdDate: 0,
                email: userData.email,
                roles: [userData.role],
            },
            billing: {
                paymentProcessorType: '',
                subscriptionPeriod: '',
                priceIncreaseLaunchDate: 0,
                priceIncreaseEffectiveDate: 0,
                priceIncreaseNotificationDate: 0,
                priceIncreaseUpdated: true,
                pssfAmount: 0,
            },
            payments: {
                currentPaymentGateway: '',
                associationCountry: '',
                affiniPayApplication: {
                    isSubmitted: false,
                    isApproved: null,
                    submittedDate: null,
                    isCanada: false,
                },
                payPalApplication: {
                    isMerchantRegisteredInPayPal: false,
                    isPrimaryEmailConfirmed: false,
                },
                isOutdatedIntegration: false,
            },
            isMembershipLevelRecurringAvailable: false,
        });
    } catch (e) {
        return waAccountInfoModel;
    }
};

export default formatWaInfo;
