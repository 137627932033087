import React from 'react';
import scriptLoader from 'react-async-script-loader';
import AppointletButton from './components/appointlet-button';
import l10n from '../../../../../../../../../../l10n/en';

import './book-a-call-button.css';

export const BookACallButton = () => (
    <div>
        <AppointletButton />
    </div>
);

export default scriptLoader(l10n.TRIAL.APPOINTLET_SETTINGS.SCRIPT_URL)(BookACallButton);
