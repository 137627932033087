import {
    showWidget, hideWidget, onWidgetOpen, onWidgetClose,
} from './api';
import ForethoughtChat from './forethought';

const initForethought = (closeHelp, startChat, accountInfo) => {
    const cleanUp = ForethoughtChat(accountInfo);

    const show = () => {
        closeHelp();
        startChat();
        showWidget();
    };

    onWidgetOpen(show);
    onWidgetClose(hideWidget);

    return () => {
        if (cleanUp) cleanUp();

        window.removeEventListener('message', show);
        window.removeEventListener('message', hideWidget);
    };
};

export default initForethought;
