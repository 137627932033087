import React from 'react';
import propTypes from 'prop-types';

function IncomingMessageRecord({ message, onOpenMessage }) {
    const handleOpen = () => {
        onOpenMessage(message);
    };

    const msgDate = new Date(message.lastChange).toDateString();

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="bg-light p-3 border-top mb-3" onClick={handleOpen} style={{ cursor: 'pointer' }}>
            <div className="h6">{`${message.displayName} on ${msgDate}`}</div>
            <div className="pt-1">
                {message.content}
            </div>
        </div>
    );
}

IncomingMessageRecord.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    message: propTypes.object.isRequired,
    onOpenMessage: propTypes.func.isRequired,
};

export default IncomingMessageRecord;
