import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button } from 'react-bootstrap';
import { showChat } from './ZendeskWidget/src/zopim-chat/live-chat-functions';

class ErrorBoundaryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    static componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(error, errorInfo);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="m-5">

                    <h1 className="text-center">Oops, something went wrong...</h1>

                    <div style={{ maxWidth: 800, margin: 'auto' }} className="mt-5">
                        <Row>
                            <Col xs={0} sm={3} md={2} className="d-none d-sm-inline-block">
                                <FontAwesomeIcon icon={faBug} size="5x" className="text-danger" />
                            </Col>
                            <Col xs={12} sm={9} md={10} lg={8}>
                                It looks lke you found a bug in our system.
                                {/* eslint-disable-next-line max-len */}
                                Our error monitoring was notified and we will try to fix the problem as soon as possible.

                                <Row className="mt-4">
                                    <Col xs={12} sm="auto">
                                        <Button variant="success" href="/" block>
                                            RELOAD
                                        </Button>
                                    </Col>

                                    <Col xs={12} sm={1} className="text-center pt-1 pb-1">
                                        or
                                    </Col>

                                    <Col xs={12} sm="auto">
                                        <Button variant="warning" onClick={showChat} block>
                                            CONTACT SUPPORT
                                        </Button>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

ErrorBoundaryPage.propTypes = {
    children: propTypes.node.isRequired,
};

export default ErrorBoundaryPage;
