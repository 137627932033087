import React from 'react';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { faCheckCircle, faPenField, faLink, faInputNumeric } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from './ListItem';

const StepIntro = ({ onNext }) => {
    const history = useHistory();

    return (
        <div>
            <p className="pb-3">
                According to
                {' '}
                <a href="https://www.fcc.gov/general/telemarketing-and-robocalls" target="_blank" rel="noopener noreferrer">
                    US
                </a>
                {' '}
                and
                {' '}
                <a href="https://crtc.gc.ca/eng/com500/faq500.htm" target="_blank" rel="noopener noreferrer">
                    Canadian
                </a>
                {' '}
                laws, before an organization can send text messages, it must obtain
                informed consent from the recipients. To approve your subscription to our text messaging service,
                WildApricot must verify that you have provided the following information.
            </p>
            <ListItem
                title="Craft a statement of intended use"
                description={
                    <>
                        You 'll need a document or webpage where
                        you clearly sate your organization's intended use of SMS messaging.
                        Have the URL for this document or webpage ready.
                        {' '}
                        <a
                            href="https://gethelp.wildapricot.com/en/articles/1961-informed-consent-document"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            See an example
                        </a>
                    </>
                }
                icon={<FontAwesomeIcon size="2x" icon={faCheckCircle} className="mr-2" />}
            />
            <ListItem
                title="Create a contact field for consent"
                description={
                    <>
                        Create a contact field for consent
                        WildApricot's text messaging will verify recipient consent using the contact field that
                        you create and designate for that purpose in your database. This
                        {' '}
                        <a
                            href="https://gethelp.wildapricot.com/en/articles/1902-getting-started-with-text-messaging#consent"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            consent field
                        </a>
                        {' '}
                        should <i>only</i> be used for tracking consent for text messaging.
                    </>
                }
                icon={<FontAwesomeIcon size="2x" icon={faPenField} className="mr-2" />}
            />
            <ListItem
                title="Locate the URL for your website's membership application"
                description={
                    <>
                        If your website does not use a
                        {' '}
                        <a
                            href="https://gethelp.wildapricot.com/en/articles/135-membership-application-form#setup"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            membership application
                        </a>
                        , or if you want to obtain consent for SMS messaging on a different website page,
                        have that page's URL ready for Contact information step
                    </>
                }
                icon={<FontAwesomeIcon size="2x" icon={faLink} className="mr-2" />}
            />
            <ListItem
                title="Locate your organization's Tax ID number"
                description={
                    <>
                        The Tax ID number, also known in the US as the Employer Identification Number (EIN) and
                        in Canada as the Business Number (BN), is a unique nine-digit number assigned by the
                        government to business entities for the purposes of identification. You will need your Tax ID
                        number when you enter your contact information.
                    </>
                }
                icon={<FontAwesomeIcon size="2x" icon={faInputNumeric} className="mr-2" />}
            />
            <div className="d-flex justify-content-between">
                <Button
                    id="TNS_Introduction_Back"
                    onClick={() => history.push('/')} variant="outline-secondary"
                >
                    Back to main menu
                </Button>
                <Button
                    id="TNS_Introduction_Next"
                    onClick={onNext}
                >
                    Continue
                </Button>
            </div>
        </div>

    );
};

StepIntro.propTypes = {
    onNext: propTypes.func.isRequired,
};

export default StepIntro;
