import React from 'react';
import { Alert } from 'react-bootstrap';

function NoIncomingMessages() {
    return (
        <div>
            <Alert variant="info" className="text-center p-4 mt-5">
                <Alert.Heading>
                    No incoming messages
                </Alert.Heading>
                {/* eslint-disable-next-line max-len */}
                There are no incoming messages so far. If you believe something should be here, try to adjust the filter or refresh the page.
            </Alert>
        </div>
    );
}

export default NoIncomingMessages;
