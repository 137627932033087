import { useCallback, useEffect, useState } from 'react';
import { useSectionContext } from '../../../../../../entities/help-widget/context/section-context-provider';

// eslint-disable-next-line import/no-anonymous-default-export
export default (ref) => {
    const { section } = useSectionContext();
    const MAX_SCROLL_HEIGHT = 80;
    const MIN_SCROLL_HEIGHT = 4;

    const [collapsed, setCollapse] = useState(false);

    const onScroll = useCallback(
        () => {
            setCollapse((isCollapsed) => {
                if (
                    !isCollapsed &&
          (ref?.current.scrollTop > MAX_SCROLL_HEIGHT)
                ) {
                    return true;
                }

                if (
                    isCollapsed &&
          ref?.current.scrollTop < MIN_SCROLL_HEIGHT
                ) {
                    return false;
                }

                return isCollapsed;
            });
        },
        [ref],
    );

    useEffect(() => {
        const node = ref;
        node?.current.addEventListener('scroll', onScroll);
        return () => node?.current.removeEventListener('scroll', onScroll);
    }, [ref, onScroll, section.key]);

    return [collapsed];
};
