import React from 'react';
import propTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

function CampaignsFilter({ campaigns, campaignId, onSelectionChange, isDisabled }) {
    const handleSelectionChange = (e) => {
        onSelectionChange(e.target.value);
    };
    let elements = [{ id: 'NONE', title: 'No filter, show all responses' }];
    const completeCampaigns = campaigns.filter((c) => c.propessingStatus === 'complete');

    completeCampaigns.sort((c1, c2) => c1.processingStatusDate - c2.processingStatusDate);
    elements = elements.concat(campaigns);

    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="campaignId">Filter by campaign</label>
            <FormControl
                disabled={isDisabled}
                type="select"
                as="select"
                name="campaignId"
                value={campaignId}
                onChange={handleSelectionChange}
            >
                {elements.map((item) => (
                    <option key={item.id} value={item.id}>{item.title}</option>
                ))}
            </FormControl>
        </div>
    );
}

CampaignsFilter.propTypes = {
    // eslint-disable-next-line react/require-default-props
    campaigns: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string.isRequired,
        title: propTypes.string.isRequired,
        processingStatusDate: propTypes.number.isRequired,
        processingStatus: propTypes.string.isRequired,
    })),
    // eslint-disable-next-line react/require-default-props
    campaignId: propTypes.string,
    onSelectionChange: propTypes.func.isRequired,
};

export default CampaignsFilter;
