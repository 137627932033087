import actionTypes from '../constants/actionTypes';
import billingService from '../apiServices/billingService';
import { handleSubscription } from './billingActions';
import settingsService from '../apiServices/settingsService';

export const saveTwilioFormData = (payload, onSuccess) => async (dispatch) => {
    dispatch({
        type: actionTypes.SubscriptionWizard.SAVE_TWILIO_FORM_DATA,
        payload,
    });
    onSuccess();
};

export const createStripeAndTwilioSubscription =
    (stripe, cardElement, billingDetails, twilioFormData, onComplete) => async (dispatch) => {
        dispatch({
            type: actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.start,
        });

        try {
            const stripeResult = await stripe.createPaymentMethod('card', cardElement, {
                billing_details: billingDetails,
            });

            if (stripeResult.error) {
                dispatch({
                    type: actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.failure,
                    error: stripeResult.error.message,
                });

                return;
            }

            const result = await billingService.createSubscription(stripeResult.paymentMethod.id, billingDetails);

            if (result.error) {
                dispatch({
                    type: actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.failure,
                    error: result.error,
                });
            } else {
                dispatch({
                    type: actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.start,
                });
                const { error } = await settingsService.saveTwilioSubscription(twilioFormData);

                if (error) {
                    dispatch({
                        type: actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.failure,
                        error,
                    });
                } else {
                    dispatch({
                        type: actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.success,
                    });
                }
                dispatch(handleSubscription(result.subscription, stripe, onComplete, true));
            }
        } catch (e) {
        // eslint-disable-next-line no-console
            console.error(e);
        }
    };
