const errorCodes = require('./errorCodes');
const mergeTags = require('./mergeTags');
const operations = require('./operations');
const externalResources = require('./externalResources');
const accountEventStatuses = require('./accountEventStatuses');
const accountStatuses = require('./accountStatuses');
const messageDeliveryStatuses = require('./messageDeliveryStatuses');
const campaignFixedIds = require('./campaignFixedIds');
const campaignStatuses = require('./campaignStatuses');
const campaignProcessingStatuses = require('./campaignProcessingStatuses');

exports.errorCodes = errorCodes;
exports.mergeTags = mergeTags;
exports.operations = operations;
exports.externalResources = externalResources;
exports.accountEventStatuses = accountEventStatuses;
exports.accountStatuses = accountStatuses;
exports.messageDeliveryStatuses = messageDeliveryStatuses;
exports.campaignFixedIds = campaignFixedIds;
exports.campaignStatuses = campaignStatuses;
exports.campaignProcessingStatuses = campaignProcessingStatuses;
