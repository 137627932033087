import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card } from 'react-bootstrap';
import * as actionCreators from '../../actions/billingActions';
import LoadingIndicator from '../LoadingIndicator';
import CancelSubscriptionDialog from './SubscriptionSettings/CancelSubscriptionDialog';
import CardInfo from './SubscriptionSettings/CardInfo';
import NotAuthorized from '../NotAuthorized';
import { getHasTwilioAccount, getIsBillingPlanProhibited } from '../../reducers/authReducer';

class SubscriptionSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderUpdateCard = this.renderUpdateCard.bind(this);
        this.renderSubscriptionStatus = this.renderSubscriptionStatus.bind(this);
        this.onCancelSubscription = this.onCancelSubscription.bind(this);
        this.onCancelSubscriptionConfirmed = this.onCancelSubscriptionConfirmed.bind(this);
        this.onCancelSubscriptionReject = this.onCancelSubscriptionReject.bind(this);
        this.renderNoSubscription = this.renderNoSubscription.bind(this);
        this.renderNotAuthorized = this.renderNotAuthorized.bind(this);
        this.onStartSubscription = this.onStartSubscription.bind(this);
        this.onUpdateSubscription = this.onUpdateSubscription.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchSubscriptionStatus();
    }

    onStartSubscription() {
        this.props.actions.navigateToStartSubscription(window.location.pathname);
    }

    onUpdateSubscription() {
        this.props.actions.navigateToUpdateSubscription(window.location.pathname);
    }

    onCancelSubscription() {
        this.setState({ cancelSubscriptionRequested: true });
    }

    onCancelSubscriptionConfirmed() {
        this.props.actions.cancelSubscription();
        this.setState({ cancelSubscriptionRequested: false });
    }

    onCancelSubscriptionReject() {
        this.setState({ cancelSubscriptionRequested: false });
    }

    renderSubscriptionStatus() {
        if (this.props.status === 'cancelled') {
            return this.renderNoSubscription('Your account payments are now cancelled.');
        }

        if (this.props.status === 'missing') return this.renderNoSubscription();

        if (this.props.status === 'expired') {
            const msg = 'Your account payments are set up but your card has expired.';

            return (
                <div>
                    {this.renderNoSubscription(msg)}
                    {this.renderUpdateCard()}
                    <Button variant="outline-danger" className="ml-2" onClick={this.onCancelSubscription}>
                        Cancel subscription
                    </Button>
                </div>
            );
        }

        if (this.props.status === 'valid') {
            return (
                <div>
                    <Card className="settingCard">
                        <Card.Header className="h5 text-muted">You have an active subscription.</Card.Header>
                        <Card.Body>

                            <CardInfo card={this.props.card} />
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-end bg-white">
                            {this.renderUpdateCard()}
                            <Button variant="outline-danger" className="ml-2" onClick={this.onCancelSubscription}>
                                Cancel subscription
                            </Button>
                        </Card.Footer>
                    </Card>
                </div>
            );
        }

        return null;
    }

    renderUpdateCard() {
        return <Button variant="outline-primary" onClick={this.onUpdateSubscription}>Update card</Button>;
    }

    renderNotAuthorized() {
        return <NotAuthorized />;
    }

    renderNoSubscription(msg) {
        return (
            <Card className="settingCard">
                <Card.Header className="h5 text-muted">You do not have a subscription.</Card.Header>
                <Card.Body>

                    <p>{msg}</p>

                    <p>
                        Without a subscription you cannot send or receive text messages,
                        however you still can explore the system as long as you need.
                    </p>

                    <p>
                        If you try to make a paid action system will suggest you to setup a subscription.
                    </p>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end bg-white">
                    <Button
                        variant="success"
                        onClick={this.onStartSubscription}
                        disabled={this.props.isBillingPlanProhibited}
                    >
                        Subscribe
                    </Button>
                </Card.Footer>

            </Card>
        );
    }

    render() {
        const { isBillingPlanProhibited } = this.props;
        return (
            <div className="mainContent">
                <h1 className="fixedHeader">Subscription settings</h1>
                <LoadingIndicator loading={this.props.isFetchingSubscriptionStatus}>
                    {!isBillingPlanProhibited ?
                        this.renderSubscriptionStatus()
                        :
                        this.renderNotAuthorized()
                    }
                </LoadingIndicator>

                <CancelSubscriptionDialog
                    show={this.state.cancelSubscriptionRequested}
                    onConfirm={this.onCancelSubscriptionConfirmed}
                    onReject={this.onCancelSubscriptionReject}
                />
            </div>
        );
    }
}

SubscriptionSettings.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,

    // eslint-disable-next-line react/require-default-props
    isFetchingSubscriptionStatus: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    status: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    card: propTypes.object,

    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    cancellingSubscription: propTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    cancelSubscriptionError: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    isBillingPlanProhibited: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    hasSubscription: propTypes.bool,

};

function mapStateToProps(state) {
    const { me } = state.auth;
    return {
        isFetchingSubscriptionStatus: state.billing.isFetchingSubscriptionStatus
            || state.billing.isCancellingSubscription
            || false,

        cancelSubscriptionError: state.settings.cancelSubscriptionError,
        status: state.billing.subscriptionStatus,
        card: state.billing.card,
        cancellingSubscription: state.billing.cancellingSubscription || false,
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
        hasSubscription: me.account.hasSubscription,
        hasTwilioAccount: getHasTwilioAccount(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubscriptionSettings);
