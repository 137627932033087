import { useCallback, useState } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [helpActive, setActive] = useState(false);

    const handleOpen = useCallback(
        () => setActive(true),
        [setActive],
    );
    const handleClose = useCallback(
        () => setActive(false),
        [setActive],
    );

    return [
        helpActive,
        handleOpen,
        handleClose,
    ];
};
