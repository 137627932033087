import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import HelpBadge from './help-badge';
import ChatBadge from './chat-badge';
import { IncomingMessages } from './chat-badge/src/chat-badge';

import { openWidget } from '../../../utils/forethought/api';
import { isChatting, showLiveChatWindow } from '../../../utils/zopim-chat/zopim-api';
import { useChatContext } from '../../../entities/help-widget/context/chat-context-provider';

import useOnHoverSecondary from './hooks/use-on-hover-secondary';

import './help-center-badge.css';

const HelpCenterBadge = ({ helpActive }) => {
    const { chatActive, unread } = useChatContext();

    const secondaryMenuRef = useRef(null);
    const [isSecondaryMenuOpen, setSecondaryMenuOpen] = useState(false);

    useOnHoverSecondary(secondaryMenuRef, () => setSecondaryMenuOpen(false));

    const talkingToAgent = isChatting();

    const handleOpen = useCallback(() => {
        if (talkingToAgent) {
            showLiveChatWindow();
        } else {
            openWidget();
        }
    }, [talkingToAgent]);

    return (
        <div
            ref={secondaryMenuRef}
            /* eslint-disable-next-line max-len */
            className={'d-flex -wa-help-center__badge -wa-help-center-z1 -wa-help-center__bottom-right flex-column align-items-center m-2 mr-4'}
        >
            {chatActive ?
                !helpActive && isSecondaryMenuOpen &&
        (
            <HelpBadge
                className="wa-help-center__show-badge"
                helpActive={helpActive}
                chatActive
            />
        )
                :
                !helpActive &&
        (
            <HelpBadge
                className="wa-help-center__show-badge"
                helpActive={helpActive}
            />
        )}
            <div>
                {!helpActive && chatActive && unread !== 0 &&
          (<IncomingMessages
              onClick={handleOpen}
              onKeyDown={handleOpen}
              unread={unread}
          />)}
                {!helpActive && chatActive &&
          (
              <div
                  onMouseOver={() => setSecondaryMenuOpen(true)}
                  onFocus={() => setSecondaryMenuOpen(true)}
              >
                  <ChatBadge
                      onClick={handleOpen}
                  />
              </div>
          )}
            </div>
        </div>
    );
};

HelpCenterBadge.propTypes =
  {
      helpActive: PropTypes.bool,
  };

HelpCenterBadge.defaultProps =
  {
      helpActive: false,
  };

export default HelpCenterBadge;
