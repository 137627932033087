import React from 'react';
import TutorialWidget from './tutorial-widget';

import './tutorials.css';

// Id must match anchor id defined in Gainsight to inject KCB
const Tutorials = () =>
    (
        <div key={window.location.pathname}>
            <div id="-wa-kcb-widget-anchor" />
            <TutorialWidget />
        </div>
    );

export default Tutorials;