/**
 * @param {timestamp} currentDate - current date timestamp
 * @param {integer} forwardHours - minimum number of hours in the future.
 *
 * @returns {timestamp} -  a minimum time in forwardHours in the future and not at night
 */
const getAppropriateDatetimeAfterADate = (currentDate, forwardHours) => {
    var desiredDate =  currentDate + forwardHours * 60 * 60 * 1000;
    var hrs = new Date(desiredDate).getUTCHours();

    // if time between 20 and 8 then set time to 8 AM
    if ((hrs >= 2) && (hrs <= 14)) {
        desiredDate = new Date(desiredDate + (14 - hrs) * 60 * 60 * 1000);
        desiredDate = Date.UTC(desiredDate.getUTCFullYear(), desiredDate.getUTCMonth(), desiredDate.getUTCDate(), 14);
    }

    return desiredDate;
};

/**
 * @param {integer} forwardHours - minimum number of hours in the future.
 *
 * @returns {timestamp} -  a minimum time in forwardHours in the future and not at night
 */
const getAppropriateDatetime = (forwardHours) => getAppropriateDatetimeAfterADate((new Date()).getTime(), forwardHours);

const toDateString = (dateNum) => {
    if (dateNum) {
        let date = new Date(dateNum);
        return date.toLocaleDateString();
    }

    return '–';
};

/**
 *
 * @param {number} timestamp
 * @returns {string} A string like '2020-03'
 */
const toPeriodString = (timestamp) => new Date(timestamp).toISOString().slice(0, 7);

/**
 * @param {number} anchorTimestamp - number, timestamp when subscription was started
 * @param {number} afterTimestamp - number, function searches for the next billing date ofter this timestamp
 * @param {number} shiftDays - should not be provided, this is to support form months shorter than 31 days during recursion
 *
 * @returns {Date} -  next billing date after 'afterTimestamp'
 */
const nextBillingDate = (anchorTimestamp, afterTimestamp, shiftDays = 0) => {

    if (shiftDays < -3) throw new Error('anchor date can not be shifted more than 3 days back');
    let anchorDate = new Date(anchorTimestamp + shiftDays * 24 * 60 * 60 * 1000);
    let after = new Date(afterTimestamp);
    let payDay = anchorDate.getDate();
    let payTime = anchorDate.getTime();

    let payYear = after.getFullYear();
    let payMonth = after.getMonth();
    if (after.getDate() > payDay || (after.getDate() === payDay &&  after.getTime() > payTime)) {
        // pay next month
        payMonth += 1;

        if (payMonth > 11) {
            payMonth = 0;
            payYear += 1;
        }
    }

    let payDate = new Date(payYear, payMonth, payDay);

    if (payDate.getDate() !== payDay) {
        payDate = nextBillingDate(anchorTimestamp, afterTimestamp, shiftDays - 1);
    }

    return payDate;
};

module.exports = {
    toPeriodString,
    nextBillingDate,
    toDateString,
    getAppropriateDatetime,
    getAppropriateDatetimeAfterADate,
};
