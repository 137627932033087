import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function campaignsReducer(state = initialState.campaigns, action) {
    switch (action.type) {
        case actionTypes.Campaigns.FETCH_CAMPAIGNS.start:
            return {
                ...state,
                isFetching: true,
                campaignsLoaded: false,
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGNS.success:
            return {
                ...state,
                isFetching: false,
                campaignsLoaded: true,
                campaigns: action.campaigns,
                error: null,
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGNS.failure:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                campaignsLoaded: false,
                campaigns: [],
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGN_SUGGESTIONS.start:
            return {
                ...state,
                isFetchingSuggestions: true,
                suggestions: [],
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGN_SUGGESTIONS.success:
            return {
                ...state,
                isFetchingSuggestions: false,
                suggestions: action.suggestions,
                fetchSuggestionsError: null,
            };

        case actionTypes.Campaigns.FETCH_CAMPAIGN_SUGGESTIONS.failure:
            return {
                ...state,
                isFetchingSuggestions: false,
                suggestions: null,
                fetchSuggestionsError: action.error,
            };

        default:
            return state;
    }
}
