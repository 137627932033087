module.exports = {
    SETTINGS_SET_API_KEY: 'Set API key',
    SETTINGS_UPDATE_EMAIL: 'Update email',
    SETTINGS_UPDATE_PASSWORD: 'Update password',

    AUTH_RESTORE_PASSWORD_FAILED: 'Restore password request failed',
    AUTH_RESTORE_PASSWORD_REQUESTED: 'Restore password request submitted',
    AUTH_RESTORE_PASSWORD_COMPLETE: 'Restore password complete',

    AUTH_LOGIN_FAILED: 'Login failed',
    AUTH_LOGIN_SUCCEED: 'Login succeed',

    ACCOUNT_CREATE: 'Create new account',

    CAMPAIGN_CREATE: 'Create new campaign',
    CAMPAIGN_UPDATE: 'Update existing campaign',
    CAMPAIGN_START: 'Start draft campaign',
    CAMPAIGN_STOP: 'Stop active campaign',

    SEND_SINGLE_MESSAGE: 'Send a single message',

    BILLING_CREATE_SUBSCRIPTION: 'Set up account payments',
    BILLING_CANCEL_SUBSCRIPTION: 'Cancel account payments',
    BILLING_UPDATE_CARD: 'Update payment method',

    USERS_MANAGEMENT_REVOKE_INVITATION: 'Revoke user invitation',
    USERS_MANAGEMENT_SUSPEND: 'Suspend / unsuspend user\'s access',
};
