/* istanbul ignore file */
import zendeskProperties from './constants/zendesk';
import zendeskWidgetSettings from './constants/zendesk-widget-settings';
import onWidgetLoad from './on-widget-load';
import { getUserId } from './selectors';

export const initialize = (self, accountInfo, accountInfoFinancialInfo, useMessaging) => {
    if (typeof window.zE === 'function' && typeof zESettings === 'object') {
        onWidgetLoad(accountInfo, accountInfoFinancialInfo, useMessaging);
    } else {
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        setTimeout(self, 100);
    }
};

const ZendeskWidget = (accountInfo, accountInfoFinancialInfo, useMessaging) => {
    window.zESettings = zendeskWidgetSettings;

    // Hide if sysadmin
    if (getUserId(accountInfo) === 0) {
        return;
    }

    // Push widget script
    const body = window.top.document.querySelector('BODY');
    const zendeskScript = document.createElement('script');
    zendeskScript.onload = () => {
        initialize(initialize, accountInfo, accountInfoFinancialInfo, useMessaging);
    };
    zendeskScript.id = zendeskProperties.ID;
    zendeskScript.src = useMessaging ? zendeskProperties.MESSAGING_PATH : zendeskProperties.PATH;

    if (body) {
        body.appendChild(zendeskScript);
    }
};

export default ZendeskWidget;
