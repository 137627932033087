import { combineReducers } from 'redux';
import campaignMessages from './campaignMessagesReducer';
import recipientMessages from './recipientMessagesReducer';
import sendMessage from './sendMessageReducer';
import latestIncomingMessages from './latestIncomingMessagesReducer';

const messagesReducer = combineReducers({
    campaignMessages,
    recipientMessages,
    sendMessage,
    latestIncomingMessages,
});

export default messagesReducer;
