import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function recipientMessagesReducer(state = initialState.messages.recipientMessages, action) {
    switch (action.type) {
        case actionTypes.Messages.FETCH_RECIPIENTS_MESSAGES.start: {
            const prevBucketState = { ...(state[action.recipientId] || {}) };
            const stateUpdate = {};
            stateUpdate[action.recipientId] = Object.assign(
                prevBucketState,
                { isFetching: true, error: null },
            );

            return { ...state, ...stateUpdate };
        }

        case actionTypes.Messages.FETCH_RECIPIENTS_MESSAGES.failure: {
            const prevBucketState = { ...(state[action.recipientId] || {}) };
            const stateUpdate = {};
            stateUpdate[action.recipientId] = Object.assign(prevBucketState, {
                isFetching: false,
                error: action.error,
                messages: null,
            });

            return { ...state, ...stateUpdate };
        }

        case actionTypes.Messages.FETCH_RECIPIENTS_MESSAGES.success: {
            const prevBucketState = { ...(state[action.recipientId] || {}) };
            const stateUpdate = {};
            const prevMessages = prevBucketState.messages;
            if (JSON.stringify(prevMessages) === JSON.stringify(action.messages)) {
                stateUpdate[action.recipientId] = Object.assign(prevBucketState, {
                    isFetching: false,
                    error: null,
                });
            } else {
                stateUpdate[action.recipientId] = Object.assign(prevBucketState, {
                    isFetching: false,
                    error: null,
                    messages: action.messages,
                });
            }
            return { ...state, ...stateUpdate };
        }
        default:
            return state;
    }
}
