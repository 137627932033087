import actionTypes from '../constants/actionTypes';
import settingsService from '../apiServices/settingsService';
import authStorage from '../utils/authStorage';
import { fetchCurrentUserInfo } from './loginActions';

export const updateEmail = (email) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.UPDATE_EMAIL.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.updateEmail(email);

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.UPDATE_EMAIL.failure,
            error: result.error,
        });
    } else {
        const authState = authStorage.getState();

        authStorage.setState(authState.token, authState.expiresAt, authState.accountId);

        dispatch({
            type: actionTypes.Settings.UPDATE_EMAIL.success,
        });

        dispatch(fetchCurrentUserInfo());
    }
};

export const updatePassword = (password) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.UPDATE_PASSWORD.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.updatePassword(password);

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.UPDATE_PASSWORD.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.UPDATE_PASSWORD.success,
        });
    }
};

export const fetchApiKey = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_APIKEY.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.fetchApiKey();

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.FETCH_APIKEY.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_APIKEY.success,
            apiKey: result.apiKey,
            apiKeyStatus: result.apiKeyStatus,
        });
    }
};

export const updateApiKey = (apiKey) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.UPDATE_APIKEY.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.updateApiKey(apiKey);

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.UPDATE_APIKEY.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.UPDATE_APIKEY.success,
        });

        dispatch(fetchApiKey());
    }
};

export const fetchWaFields = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_WA_FIELDS.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.fetchWaFields();

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.FETCH_WA_FIELDS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_WA_FIELDS.success,
            waFields: result.waFields,
        });
    }
};

export const fetchIntegrationOptions = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_INTEGRATION_OPTIONS.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.fetchIntegrationOptions();

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.FETCH_INTEGRATION_OPTIONS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_INTEGRATION_OPTIONS.success,
            integrationOptions: result.integrationOptions,
        });
    }
};

export const saveIntegrationOptions = (integrationOptions, onSuccess) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.UPDATE_INTEGRATION_OPTIONS.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const result = await settingsService.saveIntegrationOptions(integrationOptions);

    if (result.error) {
        dispatch({
            type: actionTypes.Settings.UPDATE_INTEGRATION_OPTIONS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.UPDATE_INTEGRATION_OPTIONS.success,
            integrationOptions,
        });
        onSuccess();
    }
};

export const fetchTwilioSubscription = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_TWILIO_SUBSCRIPTION.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { payload, error } = await settingsService.fetchTwilioSubscription();

    if (error) {
        dispatch({
            type: actionTypes.Settings.FETCH_TWILIO_SUBSCRIPTION.failure,
            error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_TWILIO_SUBSCRIPTION.success,
            payload,
        });
    }
};

export const refetchTwilioSubscription = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.REFETCH_TWILIO_SUBSCRIPTION.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { payload, error } = await settingsService.fetchTwilioSubscription();

    if (error) {
        dispatch({
            type: actionTypes.Settings.REFETCH_TWILIO_SUBSCRIPTION.failure,
            error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.REFETCH_TWILIO_SUBSCRIPTION.success,
            payload,
        });
    }
};

export const saveTwilioSubscription = (payload, onSuccess) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { error } = await settingsService.saveTwilioSubscription(payload);

    if (error) {
        dispatch({
            type: actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.failure,
            error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.success,
        });
        onSuccess();
    }
};

export const fetchTwilioSettings = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_TWILIO_SETTINGS.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { payload, error } = await settingsService.fetchTwilioSettings();

    if (error) {
        dispatch({
            type: actionTypes.Settings.FETCH_TWILIO_SETTINGS.failure,
            error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_TWILIO_SETTINGS.success,
            payload,
        });
    }
};

export const saveTwilioSettings = (payload, onSuccess) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.SAVE_TWILIO_SETTINGS.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { error } = await settingsService.saveTwilioSettings(payload);

    if (error) {
        dispatch({
            type: actionTypes.Settings.SAVE_TWILIO_SETTINGS.failure,
            error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.SAVE_TWILIO_SETTINGS.success,
        });
        dispatch(fetchTwilioSettings());
        onSuccess();
    }
};

export const fetchConsentWebpages = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_CONSENT_PAGES.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { consentPages, error } = await settingsService.fetchConsentWebpages();

    if (error) {
        dispatch({
            type: actionTypes.Settings.FETCH_CONSENT_PAGES.failure,
            error: [],
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_CONSENT_PAGES.success,
            consentPages,
        });
    }
};

export const fetchEin = (einNum) => async (dispatch) => {
    dispatch({
        type: actionTypes.Settings.FETCH_EIN.start,
    });

    // eslint-disable-next-line import/no-named-as-default-member
    const { ein, error } = await settingsService.fetchEin(einNum || 0);

    if (error) {
        dispatch({
            type: actionTypes.Settings.FETCH_EIN.failure,
            error,
        });
    } else {
        dispatch({
            type: actionTypes.Settings.FETCH_EIN.success,
            ein,
        });
    }
};

const api = {
    updateApiKey,
    fetchApiKey,
    updatePassword,
    updateEmail,
    fetchTwilioSubscription,
    saveTwilioSubscription,
    fetchTwilioSettings,
    saveTwilioSettings,
    fetchConsentWebpages,
    fetchEin,
};

export default api;
