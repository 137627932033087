import { useEffect } from 'react';
import { gainsight } from 'wa.admin-ui.in-product-scripts';

const useInitGainsight = (data) => {
    useEffect(() => {
        if (data) {
            gainsight(data, null, true);
        }
    }, [data]);
};

export default useInitGainsight;
