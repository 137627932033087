import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { L10nContext } from 'wa.admin-ui.l10n';
import labels from './l10n/en';

import SectionContext from './entities/help-widget/context/section-context-provider';
import AssociationContext from './entities/help-widget/context/association-context-provider';
import ChatContext from './entities/help-widget/context/chat-context-provider';
import ThemeContext from './entities/help-widget/context/theme-context-provider';

import useHelpCenterOpen from './hooks/use-help-center-open';
import useSetSection from './hooks/use-set-section';
import useSetChatActive from './hooks/use-set-chat-active';
import useSetArticle from './hooks/use-set-article';
import useSectionHistory from './hooks/use-section-history';

import HelpCenter from './help-center';
import sectionKeys from './constants/section-keys';

const HelpCenterContainer = (
    {
        isTrial,
        domNodeId,
        logo,
        serviceNotice,
        useForethought,
    }) => {
    const
        [chatActive,
            unread,
            online,
            startChat,
            endChat,
            isUnread,
            clearUnread,
            setChatOnline,
            setChatOffline] = useSetChatActive();
    const [currentState, pushState, popState, flushState] = useSectionHistory();
    const [helpActive, handleOpen, handleClose] = useHelpCenterOpen();
    const [article, setActiveArticle] = useSetArticle();
    const [section, changeSection] = useSetSection();

    const stateRef = useRef(section?.key);

    const handleChangeSection = (key, articleId) => {
        stateRef.current = key;
        if (key === sectionKeys.SECTION_HOME) {
            flushState();
        } else if (articleId !== null && articleId !== undefined) {
            pushState(section?.key, article);
            setActiveArticle(articleId);
        } else {
            pushState(section?.key, article);
        }
        changeSection(key);
    };

    // Renders help center outside of content frame in admin app
    const domNode = window.document.getElementById(domNodeId);

    return createPortal(
        <L10nContext.Provider value={{ labels }}>
            <AssociationContext.Provider value={{ isTrial, useForethought }}>
                <ThemeContext.Provider value={{ logo }}>
                    <SectionContext.Provider
                        value={{
                            section,
                            setSection: handleChangeSection,
                            updateSectionState: changeSection,
                            openWidget: handleOpen,
                            closeWidget: handleClose,
                            article,
                            setActiveArticle,
                            currentState,
                            popState,
                            pushState,
                            flushState,
                            serviceNotice,
                            stateRef,
                        }}
                    >
                        <ChatContext.Provider
                            value={{
                                chatActive,
                                unread,
                                online,
                                startChat,
                                endChat,
                                isUnread,
                                clearUnread,
                                setChatOnline,
                                setChatOffline,
                            }}
                        >
                            <HelpCenter
                                helpActive={helpActive}
                                handleClose={handleClose}
                                isTrial={isTrial}
                            />
                        </ChatContext.Provider>
                    </SectionContext.Provider>
                </ThemeContext.Provider>
            </AssociationContext.Provider>
        </L10nContext.Provider>, domNode);
};

HelpCenterContainer.propTypes =
  {
      isTrial: PropTypes.bool,
      domNodeId: PropTypes.string.isRequired,
      logo: PropTypes.node,
      serviceNotice: PropTypes.bool,
  };

HelpCenterContainer.defaultProps =
  {
      isTrial: false,
      logo: null,
      serviceNotice: false,
  };

export default HelpCenterContainer;
