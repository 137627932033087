import { useCallback, useState } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [article, setArticle] = useState(null);

    const setActiveArticle = useCallback((id) => {
        setArticle(id);
    }, [setArticle]);

    return [article, setActiveArticle];
};
