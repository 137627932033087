import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function signUpReducer(state = initialState.usersManagement, action) {
    switch (action.type) {
        case actionTypes.Users.FETCH_USERS.start:
            return { ...state, isFetching: true };

        case actionTypes.Users.FETCH_USERS.failure:
            return {
                ...state,
                isFetching: false,
                users: null,
                error: action.error,
            };

        case actionTypes.Users.FETCH_USERS.success:
            return {
                ...state,
                isFetching: false,
                users: action.users,
                error: null,
            };

        case actionTypes.Users.ADD_USER.start:
            return { ...state, isCreating: true };

        case actionTypes.Users.ADD_USER.failure:
            return {
                ...state,
                isCreating: false,
                createError: action.error,
            };

        case actionTypes.Users.ADD_USER.success:
            return { ...state, isCreating: false, createError: null };

        case actionTypes.Users.UPDATE_USER.start:
            return { ...state, isUpdating: true, updatingUserId: action.userId };

        case actionTypes.Users.UPDATE_USER.failure:
            return {
                ...state,
                isUpdating: false,
                updatingUserId: null,
                updateError: action.error,
            };

        case actionTypes.Users.UPDATE_USER.success:
            return {
                ...state,
                isUpdating: false,
                updatingUserId: null,
                updateError: null,
            };

        case actionTypes.Users.DELETE_USER.start:
            return { ...state, isUpdating: true, deletingUserId: action.userId };

        case actionTypes.Users.DELETE_USER.failure:
            return {
                ...state,
                isUpdating: false,
                deletingUserId: null,
                deleteError: action.error,
            };

        case actionTypes.Users.DELETE_USER.success:
            return {
                ...state,
                isUpdating: false,
                deletingUserId: null,
                deleteError: null,
            };

        default:
            return state;
    }
}
