import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './NotAuthorized.scss';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotAuthorized = () => {
    const history = useHistory();
    return (
        <div className="my-4">
            <Alert className="tns-not-authorized__alert" variant="danger">
                You are not authorized to view this page
                <Button
                    className="mx-3"
                    variant="light"
                    onClick={() => history.push('/')}
                >
                    <FontAwesomeIcon icon={faUndo} className="mr-2" />
                    Return home
                </Button>
            </Alert>
        </div>
    );
};

export default NotAuthorized;
