import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from '../../../actions/messagesActions';
import LoadingIndicator from '../../LoadingIndicator';
import campaignUtils from '../campaignUtils';

class MessagesListDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: '' };
        this.renderMessageRow = this.renderMessageRow.bind(this);
        this.showMessages = this.showMessages.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.shouldShowRecord = this.shouldShowRecord.bind(this);
    }

    onSearchChange(e) {
        this.setState({ search: e.target.value });
    }

    onCloseDialog() {
        this.setState({ showMessages: false });
    }

    showMessages() {
        this.setState({ showMessages: true });
        this.props.fetchCampaignMessages(this.props.campaignId);
    }

    shouldShowRecord(msg) {
        if (!this.state.search) return true;

        // eslint-disable-next-line max-len
        const index = `${msg.phone}\t${msg.displayName}\t${campaignUtils.getCampaignStatusDisplayName(msg.status)}\t${msg.failureReason}`.toLowerCase();

        return index.includes(this.state.search.toLocaleLowerCase());
    }

    renderMessageRow(message) {
        const externalUrl = message.recipientType === 'contact'
            ? `https://${this.props.accountDomainName}/admin/contacts/details/?contactId=${message.recipientExternalId}`
            : `https://${this.props.accountDomainName}/admin/events/registration-details/?DetailsDisplayMode=View&erid=${message.recipientExternalId}`;

        return (
            <tr key={message.id}>
                <td>
                    <a href={externalUrl} target="_blank" rel="noopener noreferrer">{message.displayName}</a>
                </td>
                <td>
                    <a href={externalUrl} target="_blank" rel="noopener noreferrer">{message.phone}</a>
                </td>
                <td>
                    {campaignUtils.getCampaignStatusDisplayName(message.status)}
                </td>
                <td>
                    {message.failureReason && message.failureReason !== 'null' ? message.failureReason : ''}
                </td>
            </tr>
        );
    }

    render() {
        return (
            <div className="d-flex justify-content-start">
                <Button variant="outline-primary" block onClick={this.showMessages}>Show delivery statuses</Button>
                <Modal
                    show={this.state.showMessages}
                    scrollable
                    dialogClassName="modal-75w"
                    onHide={this.onCloseDialog}
                >
                    <Modal.Header>
                        <Modal.Title>Delivery statuses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: 200 }}>
                        <div>
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="mt-2 ml-3 position-absolute"
                                style={{ opacity: 0.5 }}
                            />
                            <input
                                name="searchText"
                                onChange={this.onSearchChange}
                                type="text"
                                className="pl-5 mt-3 mb-2 mimicFormControl"
                                placeholder="Search"
                            />
                        </div>
                        <LoadingIndicator loading={this.props.isFetching}>
                            <Table borderless>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Status</th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.messages
                                        .filter(this.shouldShowRecord)
                                        .map(this.renderMessageRow)}
                                </tbody>
                            </Table>

                        </LoadingIndicator>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.onCloseDialog} name="close">Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

MessagesListDialog.propTypes = {
    campaignId: propTypes.string.isRequired,
    isFetching: propTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    error: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    messages: propTypes.array,
    // eslint-disable-next-line react/require-default-props
    accountDomainName: propTypes.string,
    fetchCampaignMessages: propTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    const messagesBucket = state.messages.campaignMessages[ownProps.campaignId] || {};
    return {
        isFetching: messagesBucket.isFetching || false,
        messages: messagesBucket.messages || [],
        error: messagesBucket.error,
        accountDomainName: state.auth.me.account.primaryDomainName,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCampaignMessages: bindActionCreators(actionCreators, dispatch).fetchCampaignMessages,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessagesListDialog);
