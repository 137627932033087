export const isValidUrl = (url) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // Validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Validate domain name
        '(\\d{1,3}\\.){3}\\d{1,3})' + // Validate IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // Validate query string
        '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // validate fragment locator
    return !!urlPattern.test(url);
};

export const ensureHttpsPrefix = (url) => {
    if (url === null || url === undefined) {
        return url;
    }
    if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
};
