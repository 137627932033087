import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import * as messagesActionCreators from '../../actions/messagesActions';
import Conversation from './Conversation';
import SendMessageControl from './SendMessageControl';
import { getIsBillingPlanProhibited } from '../../reducers/authReducer';

function NoSubscriptionAlert({
    show, onHide, onSubscribe, canSubscribe,
}) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Subscription required</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                You need an active subscription to send direct messages.
                Do you want to subscribe now?

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide} name="cancel"> Later </Button>
                <Button variant="primary" onClick={onSubscribe} disabled={!canSubscribe} name="subscribe"> Yes </Button>
            </Modal.Footer>
        </Modal>
    );
}

NoSubscriptionAlert.propTypes = {
    // eslint-disable-next-line react/require-default-props
    show: propTypes.bool,
    // eslint-disable-next-line react/require-default-props
    canSubscribe: propTypes.bool,
    onHide: propTypes.func.isRequired,
    onSubscribe: propTypes.func.isRequired,
};

const minConversationOffset = 260;

class ConversationWithSend extends React.Component {
    constructor(props) {
        super(props);
        this.state = { requireSubscription: false, conversationOffset: minConversationOffset };
        this.onConversationLoaded = this.onConversationLoaded.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.fetchMessages = this.fetchMessages.bind(this);
        this.dismissSubscription = this.dismissSubscription.bind(this);
        this.startSubscription = this.startSubscription.bind(this);
        this.conversationBlock = React.createRef();
        this.intervalRef = null;
        this.setConversationOffset = debounce(this.setConversationOffset.bind(this), 50);
    }

    async componentDidMount() {
        if (this.props.recipientId) {
            await this.fetchMessages();
        }

        // reload messages every 20 seconds
        this.intervalRef = setInterval(() => {
            if (!document.hidden) this.fetchMessages();
        }, 20000);

        this.setConversationOffset();
        window.addEventListener('resize', this.setConversationOffset);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.recipientId && this.props.recipientId !== prevProps.recipientId) {
            await this.fetchMessages();
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalRef);
        window.removeEventListener('resize', this.setConversationOffset);
    }

    onConversationLoaded() {
        const conversationBlock = this.conversationBlock.current;

        if (conversationBlock) {
            conversationBlock.scrollIntoView({ /* behavior: 'smooth', */ block: 'end' });
        }
    }

    async onSendMessage(message) {
        if (!this.props.hasSubscription) {
            this.setState({ requireSubscription: true });
            return;
        }

        if (message && this.props.recipientDisplayName) {
            const recipient = {
                id: this.props.recipientId,
                displayName: this.props.recipientDisplayName,
                recipientType: this.props.recipientType,
            };
            const sendPromise = this.props.sendMessage(this.props.recipientPhone, message, recipient);

            this.onConversationLoaded();
            await sendPromise;
            await this.fetchMessages();
        }
    }

    async fetchMessages() {
        await this.props.fetchRecipientMessages(
            this.props.recipientType,
            this.props.recipientId,
            this.props.recipientPhone,
        );
        this.onConversationLoaded();
    }

    dismissSubscription() {
        this.setState({ requireSubscription: false });
    }

    startSubscription() {
        this.setState({ requireSubscription: false });
        this.props.onStartSubscription();
    }

    setConversationOffset() {
        const mainContentContainer = document.querySelector('.mainContent');
        const mainContentTopOffset = mainContentContainer ? mainContentContainer.offsetTop + 10 : 0;

        this.setState({
            conversationOffset: minConversationOffset + mainContentTopOffset,
        });
    }

    render() {
        return (
            <div>
                <div
                    className="conversationContainer w-100 pr-3"
                    style={{ height: `calc(100vh - ${this.state.conversationOffset}px)` }}
                >
                    <div ref={this.conversationBlock}>
                        <Conversation
                            orgName={this.props.orgName}
                            recipientId={this.props.recipientId}
                            recipientDisplayName={this.props.recipientDisplayName}
                            isFetchingMessages={this.props.isFetchingMessages}
                            messages={this.props.messages}
                            fetchMessagesError={this.props.fetchMessagesError}
                            nextDirectMessage={this.props.nextDirectMessage}
                        />
                    </div>
                </div>
                <div className="w-100 mt-2 pr-3">
                    <SendMessageControl sendMessage={this.onSendMessage} />
                </div>

                <NoSubscriptionAlert
                    show={this.state.requireSubscription}
                    onHide={this.dismissSubscription}
                    onSubscribe={this.startSubscription}
                    canSubscribe={!this.props.isBillingPlanProhibited}
                />
            </div>
        );
    }
}

ConversationWithSend.propTypes = {
    // own props
    recipientId: propTypes.string.isRequired,
    recipientType: propTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    recipientPhone: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    recipientDisplayName: propTypes.string,
    onStartSubscription: propTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onBackToList: propTypes.func.isRequired,

    orgName: propTypes.string.isRequired,
    fetchRecipientMessages: propTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    messages: propTypes.array.isRequired,
    isFetchingMessages: propTypes.bool.isRequired,
    // eslint-disable-next-line react/require-default-props
    fetchMessagesError: propTypes.string,
    sendMessage: propTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    nextDirectMessage: propTypes.object,
    hasSubscription: propTypes.bool.isRequired,
    isBillingPlanProhibited: propTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    const messagesBucket = state.messages.recipientMessages[ownProps.recipientId] || {};

    let nextDirectMessage = state.messages.sendMessage;

    if (nextDirectMessage.recipientId !== ownProps.recipientId) {
        nextDirectMessage = {};
    }
    return {
        messages: messagesBucket.messages || [],
        isFetchingMessages: messagesBucket.isFetching || false,
        fetchMessagesError: messagesBucket.error,
        orgName: state.auth.me.account.organizationTitle,
        nextDirectMessage,
        // temporary hack, in future we need only one way to figure it out
        hasSubscription: state.auth.me.account.hasSubscription || state.billing.subscriptionStatus === 'valid',
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
    };
}

function mapDispatchToProps(dispatch) {
    const messagesActions = bindActionCreators(messagesActionCreators, dispatch);

    return {
        fetchRecipientMessages: messagesActions.fetchRecipientMessages,
        sendMessage: messagesActions.sendSingleMessage,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConversationWithSend);
