import React from 'react';
import PropTypes from 'prop-types';

const HelpSection = ({ children }) =>
    (
        <div className="m-1 p-4">
            {children}
        </div>
    );

HelpSection.propTypes =
  {
      children: PropTypes.node.isRequired,
  };

export default HelpSection;