import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Tabs, Tab } from 'react-bootstrap';
import * as messagesActionCreators from '../../actions/messagesActions';
import ContactsConversations from './ContactsConversations';
import LatestConversations from './LatestConversations';
import './conversations.css';
import { getTwilioSettingsAcceptIncoming } from '../../reducers/settingsReducer';
import TwilioStatuses from '../../constants/twilioStatuses';

class ConversationsListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.onTabChange = this.onTabChange.bind(this);
        this.onOpenMessage = this.onOpenMessage.bind(this);
        this.onOpenRecipient = this.onOpenRecipient.bind(this);
        this.onCampaignFilterChanged = this.onCampaignFilterChanged.bind(this);
        this.onContactFilterChanged = this.onContactFilterChanged.bind(this);
    }

    onTabChange(key) {
        this.props.navigateToConversations(key);
    }

    onOpenMessage(message) {
        this.props.openConversation(message.recipientType, message.recipientExternalId);
    }

    onOpenRecipient(recipient) {
        this.props.openConversation(recipient.recipientType, recipient.id);
    }

    onCampaignFilterChanged(campaignId) {
        this.props.navigateToConversations(this.props.section, { campaignId });
    }

    onContactFilterChanged(recipientsGroupType, recipientsListId, searchString) {
        this.props.navigateToConversations(this.props.section, {
            recipientsGroupType, recipientsListId, searchString,
        });
    }

    render() {
        return (
            <div className="mainContent">
                <h1 className="fixedHeader">Conversations</h1>
                <div className="recipientsArea mt-4">
                    {(!this.props.acceptIncoming || !this.props.twilioApproved) &&
                        <Alert variant="info">
                            Text replies are currently disabled for this account.
                        </Alert>
                    }
                    <Tabs
                        activeKey={this.props.section}
                        onSelect={this.onTabChange}
                        mountOnEnter
                    >
                        <Tab
                            disabled={!this.props.acceptIncoming || !this.props.twilioApproved}
                            eventKey="latest"
                            title="Latest responses"
                        >
                            <div className="mt-3">
                                <LatestConversations
                                    isDisabled={!this.props.acceptIncoming || !this.props.twilioApproved}
                                    onOpenMessage={this.onOpenMessage}
                                    onCampaignFilterChanged={this.onCampaignFilterChanged}
                                    campaignId={this.props.campaignId}
                                />
                            </div>
                        </Tab>
                        <Tab
                            disabled={!this.props.acceptIncoming || !this.props.twilioApproved}
                            eventKey="contacts"
                            title="Contacts"
                        >
                            <div className="mt-3">
                                <ContactsConversations
                                    onOpenRecipient={this.onOpenRecipient}
                                    onContactFilterChanged={this.onContactFilterChanged}
                                    searchString={this.props.searchString}
                                    recipientsGroupType={this.props.recipientsGroupType}
                                    recipientsListId={this.props.recipientsListId}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }
}

ConversationsListPage.propTypes = {
    acceptIncoming: propTypes.bool.isRequired,
    twilioApproved: propTypes.bool.isRequired,
    navigateToConversations: propTypes.func.isRequired,
    openConversation: propTypes.func.isRequired,

    // params
    // eslint-disable-next-line react/require-default-props
    section: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    campaignId: propTypes.string,
    recipientsGroupType: propTypes.string,
    recipientsListId: propTypes.string,
    searchString: propTypes.string,
};

ConversationsListPage.defaultProps = {
    searchString: '+phone',
    recipientsGroupType: 'PREDEFINED',
    recipientsListId: 'PREDEFINED-ALL_CONTACTS',
};

function mapStateToProps(state) {
    const { account } = state.auth.me;
    return {
        acceptIncoming: getTwilioSettingsAcceptIncoming(state),
        twilioApproved: account?.twilioSubscription?.Status === TwilioStatuses.twilio_approved,
    };
}

function mapDispatchToProps(dispatch) {
    const messagesActions = bindActionCreators(messagesActionCreators, dispatch);

    return {
        navigateToConversations: messagesActions.navigateToConversations,
        openConversation: messagesActions.openConversation,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConversationsListPage);
