import { combineReducers } from 'redux';
import contacts from './contactsReducer';
import groups from './recipientGroupsReducer';
import groupTypes from './recipientGroupTypesReducer';
import groupCounts from './recipientGroupCountsReducer';

const recipientsReducer = combineReducers({
    contacts,
    groups,
    groupTypes,
    groupCounts,
});

export default recipientsReducer;
