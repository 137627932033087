import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import twilioStatuses from '../constants/twilioStatuses';
import { getTwilioSubscription } from '../reducers/settingsReducer';
import { refetchTwilioSubscription } from '../actions/settingsActions';
import { fetchCurrentUserInfo } from '../actions/loginActions';

const statusVariants = {
    [twilioStatuses.noncompliant]: 'danger',
    [twilioStatuses.compliant]: 'danger',
    [twilioStatuses.draft]: 'danger',
    [twilioStatuses.pending_review]: 'warning',
    [twilioStatuses.in_review]: 'warning',
    [twilioStatuses.twilio_rejected]: 'danger',
    [twilioStatuses.twilio_approved]: 'success',
    [twilioStatuses.fetching_error]: 'danger',
};

const statusTextLabels = {
    [twilioStatuses.noncompliant]: 'Failed',
    [twilioStatuses.compliant]: 'Failed',
    [twilioStatuses.draft]: 'Failed',
    [twilioStatuses.pending_review]: 'Pending',
    [twilioStatuses.in_review]: 'Pending',
    [twilioStatuses.twilio_rejected]: 'Rejected',
    [twilioStatuses.twilio_approved]: 'Approved',
    [twilioStatuses.fetching_error]: 'Unable to get status',
};

const refetchDelay = 20000; // in ms - 20 seconds

function TwilioSubscriptionStatus() {
    const dispatch = useDispatch();
    const { Status, a2pBrand } = useSelector(getTwilioSubscription) || {};
    const variant = statusVariants[Status];
    const statusLabel = statusTextLabels[Status];
    const [initialStatus, setInitialStatus] = useState(Status);

    useEffect(() => {
        const refetch = () => {
            dispatch(refetchTwilioSubscription());
            dispatch(fetchCurrentUserInfo());
        };

        if (Status) {
            if (!initialStatus) {
                setInitialStatus(Status);
            }

            if (Status !== twilioStatuses.twilio_approved) {
                const refetchId = setInterval(refetch, refetchDelay);

                return () => clearInterval(refetchId);
            }
        }

        return undefined;
    }, [Status, dispatch, initialStatus]);

    if (
        !variant
        || !initialStatus
        || (initialStatus === twilioStatuses.twilio_approved && Status === twilioStatuses.twilio_approved)
    ) {
        return null;
    }

    return (
        <div className="py-5 text-center small">
            <Alert variant={variant} className="mb-2">
                <div>
                    Account status:
                    {' '}
                    {statusLabel}
                </div>
                {a2pBrand && a2pBrand.response && a2pBrand.response.failureReason && (
                    <div>
                        Failure reason:
                        {' '}
                        {a2pBrand.response.failureReason}
                    </div>
                )}
            </Alert>
        </div>
    );
}

export default TwilioSubscriptionStatus;
