import React from 'react';
import ErrorBoundaryPage from './components/ErrorBoundaryPage';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import './styles/bootstrap.min.custom.css';
import './styles/styles.scss';

const store = configureStore();

function App() {
    return (
        <ErrorBoundaryPage>
            <Root store={store} history={history} />
        </ErrorBoundaryPage>
    );
}

export default App;
