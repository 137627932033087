export const showLiveChatWindow = (onShow) => {
    if (typeof window.$zopim !== 'undefined' && typeof window.$zopim.livechat !== 'undefined') {
        window.$zopim.livechat.window.show();

        if (typeof onShow !== 'undefined' && typeof onShow === 'function') {
            onShow();
        }
    }
};

export const hideLiveChatWindow = (onHide) => {
    if (typeof window.$zopim !== 'undefined' && typeof window.$zopim.livechat !== 'undefined') {
        window.$zopim.livechat.window.hide();

        if (typeof onHide !== 'undefined' && typeof onHide === 'function') {
            onHide();
        }
    }
};

export const isChatting = () => {
    if (typeof window.$zopim !== 'undefined' && typeof window.$zopim.livechat !== 'undefined') {
        return window.$zopim.livechat.isChatting();
    }
    return false;
};

export const chatWindowActive = () => {
    if (typeof window.$zopim !== 'undefined' && typeof window.$zopim.livechat !== 'undefined') {
        return window.$zopim.livechat.window.getDisplay();
    }
    return false;
};