import authStorage from '../utils/authStorage';

const buildAuthState = () => {
    const authState = authStorage.getState();

    return {
        authStatus: (authState.token == null ? 'loggedOut' : 'authenticated'),
        accountId: authState.accountId,
        authFailed: false,
        accessToken: authState.token,
        expiresAt: authState.expiresAt,
    };
};

const initialState = {
    auth: buildAuthState(),
    campaigns: {
        campaignsLoaded: false,
        campaigns: [],
    },
    campaignDetails: {},
    billing: {},
    signUpState: {
        step: 1,
        isFetching: false,
    },
    subscriptionWizard: {},
    settings: {},
    usersManagement: {},
    messages: {
        campaignMessages: {},
        recipientMessages: {},
        latestIncomingMessages: {},
        sendMessage: {},
    },
    recipients: {
        groups: {},
        groupTypes: {},
        groupCount: {},
        contacts: {},
    },
    accounts: {},
};

export default initialState;
