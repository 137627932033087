import React from 'react';

function CampaignsListNothingFound() {
    return (
        <div style={{ margin: 'auto' }} className="text-center mt-5">
            <span className="h5">No campaigns found. Try to refine search criteria.</span>
        </div>
    );
}

export default CampaignsListNothingFound;
