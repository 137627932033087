import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HelpTooltip = ({ text, placement, children }) =>
    (
        <OverlayTrigger
            placement={placement}
            overlay={
                (
                    <Tooltip>
                        {text}
                    </Tooltip>
                )
            }
        >
            {children}
        </OverlayTrigger>
    );

export default HelpTooltip;

HelpTooltip.propTypes =
  {
      text: PropTypes.string,
      placement: PropTypes.string,
      children: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.node),
          PropTypes.node,
      ]).isRequired,
  };

HelpTooltip.defaultProps =
  {
      text: '',
      placement: 'top',
  };

