import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Navigation from './navigation/src/navigation';
import TaskBar from './task-bar';
import HomeSplash from './home-splash/src/home-splash';

import './header.css';

const Header = (
    {
        title,
        description,
        isHome,
        collapsed,
    }) =>
    (
        <Card.Header
            className={`bg-primary text-white rounded-0
      ${// eslint-disable-next-line no-nested-ternary
        !isHome ? collapsed ? '-wa-help-center__header__collapsed' : '-wa-help-center__header' : null}`}
        >
            <TaskBar title="Help center" isHome={isHome} collapsed={collapsed} />
            {
                isHome ?
                    (<HomeSplash title={title} description={description} />)
                    :
                    (<Navigation collapsed={collapsed} />)
            }
        </Card.Header>
    );

Header.propTypes =
  {
      title: PropTypes.string,
      description: PropTypes.string,
      isHome: PropTypes.bool,
      collapsed: PropTypes.bool,
  };

Header.defaultProps =
  {
      title: '',
      description: '',
      isHome: true,
      collapsed: false,
  };

export default Header;