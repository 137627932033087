import actionTypes from '../constants/actionTypes';
import initialState from './initialState';
import { getHasTwilioAccount } from './authReducer';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function settingsReducer(state = initialState.settings, action) {
    switch (action.type) {
        case actionTypes.Settings.UPDATE_EMAIL.start:
            return { ...state, updatingEmail: true };

        case actionTypes.Settings.UPDATE_EMAIL.success:
            return { ...state, updatingEmail: false, updateEmailError: null };

        case actionTypes.Settings.UPDATE_EMAIL.failure:
            return { ...state, updatingEmail: false, updateEmailError: action.error };

        case actionTypes.Settings.UPDATE_PASSWORD.start:
            return { ...state, updatingPassword: true };

        case actionTypes.Settings.UPDATE_PASSWORD.success:
            return { ...state, updatingPassword: false, updatePasswordError: null };

        case actionTypes.Settings.UPDATE_PASSWORD.failure:
            return { ...state, updatingPassword: false, updatePasswordError: action.error };

        case actionTypes.Settings.FETCH_WA_FIELDS.start:
            return { ...state, isFetchingFields: true };

        case actionTypes.Settings.FETCH_WA_FIELDS.success:
            return {
                ...state,
                isFetchingFields: false,
                fetchWaFieldsError: null,
                waFields: action.waFields,
            };

        case actionTypes.Settings.FETCH_WA_FIELDS.failure:
            return {
                ...state,
                isFetchingFields: false,
                fetchWaFieldsError: action.error,
                waFields: null,
            };

        case actionTypes.Settings.FETCH_INTEGRATION_OPTIONS.start:
            return { ...state, isFetchingIntegrationOptions: true };

        case actionTypes.Settings.FETCH_INTEGRATION_OPTIONS.success:
            return {
                ...state,
                isFetchingIntegrationOptions: false,
                fetchIntegrationOptionsError: null,
                integrationOptions: action.integrationOptions,
            };

        case actionTypes.Settings.FETCH_INTEGRATION_OPTIONS.failure:
            return {
                ...state,
                isFetchingIntegrationOptions: false,
                fetchIntegrationOptionsError: action.error,
                integrationOptions: null,
            };

        case actionTypes.Settings.UPDATE_INTEGRATION_OPTIONS.start:
            return { ...state, isUpdatingIntegrationOptions: true };

        case actionTypes.Settings.UPDATE_INTEGRATION_OPTIONS.success:
            return {
                ...state,
                isUpdatingIntegrationOptions: false,
                setIntegrationOptionsError: null,
                integrationOptions: action.integrationOptions,
            };

        case actionTypes.Settings.UPDATE_INTEGRATION_OPTIONS.failure:
            return { ...state, isUpdatingIntegrationOptions: false, setIntegrationOptionsError: action.error };

        case actionTypes.Settings.FETCH_APIKEY.start:
            return { ...state, fetchingApiKey: true };

        case actionTypes.Settings.FETCH_APIKEY.success:
            return {
                ...state,
                fetchingApiKey: false,
                apiKey: action.apiKey,
                apiKeyStatus: action.apiKeyStatus,
                fetchApiKeyError: null,
            };

        case actionTypes.Settings.FETCH_APIKEY.failure:
            return { ...state, fetchingApiKey: false, fetchApiKeyError: action.error };

        case actionTypes.Settings.UPDATE_APIKEY.start:
            return { ...state, updatingApiKey: true };

        case actionTypes.Settings.UPDATE_APIKEY.success:
            return { ...state, updatingApiKey: false, updateApiKeyError: null };

        case actionTypes.Settings.UPDATE_APIKEY.failure:
            return { ...state, updatingApiKey: false, updateApiKeyError: action.error };

        case actionTypes.Settings.FETCH_TWILIO_SUBSCRIPTION.start:
            return { ...state, twilioSubscription: { loading: true } };

        case actionTypes.Settings.FETCH_TWILIO_SUBSCRIPTION.failure:
            return { ...state, twilioSubscriptionFetchError: true, twilioSubscription: { error: action.error } };

        case actionTypes.Settings.FETCH_TWILIO_SUBSCRIPTION.success:
        case actionTypes.Settings.REFETCH_TWILIO_SUBSCRIPTION.success:
            return { ...state, twilioSubscriptionFetchError: false, twilioSubscription: { ...action.payload } };

        case actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.start:
            return {
                ...state, twilioSubscriptionSaving: true,
                twilioSubscriptionSaveError: null,
                twilioSubscriptionSaveSuccess: false,
            };

        case actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.failure:
            return {
                ...state,
                twilioSubscriptionSaving: false,
                twilioSubscriptionSaveError: action.error,
                twilioSubscriptionSaveSuccess: false };

        case actionTypes.Settings.SAVE_TWILIO_SUBSCRIPTION.success:
            return {
                ...state,
                twilioSubscriptionSaving: false,
                twilioSubscriptionSaveError: null,
                twilioSubscriptionSaveSuccess: true,
            };

        case actionTypes.Settings.FETCH_TWILIO_SETTINGS.start:
            return { ...state, twilioSettings: { ...state.twilioSettings, isFetching: true } };

        case actionTypes.Settings.FETCH_TWILIO_SETTINGS.failure:
            return {
                ...state,
                twilioSettingsFetchError: true,
                twilioSettings: { ...state.twilioSettings, error: action.error, isFetching: false },
            };

        case actionTypes.Settings.FETCH_TWILIO_SETTINGS.success:
            return {
                ...state,
                twilioSettingsFetchError: false,
                twilioSettings: { ...state.twilioSettings, ...action.payload, isFetching: false },
            };

        case actionTypes.Settings.SAVE_TWILIO_SETTINGS.start:
            return { ...state, twilioSettings: { ...state.twilioSettings, isSaving: true } };

        case actionTypes.Settings.SAVE_TWILIO_SETTINGS.failure:
            return {
                ...state,
                twilioSettingsSaveError: true,
                twilioSettings: { ...state.twilioSettings, error: action.error, isSaving: false },
            };

        case actionTypes.Settings.SAVE_TWILIO_SETTINGS.success:
            return {
                ...state,
                twilioSettingsSaveError: false,
                twilioSettings: { ...state.twilioSettings, isSaving: false } };

        case actionTypes.Settings.FETCH_CONSENT_PAGES.start:
            return {
                ...state,
                isFetchingConsentPages: true,
            };

        case actionTypes.Settings.FETCH_CONSENT_PAGES.failure:
            return {
                ...state,
                fetchConsentPagesError: true,
                isFetchingConsentPages: false,
                consentPages: action.error,
            };

        case actionTypes.Settings.FETCH_CONSENT_PAGES.success:
            return {
                ...state,
                fetchConsentPagesError: false,
                isFetchingConsentPages: false,
                consentPages: action.consentPages,
            };

        case actionTypes.Settings.FETCH_EIN.start:
            return {
                ...state,
                isFetchingEin: true,
                einFetched: false,
            };

        case actionTypes.Settings.FETCH_EIN.failure:
            return {
                ...state,
                fetchEinError: true,
                isFetchingEin: false,
                einFetched: true,
                ein: action.error,
            };

        case actionTypes.Settings.FETCH_EIN.success:
            return {
                ...state,
                fetchEinError: false,
                isFetchingEin: false,
                einFetched: true,
                ein: action.ein,
            };

        default:
            return state;
    }
}

// selectors

export const getSettings = (state) => state.settings;

export const getTwilioSubscription = (state) => state.settings.twilioSubscription;

export const getTwilioSubscriptionFromAccount = (state) => state.auth.me.account.twilioSubscription;

export const getIsTwilioSubscriptionSuccessfullyLoaded = (state) => {
    if (!getHasTwilioAccount(state)) {
        return true;
    }

    const twilioSubscription = getTwilioSubscriptionFromAccount(state);

    return !!twilioSubscription && !twilioSubscription.loading && !twilioSubscription.error;
};

export const getIntegrationOptions = (state) => state.settings.integrationOptions;

export const getEin = (state) => state.settings.ein;

export const getIntegrationOptionsSuccessfullyLoaded = (state) => {
    if (!getIntegrationOptions(state)) {
        return false;
    }

    const integrationOptions = getIntegrationOptions(state);

    return !!integrationOptions
        && !integrationOptions.isFetchingIntegrationOptions && !integrationOptions.fetchIntegrationOptionsError;
};

export const getTermsOfUseAccepted = (state) => {
    const { accepted } = getTwilioSubscription(state) || {};

    return !!accepted;
};

export const getTwilioSubscriptionSaving = (state) => !!state.settings.twilioSubscriptionSaving;

export const getTwilioSubscriptionSaveState = (state) => !!state.settings.twilioSubscriptionSaveSuccess;

export const getTwilioSubscriptionSaveError = (state) => state.settings.twilioSubscriptionSaveError || null;

export const getTwilioSubscriptionFetchError = (state) => state.settings.twilioSubscriptionFetchError || null;

export const getTwilioSettingsAcceptIncoming = (state) => {
    const { settings } = state;
    const { twilioSettings } = settings || {};
    const { acceptIncoming } = twilioSettings || {};

    return acceptIncoming || false;
};

export const getTwilioSettingsSaveState = (state) => {
    const { settings } = state;
    const { twilioSettings } = settings || {};
    const { isSaving } = twilioSettings || {};

    return isSaving || false;
};

export const getTwilioSettingsSaveError = (state) => {
    const { settings } = state;
    const { twilioSettings } = settings || {};
    const { error } = twilioSettings || {};

    return error || false;
};

export const getHasIntegrationOptions = (state) => {
    const { settings } = state;
    const { integrationOptions } = settings || null;

    return !!integrationOptions;
};

export const getHasConsentField = (state) => {
    const { settings } = state;
    const { integrationOptions } = settings || {};
    const { consentCheckMode, consentField } = integrationOptions || {};

    return consentCheckMode === 'checkField' && !!consentField;
};
