import { useCallback } from 'react';
import { client as elevioClient } from 'elevio';
import settings from '../../constants/settings';
import { useSectionContext } from '../../../../entities/help-widget/context/section-context-provider';
import sectionKeys from '../../../../constants/section-keys';

// eslint-disable-next-line import/no-anonymous-default-export
export const helpLinkRegex = /gethelp.wildapricot.com\/(en\/)?articles\/\d*/;

// eslint-disable-next-line import/no-anonymous-default-export
export default (openSectionArticle, isTrial) => {
    const { setSection, openWidget } = useSectionContext();
    const smartGroup = isTrial ? 'Trial' : 'Paid';

    const onHelpLinkClick = (event) => {
        if (helpLinkRegex.test(event.target.href)) {
            event.preventDefault();

            const articleId = parseInt(event.target.href.replace(/\D+/g, ''), 10);

            if (!articleId) return;

            openSectionArticle(articleId);
        }
    };

    const elevioInit = useCallback(
        () => {
            elevioClient.load(settings.ACCOUNT_ID).then(() => {
                elevioClient.setSettings(settings.CONFIG);
                elevioClient.setUser({ groups: [smartGroup] });
                elevioClient.setTranslations(settings.TRANSLATIONS);

                // Create link to jsSourceCombiner elevioWidget article parser
                // *\JsLibrariesCombiner\JsLibrariesSource\Scripts\Admin\AdminPanel\ElevioWidget
                window.openHelpCenterArticle = openSectionArticle;
                window.openHelpCenterHome = () => {
                    setSection(sectionKeys.SECTION_HOME);
                    openWidget();
                };

                window.addEventListener('click', onHelpLinkClick);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [smartGroup],
    );

    return [elevioInit];
};
