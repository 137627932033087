import React from 'react';
import PropTypes from 'prop-types';

import BookACall from './navigation-sections/book-a-call';
import Chat from './navigation-sections/chat';
import Email from './navigation-sections/email';
import Elevio from './navigation-sections/elevio';

import { useAssociationContext } from
    '../../../../../../../../entities/help-widget/context/association-context-provider';
import { useChatContext } from '../../../../../../../../entities/help-widget/context/chat-context-provider';

import openAppointletWidget
// eslint-disable-next-line max-len
    from '../../../../../sections/components/book-a-call/src/components/book-a-call-button/src/components/appointlet-button/src/open-appointlet-widget';

import './navigation.css';

const Navigation = ({ collapsed }) => {
    const { isTrial } = useAssociationContext();
    const { online } = useChatContext();

    return (
        <div className={`-wa-help-center__navigation d-flex justify-content-between position-relative p-4
    ${collapsed && '-wa-help-center__nav-button-container__collapsed'}`}
        >
            <div className="-wa-help-center__navigation-button-container ml-3">
                <Elevio collapsed={collapsed} />
            </div>
            {
                !isTrial &&
        (
            (
                <div className="-wa-help-center__navigation-button-container">
                    <Chat collapsed={collapsed} />
                </div>
            )
        )
            }
            {isTrial &&
        (
            <div className="-wa-help-center__navigation-button-container">
                <BookACall
                    collapsed={collapsed}
                    data-test-id="wa-help-center__nav-button__book-a-call"
                    handleClick={() => openAppointletWidget()}
                />
            </div>
        )}
            {
                online && isTrial ?
                    (
                        <div className="-wa-help-center__navigation-button-container">
                            <Chat collapsed={collapsed} />
                        </div>
                    )
                    :
                    (
                        <div className="-wa-help-center__navigation-button-container">
                            <Email collapsed={collapsed} />
                        </div>
                    )
            }
        </div>
    );
};

export default Navigation;

Navigation.propTypes =
  {
      collapsed: PropTypes.bool,
  };

Navigation.defaultProps =
  {
      collapsed: false,
  };
