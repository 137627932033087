import { push } from 'connected-react-router';
import qs from 'query-string';
import actionTypes from '../constants/actionTypes';
import messagesService from '../apiServices/messagesService';

export const fetchCampaignMessages = (campaignId, status) => async (dispatch) => {
    dispatch({
        type: actionTypes.Messages.FETCH_CAMPAIGN_MESSAGES.start,
        campaignId,
    });

    const result = await messagesService.getCampaignMessages(campaignId, status);

    if (result.error) {
        dispatch({
            type: actionTypes.Messages.FETCH_CAMPAIGN_MESSAGES.failure,
            error: result.error,
            campaignId,
        });
    } else {
        dispatch({
            type: actionTypes.Messages.FETCH_CAMPAIGN_MESSAGES.success,
            messages: result.messages,
            campaignId,
        });
    }
};

export const fetchRecipientMessages = (recipientType, recipientId, includePhone) => async (dispatch) => {
    dispatch({
        type: actionTypes.Messages.FETCH_RECIPIENTS_MESSAGES.start,
        recipientType,
        recipientId,
    });

    const result = await messagesService.getRecipientMessages(recipientType, recipientId, includePhone);

    if (result.error) {
        dispatch({
            type: actionTypes.Messages.FETCH_RECIPIENTS_MESSAGES.failure,
            error: result.error,
            recipientType,
            recipientId,
        });
    } else {
        dispatch({
            type: actionTypes.Messages.FETCH_RECIPIENTS_MESSAGES.success,
            messages: result.messages,
            recipientType,
            recipientId,
        });
    }
};

export const fetchLatestMessages = (maxMessages) => async (dispatch) => {
    dispatch({
        type: actionTypes.Messages.FETCH_LATEST_INCOMMING_MESSAGES.start,
    });

    const result = await messagesService.getLatestResponses(maxMessages || 100);

    if (result.error) {
        dispatch({
            type: actionTypes.Messages.FETCH_LATEST_INCOMMING_MESSAGES.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Messages.FETCH_LATEST_INCOMMING_MESSAGES.success,
            messages: result.messages,
        });
    }
};

export const sendSingleMessage = (phone, content, recipient, eventId, emailId) => async (dispatch) => {
    dispatch({
        type: actionTypes.Messages.SEND_MESSAGE.start,
        phone,
        content,
        recipientId: (recipient || {}).id,
    });

    const result = await messagesService.sendSingleMessage(phone, content, recipient, eventId, emailId);

    if (result.error) {
        dispatch({
            type: actionTypes.Messages.SEND_MESSAGE.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Messages.SEND_MESSAGE.success,
        });
    }
};

export const navigateToConversations = (section, params) => (dispatch) => {
    const query = { section, ...params || {} };

    dispatch(push(`/conversations?${qs.stringify(query)}`));
};

export const openConversation = (recipientType, recipientId) => (dispatch) => {
    dispatch(push(`/conversation/${recipientType}/${recipientId}`));
};
