import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as billingActionCreators from '../../actions/billingActions';
import SubscriptionForm from './SubscriptionForm';
import SubscriptionDetails from './SubscriptionDetails';
import NotAuthorized from '../NotAuthorized';
import { getHasTwilioAccount, getIsBillingPlanProhibited } from '../../reducers/authReducer';

class StartSubscriptionPage extends React.Component {
    constructor(props) {
        super(props);
        this.returnBack = this.returnBack.bind(this);
        this.confirmSubscription = this.confirmSubscription.bind(this);
    }

    componentDidMount() {
        if (this.props.hasSubscription) {
            this.returnBack();
        }
    }

    returnBack() {
        window.location = this.props.source || '/';
    }

    async confirmSubscription(stripe, cardElement, billingDetails) {
        await this.props.createSubscription(stripe, cardElement, billingDetails, () => {
            this.returnBack();
        });
    }

    render() {
        if ( !this.props.hasTwilioAccount && this.props.isBillingPlanProhibited) {
            return (<NotAuthorized />);
        }

        return (
            <div className="mainContent">
                <h1 className="fixedHeader">Create a subscription</h1>
                <div className="mt-3" style={{ maxWidth: 900 }}>
                    <SubscriptionForm
                        onCancel={this.returnBack}
                        cancelButtonTitle="Cancel"
                        subscribeButtonTitle="Subscribe"
                        onProcessCardInfo={this.confirmSubscription}
                        explanation={SubscriptionDetails}
                    />
                </div>

            </div>
        );
    }
}

StartSubscriptionPage.propTypes = {

    source: propTypes.string.isRequired,

    createSubscription: propTypes.func.isRequired,
    hasSubscription: propTypes.bool.isRequired,
    hasTwilioAccount: propTypes.bool.isRequired,
    isBillingPlanProhibited: propTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        hasSubscription: state.auth.me.account.hasSubscription,
        hasTwilioAccount: getHasTwilioAccount(state),
        isBillingPlanProhibited: getIsBillingPlanProhibited(state),
    };
}

function mapDispatchToProps(dispatch) {
    const billingActions = bindActionCreators(billingActionCreators, dispatch);
    return {
        createSubscription: billingActions.createSubscription,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StartSubscriptionPage);
