import React from 'react';
import sectionKeys from '../../../../constants/section-keys';

import Home from './home';
import Tutorials from './tutorials';
import Email from './email';
import ElevioArticles from './elevio-articles';

import l10n from '../../../../l10n/en';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    [sectionKeys.SECTION_HOME]: {
        key: sectionKeys.SECTION_HOME,
        title: l10n.NAVIGATION.HOME.HEADER_TITLE,
        component: React.memo(Home),
    },
    [sectionKeys.SECTION_TUTORIALS]: {
        key: sectionKeys.SECTION_TUTORIALS,
        title: l10n.NAVIGATION.TUTORIAL.HEADER_TITLE,
        component: Tutorials,
    },
    [sectionKeys.SECTION_EMAIL]: {
        key: sectionKeys.SECTION_EMAIL,
        title: l10n.NAVIGATION.EMAIL.HEADER_TITLE,
        component: React.memo(Email),
    },
    [sectionKeys.SECTION_ELEVIO]: {
        key: sectionKeys.SECTION_ELEVIO,
        title: l10n.NAVIGATION.ARTICLES.HEADER_TITLE,
        component: React.memo(ElevioArticles),
    },
};
