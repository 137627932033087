import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const TwilioSubscriptionDescription = ( { isSettings, rejected }) =>
    (
        <>
            <p className="pb-3">
                In order to comply with government and industry regulations concerning text messages,
                we must verify the identity of all organizations using our text messaging service.
                Please provide the official Tax ID/EIN/BN and legal business name for your organization,
                as well as the name and contact information of the person responsible for managing text messages.
                WildApricot’s text messaging is currently not available for accounts without a Tax ID/EIN/BN.
                <br/><br/>
                Your organization can begin sending text messages after the information you provide has been verified.
                Verification may take several days.
            </p>
            {rejected && isSettings &&
            (<Alert variant="danger">
                <div className="d-flex align-items-center">
                    <div>
                        <FontAwesomeIcon
                            size="2x"
                            icon={faTriangleExclamation}
                            className="pr-2 mr-4"
                        />
                    </div>
                    <span>
                        {
                            `Your text messaging application was rejected because the
                            business information you provided did not match official
                            Tax ID/EIN/BN records. Please edit your application and resubmit it for review.`
                        }
                    </span>
                </div>
            </Alert>)}
            {!isSettings && (<Alert variant="warning">
                <div className="d-flex align-items-center">
                    <div>
                        <FontAwesomeIcon
                            size="2x"
                            icon={faTriangleExclamation}
                            className="pr-2 mr-4"
                        />
                    </div>
                    <span>
                        <span className="font-weight-bold"> IMPORTANT: </span>
                        {
                            `Please ensure that the business information you
                            provide below is accurate and matches the official Tax ID/EIN/BN records.`
                        }
                    </span>
                </div>
            </Alert>)
            }
        </>
    );

export default TwilioSubscriptionDescription;
