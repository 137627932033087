import jwt from 'jsonwebtoken';

const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDs+aaaWephZ9HQJ7jBJcLdn5Te
dDtsSZ+vbmGW4gv4BkjYSuzBvKOb7U0l3M4IX6byLiedXoqs0U1Wh1aVCregnBJp
DKcACLK8wAn4VkmH84VBr+pk1DIeAi7aAv4PSx0RXlNCfCZ3syzVNe+F1l/fZYfo
ahXh8JePKbLXZ78t9wIDAQAB
-----END PUBLIC KEY-----`;

const verifyToken = (token) => {
    try {
        const options = {
            issuer: 'TNS API',
            audience: 'https://tns.wildapricot.com',
            algorithm: 'RS256',
        };

        const result = jwt.verify(token, publicKey, options);

        return { result, error: null };
    } catch (e) {
        return { result: null, error: e };
    }
};

const methods = { verifyToken };

export default methods;
