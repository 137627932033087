import apiClient from './apiClient';
import apiUrls from './apiUrls';

const listCampaigns = () => apiClient.executeGet(
    apiUrls.campaigns.list,
    (responseContent) => ({ campaigns: responseContent }),
);

// returns { id }
const saveCampaign = (campaign) => apiClient.executeRequest({
    url: apiUrls.campaigns.create,
    method: 'POST',
    body: campaign,
});

const updateCampaign = (campaign) => apiClient.executeRequest(
    {
        url: apiUrls.campaigns.getDetails(campaign.id),
        method: 'PATCH',
        body: campaign,
    },
    // return data of the same shape as saveCampaign, to process results in the same way
    () => ({ id: campaign.id }),
);

const stopCampaign = (campaignId) => apiClient.executeRequest({
    url: apiUrls.campaigns.stop(campaignId),
    method: 'POST',
});

const fetchCampaignDetails = (campaignId) => apiClient.executeGet(
    apiUrls.campaigns.getDetails(campaignId),
    (responseContent) => ({ campaign: responseContent }),
);

const fetchCampaignSuggestions = () => apiClient.executeGet(
    apiUrls.campaignSuggestions,
    (responseContent) => ({ suggestions: responseContent }),
);

const methods = {
    listCampaigns,
    saveCampaign,
    updateCampaign,
    stopCampaign,
    fetchCampaignDetails,
    fetchCampaignSuggestions,
};

export default methods;
