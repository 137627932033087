import { useCallback, useState } from 'react';
import { saveTwilioSettings } from '../../../../../actions/settingsActions';
import { useDispatch } from 'react-redux';

export const useToggleTwilioSettings = (toggleState) => {
    const dispatch = useDispatch();
    const [toggled, setToggle] = useState(toggleState);

    const toggleTwilioSettings = useCallback( () => {
        dispatch(saveTwilioSettings({ twilioSettings: { acceptIncoming: !toggled } }, setToggle(!toggled) ));
    }, [dispatch, toggled]);

    return [toggled, toggleTwilioSettings];
};
