import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Spinner } from 'react-bootstrap';
import * as actionCreators from '../../actions/billingActions';
import BillingMonthCard from './BillingMonthCard';
import * as types from '../../types';
import AuthorizationRequired from '../AuthorizationRequired';

class BillingDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.renderInvoiceCard = this.renderInvoiceCard.bind(this);
        this.showPricing = this.showPricing.bind(this);
    }

    componentDidMount() {
        this.props.actions.fetchSubscriptionStatus();
        this.props.actions.fetchInvoices();
    }

    // eslint-disable-next-line class-methods-use-this
    showPricing() {
        // freshdeskHelper.openArticle(externalResources.freshdesk.pricingArticleId);
    }

    renderInvoiceCard(invoice, idx) {
        return (
            <div key={idx} className="mt-3 mb-4 ">
                <BillingMonthCard invoice={invoice} subscriptionTimestamp={this.props.subscriptionTimestamp} />
            </div>
        );
    }

    renderContent() {
        if (this.props.isFetching) {
            return (
                <div>
                    <p>Loading data, please wait</p>
                    <Spinner animation="border" variant="primary" />
                </div>
            );
        }

        if (this.props.error) {
            return (
                <Alert variant="danger">
                    <Alert.Heading>Failed to load bills</Alert.Heading>
                    <p>{this.props.error}</p>
                </Alert>
            );
        }

        if (!this.props.invoices.length) {
            return (
                <div className=" mt-4">
                    <Alert variant="info" className="pb-3">
                        <Alert.Heading>
                            No bills yet.
                        </Alert.Heading>

                        This page will show your monthly bills with detailed usage statistics.
                    </Alert>
                </div>
            );
        }

        return (
            <div hidden={this.props.isFetching && this.props.invoices}>
                {this.props.invoices.map((invoice, idx) => this.renderInvoiceCard(invoice, idx))}
            </div>
        );
    }

    render() {
        return (
            <div className="mainContent">
                <AuthorizationRequired />
                <h1 className="fixedHeader">Bills</h1>
                {this.renderContent()}
            </div>
        );
    }
}

BillingDashboard.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    isFetching: propTypes.bool.isRequired,
    // eslint-disable-next-line react/require-default-props
    invoices: propTypes.arrayOf(types.invoice),
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    error: propTypes.object,
    // eslint-disable-next-line react/require-default-props
    subscriptionTimestamp: propTypes.number,
};

function mapStateToProps(state) {
    const invoices = (state.billing.invoices || []).slice();
    invoices.sort((i1, i2) => (i1.period < i2.period ? 1 : -1));

    return {
        invoices,
        isFetching: state.billing.isFetching || false,
        error: state.billing.error,
        subscriptionTimestamp: state.billing.subscriptionTimestamp,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BillingDashboard);
