import { useCallback, useState } from 'react';
import sectionKeys from '../../../constants/section-keys';

export const MAX_HISTORY = 20;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [stack, setStack] = useState([{ sectionKey: sectionKeys.SECTION_HOME, articleId: null }]);
    const currentState = stack[stack?.length - 1];

    const flushState = useCallback(() => setStack(
        [{ sectionKey: sectionKeys.SECTION_HOME, articleId: null }]), [setStack]);

    const pushState = useCallback(
        (key, article) => {
            setStack(currentStack => [...currentStack, { sectionKey: key, articleId: article }]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const popState = () => {
        if (stack?.length === 1) {
            return;
        }
        setStack(() => stack?.slice(0, -1));
    };

    return [currentState, pushState, popState, flushState];
};
