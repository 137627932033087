import React from 'react';
import propTypes from 'prop-types';
import {
    OverlayTrigger, Popover, Row, Col, Button,
} from 'react-bootstrap';
import { smsCalculator } from 'mp-utils';
import {
    Chart,
    ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import MessagesListDialog from './BuildingBlocks/MessagesListDialog';
import * as types from '../../types';
import campaignUtils from './campaignUtils';

Chart.register(
    ArcElement,
);

function CampaignDeliveryChart({ dataSource }) {
    const activeItems = dataSource.filter((item) => item.value);
    const colors = activeItems.map((item) => item.color);
    const data = {
        labels: activeItems.map((item) => item.title),
        datasets: [{
            data: activeItems.map((item) => item.value),
            backgroundColor: colors,
            hoverBackgroundColor: colors,
        }],
    };

    return (<Doughnut data={data} options={{ legend: { display: false } }} />);
}

CampaignDeliveryChart.propTypes = {
    dataSource: propTypes.arrayOf(propTypes.shape({
        title: propTypes.isRequired,
        value: propTypes.number,
    })).isRequired,
};

function StatRow({ title, value, color }) {
    return (
        <div className="mt-3" hidden={!(value)}>
            <span className="inlineLabel">
                <span hidden={!color} className="mr-2">
                    <FontAwesomeIcon icon={faCircle} size="lg" style={{ color }} />
                </span>
                {title}
            </span>

            <span className="mimicFormControlInlineReadonly">{value}</span>
        </div>
    );
}

StatRow.propTypes = {
    title: propTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    value: propTypes.oneOfType([propTypes.number, propTypes.string]),
    // eslint-disable-next-line react/require-default-props
    color: propTypes.string,
};

function CampaignStatistics({ campaign, onNavigateToCampaignResponses }) {
    const hasStatistics = !!(
        campaign.statistics && campaign.statistics.delivery && Object.keys(campaign.statistics.delivery).length
    );

    if (!hasStatistics) {
        return null;
    }

    const messageStat = smsCalculator.getMessageStat(campaign.content);
    const stat = campaign.statistics || { delivery: {}, price: {} };
    const budget = (stat.price.estimatedTotalPrice || 0).toFixed(2);

    const totalRecipients = Object.values(stat.delivery).reduce(
        (acc, element) => acc + element,
        0,
    );

    const budgetExplanation = (
        <Popover>
            <Popover.Title as="h3">Budget explanation</Popover.Title>
            <Popover.Content>
                <p>
                    Number of messages sent:
                    {' '}
                    {stat.delivery.delivered || 0 + stat.delivery.undelivered || 0}
                    <br />
                    Message price:
                    {' '}
                    <strong>
                        $
                        {stat.price.pricePerRecipient}
                    </strong>
                    {' '}
                    USD
                    <br />
                    <span hidden={messageStat.numberOfBlocks === 1}>
                        Message price is
                        {' '}
                        {messageStat.numberOfBlocks}
                        {' '}
                        times higher than usual, because message is too long to transmit in a single SMS.
                        See help for more information.
                    </span>
                </p>
            </Popover.Content>
        </Popover>
    );
    const statDataSource = campaignUtils.buildCampaignStatDataSource(stat);

    return (
        <div className="campaignInfoContainer">

            <Row>
                <Col className="pr-1 mb-4" xs={12} md={5}>
                    <div className="statValuesBlock">
                        <div>
                            <OverlayTrigger placement="top" overlay={budgetExplanation}>
                                <span className="d-inline-block">
                                    <StatRow title="Budget" value={`$${budget}`} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <StatRow title="Total recipients" value={totalRecipients} />
                        {statDataSource.map((item, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <StatRow key={idx} title={item.title} value={item.value} color={item.color} />
                        ))}
                        <div className="mt-3">
                            <MessagesListDialog campaignId={campaign.id} />
                        </div>
                        <div hidden={!campaign.numberOfResponses} className="mt-3">
                            <Button block variant="outline-success" onClick={onNavigateToCampaignResponses}>
                                Show
                                {' '}
                                {campaign.numberOfResponses}
                                {' '}
                                response
                                <span hidden={campaign.numberOfResponses === 1}>s</span>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={7} className="pt-5">
                    <CampaignDeliveryChart dataSource={statDataSource} />
                </Col>
            </Row>
        </div>
    );
}

CampaignStatistics.propTypes = {
    // eslint-disable-next-line react/require-default-props
    campaign: types.campaign,
    onNavigateToCampaignResponses: propTypes.func.isRequired,
};

export default CampaignStatistics;
