import { push } from 'connected-react-router';
import actionTypes from '../constants/actionTypes';
import billingService from '../apiServices/billingService';

export const fetchInvoices = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Billing.FETCH_INVOICES.start,
    });

    const result = await billingService.fetchInvoices();

    if (result.error) {
        dispatch({
            type: actionTypes.Billing.FETCH_INVOICES.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Billing.FETCH_INVOICES.success,
            invoices: result.invoices,
        });
    }
};

export const fetchStripeConstants = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Billing.FETCH_STRIPE_CONSTANTS.start,
    });

    const result = await billingService.fetchStripeConstants();

    if (result.error) {
        dispatch({
            type: actionTypes.Billing.FETCH_STRIPE_CONSTANTS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Billing.FETCH_STRIPE_CONSTANTS.success,
            stripePublishableKey: result.publishableKey,
        });
    }
};

export const handleSubscription = (subscription, stripe, onComplete, isFromWizard) => async (dispatch) => {
    // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
    const { latest_invoice } = subscription;
    // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
    const { payment_intent } = latest_invoice;

    // eslint-disable-next-line camelcase
    if (payment_intent) {
        // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
        const { client_secret, status } = payment_intent;

        if (status === 'requires_action' || status === 'requires_payment_method') {
            const result = stripe.confirmCardPayment(client_secret);

            if (result.error) {
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
                dispatch({
                    type: actionTypes.Billing.START_SUBSCRIPTION.failure,
                    error: result.error,
                });

                return;
            }
        }
    }

    dispatch({
        type: actionTypes.Billing.START_SUBSCRIPTION.success,
        status: 'valid',
    });
    if (isFromWizard) {
        dispatch({
            type: actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.success,
        });
    }

    const onCompleteResult = onComplete();

    if (typeof onCompleteResult?.type === 'string') {
        dispatch(onCompleteResult);
    }
};

export const createSubscription = (stripe, cardElement, billingDetails, onComplete) => async (dispatch) => {
    dispatch({
        type: actionTypes.Billing.START_SUBSCRIPTION.start,
    });

    try {
        const stripeResult = await stripe.createPaymentMethod('card', cardElement, {
            billing_details: billingDetails,
        });

        if (stripeResult.error) {
            dispatch({
                type: actionTypes.Billing.START_SUBSCRIPTION.failure,
                error: stripeResult.error.message,
            });

            return;
        }

        const result = await billingService.createSubscription(stripeResult.paymentMethod.id, billingDetails);

        if (result.error) {
            dispatch({
                type: actionTypes.Billing.START_SUBSCRIPTION.failure,
                error: result.error,
            });
        } else {
            dispatch(handleSubscription(result.subscription, stripe, onComplete));
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const updateBillingMethod = (stripe, cardElement, billingDetails, onComplete) => async (dispatch) => {
    dispatch({
        type: actionTypes.Billing.UPDATE_PAYMENT_METHOD.start,
    });

    try {
        const stripeResult = await stripe.createPaymentMethod('card', cardElement, {
            billing_details: billingDetails,
        });

        if (stripeResult.error) {
            dispatch({
                type: actionTypes.Billing.UPDATE_PAYMENT_METHOD.failure,
                error: stripeResult.error.message,
            });

            return;
        }

        const result = await billingService.updatePaymentMethod(stripeResult.paymentMethod.id, billingDetails);

        if (result.error) {
            dispatch({
                type: actionTypes.Billing.UPDATE_PAYMENT_METHOD.failure,
                error: result.error,
            });
        } else {
            dispatch({
                type: actionTypes.Billing.START_SUBSCRIPTION.success,
                status: 'valid',
            });

            onComplete();
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export const fetchSubscriptionStatus = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Billing.FETCH_SUBSCRIPTION_STATUS.start,
    });

    const result = await billingService.fetchSubscriptionStatus();

    if (result.error) {
        dispatch({
            type: actionTypes.Billing.FETCH_SUBSCRIPTION_STATUS.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Billing.FETCH_SUBSCRIPTION_STATUS.success,
            status: result.status,
            card: result.card,
            subscriptionTimestamp: result.subscriptionTimestamp,
        });
    }
};

export const cancelSubscription = () => async (dispatch) => {
    dispatch({
        type: actionTypes.Billing.CANCEL_SUBSCRIPTION.start,
    });

    const result = await billingService.cancelSubscription();

    if (result.error) {
        dispatch({
            type: actionTypes.Billing.CANCEL_SUBSCRIPTION.failure,
            error: result.error,
        });
    } else {
        dispatch({
            type: actionTypes.Billing.CANCEL_SUBSCRIPTION.success,
            status: result.status,
        });

        dispatch(fetchSubscriptionStatus());
    }
};

export const navigateToStartSubscription = (redirectUrl) => async (dispatch) => {
    dispatch(push(`/settings/subscription/start?source=${encodeURIComponent(redirectUrl)}`));
};

export const navigateToUpdateSubscription = (redirectUrl) => async (dispatch) => {
    dispatch(push(`/settings/subscription/update?source=${encodeURIComponent(redirectUrl)}`));
};

const api = {
    fetchInvoices,
    fetchStripeConstants,
    createSubscription,
    fetchSubscriptionStatus,
    navigateToStartSubscription,
    navigateToUpdateSubscription,
};

export default api;
