import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function recipientGroupCountsReducer(state = initialState.recipients.groupCount, action) {
    switch (action.type) {
        case actionTypes.Recipients.FETCH_RECIPIENTS_COUNT.start: {
            return {
                ...state,
                [action.recipientsListId]: {
                    isFetching: true,
                    error: null,
                    count: null,
                    hasCount: false,
                },
            };
        }

        case actionTypes.Recipients.FETCH_RECIPIENTS_COUNT.success: {
            return {
                ...state,
                [action.recipientsListId]: {
                    isFetching: false,
                    error: null,
                    count: action.count,
                    hasCount: true,
                },
            };
        }

        case actionTypes.Recipients.FETCH_RECIPIENTS_COUNT.failure: {
            return {
                ...state,
                [action.recipientsListId]: {
                    isFetching: false,
                    error: action.error,
                    count: null,
                    hasCount: false,
                },
            };
        }

        default:
            return state;
    }
}
