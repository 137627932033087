import React from 'react';
import { useParams } from 'react-router-dom';

// this helper binds path params to props
// it supports only a single child
const PathParamsBinder = ({ children }) => {
    const p = useParams();
    return React.cloneElement(children, p);
};

export default PathParamsBinder;
