import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import qs from 'query-string';
import * as actionCreators from '../../actions/loginActions';
import SplashScreenLoader from '../SplashScreenLoader/SplashScreenLoader';

export function AuthWAPage({
    authStatus, authFailed, actions, targetUrl,
}) {
    const { search } = useLocation();
    const watoken = new URLSearchParams(search).get('watoken');

    useEffect(() => {
        if (authStatus === 'loggedOut' && !authFailed) {
            actions.create(watoken);
        }
    }, [authStatus, authFailed, actions.create, watoken, actions]);

    if (authStatus === 'authenticated') {
        return (
            <Redirect to={targetUrl} />
        );
    }

    return (
        <SplashScreenLoader
            loading={!authFailed}
            error={authFailed}
            errorTitle="Unable to authenticate text messaging."
            errorMessage={(
                <>
                    Please try launching TNS from the WildApricot Apps menu.

                    If the problem persists, please contact our
                    {' '}
                    <a href="mailto:support@wildapricot.com">support team</a>
                    .
                </>
            )}
        />
    );
}

AuthWAPage.propTypes = {
    // eslint-disable-next-line react/require-default-props
    authStatus: propTypes.oneOf(['loggedOut', 'auth-in-progress', 'authenticated']),
    // eslint-disable-next-line react/require-default-props
    authFailed: propTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    actions: propTypes.object.isRequired,
    // eslint-disable-next-line react/require-default-props
    targetUrl: propTypes.string,
};

function mapStateToProps(state) {
    return {
        authStatus: state.auth.authStatus,
        authFailed: state.auth.authFailed,
        targetUrl: qs.parse(state.router.location.search).targetUrl || '/',
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthWAPage);
