import bookingSettings from './constants/booking-settings';

// eslint-disable-next-line import/no-anonymous-default-export
export default (user) => {
    if (typeof window.appointlet !== 'function') return;

    const email = user?.email;
    const name = user?.name;

    const originalBodyOverflowValue = (window || global).document.body.style.overflow;

    /* istanbul ignore next */
    const setOriginalBodyOverflow = () => {
        (window || global).document.body.style.overflow = originalBodyOverflowValue;
    };

    window.appointlet({
        organization: 'wildapricot',
        service: bookingSettings.trialCoachesCallbackId,
        email,
        fields: {
            name,
            subject: 'I want to discuss...',
            'phone-no': null,
            account: window.location.hostname,
        },
        query: {
            utm_source: 'Getting Started',
            utm_medium: 'Booking',
            utm_campaign: 'Engagement',
        },
    }).show().then(setOriginalBodyOverflow);
};
