const tags = {
    fullName: 'FULL_NAME',
    eventTitle: 'EVENT_TITLE',
    eventStartDate: 'EVENT_START_DATE',
    eventLocation: 'EVENT_LOCATION',
    emailSubject: 'EMAIL_SUBJECT',
    emailWebLink: 'EMAIL_WEB_LINK',
};

const getAllTags = () => [
    tags.fullName,
    tags.eventTitle,
    tags.eventStartDate,
    tags.eventLocation,
    tags.emailSubject,
    tags.emailWebLink,
];

const getTagDisplayName = (tag) => {
    switch (tag) {
        case tags.fullName: return "Recipient's full name";
        case tags.eventTitle: return 'Event title';
        case tags.eventStartDate: return 'Event start date';
        case tags.eventLocation: return 'Event location';
        case tags.emailSubject: return 'Email subject';
        case tags.emailWebLink: return 'Link to web copy of the email';
        default: return '';
    }
};

const getTagComment = (tag) => {
    switch (tag) {
        case tags.fullName: return "will be replaced with the recipient's full name";
        // eslint-disable-next-line max-len
        case tags.eventTitle: return 'will be replaced with event name if the message is event related. Otherwise, the macro will simply be removed.';
        // eslint-disable-next-line max-len
        case tags.eventStartDate: return 'will be replaced with event start date if the message is event related. Otherwise, the macro will simply be removed.';
        // eslint-disable-next-line max-len
        case tags.eventLocation: return 'will be replaced with event location if the message is event related. Otherwise, the macro will simply be removed.';
        case tags.emailSubject: return 'will be replaced with the email subject';
        case tags.emailWebLink: return 'will be replaced with a link to web copy of the email';
        default: return '';
    }
};

const canResolve = (tag, recipientGroupType) => {
    switch (tag) {
        case tags.fullName: return true;
        case tags.eventTitle:
        case tags.eventStartDate:
        case tags.eventLocation: return true; // return recipientGroupType === "EVENT_ATTENDEES";
        case tags.emailSubject:
        case tags.emailWebLink: return recipientGroupType === 'NEWSLETTER_NON_OPENERS';
        default: return false;
    }
};

module.exports = {
    ...tags,
    getTagDisplayName,
    getAllTags,
    getTagComment,
    canResolve,
};
