import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function subscriptionWizardReducer(state = initialState.subscriptionWizard, action) {
    switch (action.type) {
        case actionTypes.SubscriptionWizard.SAVE_TWILIO_FORM_DATA:
            return { ...state, ...action.payload };

        case actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.start:
            return {
                ...state,
                isCreatingSubscription: true,
            };

        case actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.success:
            return {
                ...state,
                isCreatingSubscription: false,
                wizardCompleted: true,
            };

        case actionTypes.SubscriptionWizard.SUBMIT_STRIPE_SUBSCRIPTION.failure:
            return {
                ...state,
                isCreatingSubscription: false,
                error: action.error,
            };

        default:
            return state;
    }
}

// Selectors
export const getTwilioFormData = (state) => state.subscriptionWizard.twilioSubscription;
