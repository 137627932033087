import actionTypes from '../../constants/actionTypes';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function recipientGroupsReducer(state = initialState.recipients.groups, action) {
    switch (action.type) {
        case actionTypes.Recipients.FETCH_RECIPIENTS_GROUPS.start: {
            return {
                ...state,
                [action.groupType]: {
                    isFetching: true,
                    error: null,
                    items: null,
                },
            };
        }

        case actionTypes.Recipients.FETCH_RECIPIENTS_GROUPS.success: {
            return {
                ...state,
                [action.groupType]: {
                    isFetching: false,
                    error: null,
                    items: action.groups,
                },
            };
        }

        case actionTypes.Recipients.FETCH_RECIPIENTS_GROUPS.failure: {
            return {
                ...state,
                [action.groupType]: {
                    isFetching: false,
                    error: action.error,
                    items: null,
                },
            };
        }

        default:
            return state;
    }
}
