import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import mapKeyToEinModel from './helpers/mapKeyToEinModel';

const TwilioAutoFillField = (
    {
        label,
        value,
        setValue,
        name,
        type,
        as,
        options,
        country,
        preventAutoFill,
        overwrite,
        autoFillData,
        autoComplete,
        mask,
        placeholder,
        isInvalid,
        feedbackError,
        isReadOnly,
        isSettings,
        onChange,
        handleBlur,
    }) => {
    const autoFillValue = mapKeyToEinModel(name, autoFillData) || '';
    const valueMatchesEinValue = (value?.toLowerCase() === autoFillValue?.toLowerCase());

    useEffect(() => {
        if (!preventAutoFill
            && !isSettings
            && autoFillData !== null
            && autoFillData !== undefined
            && (Object.keys(autoFillData).length > 0)
            && autoFillData !== ''
            && (overwrite || value === '')) {
            setValue(name, mapKeyToEinModel(name, autoFillData));
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [autoFillData, name, setValue]);

    return (
        <>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                disabled={isReadOnly}
                type={type}
                as={as}
                mask={mask}
                placeholder={placeholder}
                name={name}
                autoComplete={autoComplete}
                value={value}
                isInvalid={isInvalid ||
                    (!isSettings && !preventAutoFill && !valueMatchesEinValue && autoFillValue !== '')}
                onChange={onChange}
                onBlur={handleBlur}
            >
                {options?.map(([code, _name]) => (
                    <option value={code} key={`${country}-${code}`}>{_name}</option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{feedbackError}</Form.Control.Feedback>
            {
                !isSettings && !preventAutoFill && !valueMatchesEinValue && autoFillValue !== '' &&
                (<Form.Control.Feedback type="invalid">
                    The {label} entered does not match official tax records.
                </Form.Control.Feedback>)
            }
        </>);
};

export default TwilioAutoFillField;
